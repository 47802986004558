import React from "react";
import Header from "./LayoutHeaderHome";
import Footer from "./LayoutFooter";
import { Layout } from 'antd';
import { useSelector } from "react-redux";
import styles from './Layout.module.scss'
const { Content } = Layout;

interface LayoutProps {
  isHome?: boolean
};

const LayoutLaunchpad: React.FC<LayoutProps> = ({ children, isHome }) => {
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);

  return (
    <Layout data-theme={isDarkMode ? 'dark' : 'light'} className={isHome ? styles.homeAntLayout : ""}>
      <Header />
      <Content>
        {children}
      </Content>
      <Footer />
    </Layout>
  );
}

export default LayoutLaunchpad;