import { PRIVATE_SETTING_TEXT, SALES_TYPE_TEXT } from "common/constants";
import { formatBigNumber } from "common/functions";
import { IDOType } from "common/types/IDOType";
import React from "react";

interface PropsType {
  pool: Partial<IDOType>;
  renderContent: any;
}

const ContentLeft = (props: PropsType) => {
  const { pool, renderContent } = props;

  const contentLeft = [
    {
      text: "Access Type",
      data: PRIVATE_SETTING_TEXT[pool?.private_setting || 0],
    },
    { text: "Sales type", data: SALES_TYPE_TEXT[pool.sale_type || 0] },
    Number(pool.min_grdn) > 0 && {
      text: "Minimum GRDN tokens required",
      data: formatBigNumber(pool.min_grdn || 0) +  ` GRDN `,
    },
    Number(pool.minimum_delegate_staking_amount) > 0 && {
      text: "Minimum delegate staking amount required ",
      data:  formatBigNumber(pool.minimum_delegate_staking_amount || 0) +  ` ROSE ` ,
    },
  ];

  return (
    <>
      {contentLeft.map((item) => {
        if(item){
          const { text, data } = item;
        const content = [
          {
            text: text,
            data: data,
          },
        ];
        return (
          <React.Fragment key={text}>{renderContent(content)}</React.Fragment>
        );
        }

        return null;
        
      })}
    </>
  );
};

export default ContentLeft;
