import { Col } from "antd";
import BigNumber from "bignumber.js";
import { checkTokenDeposit, formatBigNumber } from "common/functions";
import { AcceptedCurrencyTypes } from "common/types/IDOType";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";

interface PropType {
  acceptedCurrency?: AcceptedCurrencyTypes;
  wallet?: string;
  status?: number;
}

const YourDeposit = memo((props: PropType) => {
  const { acceptedCurrency, wallet, status } = props;
  const { userAmount, userPSAmount } = useSelector((state) => state.ido);

  const totalYourDeposit = useMemo(() => {
    return new BigNumber(userAmount).plus(new BigNumber(userPSAmount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAmount, userPSAmount, status, wallet]);

  return (
    <>
      {status && status > 1 ? (
        <>
          <Col span={12} className="your-deposit">
            <div className="text">
              <span></span>
              <span>Your deposit</span>
            </div>
          </Col>
          <Col span={12} className="token">
            {!wallet || totalYourDeposit.lte(0) ? (
              "-"
            ) : (
              <>
                {formatBigNumber(totalYourDeposit.toString())}{" "}
                {checkTokenDeposit(acceptedCurrency || 1)}
              </>
            )}
          </Col>
        </>
      ) : null}
    </>
  );
});

export default YourDeposit;
