import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import styles from './index.module.scss';
import CurrencyInputField from "react-currency-input-field";

interface PropsType {
  form: FormInstance<any> | null;
  name: string;
  rules?: Rule[];
  decimalsLimit?: number;
  maxLength?: number;
  decimalScale?: number;
  disabled?: boolean;
  className?: string;
  placeHolder?: string;
  onChange?: (value: string) => void;
  addonAfter?: React.ReactNode;
  addonBefore?: React.ReactNode;
  style?: React.CSSProperties;
  value?: string
}

const CurrencyInput: React.FC<PropsType> = (props) => {
  const { name, form, rules, className, placeHolder, decimalsLimit = 6, decimalScale, disabled, maxLength,
    onChange, style, value } = props;
  return (
    <CurrencyInputField
      placeholder={placeHolder || "Please enter a number"}
      value={form?.getFieldValue(name) ?? value ?? ''}
      decimalsLimit={decimalsLimit}
      allowDecimals={!(decimalsLimit === 0)}
      decimalScale={decimalScale}
      groupSeparator=","
      decimalSeparator="."
      onValueChange={(value: any) => {
        form?.setFieldsValue({ [name]: value !== undefined ? value : '' });
        if (rules) form?.validateFields([name]);
        onChange?.(value);
      }}
      allowNegativeValue={false}
      className={`ant-input ${styles.input} ${className || ""} ${form?.getFieldError(name)?.[0] ? "ant-input-status-error" : ""}`}
      disabled={disabled}
      maxLength={maxLength}
      style={style}
    />
  );
}

export default CurrencyInput;
