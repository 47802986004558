import images from "../../../themes/images";
import "./join.scss";

interface JoinErrorProps {
  value?: string;
}

const JoinError: React.FC<JoinErrorProps> = (props: JoinErrorProps) => {
  const { value } = props;
  return (
    <span className="JoinAlert">
      <img src={images.icons.TransactionError} alt="TransactionReject"></img>
      {value ? value : "Join error"}
    </span>
  );
};

export default JoinError;
