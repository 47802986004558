import BigNumber from "bignumber.js";
import {
  // ABI_ALLOCATION,
  ACCEPTED_CURRENCIES_NAME,
  DECIMAL_ACCEPTED_CURRENCY,
} from "common/constants";
import { convert, formatBigNumber } from "common/functions";
import { IDOType } from "common/types/IDOType";
// import _ from "lodash";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import Web3 from "web3";
import "./styles.scss";
interface PropsType {
  pool: Partial<IDOType>;
}

const MaxDeposit: React.FC<PropsType> = (props: PropsType) => {
  const { pool } = props;
  const now = Math.floor(Date.now() / 1000);
  // const { ethereum } = window as any;
  // const [maxTotalDeposit, setMaxTotalDeposit] = useState("");
  // const web3 = new Web3(ethereum);
  // const rootState = useSelector((state) => state.wallet);
  // const { userInfo } = rootState;
  // const wallet = _.get(userInfo, "wallet_address", null);

  // useEffect(() => {
  //   const now = Math.floor(Date.now() / 1000);
  //   if (
  //     wallet &&
  //     pool.pool_address &&
  //     (Number(pool.start_time_deposit) < now ||
  //       Number(pool.start_time_public_sell) < now)
  //   ) {
  //     const getMaxTotalDeposit = async () => {
  //       try {
  //         const contract = new web3.eth.Contract(
  //           ABI_ALLOCATION,
  //           pool.pool_address
  //         );
  //         const maxTotalDeposit = await contract.methods
  //           .stakeTokenSupply()
  //           .call();
  //         setMaxTotalDeposit(maxTotalDeposit);
  //       } catch (error) {
  //         console.log({ error });
  //       }
  //     };
  //     getMaxTotalDeposit();
  //     const interval = setInterval(() => getMaxTotalDeposit(), 5000);
  //     return () => clearInterval(interval);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [wallet, pool.pool_address]);

  const TotalDeposit = new BigNumber(pool.total_deposit || "0").div(
    new BigNumber(10).pow(
      DECIMAL_ACCEPTED_CURRENCY[pool.accepted_currency || 1]
    )
  );

  return (
    <>
      <p className="title-progress">Total deposited</p>
      <p className="text-sale-type">
        {Number(pool.start_time_deposit) > now
          ? "TBA"
          : TotalDeposit?.isEqualTo(0) || !TotalDeposit
          ? "0"
          : formatBigNumber(convert(TotalDeposit)) +
            ` ${
              pool.accepted_currency &&
              ACCEPTED_CURRENCIES_NAME[pool.accepted_currency]
            }`}
      </p>
    </>
  );
};

export default MaxDeposit;
