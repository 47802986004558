import images from "../../../themes/images";
import styles from "./participate.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";

interface ParticipateProps {}

const Participate: React.FC<ParticipateProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Participate}>
      <Row gutter={[50, 22]} align="middle">
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <img className={styles.Participate__Bg} src={images.ladingPage.BgParticipate} alt="" />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <h2 className={styles.Participate__title}>
            {t("Participate.Title")}
          </h2>
          <div
            className={styles.Participate__title_des}
            dangerouslySetInnerHTML={{ __html: t("Participate.TitleSub") }}
          ></div>
          <Button className={styles.Participate__BtnBuy}>{t("Participate.Buy")}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default Participate;
