// import { current } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { isInteger, toNumber } from "lodash";
import moment from "moment";
import { utf8ToHex } from "web3-utils";
import i18n from "../i18n/i18n";
import { web3 } from "../services/Web3";
import {
  setFilterAirdrop,
  setFilterIDO,
  setFilterStaking,
  setLayoutTableAirdrop,
  setLayoutTableIDO,
  setLayoutTableStaking,
  setMyProjectAirdrop,
  setMyProjectIDO,
  setMyProjectStaking,
  setPageAirdrop,
  setPageIDO,
  setPageStaking,
  setSearchAirdrop,
  setSearchIDO,
  setSearchStaking,
} from "../stores/toolbar";
import { setBalance } from "../stores/wallet";
import messageCustom from "./alert/message";
import {
  ACCEPTED_CURRENCIES,
  AIRDROP_CLAIM_TYPE,
  DECIMAL,
  defaultMyProjectAirdrop,
  defaultMyProjectIDO,
  defaultMyProjectStaking,
  DETAIL_PROJECT_IDO_STATUS,
  IDO_STATUS,
  JOIN_IDO_POOL_STATUS,
  NUMBER_OF_DECIMAL,
  plainOptions,
  PRECISION,
  PROJECT_AIRDROP_TYPE,
  PROJECT_STAKING_TYPE,
  URL_SCAN_TRANSACTION,
} from "./constants";
import { AcceptedCurrencyTypes } from "./types/IDOType";

export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const secondsToTime = (time: number) => {
  const days = Math.floor(time / (3600 * 24));
  const hours = Math.floor((time % (3600 * 24)) / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return { days, hours, minutes, seconds };
};

export function formatNumber(
  value: string | number,
  roundUp?: boolean
): string {
  const MIN_NUMBER = 0.01;
  const SUPER_MIN = value;
  if (value === undefined) {
    return "";
  }
  const comps = String(value).split(".");

  if (
    comps.length > 2 ||
    !comps[0].match(/^[0-9,]*$/) ||
    (comps[1] && !comps[1].match(/^[0-9]*$/)) ||
    value === "." ||
    value === "-."
  ) {
    return "";
  }

  let suffix = "";
  if (comps.length === 1) {
    suffix = ".00";
  }
  if (comps.length === 2) {
    suffix = "." + (comps[1] || "0");
  }
  while (suffix.length > 3 && suffix[suffix.length - 1] === "0") {
    suffix = suffix.substring(0, suffix.length - 1);
  }

  const formatted = [];
  let whole = comps[0].replace(/[^0-9]/g, "");
  while (whole.substring(0, 1) === "0") {
    whole = whole.substring(1);
  }

  if (whole === "") {
    whole = "0";
    if (
      toNumber(whole + suffix) !== 0 &&
      toNumber(whole + suffix) < MIN_NUMBER
    ) {
      return SUPER_MIN.toString();
    }
  }

  const roundNumber = Number(
    (roundUp ? Math.round : Math.floor)(toNumber("0" + suffix) * 100) / 100
  );
  if (roundNumber) {
    if (roundNumber === 1) {
      whole = `${toNumber(whole) + 1}`;
      suffix = "";
    } else suffix = "." + String(roundNumber).split(".")[1];
  }

  while (whole.length) {
    if (whole.length <= 3) {
      formatted.unshift(whole);
      break;
    } else {
      const index = whole.length - 3;
      formatted.unshift(whole.substring(index));
      whole = whole.substring(0, index);
    }
  }

  return formatted.join(",") + suffix;
}

export function changeToNumber(value: string): number {
  return toNumber(value.replace(/[^0-9.]/g, ""));
}

export function roundNumberIDO(value: any, exp: number) {
  if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) return NaN;

  // Shift
  value = value.toString().split("e");
  value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
}

export const checkProjectType = (dataStake: any) => {
  const {
    projectType,
    otherUnTrigger,
    otherTrigger,
    whiteListUnTrigger,
    whiteListTrigger,
    tokenSymbol,
  } = dataStake;
  let accessType = "";
  let underTriggerTerm = "";
  let triggerTerm = "";
  switch (projectType) {
    case PROJECT_STAKING_TYPE.PUBLIC:
      accessType = i18n.t("Staking.ProjectType.Public");
      underTriggerTerm = i18n.t("Staking.Detail.Normal.ValueUnderTriggerTerm", {
        value: formatBigNumber(otherUnTrigger),
        tokenSymbol,
      });
      triggerTerm = i18n.t("Staking.Detail.Normal.ValueTriggerTerm", {
        value: formatBigNumber(otherTrigger),
      });
      break;
    case PROJECT_STAKING_TYPE.PRIVATE:
      accessType = i18n.t("Staking.ProjectType.Private");
      underTriggerTerm = i18n.t("Staking.Detail.Normal.ValueUnderTriggerTerm", {
        value: formatBigNumber(whiteListUnTrigger),
        tokenSymbol,
      });
      triggerTerm = i18n.t("Staking.Detail.Normal.ValueTriggerTerm", {
        value: formatBigNumber(whiteListTrigger),
      });
      break;
    default:
      accessType = i18n.t("Staking.ProjectType.Hybrid");
      underTriggerTerm = i18n.t("Staking.Detail.Hybrid.ValueUnderTriggerTerm", {
        valueWhiteList: formatBigNumber(whiteListUnTrigger),
        valueOthers: formatBigNumber(otherUnTrigger),
        tokenSymbol,
      });
      triggerTerm = i18n.t("Staking.Detail.Hybrid.ValueTriggerTerm", {
        valueWhiteList: formatBigNumber(whiteListTrigger),
        valueOthers: formatBigNumber(otherTrigger),
      });
  }
  return { accessType, underTriggerTerm, triggerTerm };
};

export const toRealNumber = (bigNumber: any, decimal?: number) => {
  return new BigNumber(bigNumber)
    .multipliedBy(new BigNumber(10).exponentiatedBy(-(decimal || DECIMAL)))
    .toString();
};

export const plusBigNumber = (x: any, y: any) => {
  return new BigNumber(x)
    .plus(y)
    .multipliedBy(new BigNumber(10).exponentiatedBy(-DECIMAL))
    .toString();
};

export const getIconUrl = (filename: any) => {
  return `${process.env.REACT_APP_UPLOAD_URL}/${filename}`;
};

export const formatUnixTimeToDate = (timeUnix: any) => {
  return moment.unix(timeUnix).format("YYYY-MM-DD HH:mm");
};

export const signMessage = async (message: string, address: string) => {
  let signature = "";
  await web3?.eth.personal
    .sign(utf8ToHex(message), address, "")
    .then((value) => {
      return (signature = value);
    })
    .catch((err) => {
      if (err.code === 4001) {
        messageCustom.error("Transaction Reject");
      } else {
        messageCustom.error("Join error");
      }
    });
  return signature;
};

export const checkRenderPublicLinearAndQuadratic = (
  projectType: number,
  distributionMethod: number
) => {
  return (
    projectType === PROJECT_AIRDROP_TYPE.PUBLIC &&
    (distributionMethod === AIRDROP_CLAIM_TYPE.LINEAR ||
      distributionMethod === AIRDROP_CLAIM_TYPE.QUADRATIC)
  );
};

export const checkRenderPublicFixedAmount = (
  projectType: number,
  distributionMethod: number
) => {
  return (
    projectType === PROJECT_AIRDROP_TYPE.PUBLIC &&
    distributionMethod === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT
  );
};

export const checkRenderPrivateLinearAndQuadratic = (
  projectType: number,
  distributionMethod: number
) => {
  return (
    projectType === PROJECT_AIRDROP_TYPE.PRIVATE &&
    (distributionMethod === AIRDROP_CLAIM_TYPE.LINEAR ||
      distributionMethod === AIRDROP_CLAIM_TYPE.QUADRATIC)
  );
};

export const checkRenderPrivateFixedAmount = (
  projectType: number,
  distributionMethod: number
) => {
  return (
    projectType === PROJECT_AIRDROP_TYPE.PRIVATE &&
    distributionMethod === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT
  );
};

export const checkTime = (time: number) => {
  if (time) {
    return formatUnixTimeToDate(time);
  }
  return "";
};

const formatAfterDecimal = (number: any) => {
  if (!number) return 0;
  let newNumber = number;
  for (let i = number.length - 1; i >= 0; i--) {
    if (newNumber[i] === "0")
      newNumber = newNumber.substring(0, newNumber.length - 1);
    else {
      break;
    }
  }
  return newNumber;
};

export const roundNumber = (number: number, precision = PRECISION) => {
  if (Math.round(number).toString().length > 9) {
    return `${(number / 1000000000).toLocaleString("en-US")} B`;
  } else if (Math.round(number).toString().length > 6) {
    return `${(number / 1000000).toLocaleString("en-US")} M`;
  } else if (Math.round(number).toString().length > 3) {
    return `${(number / 1000).toLocaleString("en-US")} K`;
  } else return formatNumberDecimal(number, precision);
};

// Hàm làm tròn sau n dấu phẩy :
export const formatNumberDecimal = (
  num: number,
  number_decimal = NUMBER_OF_DECIMAL
) => {
  if (!num) return 0;
  if (
    Number(new BigNumber(num)) < Number(new BigNumber(`1e-${number_decimal}`))
  )
    return 0;
  const numString = new BigNumber(num).toString().split(".");
  const beforeDecimal = numString[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const notShowAfterDecimal = number_decimal === 0;
  const defaultNumber = `${beforeDecimal}.0000`;

  if (numString.length > 1) {
    let afterDecimal = formatAfterDecimal(
      numString[1].substring(0, number_decimal)
    );
    if (afterDecimal === "0" || afterDecimal === "")
      return `${notShowAfterDecimal ? beforeDecimal : defaultNumber}`;
    return `${
      notShowAfterDecimal ? beforeDecimal : `${beforeDecimal}.${afterDecimal}`
    }`;
  } else {
    return `${notShowAfterDecimal ? beforeDecimal : defaultNumber}`;
  }
};

export const getBalance = async (walletAddress: string) => {
  await web3?.eth.getBalance(walletAddress).then((balance: any) => {
    setBalance(toNumber(web3?.utils.fromWei(balance)));
  });
};

// Hàm reset state toolbar
export const resetStateToolbar = () => {
  setLayoutTableStaking();
  setLayoutTableAirdrop();
  setLayoutTableIDO();
  setMyProjectStaking(defaultMyProjectStaking);
  setMyProjectAirdrop(defaultMyProjectAirdrop);
  setMyProjectIDO(defaultMyProjectIDO);
  setFilterStaking(plainOptions);
  setFilterAirdrop(plainOptions);
  setFilterIDO([`${IDO_STATUS.UPCOMING}`]);
  setSearchStaking("");
  setSearchAirdrop("");
  setSearchIDO("");
  setPageStaking(1);
  setPageAirdrop(1);
  setPageIDO(1);
};

export const URL_TRANSACTION = (hash: string) => {
  // return `${URL_SCAN_TRANSACTION}/${hash}?runtime=${EMERAL_ID}`;
  return `${URL_SCAN_TRANSACTION}/tx/${hash}`;
};
export const collapseWallet = (wallet_address: string, suffixCount = 4) => {
  if (!wallet_address) return wallet_address;
  const start = wallet_address.slice(0, 6).trim();
  const suffix = wallet_address.slice(-suffixCount).trim();
  return `${start}...${suffix}`;
};

export const stringfy = (params?: { [key: string]: string | number }) =>
  params
    ? Object.entries(params)
        .filter((arr) => arr[1])
        .map((arr) => arr.join("="))
        .join("&")
    : "";

export const checkStatus = (
  status: string | number,
  joinIdo: string,
  acceptedCurrency?: AcceptedCurrencyTypes
) => {
  if (DETAIL_PROJECT_IDO_STATUS.UP_COMMING === Number(status)) {
    if (joinIdo.toString() === "9") {
      switch (acceptedCurrency) {
        case AcceptedCurrencyTypes.ROSE:
          return "Deposit";
        case AcceptedCurrencyTypes.USDC:
          return "Approve USDC";
        case AcceptedCurrencyTypes.USDT:
          return "Approve USDT";
        default:
          return "Deposit" as string;
      }
    }
    return "Join IDO pool" as string;
  }

  if (
    DETAIL_PROJECT_IDO_STATUS.DEPOSIT === Number(status) &&
    Number(joinIdo) === JOIN_IDO_POOL_STATUS.JOINED
  ) {
    return "Deposit";
  }
  if (
    DETAIL_PROJECT_IDO_STATUS.PUBLICSELL === Number(status) &&
    Number(joinIdo) === JOIN_IDO_POOL_STATUS.JOINED
  ) {
    return "Deposit";
  }
  if (
    // joinIdo.toString() === "3" &&
    DETAIL_PROJECT_IDO_STATUS.PUBLICSELL === Number(status)
  ) {
    switch (acceptedCurrency) {
      case AcceptedCurrencyTypes.USDC:
        return "Approve USDC";
      case AcceptedCurrencyTypes.USDT:
        return "Approve USDT";
      default:
        return "Deposit" as string;
    }
  }
  if (DETAIL_PROJECT_IDO_STATUS.CLAIMABLE === Number(status)) {
    return null;
  }
  if (DETAIL_PROJECT_IDO_STATUS.ENDED === Number(status)) {
    return null;
  }
};

export const checkCurrency = (acceptedCurrency: AcceptedCurrencyTypes) => {
  if (acceptedCurrency === 2) {
    return process.env.REACT_APP_TOKEN_USDT;
  }
  if (acceptedCurrency === 3) {
    return process.env.REACT_APP_TOKEN_USDC;
  }
  return "";
};

export const checkTimeCountDown = (
  timeApplicationStart: any,
  timeApplicationEnd: any,
  timeDepositStart: any,
  timeDepositEnd: any,
  timeClaimStart: any,
  timePublicSellEnd: any,
  timePublicSellStart: any
): number => {
  if (timeApplicationStart !== 0) {
    return timeApplicationStart;
  } else if (timeApplicationEnd !== 0) {
    return timeApplicationEnd;
  } else if (timeDepositStart !== 0) {
    return timeDepositStart;
  } else if (timeDepositEnd !== 0) {
    return timeDepositEnd;
  } else if (timePublicSellStart !== 0) {
    return timePublicSellStart;
  } else if (timePublicSellEnd !== 0) {
    return timePublicSellEnd;
  } else {
    return timeClaimStart;
  }
};

export const checkClass = (
  // poolAddress: boolean,
  time: number,
  joinIdo: string,
  timeDepositStart: number,
  timePublicSellStart: number,
  timeClaimStart: number,
  balance: number,
  status: number,
  disable: boolean,
  checkMaxDeposit: boolean,
  checkMaxTotalSoldCoin: boolean,
  disableButtonMaxDeposit?: boolean,
  saleTypes?: number,
  isForbiddenCountries?: boolean,
  isNotKYC?: boolean,
  isNotMaxparticipants?: boolean,
  isNotEnoghGRDN?: boolean
) => {
  if (
    time === timeDepositStart ||
    time === timePublicSellStart ||
    time === timeClaimStart ||
    (status === 1 && disable) ||
    // (joinIdo === "9" && status > 1) ||
    joinIdo === "5" ||
    joinIdo === "10" ||
    joinIdo === "6" ||
    (joinIdo === "3" && status !== DETAIL_PROJECT_IDO_STATUS.PUBLICSELL) ||
    disableButtonMaxDeposit ||
    (checkMaxDeposit && saleTypes === 1) ||
    (checkMaxTotalSoldCoin && saleTypes === 1) ||
    isForbiddenCountries ||
    isNotKYC ||
    isNotMaxparticipants ||
    isNotEnoghGRDN
  ) {
    return "button-ido-disabled ";
  } else {
    return "button-ido";
  }
};

export const formatRoundNumber = (number: number, precision = PRECISION) => {
  const bilion = 1000000000;
  if (Math.round(number).toString().length > 10) {
    return `${(number / bilion).toLocaleString("en-US")}B`;
  } else return formatBigNumber(number, precision);
};

export const formatBigNumber = (
  value: BigNumber.Value,
  decimals = 2,
  rounDown?: boolean
) => {
  BigNumber.config({
    ROUNDING_MODE: rounDown
      ? BigNumber.ROUND_HALF_DOWN
      : BigNumber.ROUND_HALF_UP,
  });
  if (new BigNumber(value).isNaN()) value = 0;
  return new BigNumber(value).decimalPlaces(decimals).toFormat();
};

export function convert(n: any) {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
}

export const checkBalanceValue = (
  currency?: number,
  balance?: string,
  balanceUSDC?: string,
  balanceUSDT?: string
) => {
  if (currency === 1 && balance) {
    if (isInteger(+balance)) {
      return formatBigNumber(convert(balance || 0));
    } else {
      return formatBigNumber(balance);
    }
  }
  if (currency === 2 && balanceUSDT) {
    if (isInteger(+balanceUSDT)) {
      return formatBigNumber(convert(balanceUSDT || 0));
    } else {
      return formatBigNumber(balanceUSDT);
    }
  }
  if (currency === 3 && balanceUSDC) {
    if (isInteger(+balanceUSDC)) {
      return formatBigNumber(convert(balanceUSDC || 0));
    } else {
      return formatBigNumber(balanceUSDC);
    }
  }
};

export const checkTokenDeposit = (currency: number) => {
  if (currency === ACCEPTED_CURRENCIES.ROSE) {
    return "ROSE";
  }
  if (currency === ACCEPTED_CURRENCIES.USDC) {
    return "USDC";
  }
  if (currency === ACCEPTED_CURRENCIES.USDT) {
    return "USDT";
  }
};
