import React, { useEffect } from "react";
import Header from "./LayoutHeaderPrivate";
import { Layout, Menu, MenuProps, Select } from "antd";
import Icon from "@ant-design/icons";
import { ROUTES, SIDER, HYPER_LINK } from "../../common/constants";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../common/styles/customAntd.scss";
import styles from "./Layout.module.scss";
import {
  renderIconStaking,
  renderIconAirDrop,
  renderIconArrow,
  renderIconIDO,
  renderIconProfile,
} from "../../common/renderSvgs";
import images from "themes/images";
const { Content, Sider } = Layout;

interface LayoutProps {}

const renderIconlaunchGardenGithub = () => {
  return (
    <img
      alt="icon github"
      className={styles.iconImageGitHub}
      src={images.logo.IconGitHub}
    />
  );
};

// hyperlink

const HYPERLINKMENU: MenuProps["items"] = [
  {
    key: HYPER_LINK.BUY_ROSE,
    icon: <Icon style={{ color: "#b1b5c3" }} component={renderIconArrow} />,
    label: (
      <a
        className={styles.HyperLinkMenu}
        href={HYPER_LINK.BUY_ROSE}
        rel="noreferrer"
        target="_blank"
      >
        {SIDER.BUY_ROSE}
      </a>
    ),
  },
  {
    key: HYPER_LINK.LAUNCH_GARDEN_GITHUB,
    icon: (
      <Icon
        style={{ color: "#b1b5c3" }}
        component={renderIconlaunchGardenGithub}
      />
    ),
    label: (
      <a
        className={styles.HyperLinkMenu}
        href={HYPER_LINK.LAUNCH_GARDEN_GITHUB}
        rel="noreferrer"
        target="_blank"
      >
        {SIDER.LAUNCH_GARDEN_GITHUB}
      </a>
    ),
  },
];

// menu item

const MENUSITEM: MenuProps["items"] = [
  {
    key: ROUTES.IDOPOOL,
    icon: <Icon component={renderIconIDO} />,
    label: (
      <NavLink to={ROUTES.IDOPOOL}>
        <span className={styles.menuTitle}>{SIDER.IDOPOOL}</span>
      </NavLink>
    ),
  },
  // {
  //   key: ROUTES.STAKING,
  //   icon: <Icon component={renderIconStaking} />,
  //   label: (
  //     <NavLink to={ROUTES.STAKING}>
  //       <span className={styles.menuTitle}>{SIDER.STAKING}</span>
  //     </NavLink>
  //   ),
  // },
  // {
  //   key: ROUTES.AIRDROP,
  //   icon: <Icon component={renderIconAirDrop} />,
  //   label: (
  //     <NavLink to={ROUTES.AIRDROP}>
  //       <span className={styles.menuTitle}>{SIDER.AIRDROP}</span>
  //     </NavLink>
  //   ),
  // },
  // {
  //   key: ROUTES.MYPROFILE,
  //   icon: <Icon component={renderIconProfile} />,
  //   label: (
  //     <NavLink to={ROUTES.MYPROFILE}>
  //       <span className={styles.menuTitle}>{SIDER.MYPROFILE}</span>
  //     </NavLink>
  //   ),
  // },
];

export const renderHyperLinkMenu = () => {
  return <Menu className={styles.HyperLinkMenu} items={HYPERLINKMENU} />;
};

export const MainMenu = (props: { onSelect?: () => void }) => {
  const location = useLocation();
  const history = useHistory();

  const handleChangeMenuItems: MenuProps["onClick"] = (e) => {
    history.push(e.key);
  };

  return (
    <Menu
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
      className={styles.menuMobile}
      onSelect={props.onSelect}
      onClick={handleChangeMenuItems}
      items={MENUSITEM}
    />
  );
};

export const renderSelectMenu = (isDarkMode: string, location: any) => {
  return (
    <div className={styles.LayoutPrivate__Sider__Menu__Main_Mobile}>
      <Select
        defaultValue={[location.pathname]}
        className="SelectMenu select-menu-custom"
        dropdownClassName="menuDropdown"
      >
        <Select.Option
          value={ROUTES.STAKING}
          data-theme={isDarkMode ? "dark" : "light"}
          className="select-menu-custom-item"
        >
          <Icon component={renderIconStaking} />
          <span>{SIDER.STAKING}</span>
          <NavLink to={ROUTES.STAKING} />
        </Select.Option>
        <Select.Option
          value={ROUTES.AIRDROP}
          data-theme={isDarkMode ? "dark" : "light"}
          className="select-menu-custom-item"
        >
          <Icon component={renderIconAirDrop} />
          <span>{SIDER.AIRDROP}</span>
          <NavLink to={ROUTES.AIRDROP} />
        </Select.Option>
      </Select>
    </div>
  );
};

const LayoutPrivate: React.FC<LayoutProps> = ({ children }) => {
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (
      location?.pathname === ROUTES.AIRDROP ||
      location?.pathname === ROUTES.STAKING ||
      location?.pathname === ROUTES.AIRDROP_DETAIL ||
      location?.pathname === ROUTES.STAKING_DETAIL
    ) {
      history.push(ROUTES.IDOPOOL);
    }
  }, [history, location?.pathname]);
  return (
    <Layout data-theme={isDarkMode ? "dark" : "light"}>
      <Header />
      <Layout className={`${styles.LayoutPrivate} layout `}>
        <Sider theme="light" className={styles.LayoutPrivate__Sider}>
          <div className={styles.LayoutPrivate__Sider__Menu}>
            <div className={styles.LayoutPrivate__Sider__Menu__Main_Web}>
              <MainMenu />
            </div>
            <div className={styles.LayoutPrivate__Sider__Menu__Line}></div>
          </div>
          {/* <div className={styles.LayoutPrivate__Sider__Menu__HyperLink_Web}>
            {renderHyperLinkMenu()}
          </div> */}
        </Sider>
        <Layout className={styles.LayoutPrivate} id={"private-layout"}>
          <Content style={{ padding: "4px" }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutPrivate;
