import { Layout, Button, Typography, Drawer } from "antd";
import _ from "lodash";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./connectWallet";
import React from "react";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.scss";
import {
  setDarkMode,
  setUserInfo,
  setConnectNetwork,
  setConnectMetaMark,
  setBalance,
} from "../../stores/wallet";
import images from "../../themes/images";
import StorageUtils, { STORAGE_KEYS } from "../../utils/storage";
import { ConnectWalletService } from "../../services/ConnectWallet";
import { useSelector } from "react-redux";
import stylesCommon from "../../common/styles/styles.module.scss";
import { isLoginWithAcc, isMetaMaskInstalled } from "../../services/Web3";
import { NavLink } from "react-router-dom";
import {
  METAMASK_SIGN_MESSAGE,
  NETWORK,
  POPUP,
  ROUTES,
} from "../../common/constants";
import ConnectWallet from "../../common/popup/connectWallet";
import ConnectNetwork from "../../common/popup/connectWallet/wrongNetwork";
import ToolTipConnect from "../../common/popup/tooltip";
import {
  formatBigNumber,
  getBalance,
  signMessage,
} from "../../common/functions";
import { MainMenu } from "./LayoutPrivate";
import Logout from "../../common/icon/LogoutIcon";
import BrightNess from "../../common/icon/BrightNess";
import TooltipCustom from "components/TooltipHeader";
import { getLibrary } from "App";

const { Header } = Layout;
const { Text } = Typography;
const SUFFIX_COUNT = 4;

const LayoutHeader = () => {
  const { t } = useTranslation();
  const [isDarkMode, setDarkModeState] = useState<boolean>(
    StorageUtils.getItem(STORAGE_KEYS.DARKMODE, "false") === "true"
  );
  const ConnectWalletAPI = new ConnectWalletService();
  const windowObj = window as any;
  const { ethereum } = windowObj;
  const { activate, deactivate, chainId: idchain } = useWeb3React();
  const RootState = useSelector((state) => state.wallet);
  const { isConnectNetwork, isConnectMetaMark, userInfo, balance } = RootState;
  const [isShowButtonDisconnect, setShowButtonDisconnect] = useState<boolean>();
  const [isConnectWalletPopUp, setIsConnectWalletPopUp] =
    useState<boolean>(false);
  const [isNetworkPopUp, setIsNetworkPopUp] = useState<boolean>(false);
  const [chainId, setChainId] = useState<string>("");
  const [account, setAccount] = useState<string>("");
  const [isTooltip, setIsTooltip] = useState(false);
  const [isShowMenu, setShowMenu] = useState<boolean>(false);
  const refButtonDisconnect = useRef<HTMLDivElement>(null);
  let _check = false;

  const onCheck = async () => {
    const check = checkNetwork();
    if (!check) {
      await ethereum?.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${NETWORK.CHAIN_ID_HEX}`,
            rpcUrls: [NETWORK.RPC_URLS],
            chainName: NETWORK.CHAIN_NAME,
            blockExplorerUrls: [NETWORK.BLOCK_EXPLORER_URLS],
            nativeCurrency: {
              name: NETWORK.NATIVE_CURRENCY.NAME,
              symbol: NETWORK.NATIVE_CURRENCY.SYMBOL,
              decimals: Number(NETWORK.NATIVE_CURRENCY.DECIMAL),
            },
          },
        ],
      });
      _check = true;
    } else {
      connectWallet();
    }
  };
  const connectWallet = async () => {
    try {
      if (!isMetaMaskInstalled()) {
        return POPUP.CONNECTWALLET.STATUS.UNINSTALL;
      }

      deactivate();
      await activate(injected);
      const accounts = await ethereum?.request({
        method: "eth_requestAccounts",
      });
      const loginUser = accounts.length ? accounts[0] : "";
      const signature = await signMessage(METAMASK_SIGN_MESSAGE, loginUser);
      if (!signature) {
        return;
      }
      const params = {
        wallet_address: loginUser,
        signature,
      };
      const result = await ConnectWalletAPI.connectWallet(params);
      const { data } = result;
      const resultToken = _.get(data, "data.token", "");
      const user = _.get(data, "data.user", "");

      if (resultToken) {
        setConnectMetaMark(true);
        setIsConnectWalletPopUp(false);
        const { token } = resultToken;
        StorageUtils.setUser(user);
        StorageUtils.setToken(token);
        setUserInfo(user);

        setConnectNetwork(true);
        setIsNetworkPopUp(false);
        const walletAddress = _.get(user, "wallet_address", "");
        getBalance(walletAddress);
      } else {
        setConnectMetaMark(false);
        return POPUP.CONNECTWALLET.STATUS.ERROR;
      }
    } catch (error: any) {
      console.log("error", error);
      if (error?.code === 4001) {
        return error.error("Transaction Reject");
      }
      return POPUP.CONNECTWALLET.STATUS.ERROR;
    } finally {
      _check = false;
    }
  };

  const handleAddNetwork = async () => {
    try {
      await ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${NETWORK.CHAIN_ID_HEX}` }],
      });
    } catch (err: any) {
      if (err.code === 4902) {
        try {
          await ethereum?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${NETWORK.CHAIN_ID_HEX}`,
                rpcUrls: [NETWORK.RPC_URLS],
                chainName: NETWORK.CHAIN_NAME,
                blockExplorerUrls: [NETWORK.BLOCK_EXPLORER_URLS],
                nativeCurrency: {
                  name: NETWORK.NATIVE_CURRENCY.NAME,
                  symbol: NETWORK.NATIVE_CURRENCY.SYMBOL,
                  decimals: Number(NETWORK.NATIVE_CURRENCY.DECIMAL),
                },
              },
            ],
          });
        } catch (addError) {
          throw console.log("addError", addError);
        }
      } else {
        throw console.log("err", err);
      }
    }
  };

  const logoutWallet = () => {
    deactivate();
    StorageUtils.removeUser();
    StorageUtils.removeToken();
    setConnectMetaMark(false);
    setConnectNetwork(false);
    setUserInfo(null);
    setBalance(0);
  };

  const checkNetwork = () => {
    const network = ethereum?.networkVersion;
    console.log(ethereum?.networkVersion, NETWORK.CHAIN_ID_DECIMAL);
    if (network === NETWORK.CHAIN_ID_DECIMAL) {
      return true;
    }
    return false;
  };

  const handleDarkMode = () => {
    setDarkModeState(!isDarkMode);
  };

  const handleCheckConnectMetaMark = async () => {
    const currentAccount = await isLoginWithAcc();

    const user = StorageUtils.getUser();
    const token = StorageUtils.getToken();
    const walletAddress = _.get(user, "wallet_address", "");
    if (currentAccount === walletAddress && token) {
      setConnectMetaMark(true);
      const check = checkNetwork();
      if (!check) {
        setConnectNetwork(false);
        setIsNetworkPopUp(true);
      } else {
        setConnectNetwork(true);
        setIsNetworkPopUp(false);
        getBalance(walletAddress);
      }
    } else {
      setIsConnectWalletPopUp(true);
    }
  };

  const closePopUpConnectWallet = () => {
    if (isConnectMetaMark) {
      setIsNetworkPopUp(!isNetworkPopUp);
      setIsTooltip(false);
    } else {
      setIsConnectWalletPopUp(!isConnectWalletPopUp);
    }
  };

  const closePopUpNetwork = () => {
    setIsNetworkPopUp(!isNetworkPopUp);
  };

  const closeTooltip = () => {
    setIsTooltip(!isTooltip);
  };

  const showToolTips = () => {
    if (!isTooltip) {
      setIsTooltip(!isTooltip);
    }
  };

  const handleClickOutside = (event: any) => {
    if (
      refButtonDisconnect.current &&
      !refButtonDisconnect.current?.contains(event.target)
    ) {
      setShowButtonDisconnect(false);
    }
  };

  ethereum?.on("networkChanged", (chainId: string) => {
    if (_check) connectWallet();
    setChainId(chainId);
    return;
  });

  ethereum?.on("accountsChanged", (newAccount: string) => {
    setAccount(newAccount);
    return;
  });

  useEffect(() => {
    handleCheckConnectMetaMark();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!isConnectMetaMark) {
      setIsTooltip(true);
    } else if (!isConnectNetwork) {
      setIsTooltip(true);
    }
  }, [isConnectMetaMark, isConnectNetwork]);

  useLayoutEffect(() => {
    if (isConnectMetaMark && chainId) {
      console.log(chainId, NETWORK.CHAIN_ID_DECIMAL);
      if (chainId === NETWORK.CHAIN_ID_DECIMAL) {
        setConnectNetwork(true);
        setIsNetworkPopUp(false);
        handleCheckConnectMetaMark();
      } else {
        // setConnectNetwork(false);
        // setIsNetworkPopUp(true);
        logoutWallet();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useLayoutEffect(() => {
    if (isConnectMetaMark && account) {
      logoutWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    setDarkMode({ isDarkMode });
    StorageUtils.setItem(STORAGE_KEYS.DARKMODE, `${isDarkMode}`);
  }, [isDarkMode]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const handleDisconnect = () => {
    deactivate();
    StorageUtils.removeUser();
    StorageUtils.removeToken();
    setConnectMetaMark(false);
    setConnectNetwork(false);
    setUserInfo(null);
    setBalance(0);
  };

  const EllipsisMiddle = (props: any) => {
    if (!userInfo?.wallet_address) return <div />;
    const { wallet_address } = userInfo;
    const start = wallet_address.slice(0, 6).trim();
    const suffix = wallet_address.slice(-SUFFIX_COUNT).trim();

    return (
      <div className={styles.Header__ConnectWalletSuccess__EllipsisMiddle}>
        <Text>{`${start}...${suffix}`}</Text>
        <div
          className={`
            ${
              styles.Header__ConnectWalletSuccess__EllipsisMiddle__ButtonDisconnect
            }
            ${
              isShowButtonDisconnect
                ? stylesCommon.EnableElement
                : stylesCommon.DisableElement
            }`}
          onClick={handleDisconnect}
        >
          <img src={images.icons.Upload} alt="Upload" />
          {t("Header.Disconnect")}
          <div className={styles.TriangleUp} />
        </div>
      </div>
    );
  };
  const EllipsisMiddleDrawer = () => {
    if (!userInfo?.wallet_address) return <div />;
    const { wallet_address } = userInfo;
    const start = wallet_address.slice(0, 6).trim();
    const suffix = wallet_address.slice(-SUFFIX_COUNT).trim();

    return (
      <div className={styles.EllipsisMiddleDrawer}>
        <Text>{`${start}...${suffix}`}</Text>
      </div>
    );
  };
  const onRenderTooltipTitle = (IsWrongWallet?: boolean) => {
    return (
      <div
        onClick={() => IsWrongWallet && setIsNetworkPopUp(!isNetworkPopUp)}
        className={styles.toolTipConnect}
      >
        Connect to your wallet to start using LaunchGarden
      </div>
    );
  };

  const renderConnectWallet = (
    isWebVersion?: boolean,
    disableTooltips?: boolean
  ) => (
    <div
      className={`${styles.Header__ConnectWallet} ${
        isWebVersion && styles.Header__ConnectWallet_Web
      }`}
    >
      {disableTooltips ? (
        <Button
          onClick={closePopUpConnectWallet}
          className={styles.Header__ButtonPrimary}
          type="primary"
        >
          {t("Header.ConnectWallet")}
        </Button>
      ) : (
        <ToolTipConnect
          show={isTooltip}
          closeTooltip={closeTooltip}
          onRenderTitle={onRenderTooltipTitle}
        >
          <Button
            onClick={closePopUpConnectWallet}
            className={styles.Header__ButtonPrimary}
            type="primary"
            onMouseMove={showToolTips}
            // onMouseLeave={closeTooltip}
          >
            {t("Header.ConnectWallet")}
          </Button>
        </ToolTipConnect>
      )}
    </div>
  );
  const renderConnectWrongNetwork = (isWebVersion?: boolean) => (
    <div
      className={`${styles.Header__ConnectWalletSuccess}  ${
        isWebVersion && styles.Header__ConnectWalletSuccess_Web
      }`}
    >
      <ToolTipConnect
        show={isTooltip}
        closeTooltip={closeTooltip}
        onRenderTitle={() => onRenderTooltipTitle(true)}
      >
        <div
          className={styles.Header__ConnectWalletSuccess__WrongNetwork}
          onClick={() => setIsNetworkPopUp(!isNetworkPopUp)}
          onMouseMove={showToolTips}
          // onMouseLeave={closeTooltip}
        >
          <img src={images.icons.IconErrorNetwork} alt="Frame Icon" />
          {t("Header.WrongNetwork")}
        </div>
      </ToolTipConnect>
      <div
        className={styles.Header__ConnectWalletSuccess__WrapperWalletAddress}
        onClick={() => setShowButtonDisconnect(!isShowButtonDisconnect)}
        ref={refButtonDisconnect}
      >
        <img
          className={
            styles.Header__ConnectWalletSuccess__WrapperWalletAddress__WalletIcon
          }
          src={images.icons.WalletIcon}
          alt="Wallet Icon"
        />
        <EllipsisMiddle />
        <img
          className={
            styles.Header__ConnectWalletSuccess__WrapperWalletAddress__IconDown
          }
          src={images.icons.IconDown}
          alt="Icon Down"
        />
      </div>
    </div>
  );

  const renderConnectSuccess = (isWebVersion?: boolean) => (
    <div
      className={`${styles.Header__ConnectWalletSuccess}  ${
        isWebVersion && styles.Header__ConnectWalletSuccess_Web
      }`}
    >
      <div className={styles.Header__ConnectWalletSuccess__OasisMainnet}>
        <img
          src={images.icons.ETHNetwork}
          alt="Frame Icon"
          style={{ width: "25px", height: "25px", objectFit: "contain" }}
        />
        {NETWORK.CHAIN_NAME}
      </div>
      <div className={styles.Header__ConnectWalletSuccess__OasisBalance}>
        {`${formatBigNumber(balance, 2, true)} ${
          NETWORK.NATIVE_CURRENCY.SYMBOL
        }`}
      </div>
      <div
        className={styles.Header__ConnectWalletSuccess__WrapperWalletAddress}
        onClick={() => setShowButtonDisconnect(!isShowButtonDisconnect)}
        ref={refButtonDisconnect}
      >
        <img
          className={
            styles.Header__ConnectWalletSuccess__WrapperWalletAddress__WalletIcon
          }
          src={images.icons.WalletIcon}
          alt="Wallet Icon"
        />
        <EllipsisMiddle />
        <img
          className={
            styles.Header__ConnectWalletSuccess__WrapperWalletAddress__IconDown
          }
          src={images.icons.IconDown}
          alt="Icon Down"
        />
      </div>
    </div>
  );
  const renderConnectSuccessDrawerNetWork = () => (
    <div className={styles.ConnectWalletSuccessDrawer}>
      <div className={styles.ConnectWalletSuccessDrawer__NetWork}>
        <div
          className={styles.ConnectWalletSuccessDrawer__NetWork__OasisMainnet}
        >
          <img
            src={images.icons.ETHNetwork}
            style={{ width: "25px", height: "25px", objectFit: "contain" }}
            alt="Frame Icon"
          />
          {NETWORK.CHAIN_NAME}
        </div>
        <Button onClick={handleDarkMode} className={styles.IconBrightness}>
          <BrightNess />
        </Button>
      </div>

      <div className={styles.ConnectWalletSuccessDrawer__Wallet}>
        <div
          className={
            styles.ConnectWalletSuccessDrawer__Wallet__WrapperWalletAddress
          }
          ref={refButtonDisconnect}
        >
          <img
            className={
              styles.ConnectWalletSuccessDrawer__Wallet__WrapperWalletAddress__WalletIcon
            }
            src={images.icons.WalletIconGrey}
            alt="Wallet Icon"
          />
          <EllipsisMiddleDrawer />
          <TooltipCustom
            text={userInfo?.wallet_address}
            title={"Copy address"}
          />
        </div>
        <Button
          className={styles.Btn_logout}
          onClick={() => handleDisconnect()}
        >
          <Logout />
        </Button>
      </div>
    </div>
  );
  return (
    <Header className={`${styles.Header} ${styles.Header__Private}`}>
      <div className={styles.Header__WrapperLogo}>
        <NavLink to={ROUTES.HOME}>
          <img
            className={styles.logoWeb}
            src={images.logo.LogoLaunchGarden}
            alt="logo"
          />
          <img
            className={styles.logoPhone}
            src={images.logo.LogoIcon}
            alt="logo"
          />
        </NavLink>
      </div>
      {isConnectMetaMark
        ? isConnectNetwork
          ? renderConnectSuccess(true)
          : renderConnectWrongNetwork(true)
        : renderConnectWallet(true)}
      <ConnectWallet
        isShow={isConnectMetaMark ? false : isConnectWalletPopUp}
        handleCancel={closePopUpConnectWallet}
        connectWallet={onCheck}
      />
      <ConnectNetwork
        isNetworkPopUp={isConnectWalletPopUp ? false : isNetworkPopUp}
        handleCancel={closePopUpNetwork}
      />
      <img
        onClick={handleDarkMode}
        src={images.icons.BrightnessUp}
        alt="BrightnessUp"
        className={`${styles.BrightnessUp} ${styles.BrightnessUp_Web}`}
      />
      <div
        onClick={() => setShowMenu(true)}
        className={styles.Header__HamburgerMemu}
      >
        <img src={images.icons.BuggerIcon} alt="hbg-menu" />
      </div>
      <Drawer
        width="100vw"
        onClose={() => setShowMenu(false)}
        visible={isShowMenu}
        data-theme={isDarkMode ? "dark" : "light"}
        className={styles.Header__HamburgerMemu__Drawer + " layout"}
        title={
          <NavLink to={ROUTES.HOME}>
            <img
              className={styles.imageLogoMobile}
              src={images.logo.LogoLaunchGarden}
              alt="logo"
            />
          </NavLink>
        }
      >
        <div className={styles.LayoutPrivate__Sider__Menu}>
          <div className={styles.LayoutPrivate__Sider__Menu__Main_Mobile}>
            <MainMenu onSelect={() => setShowMenu(false)} />
          </div>
          <div className={styles.LayoutPrivate__Sider__Menu__Line}></div>
        </div>
        <div
          className={`${styles.LayoutPrivate__Sider__Menu__HyperLink_Mobile} HyperLinkMobile`}
        >
          {/* {renderHyperLinkMenu()} */}
        </div>
        <div className={styles.Header__HamburgerMemu__Drawer__Bottom}>
          <div className={styles.Header__HamburgerMemu__Drawer__Bottom__Oasis}>
            {isConnectMetaMark
              ? isConnectNetwork
                ? renderConnectSuccessDrawerNetWork()
                : renderConnectWrongNetwork()
              : renderConnectWallet(false, true)}
          </div>
          <ConnectWallet
            isShow={isConnectMetaMark ? false : isConnectWalletPopUp}
            handleCancel={closePopUpConnectWallet}
            connectWallet={connectWallet}
          />
          <ConnectNetwork
            isNetworkPopUp={isConnectWalletPopUp ? false : isNetworkPopUp}
            handleCancel={closePopUpNetwork}
          />
        </div>
      </Drawer>
    </Header>
  );
};

export default LayoutHeader;
