import { memo } from "react";
import { Pagination, Row, Col } from "antd";
import _ from "lodash";
import styles from "./StakingCard.module.scss";
import useScreenResize from "../../../../common/hooks/useScreenResize";
import CardComponent from "./CardComponent";
import NotRecordFound from "../../../../common/pages/NotRecordFound";
import "../../../../common/styles/customAntd.scss";

interface StakingProp {
  stakingList: any;
  handleChangePage: (page: number) => void;
  onFinishCountDown: (id: number, status: number) => void;
  total: string;
  pageInit: number;
  pageSize: string;
}

const StakingCard: React.FC<StakingProp> = ({
  stakingList,
  handleChangePage,
  total,
  pageInit,
  onFinishCountDown,
  pageSize,
}) => {
  const [screenWidth] = useScreenResize();

  const onChange = (page: number) => {
    handleChangePage(page);
  };

  let dateRender: any[] = [];

  stakingList &&
    stakingList.map((item: any, index: number, arr: any[]) => {
      switch (true) {
        case screenWidth === "lg":
          if (arr.length > 3 && index <= 2) {
            dateRender.push({
              project_1: arr[index],
              project_2: arr[index + 3],
            });
          } else if (arr.length <= 3) {
            dateRender.push({ project_1: arr[index] });
          }
          break;

        case screenWidth === "md":
          if (arr.length > 2 && index <= 1) {
            dateRender.push({
              project_1: arr[index],
              project_2: arr[index + 2],
              project_3: arr[index + 4],
              project_4: arr[index + 6],
            });
          } else if (arr.length <= 1) {
            dateRender.push({ project_1: arr[index] });
          }
          break;

        default:
          dateRender.push({ project_1: arr[index] });
          break;
      }
      return true;
    });

  const renderGridCard = dateRender.map((item, index) => {
    return (
      <Col
        md={{ span: 12 }}
        xl={{ span: 8 }}
        key={index}
        className={styles.StakingContainer}
      >
        <CardComponent
          item={item.project_1}
          onFinishCountDown={onFinishCountDown}
        />
        {item.project_2 && (
          <CardComponent
            item={item.project_2}
            onFinishCountDown={onFinishCountDown}
          />
        )}
        {item.project_3 && (
          <CardComponent
            item={item.project_3}
            onFinishCountDown={onFinishCountDown}
          />
        )}
        {item.project_4 && (
          <CardComponent
            item={item.project_4}
            onFinishCountDown={onFinishCountDown}
          />
        )}
      </Col>
    );
  });

  return (
    <div className={styles.Container}>
      {!_.isEqual(stakingList, []) ? (
        <>
          <Row className={styles.Row}>{renderGridCard}</Row>
          <Pagination
            className={styles.Pagination}
            onChange={onChange}
            total={parseInt(total) || 0}
            current={pageInit}
            responsive
            showLessItems
            showSizeChanger={false}
            pageSize={6}
            hideOnSinglePage={true}
          />
        </>
      ) : (
        <NotRecordFound />
      )}
    </div>
  );
};

export default memo(StakingCard);
