import MyWallet from "./myWallet/MyWallet";
// import OasisWallet from "./oasisWallet/OasisWallet";
import StatusKYC from "./statusKYC";

function DetailMyProfile() {
  return (
    <div>
      <MyWallet title="My wallet address:" />
      {/* <OasisWallet title='Oasis wallet address:' /> */}
      <StatusKYC title="KYC status:" />
    </div>
  );
}

export default DetailMyProfile;
