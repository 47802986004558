/* eslint-disable react-hooks/exhaustive-deps */
import { Button, message } from "antd";
import BigNumber from "bignumber.js";
import JoinError from "common/alert/joinAirdrop/joinError";
import messageCustom from "common/alert/message";
import {
  ACCEPTED_CURRENCIES,
  ALERT_TIME,
  APPROVE_MAX_VALUE,
  BYPASS_STATUS,
  DECIMAL_ACCEPTED_CURRENCY,
  DETAIL_PROJECT_IDO_STATUS,
  IDO_STATUS,
  JOIN_AIRDROP_MESSAGE,
  JOIN_IDO_POOL_STATUS,
  STATUS_KYC_USER,
} from "common/constants";
import {
  checkClass,
  checkCurrency,
  checkStatus,
  signMessage,
} from "common/functions";
import useValueFunc from "common/hooks/useValueFunc";
import { IDOType } from "common/types/IDOType";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserService } from "services/UserServicer";
import { setAllowance, setJoinIdo, setLoading } from "stores/ido";
import Web3 from "web3";

interface PropsTypes {
  pool: Partial<IDOType>;
  timer: number;
  openDepositModal: () => void;
}

const ButtonStatus: React.FC<PropsTypes> = (props: PropsTypes) => {
  const { pool, timer, openDepositModal } = props;
  const {
    acceptedCurrency,
    timeDepositStart,
    status,
    poolAddress,
    timePublicSellStart,
    timeClaimStart,
    totalDeposit,
    totalSoldCoin,
    maxDeposit,
    saleTypes,
    // timeApplicationStart,
    exchangeRate,
    minGrdn,
  } = useValueFunc({ pool });
  const userService = new UserService();
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const { ethereum } = window as any;
  const { participant, numberOfParticipant, joinIdo } = useSelector(
    (state) => state.ido
  );
  const [disable, setDisable] = useState(
    Number(joinIdo) === JOIN_IDO_POOL_STATUS.JOINED ||
      Number(joinIdo) === JOIN_IDO_POOL_STATUS.NOT_START_APPLICATION_TIME
  );
  const web3 = new Web3(ethereum);
  const { userInfo, balance, amountTokenGRDN } = useSelector(
    (state) => state.wallet
  );
  const { allowance, loading } = useSelector((state) => state.ido);
  const { userAmount, userPSAmount } = useSelector((state) => state.ido);
  const wallet = Web3.utils.isAddress(userInfo?.wallet_address)
    ? userInfo?.wallet_address
    : " ";
  const { wallet_address: address, kyc_status, kyc_country } = userInfo || {};
  const isNotKYC =
    pool?.kyc_bypass === BYPASS_STATUS.REQUIRED &&
    address &&
    kyc_status !== STATUS_KYC_USER.VERIFIED;
  const isForbiddenCountries =
    pool && pool?.forbidden_countries?.includes(kyc_country);
  const ERC20_ABI = require("../../../../../../../abi/ERC20.json");

  const totalRewardAmount = useMemo(() => {
    return new BigNumber(userPSAmount).plus(new BigNumber(userAmount));
  }, [userPSAmount, userAmount]);

  const isNotMaxparticipants =
    Number(participant) === Number(numberOfParticipant) &&
    pool.sale_type === 1 &&
    pool.status === IDO_STATUS.DEPOSIT &&
    new BigNumber(totalRewardAmount).lte(0);
  const handleApproveToken = async () => {
    try {
      dispatch(setLoading(false));
      const contract = new web3.eth.Contract(
        ERC20_ABI,
        checkCurrency(acceptedCurrency)
      );
      const allowance = await contract.methods
        .allowance(wallet, poolAddress)
        .call({
          from: wallet,
        });
      dispatch(setLoading(true));

      if (allowance === "0") {
        const approve = await contract.methods
          .approve(poolAddress, APPROVE_MAX_VALUE)
          .send({ from: wallet });
        if (approve) {
          dispatch(setAllowance(true));
          messageCustom.success("Transaction complete!");
        }
      }
    } catch (error: any) {
      if (error?.code === 4001) {
        dispatch(setLoading(false));
        return message.info({
          content: <JoinError value="User denied approved " />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleJoinIdoPool = async () => {
    try {
      if (poolAddress) {
        const signature = await signMessage(JOIN_AIRDROP_MESSAGE, wallet);
        const params = {
          id: id,
          wallet_address: wallet,
          signature: signature,
        };

        const result = await userService.JoinIdoPool(params);
        const { data } = result.data;
        if (data) {
          setDisable(true);
          dispatch(setJoinIdo(JOIN_IDO_POOL_STATUS.JOINED.toString()));
          messageCustom.success("Joined successfully!");
        }
      } else {
        setDisable(false);
        return message.info({
          content: <JoinError value="Pool is not deploy" />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    switch (joinIdo.toString()) {
      case "9":
        if (status === 1 && acceptedCurrency === 1) {
          setDisable(true);
        } else if (allowance && acceptedCurrency !== 1 && status === 1) {
          setDisable(true);
        } else {
          setDisable(false);
        }
        break;
      case "5":
      case "6":
      case "10":
      case "1":
        setDisable(true);
        break;
      default:
        setDisable(false);
    }
  }, [
    joinIdo,
    status,
    allowance,
    acceptedCurrency,
    timeDepositStart,
    timePublicSellStart,
    timer,
  ]);

  const DepositFromWei = useMemo(() => {
    if (totalDeposit) {
      return new BigNumber(totalDeposit)
        .div(
          new BigNumber(10).pow(
            DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
          )
        )
        .multipliedBy(exchangeRate || 1)
        .toFixed(2);
    } else {
      return 0;
    }
  }, [totalDeposit]);

  const checkMaxDeposit = new BigNumber(maxDeposit || "0").isEqualTo(
    new BigNumber(userAmount).plus(new BigNumber(userPSAmount))
  );
  const checkMaxTotalSoldCoin = new BigNumber(
    totalSoldCoin || "0"
  ).isLessThanOrEqualTo(DepositFromWei || "0");

  const disableButtonMaxDeposit = checkMaxTotalSoldCoin && status !== 1;

  const isNotEnoghGRDN = () => {
    if (!minGrdn) return false;
    return (
      new BigNumber(amountTokenGRDN).isLessThan(minGrdn) &&
      status !== DETAIL_PROJECT_IDO_STATUS.PUBLICSELL
    );
  };

  const renderButtonApprove = () => {
    return (
      <Button
        className={checkClass(
          timer,
          joinIdo.toString(),
          timeDepositStart,
          timePublicSellStart,
          timeClaimStart,
          balance,
          status,
          disable,
          disableButtonMaxDeposit,
          checkMaxDeposit,
          checkMaxTotalSoldCoin,
          saleTypes,
          isNotKYC,
          isForbiddenCountries,
          isNotMaxparticipants,
          isNotEnoghGRDN()
        )}
        onClick={
          (joinIdo.toString() === "9" ||
            status === DETAIL_PROJECT_IDO_STATUS.PUBLICSELL) &&
          !allowance
            ? handleApproveToken
            : checkStatus(status, joinIdo.toString()) === "Join IDO pool"
            ? () => handleJoinIdoPool()
            : checkStatus(status, joinIdo.toString()) === "Deposit"
            ? () => openDepositModal()
            : () => {}
        }
        loading={loading}
        disabled={
          isForbiddenCountries ||
          isNotKYC ||
          disable ||
          isNotMaxparticipants ||
          timer === timeDepositStart ||
          disableButtonMaxDeposit ||
          timer === timePublicSellStart ||
          (checkMaxDeposit &&
            saleTypes === 1 &&
            (status === 2 || status === 3)) ||
          (checkMaxTotalSoldCoin && saleTypes === 1 && status === 2) ||
          isNotEnoghGRDN()
        }
      >
        {joinIdo.toString() === "9" && status <= 3 && !allowance
          ? acceptedCurrency === ACCEPTED_CURRENCIES.USDC
            ? "Approve USDC"
            : "Approve USDT"
          : checkStatus(status, joinIdo.toString(), acceptedCurrency)}
      </Button>
    );
  };

  const renderButtonDeposit = () => {
    return (
      <>
        <Button
          disabled={
            isForbiddenCountries ||
            isNotKYC ||
            disable ||
            isNotMaxparticipants ||
            timer === timeDepositStart ||
            timer === timePublicSellStart ||
            disableButtonMaxDeposit ||
            (checkMaxDeposit &&
              saleTypes === 1 &&
              (status === 2 || status === 3)) ||
            (checkMaxTotalSoldCoin && status === 2) ||
            isNotEnoghGRDN()
          }
          className={checkClass(
            timer,
            joinIdo.toString(),
            timeDepositStart,
            timePublicSellStart,
            timeClaimStart,
            balance,
            status,
            disable,
            disableButtonMaxDeposit,
            checkMaxDeposit,
            checkMaxTotalSoldCoin,
            saleTypes,
            isNotKYC,
            isForbiddenCountries,
            isNotMaxparticipants,
            isNotEnoghGRDN()
          )}
          onClick={
            checkStatus(status, joinIdo.toString()) === "Join IDO pool"
              ? () => handleJoinIdoPool()
              : checkStatus(status, joinIdo.toString()) === "Deposit"
              ? () => openDepositModal()
              : () => {}
          }
        >
          {checkStatus(status, joinIdo.toString())}
        </Button>
      </>
    );
  };

  return (
    <>
      {acceptedCurrency === ACCEPTED_CURRENCIES.ROSE || allowance ? (
        (timer !== 0 &&
          joinIdo.toString() !== "3" &&
          joinIdo.toString() !== "4" &&
          joinIdo.toString() !== "2") ||
        status === DETAIL_PROJECT_IDO_STATUS.PUBLICSELL ? (
          <>{renderButtonDeposit()}</>
        ) : null
      ) : timer !== 0 ||
        (joinIdo.toString() !== "3" &&
          joinIdo.toString() !== "4" &&
          joinIdo.toString() !== "2" &&
          joinIdo.toString() !== "1" &&
          status === IDO_STATUS.CLAIMABLE) ||
        joinIdo.toString() === "3" ? (
        <>{renderButtonApprove()}</>
      ) : null}
    </>
  );
};

export default ButtonStatus;
