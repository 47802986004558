/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, InfoCircleFilled } from "@ant-design/icons";
import BigNumber from "bignumber.js";
import {
  ABI_ALLOCATION,
  ACCEPTED_CURRENCIES,
  DECIMAL,
  IDO_STATUS,
  JOIN_IDO_POOL_STATUS,
  SALES_TYPES,
  STATUS_DEPLOY_PROJECT,
} from "common/constants";
import { collapseWallet } from "common/functions";
import useValueFunc from "common/hooks/useValueFunc";
import { IDOType } from "common/types/IDOType";
import SynapsKYC from "components/SynapsKYC";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNumberOfParticipant, setParticipant } from "stores/ido";
import { UserInfoType } from "stores/wallet";
import Web3 from "web3";
import styles from "./index.module.scss";

interface INotification {
  message?: string;
  pool: Partial<IDOType>;
  joinStatus?: number;
  isClaim?: boolean;
  joinIdo?: string;
  isWhitelist?: boolean;
  bypass?: number;
  forbiddenCountries?: string[];
}

const IDOnotification: React.FC<INotification> = (props: INotification) => {
  const { joinIdo, isWhitelist, pool } = props;
  const { timeClaimStart, poolAddress } = useValueFunc({ pool });
  const [isClose, setIsClose] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const wallet_address = useSelector(
    ({ wallet }) => wallet?.userInfo?.wallet_address
  );
  const { ethereum } = window as any;
  const web3 = new Web3(ethereum);
  const wallet = web3.utils.isAddress(wallet_address) ? wallet_address : "";
  const userInfo: UserInfoType | null = useSelector(
    ({ wallet }) => wallet.userInfo
  );

  const {
    participant,
    allowance,
    numberOfParticipant,
    userAmount,
    userPSAmount,
  } = useSelector((state) => state.ido);
  const dispatch = useDispatch();
  let message: React.ReactNode;

  const amountTokenGRDN = useSelector(({ wallet }) => wallet.amountTokenGRDN);
  const amountTotalDelegate = useSelector(
    ({ wallet }) => wallet.delegateStakingAmount
  );

  const DepositFromWei = useMemo(() => {
    if (pool?.total_deposit) {
      return new BigNumber(pool?.total_deposit).div(
        new BigNumber(10).pow(DECIMAL)
      );
    } else {
      return 0;
    }
  }, [pool?.total_deposit]);
  useEffect(() => {
    setIsClose(true);
  }, []);

  const handleClose = () => {
    setIsClose(false);
  };

  const totalRewardAmount = useMemo(() => {
    return new BigNumber(userPSAmount).plus(new BigNumber(userAmount));
  }, [userPSAmount, userAmount]);

  const getMaxParticipant = async () => {
    try {
      const contract = new web3.eth.Contract(ABI_ALLOCATION, poolAddress);
      const numberParticipant = await contract.methods
        .maxParticipant()
        .call({ from: wallet });
      dispatch(setParticipant(numberParticipant));
    } catch (error) {
      console.log({ error });
    }
  };

  const getNumberOfParticipant = async () => {
    try {
      const contract = new web3.eth.Contract(ABI_ALLOCATION, poolAddress);
      const numberOfParticipant = await contract.methods
        .numberOfParticipant()
        .call({ from: wallet });
      dispatch(setNumberOfParticipant(numberOfParticipant));
    } catch (error) {
      console.log({ error });
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setVisible(true);
  };
  useEffect(() => {
    if (wallet && poolAddress) {
      getMaxParticipant();
      getNumberOfParticipant();
    }
  }, [wallet, poolAddress]);

  const checkMaxTotalSoldCoin = new BigNumber(
    pool.total_sold_coin || "0"
  ).isEqualTo(DepositFromWei || "0");

  const isNotEnoghGRDN =
    amountTokenGRDN !== "" &&
    new BigNumber(amountTokenGRDN).isLessThan(pool?.min_grdn || 0);

  const isNotEnoghDelegate =
    amountTotalDelegate !== "" &&
    new BigNumber(amountTotalDelegate || 0).lt(
      new BigNumber(pool?.minimum_delegate_staking_amount || 0)
    );

  const checkIsJoinStatus = () => {
    return pool && pool?.status === IDO_STATUS.UPCOMING;
  };

  const checkIsMaxParticipantsTime = () => {
    return pool && pool?.status === IDO_STATUS.DEPOSIT;
  };

  const checkIsPoolPS = () => {
    return pool && pool?.status! < IDO_STATUS.PUBLIC_SELL;
  };

  const start_time = pool.claim_configs?.[0]?.start_time;

  const formatDate = moment(Number(timeClaimStart) || "").format(
    "HH:mm A, DD MMMM YYYY"
  );
  if (checkIsPoolPS() && isNotEnoghGRDN) {
    message =
      "We are sorry! Your current GRDN tokens are not enough to join the project. Please increase your amount and come back later.";
  }
  if (checkMaxTotalSoldCoin && checkIsMaxParticipantsTime()) {
    message = `All tokens are sold out. Thank you for your participation. You can claim at ${
      start_time
        ? `${moment(Number(start_time) * 1000 || new Date()).format(
            "HH:mm A, DD MMMM YYYY"
          )} UTC`
        : "TBA"
    }`;
  }
  if (checkIsJoinStatus() && isNotEnoghDelegate) {
    message =
      "We are sorry! Your current delegate staking amount is not enough to join the project. Please increase your amount and come back later.";
  }
  if (checkIsJoinStatus() && isNotEnoghDelegate && isNotEnoghGRDN) {
    message =
      "We are sorry! Your current GRDN tokens and delegate staking amount are not enough to join the project. Please increase your amount and come back later.";
  }
  if (
    Number(numberOfParticipant) === Number(participant) &&
    checkIsMaxParticipantsTime() &&
    pool.sale_type === SALES_TYPES.EQUAL_SALE &&
    new BigNumber(totalRewardAmount).lte(0)
  ) {
    message =
      "The pool has reached the max number of participants. Thank you for joining and see you in the upcoming pools.";
  }
  if (Number(joinIdo) === JOIN_IDO_POOL_STATUS.FORBIDDEN_COUNTRY) {
    message = "Oops! It seems like your region is not supported.";
  }

  // if (
  //   new BigNumber(totalSoldCoin || "0").isEqualTo(
  //     new BigNumber(totalRewardAmount),
  //   )
  // ) {
  //   message =
  //     "You have reached the max deposit. Thank you for your participation.";
  // }
  if (
    new BigNumber(pool.max_deposit || "0").isEqualTo(
      new BigNumber(totalRewardAmount)
    ) &&
    checkIsMaxParticipantsTime() &&
    pool.sale_type === SALES_TYPES.EQUAL_SALE
  ) {
    message =
      "You have reached the max deposit. Thank you for your participation.";
  }

  if (
    Number(joinIdo) === JOIN_IDO_POOL_STATUS.NOT_KYC &&
    Number(pool?.status) === IDO_STATUS.UPCOMING
  ) {
    return isClose ? (
      <div className={styles.notification}>
        <InfoCircleFilled className={styles.infoIcon} />
        <span>
          The connected wallet address (
          {collapseWallet(userInfo?.wallet_address || "")}) is unverified.
          Please submit{" "}
          <a href="#kyc" onClick={handleOpen}>
            KYC now
          </a>{" "}
          or switch to a verified address.
        </span>
        <CloseOutlined
          className={styles.close}
          onClick={() => setIsClose(false)}
          title="Close"
        />
        <SynapsKYC visible={visible} onClose={() => setVisible(false)} />
      </div>
    ) : null;
  }
  if (
    Number(joinIdo) === JOIN_IDO_POOL_STATUS.USER_NOT_WHITELISTED &&
    isWhitelist &&
    checkIsJoinStatus()
  ) {
    message =
      "Sorry! You have not been chosen as a whitelist. See you in the upcoming pools.";
  } else if (
    new BigNumber(pool?.total_sold_coin || "0").isEqualTo(DepositFromWei) &&
    timeClaimStart &&
    pool.sale_type === SALES_TYPES.EQUAL_SALE
  ) {
    message = `All tokens are sold out. Thank you for your participation. You can claim at ${formatDate} UTC.`;
  } else if (
    Number(joinIdo) === JOIN_IDO_POOL_STATUS.JOINED &&
    Number(pool?.status) <= IDO_STATUS.PUBLIC_SELL
  ) {
    if (pool?.accepted_currency === ACCEPTED_CURRENCIES.USDC && !allowance) {
      if (
        Number(numberOfParticipant) === Number(participant) &&
        checkIsMaxParticipantsTime() &&
        pool.sale_type === SALES_TYPES.EQUAL_SALE &&
        new BigNumber(totalRewardAmount).lte(0)
      ) {
        message =
          "The pool has reached the max number of participants. Thank you for joining and see you in the upcoming pools.";
      } else if (new BigNumber(totalRewardAmount).lte(0)) {
        message =
          "The pool is open now. You must first approve USDC before depositing.";
      }
    }
    if (pool?.accepted_currency === ACCEPTED_CURRENCIES.USDT && !allowance) {
      if (
        Number(numberOfParticipant) === Number(participant) &&
        checkIsMaxParticipantsTime() &&
        pool.sale_type === SALES_TYPES.EQUAL_SALE &&
        new BigNumber(totalRewardAmount).lte(0)
      ) {
        message =
          "The pool has reached the max number of participants. Thank you for joining and see you in the upcoming pools.";
      } else {
        message =
          "The pool is open now. You must first approve USDT before depositing.";
      }
    }
  }

  return (
    <>
      {isClose &&
      wallet &&
      message &&
      pool.status_deploy === STATUS_DEPLOY_PROJECT.SUCCESS ? (
        <div className={styles.notification}>
          <InfoCircleFilled className={styles.infoIcon} />
          <span>{message}</span>
          <CloseOutlined
            className={styles.close}
            onClick={handleClose}
            title="Close"
          />
        </div>
      ) : null}
    </>
  );
};

export default IDOnotification;
