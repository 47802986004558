import React from "react";
import { RouteProps, withRouter } from "react-router";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { ROUTES } from "./common/constants";
import LayoutLaunchpad from "./components/layout";
import Home from "./pages/home";
import PageNotFound from "./common/pages/404";
import LayoutPrivate from "./components/layout/LayoutPrivate";
import Airdrop from "./pages/user/airdrop";
import Staking from "./pages/user/staking";
import StakingDetail from "./pages/user/staking/detailProject";
import AirdropDetail from "./pages/user/airdrop/detailProject";
import MyProfile from "./pages/user/myProfile";
import IDOPool from "./pages/user/ido-pool/list";
import DetailIDO from "./pages/user/ido-pool/detail/index";

const Routes: React.FC<RouteProps> = (props) => {
  return (
    <LayoutLaunchpad>
      <Route {...props} />
    </LayoutLaunchpad>
  );
};

const RouteUser: React.FC<RouteProps> = (props) => {
  return (
    <LayoutPrivate>
      <Route {...props} />
    </LayoutPrivate>
  );
};

const LaunchPadRoutes: React.FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <RouteUser path={ROUTES.IDOPOOL} exact component={IDOPool} />
      <RouteUser path={ROUTES.IDOPOOL_DETAIL} exact component={DetailIDO} />
      <RouteUser path={ROUTES.STAKING} exact component={Staking} />
      <RouteUser path={ROUTES.AIRDROP} exact component={Airdrop} />
      <RouteUser path={ROUTES.STAKING_DETAIL} exact component={StakingDetail} />
      <RouteUser path={ROUTES.AIRDROP_DETAIL} exact component={AirdropDetail} />
      <RouteUser path={ROUTES.MYPROFILE} exact component={MyProfile} />
      <Routes path={ROUTES.HOME} exact component={Home} />
      <Route path={ROUTES.PAGE_NOT_FOUND} exact component={PageNotFound} />
    </Switch>
  );
};

export default withRouter(LaunchPadRoutes);
