import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { formatUnixTimeToDate } from "common/functions";
import { IDOType } from "common/types/IDOType";
import React, { useCallback } from "react";
import ContentLeft from "./contentLeft";
import ContentLeftDepositComponent from "./contentLeftDeposit";
import "./styles.scss";

interface PropsType {
  pool: Partial<IDOType>;
}

const ListType: React.FC<PropsType> = (props: PropsType) => {
  const { pool } = props;
  const formatDateStart = Number(pool?.start_time_deposit);
  const formatDateEnd = Number(pool?.end_time_deposit);
  const convertDateStart = formatUnixTimeToDate(formatDateStart);
  const convertDateEnd = formatUnixTimeToDate(formatDateEnd);

  const convertDateStartPS = formatUnixTimeToDate(pool?.start_time_public_sell);
  const convertDateEndPS = formatUnixTimeToDate(pool?.end_time_public_sell);

  const claim_time = pool.claim_configs?.[0]?.start_time;
  const convertDateClaim = formatUnixTimeToDate(claim_time);

  const contentRight = [
    {
      text: "Start deposit time",
      data: convertDateStart + ` UTC `,
    },
    { text: "End deposit time", data: convertDateEnd + ` UTC ` },
    {
      text: "Claim time",
      data: claim_time ? convertDateClaim + ` UTC ` : "TBA",
    },
  ];
  const contentRightPS = [
    {
      text: "Start deposit time",
      data: convertDateStart + ` UTC `,
    },
    { text: "End deposit time", data: convertDateEnd + ` UTC ` },
    {
      text: "Start public sell time",
      data: convertDateStartPS + ` UTC `,
    },
    { text: "End public sell time", data: convertDateEndPS + ` UTC ` },
    {
      text: "Claim time",
      data: claim_time ? convertDateClaim + ` UTC ` : "TBA",
    },
  ];

  const mapTimeStatus = pool?.start_time_public_sell
    ? contentRightPS
    : contentRight;

  const showToolTips = (text: string) => {
    if (text === "Access Type") {
      return (
        <div className="Tooltips">
          <p>
            <span className="title">Normal users:</span> For everyone
          </p>
          <p>
            <span className="title">Whitelist:</span> Only for the whitelisted
          </p>
        </div>
      );
    } else {
      return (
        <div className="Tooltips">
          <p>
            To be eligible for the reward, please keep the GRDN in your wallet
            until the pool ends the deposit period.
          </p>
        </div>
      );
    }
  };

  const renderContent = useCallback((contentData) => {
    return contentData.map(({ text, data }: any) => {
      return (
        <div className="element" key={text}>
          <div className="access-type">
            <span></span>
            <span>{text}</span>
            {text === "Access Type" ||
            text === "Minimum GRDN tokens required" ? (
              <Tooltip
                placement="bottomLeft"
                color="#fff"
                title={showToolTips(text)}
              >
                <InfoCircleOutlined className="QuestionIcon" />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <span className="text">{data}</span>
        </div>
      );
    });
  }, []);

  return (
    <Row style={{ marginTop: "4px" }}>
      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
        {pool?.sale_type === 1 ? (
          <ContentLeftDepositComponent
            pool={pool}
            renderContent={renderContent}
          />
        ) : (
          <ContentLeft pool={pool} renderContent={renderContent} />
        )}
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
        {mapTimeStatus.map((item) => {
          const { text, data } = item;
          const content = [
            {
              text: text,
              data: data,
            },
          ];
          return (
            <React.Fragment key={text}>{renderContent(content)}</React.Fragment>
          );
        })}
      </Col>
    </Row>
  );
};

export default ListType;
