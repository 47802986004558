import React from "react";
import _ from "lodash";
import { Dictionary } from "../../../../common/types/Types";
import { PROJECT_STAKING_TYPE } from "../../../../common/constants";
import styles from "./DetailProject.module.scss";
import { useTranslation } from "react-i18next";
import { formatBigNumber, toRealNumber } from "../../../../common/functions";

interface WrapperStakeProps {
  projectType?: number;
  otherStake?: Dictionary<number>;
  whiteListStake?: Dictionary<number>;
  dataTotalStake?: any;
  otherHardCap?: string;
  whitelistHardCap?: string;
  tokenSymbol?: string;
  totalParticipantFake?: any;
}

const WrapperStake: React.FC<WrapperStakeProps> = ({
  projectType,
  otherStake,
  whiteListStake,
  otherHardCap,
  whitelistHardCap,
  tokenSymbol,
  dataTotalStake,
  totalParticipantFake,
}) => {
  const { t } = useTranslation();
  const totalStake = toRealNumber(_.get(dataTotalStake, "total_stake", 0));
  const totalotherStake = toRealNumber(_.get(otherStake, "total_stake", 0));
  const totalParticipant = _.get(dataTotalStake, "total_participant", 0);
  const otherTotalParticipant = _.get(otherStake, "total_participant", 0);
  const whiteListTotalStake = toRealNumber(
    _.get(whiteListStake, "total_stake", 0)
  );
  const whiteListTotalParticipant = _.get(
    whiteListStake,
    "total_participant",
    0
  );

  const renderWhiteListStake = () => {
    return (
      <section>
        <article>
          <span className={styles.DetailProject__WrapperStake__Title}>
            {t("HardCap")}
          </span>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {whitelistHardCap && formatBigNumber(whitelistHardCap)}
            <span>{tokenSymbol}</span>
          </p>
        </article>
        <article>
          <span className={styles.DetailProject__WrapperStake__Title}>
            {t("TotalStaked")}
          </span>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {projectType === PROJECT_STAKING_TYPE.PRIVATE
              ? totalStake === "0"
                ? "0"
                : formatBigNumber(totalStake)
              : whiteListTotalStake === "0"
              ? "0"
              : formatBigNumber(whiteListTotalStake)}
            {` ${t("ETH")}`}
          </p>
        </article>
        <article>
          <span className={styles.DetailProject__WrapperStake__Title}>
            {t("TotalParticipants")}
          </span>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {projectType === PROJECT_STAKING_TYPE.PRIVATE
              ? totalParticipant || t("Airdrop.Tba")
              : whiteListTotalParticipant}
          </p>
        </article>
      </section>
    );
  };

  const renderOtherStake = () => {
    return (
      <section>
        <article>
          <span className={styles.DetailProject__WrapperStake__Title}>
            {t("HardCap")}
          </span>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {otherHardCap && formatBigNumber(otherHardCap)}
            <span>{tokenSymbol}</span>
          </p>
        </article>
        <article>
          <span className={styles.DetailProject__WrapperStake__Title}>
            {t("TotalStaked")}
          </span>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {projectType === PROJECT_STAKING_TYPE.PUBLIC
              ? totalStake === "0"
                ? "0"
                : formatBigNumber(totalStake)
              : totalotherStake === "0"
              ? "0"
              : formatBigNumber(totalotherStake)}
            {` ${t("ETH")}`}
          </p>
        </article>
        <article>
          <span className={styles.DetailProject__WrapperStake__Title}>
            {t("TotalParticipants")}
          </span>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {projectType === PROJECT_STAKING_TYPE.PUBLIC
              ? Math.max(
                  Number(totalParticipant),
                  Number(totalParticipantFake)
                ) || t("Airdrop.Tba")
              : otherTotalParticipant}
          </p>
        </article>
      </section>
    );
  };

  return (
    <div className={styles.DetailProject__WrapperStake}>
      {/* whiteList */}
      {projectType === PROJECT_STAKING_TYPE.PRIVATE && (
        <div className={styles.DetailProject__WrapperStake__Normal}>
          {renderWhiteListStake()}
        </div>
      )}
      {/* other */}
      {projectType === PROJECT_STAKING_TYPE.PUBLIC && (
        <div className={styles.DetailProject__WrapperStake__Normal}>
          {renderOtherStake()}
        </div>
      )}
      {/* all */}
      {projectType === PROJECT_STAKING_TYPE.HYBRID && (
        <div className={styles.DetailProject__WrapperStake__HyBird}>
          <div
            className={styles.DetailProject__WrapperStake__HyBird__Wrapper}
            style={{ marginBottom: "5px" }}
          >
            <h6>{t("Staking.Whitelist")}</h6>
            {renderWhiteListStake()}
          </div>
          <div className={styles.DetailProject__WrapperStake__HyBird__Wrapper}>
            <h6>{t("Staking.Others")}</h6>
            {renderOtherStake()}
          </div>
        </div>
      )}
    </div>
  );
};

export default WrapperStake;
