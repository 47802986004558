import Web3 from 'web3';

export const isMetaMaskInstalled = () => {
  const windowObj = window as any;
  const { ethereum } = windowObj;
  return ethereum && ethereum.isMetaMask
}

export const getWeb3Instance = () => {
  const windowObj = window as any;
  const { ethereum, web3 } = windowObj;
  if (ethereum && ethereum.isMetaMask) {
    return new Web3(ethereum);
  }
  if (web3) {
    return new Web3(web3.currentProvider);
  }
  return null;
};

export const web3 = getWeb3Instance();

export const isLoginWithAcc = async (): Promise<boolean | string> => {
  let result: string | boolean = false;
  await web3?.eth.getAccounts(function (err, accounts) {
    if (err != null) console.error("An error occurred: " + err);
    else if (accounts.length === 0) {
    } else {
      result = accounts[0];
    }
  });
  return result;
};
