import { CloseOutlined, InfoCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import {
  BYPASS_STATUS,
  DETAIL_PROJECT_STATUS,
  PROJECT_STAKING_TYPE,
  STATUS_JOIN_POOL,
  STATUS_KYC_USER,
} from "../../common/constants";
import { collapseWallet } from "../../common/functions";
import { AirdropType } from "../../common/types/AirdropType";
import { StakingType } from "../../common/types/StakingType";
import { UserInfoType } from "../../stores/wallet";
import SynapsKYC from "../SynapsKYC";
import styles from "./index.module.scss";

interface PropsTypes {
  message?: string;
  bypass?: number;
  forbiddenCountries?: string[];
  poolDetail?: StakingType | AirdropType | any;
  isWhiteList?: boolean;
  joinStatus?: number;
  isClaim?: boolean;
}
export const HeaderNotification: React.FC<PropsTypes> = (props) => {
  const {
    bypass,
    forbiddenCountries,
    poolDetail,
    isWhiteList,
    joinStatus,
    isClaim,
  } = props;
  const [visible, setVisible] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const userInfo: UserInfoType | null = useSelector(
    ({ wallet }) => wallet.userInfo
  );
  const amountTokenGRDN = useSelector(({ wallet }) => wallet.amountTokenGRDN);
  const amountTotalDelegate = useSelector(
    ({ wallet }) => wallet.delegateStakingAmount
  );
  useEffect(() => {
    setVisible(false);
    setIsClose(false);
  }, [userInfo?.wallet_address]);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setVisible(true);
  };
  const handleClose = () => setVisible(false);
  let message: React.ReactNode = props.message || null;
  const checkIsClaimTime = () => {
    return (
      poolDetail &&
      poolDetail.status >= DETAIL_PROJECT_STATUS.AWAITING_RESULTS &&
      poolDetail.status <= DETAIL_PROJECT_STATUS.COMPLETED
    );
  };

  const checkIsJoinTime = () => {
    return (
      poolDetail &&
      poolDetail.status >= DETAIL_PROJECT_STATUS.AWAITING_STAKING &&
      poolDetail.status <= DETAIL_PROJECT_STATUS.STAKING
    );
  };
  const checkIsStakingTime = () => {
    return poolDetail && poolDetail.status === DETAIL_PROJECT_STATUS.STAKING;
  };
  const isNotKYC =
    checkIsJoinTime() &&
    bypass === BYPASS_STATUS.REQUIRED &&
    userInfo?.wallet_address &&
    userInfo.kyc_status !== STATUS_KYC_USER.VERIFIED;
  const isForbiddenCountries =
    checkIsJoinTime() &&
    userInfo?.kyc_country &&
    forbiddenCountries?.includes(userInfo?.kyc_country);
  const isNotEnoghDelegate =
    amountTotalDelegate !== "" &&
    new BigNumber(amountTotalDelegate || 0).lt(
      new BigNumber(poolDetail?.minimum_delegate_staking_amount || 0)
    );
  const isNotEnoghGRDN =
    amountTokenGRDN !== "" &&
    new BigNumber(amountTokenGRDN || 0).lt(
      new BigNumber(poolDetail?.min_grdn || 0)
    );
  if (isForbiddenCountries)
    message = "Oops! It seems like your region is not supported.";
  if (
    poolDetail?.project_type !== PROJECT_STAKING_TYPE.PRIVATE ||
    (poolDetail?.project_type === PROJECT_STAKING_TYPE.PRIVATE && isWhiteList)
  ) {
    if (poolDetail?.project_type !== PROJECT_STAKING_TYPE.PRIVATE) {
      if (
        joinStatus === STATUS_JOIN_POOL.NOT_JOIN &&
        checkIsJoinTime() &&
        isNotEnoghDelegate
      )
        message =
          "We are sorry! Your current delegate staking amount is not enough to join the project. Please increase your amount and come back later.";
      if (
        joinStatus === STATUS_JOIN_POOL.NOT_JOIN &&
        checkIsJoinTime() &&
        isNotEnoghGRDN
      )
        message =
          "We are sorry! Your current GRDN tokens is not enough to join the project. Please increase your amount and come back later.";
      if (
        joinStatus === STATUS_JOIN_POOL.NOT_JOIN &&
        checkIsJoinTime() &&
        isNotEnoghDelegate &&
        isNotEnoghGRDN
      )
        message =
          " We are sorry! Your current GRDN tokens and delegate staking amount are not enough to join the project. Please increase your amount and come back later.";
    }

    if (
      joinStatus === STATUS_JOIN_POOL.INVALID_DELEGATE_STAKING_AMOUNT &&
      checkIsStakingTime()
    )
      message =
        "We are sorry! Your current delegate staking amount is not enough to stake. Please increase your amount and come back later.";
    if (
      joinStatus === STATUS_JOIN_POOL.INVALID_GRDN_TOKEN_AMOUNT &&
      checkIsStakingTime()
    )
      message =
        "We are sorry! Your current GRDN tokens is not enough to stake. Please increase your amount and come back later.";
    if (
      checkIsStakingTime() &&
      ((joinStatus === STATUS_JOIN_POOL.INVALID_GRDN_TOKEN_AMOUNT &&
        isNotEnoghDelegate) ||
        (joinStatus === STATUS_JOIN_POOL.INVALID_DELEGATE_STAKING_AMOUNT &&
          isNotEnoghGRDN))
    )
      message =
        "We are sorry! Your current GRDN tokens and delegate staking amount are not enough to stake. Please increase your amount and come back later.";

    if (
      !isClaim &&
      joinStatus === STATUS_JOIN_POOL.INVALID_DELEGATE_STAKING_AMOUNT &&
      checkIsClaimTime()
    )
      message =
        "We are sorry! Your current delegate staking amount is not enough to claim your reward. Please increase your amount and come back later.";
    if (
      !isClaim &&
      joinStatus === STATUS_JOIN_POOL.INVALID_GRDN_TOKEN_AMOUNT &&
      poolDetail &&
      poolDetail.status >= DETAIL_PROJECT_STATUS.AWAITING_RESULTS &&
      poolDetail.status <= DETAIL_PROJECT_STATUS.AWAITING_STAKING
    )
      message =
        "We are sorry! Your current GRDN tokens is not enough to claim your reward. Please increase your amount and come back later.";
    if (
      !isClaim &&
      checkIsClaimTime() &&
      ((joinStatus === STATUS_JOIN_POOL.INVALID_GRDN_TOKEN_AMOUNT &&
        isNotEnoghDelegate) ||
        (joinStatus === STATUS_JOIN_POOL.INVALID_DELEGATE_STAKING_AMOUNT &&
          isNotEnoghGRDN))
    )
      message =
        "We are sorry! Your current GRDN tokens and delegate staking amount are not enough to claim your reward. Please increase your amount and come back later.";
  }
  if (isClose || !userInfo?.wallet_address) return null;

  if (message)
    return (
      <div className={styles.notification}>
        <InfoCircleFilled className={styles.infoIcon} />
        <span>{message}</span>
        <CloseOutlined
          className={styles.close}
          onClick={() => setIsClose(true)}
          title="Close"
        />
      </div>
    );
  else if (isNotKYC) {
    return (
      <div className={styles.notification}>
        <InfoCircleFilled className={styles.infoIcon} />
        <span>
          The connected wallet address (
          {collapseWallet(userInfo?.wallet_address || "")}) is unverified.
          Please submit{" "}
          <a href="#kyc" onClick={handleOpen}>
            KYC now
          </a>{" "}
          or switch to a verified address.
        </span>
        <CloseOutlined
          className={styles.close}
          onClick={() => setIsClose(true)}
          title="Close"
        />
        <SynapsKYC visible={visible} onClose={handleClose} />
      </div>
    );
  }
  return null;
};

export default HeaderNotification;
