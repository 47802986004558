import { KEY } from "../common/constants";

export const STORAGE_KEYS = {
  DARKMODE: "isDarkMode",
};

export default class StorageUtils {
  static setItem(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  static getItem(key: string, defaultValue: string) {
    const result = window.localStorage.getItem(key);
    if (result === null || result === undefined) return defaultValue;
    return result;
  }

  static removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  // section storage
  static setSectionStorageItem(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  static getSectionStorageItem(key: string) {
    return window.localStorage.getItem(key);
  }

  static removeSectionStorageItem(key: string) {
    window.localStorage.removeItem(key);
  }

  static removeUser() {
    this.removeSectionStorageItem(KEY.USER);
  }

  // Token
  static setToken(value = "") {
    StorageUtils.setSectionStorageItem(KEY.TOKEN_ACCESS, value);
  }

  static getToken() {
    return StorageUtils.getSectionStorageItem(KEY.TOKEN_ACCESS);
  }

  static removeToken() {
    StorageUtils.removeSectionStorageItem(KEY.TOKEN_ACCESS);
  }

  static setUser(value: any) {
    this.setSectionStorageItem(KEY.USER, JSON.stringify(value));
  }

  static getUser() {
    const user = this.getSectionStorageItem(KEY.USER) as string;
    return JSON.parse(user);
  }
}
