import { Col, Divider, Row } from "antd";
import BigNumber from "bignumber.js";
import {
  ACCEPTED_CURRENCIES_NAME,
  DETAIL_PROJECT_IDO_STATUS,
  STATUS_DEPLOY_PROJECT,
} from "common/constants";
import { checkTimeCountDown, formatBigNumber } from "common/functions";
import useValueFunc from "common/hooks/useValueFunc";
import ModalDeposit from "common/popup/deposit/ModalDeposit";
import { IDOType } from "common/types/IDOType";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserService } from "services/UserServicer";
import { setJoinIdo, setOpenDeposit } from "stores/ido";
import Web3 from "web3";

import ButtonStatus from "./ButtonStatus";
import "./styles.scss";
import YourDeposit from "./YourDeposit";
import YourReward from "./YourReward.tsx";

interface PropsType {
  pool: Partial<IDOType>;
  getAllBalance: () => void;
  onDepositSuccess: (value: string) => void;
  yourReward?: string;
}

const AmoutCard: React.FC<PropsType> = memo((props: PropsType) => {
  const { pool, getAllBalance, onDepositSuccess, yourReward } = props;
  const {
    totalSoldCoin,
    tokenSymbol,
    exchangeRate,
    acceptedCurrency,
    timeDepositStart,
    timeApplicationStart,
    timeApplicationEnd,
    timeDepositEnd,
    maxDeposit,
    poolAddress,
    timePublicSellEnd,
    timePublicSellStart,
    timeClaimStart,
    status,
    remainingToken,
    saleTypes,
    totalDeposit,
    receivedFeeWallet,
    statusDeploy,
    tokenDecimal,
    minGrdn,
    claimConfig,
  } = useValueFunc({ pool });
  const { openDeposit, joinIdo, userPSAmount } = useSelector(
    (state) => state.ido
  );
  const { userInfo } = useSelector((state) => state.wallet);
  const wallet = Web3.utils.isAddress(userInfo?.wallet_address)
    ? userInfo?.wallet_address
    : "";
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const userService = new UserService();
  const exchangeRates = new BigNumber(exchangeRate as any);

  const timer = checkTimeCountDown(
    timeApplicationStart,
    timeApplicationEnd,
    timeDepositStart,
    timeDepositEnd,
    timeClaimStart,
    timePublicSellEnd,
    timePublicSellStart
  );
  const openDepositModal = (): void => {
    dispatch(setOpenDeposit(true));
  };

  const hideDeposit = (): void => {
    dispatch(setOpenDeposit(false));
  };

  // const yourReward = useMemo(() => {
  //   return new BigNumber(exchangeRate as string)
  //     .multipliedBy(new BigNumber(userAmount))
  //     .toString();
  // }, [userAmount, exchangeRate]);

  // const yourPSReward = useMemo(() => {
  //   return new BigNumber(exchangeRate as string)
  //     .multipliedBy(new BigNumber(userPSAmount))
  //     .toString();
  // }, [userPSAmount, exchangeRate]);

  const checkJoinIdoPool = async () => {
    try {
      const params = {
        ido_pool_id: id,
        wallet_address: wallet,
      };
      const result = await userService.CheckJoinPool(params);
      const data = result?.data || {};
      switch (data.status.toString()) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "10":
          dispatch(setJoinIdo(data.status.toString()));
          break;
        case "8":
          dispatch(setJoinIdo(data.status.toString()));
          break;
        case "9":
          dispatch(setJoinIdo(data.status.toString()));
          break;
        default:
          dispatch(setJoinIdo(""));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (poolAddress && wallet) checkJoinIdoPool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, poolAddress, timer]);

  return (
    <>
      <Row
        style={{ background: "#fff", display: "flex", alignItems: "center" }}
        className="row"
      >
        <Row style={{ width: "100%" }}>
          <YourDeposit
            acceptedCurrency={acceptedCurrency}
            wallet={wallet}
            status={status}
          />
          <YourReward
            yourReward={yourReward}
            // yourPSReward={yourPSReward}
            poolAddress={poolAddress}
            tokenSymbol={tokenSymbol}
            wallet={wallet}
            status={status}
            userPSAmount={userPSAmount}
            tokenDecimal={tokenDecimal}
            minGrdn={minGrdn}
            claimConfig={claimConfig}
          />
          {status >= 2 ? <Divider /> : ""}

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
          >
            <div className="hard-cap">
              <span>Hard cap</span>
              <div className="hard-cap-num">
                <span>{formatBigNumber(totalSoldCoin || 0)}</span>
                <span> {tokenSymbol}</span>
              </div>
            </div>
            <div className="exchange-rate">
              <span>Exchange rate</span>
              {new BigNumber(exchangeRates).isGreaterThan(0) ? (
                <span>
                  1 {ACCEPTED_CURRENCIES_NAME[acceptedCurrency as any]} ={" "}
                  {formatBigNumber(exchangeRates) || "0"} {tokenSymbol}
                </span>
              ) : (
                <span>TBA</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            className="col-button"
          >
            {statusDeploy === STATUS_DEPLOY_PROJECT.SUCCESS &&
              wallet !== "" &&
              (joinIdo.toString() !== "4" ||
                status === DETAIL_PROJECT_IDO_STATUS.PUBLICSELL) &&
              joinIdo.toString() !== "2" && (
                <ButtonStatus
                  pool={pool}
                  timer={timer}
                  openDepositModal={openDepositModal}
                />
              )}
          </Col>
        </Row>
      </Row>
      <ModalDeposit
        openDeposit={openDeposit}
        hideDeposit={hideDeposit}
        maxDeposit={maxDeposit}
        acceptedCurrency={acceptedCurrency}
        poolAddress={poolAddress}
        exchangeRate={exchangeRate}
        status={status}
        remainingToken={remainingToken}
        saleTypes={saleTypes}
        tokenSymbol={tokenSymbol}
        totalSoldCoin={totalSoldCoin}
        totalDeposit={totalDeposit}
        getAllBalance={getAllBalance}
        onDepositSuccess={onDepositSuccess}
        receivedFeeWallet={receivedFeeWallet}
        id={id}
        tokenDecimal={tokenDecimal}
      />
    </>
  );
});

export default AmoutCard;
