import React from "react";
import styles from "./ProjectDetailItem.module.scss";
import images from "../../themes/images";
import { useTranslation } from "react-i18next";

export interface Dictionary<T> {
  [Key: string]: T;
}

interface StatusItemProps {
  statusItem: Dictionary<number>;
  currentStatus?: number;
  index?: number;
}

const StatusItem: React.FC<StatusItemProps> = ({
  statusItem,
  currentStatus,
  index
}) => {
  const { t } = useTranslation();
  const { status, label, statusRender } = statusItem;
  if (!currentStatus) return <div />;

  return (
    <div className={styles.StatusItem}>
      {status <= currentStatus ? (
        <img src={images.icons.StatusIconSvg} alt="Status Icon" />
      ) : (
        <div className={styles.StatusItem__CurrentStatus}>{statusRender ? statusRender : index || status}</div>
      )}
      <div className={`${styles.StatusItem__Label} ${status > currentStatus ? styles.active : ''}`}>{t(`${label}`)}</div>
    </div>
  );
};

export default StatusItem;
