import React, { useEffect, useState, createContext } from "react";
import _, { toNumber } from "lodash";
import styles from "./DetailProject.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { UserService } from "../../../../services/UserServicer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AIRDROP_STATUS_PUBLIC_LINEAR_AND_QUADRATIC,
  AIRDROP_STATUS_PUBLIC_FIXED_AMOUNT,
  AIRDROP_STATUS_PRIVATE_LINEAR_AND_QUADRATIC,
  AIRDROP_STATUS_PRIVATE_FIXED_AMOUNT,
  AIRDROP_TYPE,
  AIRDROP_CLAIM_TYPE,
  AIRDROP_STATUS,
  ROUTES,
  ALERT_TIME,
  STATUS_JOIN_POOL,
  PROJECT_AIRDROP_TYPE,
} from "../../../../common/constants";
import {
  checkRenderPublicLinearAndQuadratic,
  checkRenderPublicFixedAmount,
  checkRenderPrivateLinearAndQuadratic,
  checkRenderPrivateFixedAmount,
  formatBigNumber,
} from "../../../../common/functions";
import WrapperStake from "./WrapperStake";
import CardStatus from "../../../../common/projectDetailItem/CardStatus";
import "./MessageCustom.scss";
import { checkTime } from "../../../../common/functions";
import { checkProjectType } from "../../../../common/functions";
import HeaderProject from "../../../../common/projectDetailItem/HeaderProject";
import CountDown from "../../../../common/countDown";
import YourReward from "./YourReward";
import { Dictionary } from "@reduxjs/toolkit";
import ErrorSystem from "../../../../common/alert/errorSystem";
import { message } from "antd";
import HeaderNotification from "../../../../components/HeaderNotification";
import BigNumber from "bignumber.js";
import messageCustom from "../../../../common/alert/message";
import { web3 } from "../../../../services/Web3";
import {
  setAmountTokenGRDN,
  // setTotalDelegateStakingAmount,
} from "../../../../stores/wallet";
const ERC20_ABI = require("../../../../abi/ERC20.json");
interface DetailAirdrop {
  id?: number;
  airdrop_fix_amount?: number;
  announce_time: number;
  buy_type?: number;
  created_at?: moment.Moment;
  display_price_rate?: number;
  distribute_time: number;
  distribution_method: number;
  finish_time: number;
  max_stake?: number;
  medium_link?: string;
  min_stake?: number;
  other_hardcap?: string;
  other_stake?: Dictionary<number>;
  other_trigger?: string;
  other_untrigger?: string;
  project_contract_address?: string;
  project_information?: string;
  project_name?: string;
  project_type: number;
  snapshot_time: number;
  start_time: number;
  status?: number;
  telegram_link?: string;
  title?: string;
  token_address?: string;
  token_name?: string;
  token_symbol?: string;
  total_raise_amount?: number;
  twitter_link?: string;
  updated_at?: moment.Moment;
  website?: string;
  whiteList_stake?: Dictionary<number>;
  whitelist_hardcap?: string;
  whitelist_trigger?: string;
  whitelist_untrigger?: string;
  whitepaper_link?: string;
  token_icon?: string;
  total_stake?: string;
  total_participant: string;
  token_conversion_rate: string;
  status_deploy?: string;
  kyc_bypass?: number;
  forbidden_countries?: string[];
  stake_type?: number;
  fee?: string;
  min_grdn?: string;
  minimum_delegate_staking_amount?: string;
  token_decimal: number;
}

export const stakeContext = createContext({});

const DetailProject: React.FC = () => {
  const { id } = useParams<any>();
  const { t } = useTranslation();
  const UserServiceAPI = new UserService();
  const rootState = useSelector((state) => state.wallet);
  const { userInfo } = rootState;
  const initDataDetail = {
    announce_time: 0,
    distribute_time: 0,
    distribution_method: 0,
    finish_time: 0,
    project_type: 0,
    snapshot_time: 0,
    start_time: 0,
    total_participant: "",
    token_conversion_rate: "",
    token_decimal: 18,
  };
  const [dataDetail, setDataDetail] = useState<DetailAirdrop>(initDataDetail);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const wallet = _.get(userInfo, "wallet_address", null);
  const [currentStatus, setCurrentStatus] = useState<number>();
  const [joinStatus, setJoinStatus] = useState(0);
  const [isClaim, setClaim] = useState<boolean>(false);
  const [isCheckJoinAndClaim, setIsCheckJoinAndClaim] = useState(false);
  const [isWhiteList, setIsWhiteList] = useState<boolean>(false);
  const [isProcess, setIsProcess] = useState<boolean>(false);
  let interval: NodeJS.Timeout;
  const history = useHistory();

  const {
    project_type,
    token_symbol,
    twitter_link,
    medium_link,
    telegram_link,
    whitepaper_link,
    status,
    other_trigger,
    other_untrigger,
    whitelist_untrigger,
    whitelist_trigger,
    start_time,
    finish_time,
    announce_time,
    distribute_time,
    project_contract_address,
    token_icon,
    website,
    token_name,
    snapshot_time,
    token_conversion_rate,
    distribution_method,
    total_participant,
    total_raise_amount,
    status_deploy,
    kyc_bypass,
    forbidden_countries,
    min_grdn,
    minimum_delegate_staking_amount,
  } = dataDetail;

  const dataStake = {
    projectType: project_type,
    otherUnTrigger: other_untrigger,
    otherTrigger: other_trigger,
    whiteListUnTrigger: whitelist_untrigger,
    whiteListTrigger: whitelist_trigger,
    tokenSymbol: token_symbol,
  };

  const getDetailProject = async () => {
    try {
      const params = {
        id,
      };
      const result = await UserServiceAPI.getAirdropDetailProject(params);
      const dataDetail = _.get(result, "data.data", {});
      if (!dataDetail) {
        history.push(ROUTES.PAGE_NOT_FOUND);
        return;
      }
      const {
        total_raise_amount,
        token_conversion_rate,
        status,
        distribution_method,
      } = dataDetail;
      if (new BigNumber(total_raise_amount || 0).gt(0)) {
        dataDetail.total_raise_amount = total_raise_amount;
      }
      if (new BigNumber(token_conversion_rate || 0).gt(0)) {
        switch (Number(distribution_method)) {
          case AIRDROP_CLAIM_TYPE.LINEAR: {
            dataDetail.token_conversion_rate = token_conversion_rate;
            break;
          }
          case AIRDROP_CLAIM_TYPE.FIXED_AMOUNT: {
            dataDetail.token_conversion_rate = token_conversion_rate;
            break;
          }
          default: {
            break;
          }
        }
      }
      status !== currentStatus && clearInterval(interval);
      setCurrentStatus(status);
      setDataDetail(dataDetail);
    } catch (error) {
      return message.info({
        content: <ErrorSystem />,
        icon: <></>,
        duration: ALERT_TIME,
      });
    }
  };
  const getAmountTokenGRDN = async () => {
    if (userInfo?.wallet_address) {
      const contract =
        web3 &&
        (new web3.eth.Contract(
          ERC20_ABI,
          process.env.REACT_APP_TOKEN_GRDN_ADRRESS
        ) as any);
      const decimals = await contract?.methods.decimals().call();

      const tokenGRDN = await contract?.methods
        .balanceOf(userInfo.wallet_address)
        .call();

      setAmountTokenGRDN(
        new BigNumber(tokenGRDN).div(new BigNumber(10).pow(decimals)).toString()
      );
    }
  };
  // const getDelegateAmount = async () => {
  //   if (userInfo?.oasis_address) {
  //     try {
  //       const { data } = await UserServiceAPI.getDelegateStakingAmount(
  //         userInfo.oasis_address
  //       );
  //       const totalAmount = _.get(data, "data.total_amount", "");
  //       setTotalDelegateStakingAmount(web3?.utils.fromWei(totalAmount) || "0");
  //     } catch (err) {
  //       return message.info({
  //         content: <ErrorSystem />,
  //         icon: <></>,
  //         duration: ALERT_TIME,
  //       });
  //     }
  //   }
  // };
  useEffect(() => {
    getAmountTokenGRDN();
    getDetailProject();
    // getDelegateAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, isJoined, id]);

  const { accessType } = checkProjectType(dataStake);
  const checkTypeProject = () => {
    if (project_type === AIRDROP_TYPE.PUBLIC) {
      if (distribution_method === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT) {
        return AIRDROP_STATUS_PUBLIC_FIXED_AMOUNT;
      }
      return AIRDROP_STATUS_PUBLIC_LINEAR_AND_QUADRATIC;
    } else {
      if (distribution_method === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT) {
        return AIRDROP_STATUS_PRIVATE_FIXED_AMOUNT;
      }
      return AIRDROP_STATUS_PRIVATE_LINEAR_AND_QUADRATIC;
    }
  };
  const checkJoinAndClaimProject = async (
    projectId: number,
    address: string
  ) => {
    try {
      setIsProcess(true);
      const params = { projectId: projectId, address: address };
      const result = await UserServiceAPI.checkJoinAndClaimAirdropProject(
        params
      );
      const data = result.data.data;
      if (!data) throw new Error("Cannot check Join and Claim!");
      if (
        data?.status_join === STATUS_JOIN_POOL.JOINED ||
        data?.status_join === STATUS_JOIN_POOL.INVALID_GRDN_TOKEN_AMOUNT
      ) {
        if (data.status_claim === 1) {
          setClaim(true);
        } else {
          setClaim(false);
        }
      }
      setJoinStatus(data?.status_join);
      setIsCheckJoinAndClaim(true);
    } catch {
      setIsCheckJoinAndClaim(false);
      return messageCustom.error("System Error");
    } finally {
      setIsProcess(false);
    }
  };

  const getCheckWhiteList = async (
    projectId: number,
    projectType: number,
    isConnectMetaMark: boolean,
    isConnectNetwork: boolean
  ) => {
    if (
      projectType === PROJECT_AIRDROP_TYPE.PRIVATE &&
      isConnectMetaMark &&
      isConnectNetwork
    ) {
      try {
        const result = await UserServiceAPI.checkWhiteList(projectId);
        const data = _.get(result, "data.data", {});

        if (data) {
          setIsWhiteList(true);
        }
      } catch (error: any) {
        return message.info({
          content: <ErrorSystem />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
    } else {
      setIsWhiteList(false);
    }
  };
  const minimumStakeAmountRequired =
    minimum_delegate_staking_amount && +minimum_delegate_staking_amount
      ? t("Staking.Detail.StakeValue", {
          value: formatBigNumber(minimum_delegate_staking_amount),
        })
      : t("Staking.MinimumStake.None");
  const minimumStakeGRDNAmount =
    min_grdn && +min_grdn
      ? t("Staking.Detail.StakeGRDNToken", {
          value: formatBigNumber(min_grdn),
        })
      : t("Staking.MinimumStake.None");
  const checkClaimMethod = () => {
    let claimMethod = "";
    let tokenRate = "";
    switch (Number(distribution_method)) {
      case AIRDROP_CLAIM_TYPE.LINEAR:
        claimMethod = t("Airdrop.ClaimMethod.Linear");
        tokenRate = t("Airdrop.Linear.Rate", {
          tokenSymbol: token_symbol,
          tokenRate: formatBigNumber(token_conversion_rate),
        });
        break;

      case AIRDROP_CLAIM_TYPE.QUADRATIC:
        claimMethod = t("Airdrop.ClaimMethod.Quadratic");
        tokenRate = t("Airdrop.Quadratic.Rate", {
          tokenSymbol: token_symbol,
        });
        break;
      /* Fixed Amount Claim type*/
      default:
        claimMethod = t("Airdrop.ClaimMethod.FixedAmount");
        tokenRate = t("Airdrop.FixedAmount.Rate", {
          tokenRate: formatBigNumber(token_conversion_rate),
          tokenSymbol: token_symbol,
        });
        break;
    }
    return { claimMethod, tokenRate };
  };
  const { claimMethod, tokenRate } = checkClaimMethod();

  const handleFinishCountdown = () => {
    interval = setInterval(() => {
      if (dataDetail.status === currentStatus) {
        getDetailProject();
      }
    }, 5000);
    if (dataDetail.status === currentStatus) {
      getDetailProject();
    }
  };

  const renderCountdown = () => {
    let timer = 0;
    let prefix = "";

    switch (Number(status)) {
      case AIRDROP_STATUS.AWAITING_APPLICATION:
        timer = start_time;
        prefix = "Application starts in ";
        break;
      case AIRDROP_STATUS.APPLICATION:
        timer = finish_time;
        prefix = "Application ends in ";
        break;
      case AIRDROP_STATUS.AWAITING_RESULTS:
        timer = announce_time;
        prefix = "Results announced in  ";
        break;
      case AIRDROP_STATUS.AWAITING_CLAIM:
        timer = distribute_time;
        prefix = "Claiming starts in ";
        break;
      default:
        return;
    }

    return (
      <CountDown
        timer={timer}
        prefix={prefix}
        onFinish={handleFinishCountdown}
        isDetail={true}
      />
    );
  };
  const renderMinimumDelegateStakingRequired = () => {
    return (
      <CardStatus
        title={t("Staking.Detail.MinimumDelegateStakeAmount")}
        value={minimumStakeAmountRequired}
      />
    );
  };
  const renderMinimumGRDNTokenStakingRequired = () => {
    return (
      <CardStatus
        title={t("Staking.Detail.MinimumGRDN")}
        value={minimumStakeGRDNAmount}
        tooltip
      />
    );
  };
  const renderPublicLinearAndQuadratic = () => (
    <>
      <div className={styles.DetailProject__List__Type}>
        <CardStatus
          title={t("Staking.Detail.AccessType")}
          value={accessType}
          tooltip
        />
        {minimum_delegate_staking_amount &&
        new BigNumber(minimum_delegate_staking_amount).gt(0)
          ? renderMinimumDelegateStakingRequired()
          : null}
        {min_grdn && new BigNumber(min_grdn).gt(0)
          ? renderMinimumGRDNTokenStakingRequired()
          : null}
        <CardStatus
          title={t("Airdrop.Detail.StartJoinTime")}
          time={checkTime(start_time)}
        />
        <CardStatus
          title={t("Airdrop.Detail.EndJoinTime")}
          time={checkTime(finish_time)}
        />
      </div>
      <div className={styles.DetailProject__List__Time}>
        <CardStatus
          title={t("Airdrop.Detail.SnapshotTime")}
          time={checkTime(snapshot_time)}
        />
        <CardStatus
          title={t("Airdrop.Detail.AnnounceResultsTime")}
          time={checkTime(announce_time)}
        />
        <CardStatus
          title={t("Airdrop.Detail.ClaimTime")}
          time={checkTime(distribute_time)}
        />
      </div>
    </>
  );

  const renderPublicFixedAmount = () => (
    <>
      <div className={styles.DetailProject__List__Type}>
        <CardStatus
          title={t("Staking.Detail.AccessType")}
          value={accessType}
          tooltip
        />
        {minimum_delegate_staking_amount &&
        new BigNumber(minimum_delegate_staking_amount).gt(0)
          ? renderMinimumDelegateStakingRequired()
          : null}
        {min_grdn && new BigNumber(min_grdn).gt(0)
          ? renderMinimumGRDNTokenStakingRequired()
          : null}
        <CardStatus
          title={t("Airdrop.Detail.StartJoinTime")}
          time={checkTime(start_time)}
        />
      </div>
      <div className={styles.DetailProject__List__Time}>
        <CardStatus
          title={t("Airdrop.Detail.EndJoinTime")}
          time={checkTime(finish_time)}
        />
        <CardStatus
          title={t("Airdrop.Detail.ClaimTime")}
          time={checkTime(distribute_time)}
        />
      </div>
    </>
  );

  const renderPrivateLinearAndQuadratic = () => (
    <>
      <div className={styles.DetailProject__List__Type}>
        <CardStatus
          title={t("Staking.Detail.AccessType")}
          value={accessType}
          tooltip
        />
        {minimum_delegate_staking_amount &&
        new BigNumber(minimum_delegate_staking_amount).gt(0)
          ? renderMinimumDelegateStakingRequired()
          : null}
        {min_grdn && new BigNumber(min_grdn).gt(0)
          ? renderMinimumGRDNTokenStakingRequired()
          : null}
        <CardStatus
          title={t("Airdrop.Detail.SnapshotTime")}
          time={checkTime(snapshot_time)}
        />
      </div>
      <div className={styles.DetailProject__List__Time}>
        <CardStatus
          title={t("Airdrop.Detail.AnnounceResultsTime")}
          time={checkTime(announce_time)}
        />
        <CardStatus
          title={t("Airdrop.Detail.ClaimTime")}
          time={checkTime(distribute_time)}
        />
      </div>
    </>
  );

  const renderPrivateFixedAmount = () => (
    <>
      <div className={styles.DetailProject__List__Type}>
        <CardStatus title={t("Staking.Detail.AccessType")} value={accessType} />
        {minimum_delegate_staking_amount &&
        new BigNumber(minimum_delegate_staking_amount).gt(0)
          ? renderMinimumDelegateStakingRequired()
          : null}
        {min_grdn && new BigNumber(min_grdn).gt(0)
          ? renderMinimumGRDNTokenStakingRequired()
          : null}
      </div>

      <div className={styles.DetailProject__List__Time}>
        <CardStatus
          title={t("Airdrop.Detail.ClaimTime")}
          time={checkTime(distribute_time)}
        />
      </div>
    </>
  );
  const handleJoinAirDrop = () => {
    setIsJoined(!isJoined);
  };
  return (
    <div className={styles.DetailProject}>
      <HeaderNotification
        bypass={kyc_bypass}
        forbiddenCountries={forbidden_countries}
        poolDetail={dataDetail}
        joinStatus={joinStatus}
        isClaim={isClaim}
        isWhiteList={true}
      />
      <HeaderProject
        tokenIcon={token_icon}
        tokenName={token_name}
        tokenSymbol={token_symbol}
        website={website}
        whitepaperLink={whitepaper_link}
        telegramLink={telegram_link}
        mediumLink={medium_link}
        twitterLink={twitter_link}
        ListStatus={checkTypeProject()}
        projectStatus={status}
        renderCountdown={renderCountdown}
      />
      <div className={styles.DetailProject__Wrapper}>
        <WrapperStake
          projectType={project_type}
          tokenRate={tokenRate}
          claimMethod={claimMethod}
          tokenSymbol={token_symbol}
          totalParticipant={total_participant}
          totalAmount={total_raise_amount}
          status={toNumber(status)}
          distributionMethod={distribution_method}
        />
        <YourReward
          status={toNumber(status)}
          projectId={id}
          tokenSymbol={token_symbol}
          projectContractAddress={project_contract_address}
          projectType={project_type}
          distributionMethod={distribution_method}
          statusDeploy={status_deploy}
          joinAirDrop={handleJoinAirDrop}
          minGRDN={min_grdn}
          minDelegateStakingAmount={minimum_delegate_staking_amount}
          poolDetail={dataDetail}
          setJoinStatus={setJoinStatus}
          checkJoinAndClaimProject={checkJoinAndClaimProject}
          isJoinProject={joinStatus >= STATUS_JOIN_POOL.JOINED}
          isClaim={isClaim}
          isCheckJoinAndClaim={isCheckJoinAndClaim}
          setClaim={setClaim}
          isWhiteList={isWhiteList}
          isProcess={isProcess}
          getCheckWhiteList={getCheckWhiteList}
        />
      </div>
      <div className={styles.DetailProject__List}>
        {checkRenderPublicLinearAndQuadratic(
          project_type,
          distribution_method
        ) && renderPublicLinearAndQuadratic()}
        {checkRenderPublicFixedAmount(project_type, distribution_method) &&
          renderPublicFixedAmount()}
        {checkRenderPrivateLinearAndQuadratic(
          project_type,
          distribution_method
        ) && renderPrivateLinearAndQuadratic()}
        {checkRenderPrivateFixedAmount(project_type, distribution_method) &&
          renderPrivateFixedAmount()}
      </div>
    </div>
  );
};

export default DetailProject;
