import { Button, Divider, Tooltip, Slider } from "antd";
import { SliderMarks } from "antd/lib/slider";
import BigNumber from "bignumber.js";
import { convert, formatBigNumber, toRealNumber } from "common/functions";
import useValueFunc from "common/hooks/useValueFunc";
import DepositClaim from "common/popup/depositClaim";
import { IDOType } from "common/types/IDOType";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserService } from "services/UserServicer";
import { setClaims } from "stores/ido";
import StorageUtils from "utils/storage";
import "./styles.scss";

interface SliderConfigType {
  marks: SliderMarks;
  total: number;
  value: number;
}
interface PropsType {
  pool: Partial<IDOType>;
}
const Claimable: React.FC<PropsType> = (props: PropsType) => {
  const { pool } = props;
  const dispatch = useDispatch();
  const {
    poolAddress,
    acceptedCurrency,
    claimConfig,
    tokenSymbol,
    exchangeRate,
    tokenDecimal,
  } = useValueFunc({ pool });
  const [openDeposit, setOpenDeposit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState("");
  const [amount, setAmount] = useState("");
  const userService = new UserService();
  const token = StorageUtils.getToken();
  const { id } = useParams<any>();
  const { userAmount, claim, userPSAmount } = useSelector((state) => state.ido);
  const wallet_address = useSelector(
    (state) => state?.wallet?.userInfo?.wallet_address
  );
  const [total, setTotal] = useState("");

  const handleOpenDeposit = () => setOpenDeposit(true);
  const handleCloseDeposit = () => setOpenDeposit(false);

  useEffect(() => {
    const getSignature = async () => {
      try {
        const result = await userService.getSignatureClaim({ id, token });
        const { data } = result.data;

        if (data) {
          setSignature(data.signature);
          setAmount(convert(data.amount));
          setTotal(toRealNumber(data.totalReward, tokenDecimal));
        } else {
          setSignature("");
          setAmount("0");
        }
      } catch (error) {}
    };

    if (wallet_address) {
      getSignature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claim,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Date.now() / 1000 > claimConfig?.[0]?.start_time,
    wallet_address,
    id,
  ]);

  const amountValue = toRealNumber(amount, tokenDecimal);

  const yourReward = new BigNumber(exchangeRate || "0")
    .multipliedBy(new BigNumber(userAmount))
    .toString();

  const yourPSReward = new BigNumber(exchangeRate || "0")
    .multipliedBy(new BigNumber(userPSAmount))
    .toString();

  const totalRewardAmount = new BigNumber(yourReward)
    .plus(yourPSReward)
    .toString();

  const initalConfig: SliderConfigType = {
    total: 0,
    value: 0,
    marks: {
      0: (
        <span
          className="mark-custom-zero"
          style={{
            color:
              Date.now() / 1000 > claimConfig?.[0]?.start_time
                ? "#F22222"
                : "#b1b5c3",
            fontSize: "14px",
            fontFamily: "Inter",
          }}
        >{`0%`}</span>
      ),
    },
  };
  const sliderConfig = (claimConfig || []).reduce(
    (config: SliderConfigType, item) => {
      config.total += item.max_claim;
      const now = Date.now() / 1000;
      if (now > item.start_time) config.value += item.max_claim;
      const claimTime = moment(
        Number(item.start_time) * 1000 || new Date()
      ).format("HH:mm A, DD MMMM YYYY");
      const currentReward = new BigNumber(total).multipliedBy(
        config.total / 100
      );
      const isShowReward = config.total === 100;
      if (config.marks) {
        config.marks[config.total] = {
          style: {
            color: now > item.start_time ? "#F22222" : "#b1b5c3",
          },
          label: (
            <Tooltip
              title={
                <span className="tooltip-content">
                  You can claim {formatBigNumber(config.total)}% of the tokens
                  after {claimTime} UTC
                </span>
              }
              placement="bottomLeft"
              color="#7F8997"
            >
              <span className="mark-custom">
                {formatBigNumber(config.total)}%
                {isShowReward && (
                  <>
                    <br />({formatBigNumber(currentReward)} {tokenSymbol})
                  </>
                )}
              </span>
            </Tooltip>
          ),
        };
      }
      return config;
    },
    initalConfig
  );

  if (sliderConfig.total < 100 && sliderConfig.marks) {
    sliderConfig.marks[100] = {
      style: {
        color: "#b1b5c3",
      },
      label: (
        <span>
          100%
          <br />({formatBigNumber(total)} {tokenSymbol})
        </span>
      ),
    };
  }
  const onClaim = (loading: boolean, success?: boolean) => {
    setLoading(loading);
    if (success) {
      dispatch(setClaims(toRealNumber(amount, tokenDecimal)));
      setAmount("0");
    }
  };

  return (
    <div className="main-claim">
      <span className="title">Token Claim</span>
      <Divider />
      <span className="claim-policy">{`Claim Policy:  ${pool.claim_policy}`}</span>
      <Slider
        defaultValue={0}
        marks={sliderConfig.marks}
        value={sliderConfig.value}
        tooltipVisible={false}
        disabled={!Number(amount)}
        className={
          sliderConfig.value !== 0 && new BigNumber(amount || 0).lte(0)
            ? "hidden-handle"
            : ""
        }
      />
      <Button
        onClick={handleOpenDeposit}
        disabled={!Number(amount) || !wallet_address || loading}
        className="claim"
      >
        Claim
      </Button>
      <DepositClaim
        openDeposit={openDeposit}
        handleCloseDeposit={handleCloseDeposit}
        acceptedCurrency={acceptedCurrency}
        poolAddress={poolAddress}
        value={sliderConfig.value}
        rewardClaim={amountValue}
        signature={signature}
        yourRewardValue={totalRewardAmount}
        amount={amountValue}
        claim={claim}
        onClaim={onClaim}
        pool={pool}
        tokenDecimal={tokenDecimal || 18}
      />
    </div>
  );
};

export default Claimable;
