import images from "../../../themes/images";
import './transaction.scss';

interface TransactionRejectProps {

}

const TransactionReject: React.FC<TransactionRejectProps> = () => {
  return (
    <span className="Transaction">
      <img src={images.icons.TransactionError} alt="TransactionReject"></img>
      Transaction Reject
    </span>
  );
}

export default TransactionReject;