import styles from "./guideBuyIDO.module.scss";
import images from "../../../themes/images";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";

export default function GuideBuyIDO() {
  const { t } = useTranslation();
  return (
    <div className={styles.hero}>
      <div className={styles.hero__Main}>
        <Row gutter={[40, 40]} align="middle">
          <Col md={{ span: 12 }} className={styles.hero__Main__Desc}>
            <h1 id="top">{t("GuiBuyIDO.Discover")}</h1>
            <p className={styles.firtTitle}>{t("GuiBuyIDO.DesTile")}</p>
            <p className={styles.lastTitle}>{t("GuiBuyIDO.Des")}</p>
            <p className={styles.lastTitle} style={{ marginTop: 5 }}>
              {t("GuiBuyIDO.Des2")}
            </p>

            <div className={styles.hero__Main__Desc__Btn}>
              <a
                href={"https://forms.gle/2euvPNYYg5HyYSg2A"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("GuiBuyIDO.ApplyLaunch")}
              </a>
              {/* <NavLink to={ROUTES.IDOPOOL}>{t("GuiBuyIDO.LaunchApp")}</NavLink> */}
            </div>
          </Col>
          <Col
            md={{ span: 12 }}
            className="grid-item"
            style={{ textAlign: "right" }}
          >
            <img src={images.ladingPage.BgDiscover} alt="logo" />
          </Col>
        </Row>
      </div>
    </div>
  );
}
