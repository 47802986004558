import { createSlice, PayloadAction, Store } from "@reduxjs/toolkit";
import { IDO_STATUS, plainOptions } from "../../common/constants";

let customStore: Store | undefined;

export const setStoreToolbar = (store: Store) => {
  customStore = store;
};

const initialState = {
  isShowLayoutTableStaking: true,
  isShowLayoutCardStaking: false,
  isShowLayoutTableAirdrop: true,
  isShowLayoutCardAirdrop: false,
  isShowLayoutTableIDO: false,
  isShowLayoutCardIDO: true,
  optionFilterStaking: plainOptions,
  optionFilterAirdrop: plainOptions,
  optionFilterIDO: [`${IDO_STATUS.UPCOMING}`],
  isActiveMyProjectStaking: {
    isActiveStaking: false,
    registed: "",
  },
  isActiveMyProjectAirdrop: {
    isActiveAirdrop: false,
    registed: "",
  },
  isActiveMyProjectIDO: {
    isActiveIDO: false,
    registed: "",
  },
  valueSearchStaking: "",
  valueSearchAirdrop: "",
  valueSearchIDO: "",
  pageStaking: 1,
  pageAirdrop: 1,
  pageIDO: 1,
};

const storeToolbar = createSlice({
  name: "storeToolbar",
  initialState,
  reducers: {
    setLayoutTableStaking: (state) => ({
      ...state,
      isShowLayoutTableStaking: true,
      isShowLayoutCardStaking: false,
    }),

    setLayoutCardStaking: (state) => ({
      ...state,
      isShowLayoutTableStaking: false,
      isShowLayoutCardStaking: true,
    }),

    setLayoutTableAirdrop: (state) => ({
      ...state,
      isShowLayoutTableAirdrop: true,
      isShowLayoutCardAirdrop: false,
    }),

    setLayoutCardAirdrop: (state) => ({
      ...state,
      isShowLayoutTableAirdrop: false,
      isShowLayoutCardAirdrop: true,
    }),


    setLayoutTableIDO: (state) => ({
      ...state,
      isShowLayoutTableIDO: true,
      isShowLayoutCardIDO: false,
    }),

    setLayoutCardIDO: (state) => ({
      ...state,
      isShowLayoutTableIDO: false,
      isShowLayoutCardIDO: true,
    }),

    setMyProjectStaking: (state, action: PayloadAction<any>) => ({
      ...state,
      isActiveMyProjectStaking: action.payload,
    }),

    setMyProjectAirdrop: (state, action: PayloadAction<any>) => ({
      ...state,
      isActiveMyProjectAirdrop: action.payload,
    }),

    setMyProjectIDO: (state, action: PayloadAction<any>) => ({
      ...state,
      isActiveMyProjectIDO: action.payload,
    }),

    setFilterStaking: (state, action: PayloadAction<any>) => ({
      ...state,
      optionFilterStaking: action.payload,
    }),

    setFilterAirdrop: (state, action: PayloadAction<any>) => ({
      ...state,
      optionFilterAirdrop: action.payload,
    }),

    setFilterIDO: (state, action: PayloadAction<any>) => ({
      ...state,
      optionFilterIDO: action.payload,
    }),

    setSearchStaking: (state, action: PayloadAction<any>) => ({
      ...state,
      valueSearchStaking: action.payload,
    }),

    setSearchAirdrop: (state, action: PayloadAction<any>) => ({
      ...state,
      valueSearchAirdrop: action.payload,
    }),

    setSearchIDO: (state, action: PayloadAction<any>) => ({
      ...state,
      valueSearchIDO: action.payload,
    }),

    setPageStaking: (state, action: PayloadAction<any>) => ({
      ...state,
      pageStaking: action.payload,
    }),

    setPageAirdrop: (state, action: PayloadAction<any>) => ({
      ...state,
      pageAirdrop: action.payload,
    }),

    setPageIDO: (state, action: PayloadAction<any>) => ({
      ...state,
      pageIDO: action.payload,
    }),
  },
});

export const setLayoutTableStaking = () => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setLayoutTableStaking());
};

export const setLayoutCardStaking = () => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setLayoutCardStaking());
};

export const setLayoutTableAirdrop = () => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setLayoutTableAirdrop());
};

export const setLayoutCardAirdrop = () => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setLayoutCardAirdrop());
};

export const setLayoutTableIDO = () => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setLayoutTableIDO());
};

export const setLayoutCardIDO = () => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setLayoutCardIDO());
};

export const setMyProjectStaking = (myProjects: Object) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setMyProjectStaking(myProjects));
};

export const setMyProjectAirdrop = (myProjects: Object) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setMyProjectAirdrop(myProjects));
};

export const setMyProjectIDO = (myProjects: Object) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setMyProjectIDO(myProjects));
};

export const setFilterStaking = (filterOption: string[]) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setFilterStaking(filterOption));
};

export const setFilterAirdrop = (filterOption: string[]) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setFilterAirdrop(filterOption));
};

export const setFilterIDO = (filterOption: string[]) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setFilterIDO(filterOption));
};

export const setSearchStaking = (value: string) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setSearchStaking(value));
};

export const setSearchAirdrop = (value: string) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setSearchAirdrop(value));
};

export const setSearchIDO = (value: string) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setSearchIDO(value));
};

export const setPageStaking = (page: number) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setPageStaking(page));
};

export const setPageAirdrop = (page: number) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setPageAirdrop(page));
};

export const setPageIDO = (page: number) => {
  customStore &&
    customStore.dispatch(storeToolbar.actions.setPageIDO(page));
};

export { storeToolbar };
