import images from "../../../themes/images";
import { URL_TRANSACTION } from "../../functions";
import "./transaction.scss";

interface TransactionCompleteProps {
  hash: string;
}

const TransactionComplete: React.FC<TransactionCompleteProps> = ({ hash }) => {
  return (
    <span className="Transaction">
      <img
        src={images.icons.TransactionComplete}
        alt="Transaction Complete"
      ></img>
      Transaction Complete
      <a href={URL_TRANSACTION(hash)} target="_blank" rel="noreferrer">
        <img src={images.icons.ExternalLink} alt="external-link" />
        <span>View on Etherscan</span>
      </a>
    </span>
  );
};

export default TransactionComplete;
