import BigNumber from "bignumber.js";
import {
  // DETAIL_PROJECT_IDO_STATUS,
  IDO_STATUS,
  STATUS_TEXT_tIME,
} from "common/constants";
import { IDOType } from "common/types/IDOType";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { secondsToTime } from "../../../../../../../common/functions";
import "./styles.scss";

interface PropsType {
  time?: any;
  checkTextTime: any;
  status?: number;
  onFinish?: () => void;
  pool: Partial<IDOType>;
}

const CountdownStart: React.FC<PropsType> = memo((props: PropsType) => {
  const { time, checkTextTime, status, pool, onFinish } = props;
  const [secCountDown, setSecCountDown] = useState<number>(time);
  const timeRemain = secondsToTime(secCountDown);
  const { days, hours, minutes, seconds } = timeRemain;
  const { userAmount, userPSAmount } = useSelector((state) => state.ido);

  const { joinIdo } = useSelector((state) => state.ido);

  const timeClaim = pool.claim_configs?.[0]?.start_time;

  useEffect(() => {
    if (time) {
      let count = time;
      const intervalId = setInterval(() => {
        count--;
        setSecCountDown(count);
        if (count <= 0) {
          clearInterval(intervalId);
          onFinish?.();
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const checkStatusText = (status: number) => {
    switch (status) {
      case 1:
        return STATUS_TEXT_tIME.CAN_NOT_JOIN;
      case 2:
        return STATUS_TEXT_tIME.CAN_NOT_DEPOSIT;
      case 3:
        return STATUS_TEXT_tIME.CAN_NOT_DEPOSIT;
      case 4:
        if (
          new BigNumber(userAmount).isEqualTo(0) &&
          new BigNumber(userPSAmount).isEqualTo(0) &&
          (!timeClaim || timeClaim)
        ) {
          return STATUS_TEXT_tIME.CAN_NOT_CLAIM;
        } else if (
          (new BigNumber(userAmount).gt(0) ||
            new BigNumber(userPSAmount).gt(0)) &&
          !timeClaim
        ) {
          return STATUS_TEXT_tIME.CAN_NOT_CLAIM_TIME;
        } else if (
          (new BigNumber(userAmount).gt(0) ||
            new BigNumber(userPSAmount).gt(0)) &&
          timeClaim
        ) {
          return STATUS_TEXT_tIME.CAN_CLAIM;
        }
        return null;
      default:
        return STATUS_TEXT_tIME.POOL_ENDED;
    }
  };

  const renderCountDown = () => {
    return (
      <>
        <span className="text-cooldown">{checkTextTime}</span>
        <div className="timer">
          <div className="time">
            <span>
              {days < 10 ? "0" : ""}
              {days}
            </span>
            <span>days</span>
          </div>
          <div className="dot">:</div>
          <div className="time">
            <span>
              {hours < 10 ? "0" : ""}
              {hours}
            </span>
            <span>hours</span>
          </div>
          <div className="dot">:</div>
          <div className="time">
            <span>
              {minutes < 10 ? "0" : ""}
              {minutes}
            </span>
            <span>minutes</span>
          </div>
          <div className="dot">:</div>
          <div className="time">
            <span>
              {seconds < 10 ? "0" : ""}
              {seconds}
            </span>
            <span>seconds</span>
          </div>
        </div>
      </>
    );
  };

  if (time > 0 && status === IDO_STATUS.PUBLIC_SELL) {
    return <>{renderCountDown()}</>;
  }

  if (
    time > 0 &&
    status === IDO_STATUS.CLAIMABLE &&
    Number(userPSAmount) + Number(userAmount) > 0
  ) {
    return <>{renderCountDown()}</>;
  }

  return (
    <>
      {time === 0 ||
      (status !== 1 && joinIdo === "3") ||
      joinIdo === "4" ||
      joinIdo === "2" ? (
        <span className="text-cooldown-end">
          {checkStatusText(Number(status))}
        </span>
      ) : (
        renderCountDown()
      )}
    </>
  );
});

export default CountdownStart;
