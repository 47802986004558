/* eslint-disable react-hooks/exhaustive-deps */
import { Button, message, Modal } from "antd";
import BigNumber from "bignumber.js";
import messageCustom from "common/alert/message";
import Transaction from "common/alert/transaction";
import useValidateInput from "common/hooks/useValidateInput";
import { BigNumberish } from "ethers";
import _ from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "services/UserServicer";
import Web3 from "web3";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close-popup.svg";
import {
  setAllowance,
  setDeposit,
  setOpenDeposit,
  setUserAmount,
  setUserPSAmount,
} from "../../../stores/ido";
import {
  ABI_ALLOCATION,
  ACCEPTED_CURRENCIES_NAME,
  ALERT_TIME,
  DECIMAL_ACCEPTED_CURRENCY,
  SALES_TYPES,
} from "../../constants";
import {
  checkBalanceValue,
  checkCurrency,
  convert,
  formatBigNumber,
} from "../../functions";
import { AcceptedCurrencyTypes } from "../../types/IDOType";
import InputDeposit from "./InputDeposit";
import MainTitle from "./mainTitle";
import "./styles.scss";

interface PropsType {
  openDeposit: boolean;
  hideDeposit: () => void;
  acceptedCurrency?: AcceptedCurrencyTypes;
  maxDeposit?: string;
  poolAddress?: string;
  exchangeRate: any;
  status?: number;
  remainingToken?: string;
  saleTypes?: number;
  tokenSymbol?: string;
  totalSoldCoin?: string;
  totalDeposit?: string;
  id: string;
  receivedFeeWallet?: string;
  tokenDecimal?: number;
  getAllBalance?: () => void;
  onDepositSuccess?: (value: string) => void;
}

const ModalDeposit = memo((props: PropsType) => {
  const {
    openDeposit,
    hideDeposit,
    acceptedCurrency,
    maxDeposit,
    poolAddress,
    status,
    exchangeRate,
    // remainingToken,
    saleTypes,
    tokenSymbol,
    totalSoldCoin,
    totalDeposit,
    id,
    getAllBalance,
    onDepositSuccess,
    // tokenDecimal,
  } = props;
  const { ethereum } = window as any;
  const web3 = new Web3(ethereum);
  const rootState = useSelector((state) => state.wallet);
  const { userInfo, balance, balanceUSDC, balanceUSDT } = rootState;
  const [value, setValue] = useState("");
  const [maxTotalDeposit, setMaxTotalDeposit] = useState("");
  const [loading, setLoading] = useState(false);
  const { userAmount, balanceMain, userPSAmount } = useSelector(
    (state) => state.ido
  );

  const userService = new UserService();

  const wallet = _.get(userInfo, "wallet_address", null);
  const dispatch = useDispatch();
  const fromWei = new BigNumber(totalDeposit || "0").dividedBy("1e18");
  const approximateAmount = useMemo(() => {
    return exchangeRate && value
      ? new BigNumber(exchangeRate || "0")
          .multipliedBy(new BigNumber(value || "0"))
          .toFixed()
      : "0";
  }, [exchangeRate, value]);
  const getMaxTotalDeposit = async () => {
    try {
      const contract = await new web3.eth.Contract(ABI_ALLOCATION, poolAddress);
      const maxTotalDeposit = await contract.methods.stakeTokenSupply().call();
      setMaxTotalDeposit(maxTotalDeposit);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (wallet && poolAddress) {
      getMaxTotalDeposit();
    }
  }, [wallet, poolAddress]);

  const totalDespositRemaining = useMemo(() => {
    return exchangeRate && totalSoldCoin
      ? new BigNumber(totalSoldCoin || "0")
          .div(new BigNumber(exchangeRate || "0"))
          .toFixed()
      : "0";
  }, [exchangeRate, totalSoldCoin]);

  const TotalDeposit = new BigNumber(maxTotalDeposit).div(
    10 ** DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
  );

  const acceptableAmount = useMemo(() => {
    return maxTotalDeposit && totalDespositRemaining
      ? new BigNumber(totalDespositRemaining || "0")
          .minus(TotalDeposit)
          .toFixed()
      : "0";
  }, [maxTotalDeposit, totalDespositRemaining]);

  const remainingTokenFromWei = useMemo(() => {
    const soldCoin = new BigNumber(totalDeposit || 0)
      .div(
        new BigNumber(10).pow(DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1])
      )
      .multipliedBy(Number(exchangeRate));

    return String(Number(totalSoldCoin) - Number(soldCoin.toString()));
  }, [totalDeposit, acceptedCurrency, exchangeRate, totalSoldCoin]);

  const totalAmountDeposit = useMemo(() => {
    return new BigNumber(userPSAmount).plus(new BigNumber(userAmount));
  }, [userAmount, userPSAmount]);

  const totalMaxDeposit = useMemo(() => {
    return new BigNumber(totalAmountDeposit).plus(new BigNumber(value));
  }, [totalAmountDeposit, value]);

  const totalRemaining = useMemo(() => {
    return new BigNumber(approximateAmount)
      .plus(new BigNumber(value))
      .toString();
  }, [userAmount, value]);

  console.log("approximateAmount", approximateAmount);

  const {
    checkBalance,
    checkBalanceUSDT,
    deposit,
    checkBalanceUSDC,
    checkValueZero,
    checkTotalSoldcoins,
    checkAcceptableAmount,
    handleCloseModal,
    checkRemainingToken,
  } = useValidateInput({
    value,
    maxDeposit,
    fromWei,
    acceptedCurrency,
    balance,
    balanceUSDC,
    balanceUSDT,
    saleTypes,
    totalSoldCoin,
    approximateAmount,
    status,
    acceptableAmount,
    remainingTokenFromWei,
    totalMaxDeposit,
    totalRemaining,
  });

  // const exchangeRateMul = new BigNumber(fromWei || "0").multipliedBy(
  //   exchangeRate
  // );

  // const remainToken = new BigNumber(totalSoldCoin || "0").minus(
  //   exchangeRateMul
  // );

  const ERC20_ABI = require("../../../abi/ERC20.json");

  const dataDeposit: any[] = [
    {
      deposit: acceptedCurrency && ACCEPTED_CURRENCIES_NAME[acceptedCurrency],
      text: "Deposit asset",
    },
    {
      deposit: formatBigNumber(totalAmountDeposit),
      text: "Your deposit",
    },
    {
      deposit:
        status === 3
          ? formatBigNumber(remainingTokenFromWei)
          : formatBigNumber(maxDeposit?.toString() || 0),
      text: status === 3 ? "Remaining tokens" : "Max deposit",
    },
  ];
  const dataDepositSaletypes: any[] = [
    {
      deposit: acceptedCurrency && ACCEPTED_CURRENCIES_NAME[acceptedCurrency],
      text: "Deposit asset",
    },
    {
      deposit: formatBigNumber(totalAmountDeposit),
      text: "Your deposit",
    },
    {
      deposit: formatBigNumber(remainingTokenFromWei),
      text: "Remaining tokens",
    },
  ];

  const dataDepositSale: any[] = [
    {
      deposit: acceptedCurrency && ACCEPTED_CURRENCIES_NAME[acceptedCurrency],
      text: "Deposit asset",
    },
    {
      deposit: formatBigNumber(totalAmountDeposit),
      text: "Your deposit",
    },
  ];

  const checkTypeArr =
    saleTypes === 1
      ? dataDeposit
      : saleTypes === 2
      ? dataDepositSaletypes
      : dataDepositSale;

  const handleChange = useCallback((value: any) => {
    if (value === ".") value = "";
    setValue(value || "");
  }, []);

  const renderData = useCallback((data) => {
    return data.length > 0
      ? data.map((item: any) => {
          const { text, deposit } = item;
          return (
            <div key={text} className="title__deposit">
              <div className="title__deposit__right">
                <span></span>
                <span>{text}</span>
              </div>
              <div className="title__deposit__left">
                <span>{deposit}</span>
              </div>
            </div>
          );
        })
      : null;
  }, []);
  const getMaxValue = () => {
    if (acceptedCurrency === 1) {
      setValue(convert(balanceMain));
    } else if (acceptedCurrency === 2) {
      setValue(convert(balanceUSDT));
    } else if (acceptedCurrency === 3) {
      setValue(convert(balanceUSDC));
    }
  };
  const handleDeposit = async () => {
    try {
      let transactionHash = "";
      const contract = new web3.eth.Contract(
        ERC20_ABI,
        checkCurrency(acceptedCurrency || 1)
      );
      if (poolAddress && wallet) {
        if (acceptedCurrency !== 1) {
          setLoading(true);
          const allowance = await contract.methods
            .allowance(wallet, poolAddress)
            .call({
              from: wallet,
            });

          const input = new BigNumber(value || "0")
            .multipliedBy(
              10 ** DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
            )
            .toString();

          if (
            Number(value) >
            Number(
              new BigNumber(allowance || "0")
                .div(
                  new BigNumber(10).pow(
                    DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
                  )
                )
                .toString()
            )
          ) {
            const approve = await contract.methods
              .approve(poolAddress, input)
              .send({ from: wallet });
            if (approve) {
              dispatch(setAllowance(true));
              messageCustom.success("Approve complete!");
            }
          }
          const result = await userService.getSignatureDeposit({
            id,
            amount: input,
          });
          const { data } = result.data;
          if (data?.signature) {
            const contractAllocation = new web3.eth.Contract(
              ABI_ALLOCATION,
              poolAddress
            );
            await contractAllocation.methods
              .IDOToken(input, data?.signature)
              .send({
                from: wallet,
              })
              .on("transactionHash", function (hash: any) {
                transactionHash = hash;
              })
              .on("receipt", (receipt: any) => {
                getMaxTotalDeposit();
                dispatch(setOpenDeposit(false));
                dispatch(setDeposit(true));
                onDepositSuccess?.(input);
                dispatch(
                  setUserAmount(
                    new BigNumber(userAmount).plus(value).toString()
                  )
                );
                setLoading(false);
                messageCustom.success("Transaction complete");
              })
              .on("error", function (error: any, receipt: any) {
                setLoading(false);
                return message.info({
                  content: (
                    <Transaction
                      type={transactionHash ? "error" : "reject"}
                      hash={transactionHash}
                    />
                  ),
                  icon: <></>,
                  duration: ALERT_TIME,
                  className: "custom-message",
                });
              });
          }
        } else {
          const contractAllocation = new web3.eth.Contract(
            ABI_ALLOCATION,
            poolAddress
          );
          const valueToWei: BigNumberish = web3.utils.toWei(
            value.toString() || "0"
          );
          await contractAllocation.methods
            .IDOROSE()
            .send({
              from: wallet,
              value: valueToWei,
            })
            .on("transactionHash", function (hash: any) {
              transactionHash = hash;
            })
            .on("receipt", (receipt: any) => {
              getMaxTotalDeposit();
              dispatch(setOpenDeposit(false));
              dispatch(
                setUserAmount(
                  formatBigNumber(new BigNumber(userAmount).plus(value))
                )
              );
              dispatch(setDeposit(true));
              onDepositSuccess?.(valueToWei);
              setLoading(false);
              messageCustom.success("Transaction complete");
            })
            .on("error", function (error: any, receipt: any) {
              setLoading(false);
              return message.info({
                content: <Transaction type="reject" hash={transactionHash} />,
                icon: <></>,
                duration: ALERT_TIME,
                className: "custom-message",
              });
            });
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      dispatch(setOpenDeposit(false));
      setLoading(false);
      setValue("");
    }
  };
  const handlePublicSell = async () => {
    try {
      let transactionHash = "";
      const contract = new web3.eth.Contract(
        ERC20_ABI,
        checkCurrency(acceptedCurrency || 1)
      );

      if (poolAddress && wallet) {
        if (acceptedCurrency !== 1) {
          setLoading(true);
          const allowance = await contract.methods
            .allowance(wallet, poolAddress)
            .call({
              from: wallet,
            });

          const input = new BigNumber(value || "0")
            .multipliedBy(
              10 ** DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
            )
            .toString();

          if (
            Number(value) >
            Number(
              new BigNumber(allowance || "0")
                .div(
                  new BigNumber(10).pow(
                    DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
                  )
                )
                .toString()
            )
          ) {
            const approve = await contract.methods
              .approve(poolAddress, input)
              .send({ from: wallet });
            if (approve) {
              dispatch(setAllowance(true));
              messageCustom.success("Approve complete!");
            }
          }
          const contractAllocation = new web3.eth.Contract(
            ABI_ALLOCATION,
            poolAddress
          );

          const towei = new BigNumber(value || "0")
            .multipliedBy(
              10 ** DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1]
            )
            .toString();
          await contractAllocation.methods
            .buyPSToken(towei)
            .send({
              from: wallet,
            })
            .on("transactionHash", function (hash: any) {
              transactionHash = hash;
            })
            .on("receipt", (receipt: any) => {
              getMaxTotalDeposit();
              dispatch(setOpenDeposit(false));
              dispatch(setDeposit(true));
              onDepositSuccess?.(towei);
              dispatch(
                setUserPSAmount(
                  formatBigNumber(new BigNumber(userPSAmount).plus(value))
                )
              );
              setLoading(false);
            })
            .on("error", function (error: any, receipt: any) {
              setLoading(false);
              return message.info({
                content: (
                  <Transaction
                    type={transactionHash ? "error" : "reject"}
                    hash={transactionHash}
                  />
                ),
                icon: <></>,
                duration: ALERT_TIME,
                className: "custom-message",
              });
            });
        } else {
          const contractAllocation = new web3.eth.Contract(
            ABI_ALLOCATION,
            poolAddress
          );

          const valueToWei: BigNumberish = web3.utils.toWei(
            value.toString() || "0"
          );
          await contractAllocation.methods
            .buyPSROSE()
            .send({
              from: wallet,
              value: valueToWei,
            })
            .on("transactionHash", function (hash: any) {
              transactionHash = hash;
            })
            .on("receipt", (receipt: any) => {
              getMaxTotalDeposit();
              dispatch(
                setUserPSAmount(
                  formatBigNumber(new BigNumber(userPSAmount).plus(value))
                )
              );
              dispatch(setOpenDeposit(false));
              dispatch(setDeposit(true));
              onDepositSuccess?.(valueToWei);
              setLoading(false);
            })
            .on("error", function (error: any, receipt: any) {
              setLoading(false);
              return message.info({
                content: <Transaction type="reject" hash={transactionHash} />,
                icon: <></>,
                duration: ALERT_TIME,
                className: "custom-message",
              });
            });
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      dispatch(setOpenDeposit(false));
      setLoading(false);
      setValue(" ");
    }
  };
  const DepositFromWei = useMemo(() => {
    return new BigNumber(totalDeposit || "0")
      .div(
        new BigNumber(10).pow(DECIMAL_ACCEPTED_CURRENCY[acceptedCurrency || 1])
      )
      .multipliedBy(exchangeRate || 1);
  }, [totalDeposit, exchangeRate]);

  const totalSoldCoinDeposit = useMemo(() => {
    return new BigNumber(DepositFromWei).plus(approximateAmount).toString();
  }, [DepositFromWei, approximateAmount]);

  useEffect(() => {
    if (openDeposit) console.log("get");
    if (openDeposit) getAllBalance?.();
  }, [openDeposit]);
  return (
    <Modal
      visible={openDeposit}
      onOk={() => {
        setValue(() => "");
        handleCloseModal();
        hideDeposit();
      }}
      onCancel={() => {
        setValue(() => "");
        handleCloseModal();
        hideDeposit();
      }}
      width="448px"
      closeIcon={() => null}
      footer={null}
    >
      <div className="header">
        <span>{`${
          status === 3 ? `Deposit (public sell time)` : `Deposit`
        }   `}</span>
        <CloseIcon className="icon" onClick={hideDeposit} />
      </div>
      <div className="main">
        <div className="title">
          <MainTitle renderData={renderData} dataDeposit={checkTypeArr} />
        </div>
        <div className="content">
          <div className="content__top">
            <div className="text">
              <span>Amount</span>
              <span>
                Balance:{" "}
                {checkBalanceValue(
                  acceptedCurrency,
                  balance.toString(),
                  balanceUSDC.toString(),
                  balanceUSDT.toString()
                )}{" "}
                {acceptedCurrency && ACCEPTED_CURRENCIES_NAME[acceptedCurrency]}
              </span>
            </div>
            <InputDeposit
              handleChange={handleChange}
              getMaxValue={getMaxValue}
              value={value}
              checkDeposit={deposit}
              checkBalance={checkBalance}
              checkBalanceUSDC={checkBalanceUSDC}
              checkBalanceUSDT={checkBalanceUSDT}
              checkValueZero={checkValueZero}
              maxDeposit={maxDeposit}
              saleTypes={saleTypes}
              totalSoldCoin={totalSoldCoin}
              checkTotalSoldcoin={checkTotalSoldcoins}
              acceptedCurrency={acceptedCurrency}
              status={status}
              checkAcceptableAmount={checkAcceptableAmount}
              acceptableAmount={acceptableAmount}
              checkRemainingToken={checkRemainingToken}
              remainingToken={remainingTokenFromWei}
              totalMaxDeposit={totalMaxDeposit}
              totalRemaining={totalRemaining}
              totalSoldCoinDeposit={totalSoldCoinDeposit}
              totalDeposit={DepositFromWei.toString()}
              approximateAmount={approximateAmount}
            />
          </div>
          <div className="content__bottom">
            <span>You will get approximately</span>
            {saleTypes === SALES_TYPES.PUBLIC_VALUATION_SALE ? (
              <span>TBA</span>
            ) : (
              <span>
                {approximateAmount ? formatBigNumber(approximateAmount) : 0}{" "}
                {tokenSymbol}
              </span>
            )}
          </div>
          <Button
            loading={loading}
            onClick={status === 3 ? handlePublicSell : handleDeposit}
            className={
              value === "" ||
              value === "0" ||
              (acceptedCurrency === 1 &&
                new BigNumber(value).isGreaterThan(convert(balance))) ||
              (acceptedCurrency === 3 &&
                new BigNumber(value).isGreaterThan(convert(balanceUSDC))) ||
              (acceptedCurrency === 2 &&
                new BigNumber(value).isGreaterThan(convert(balanceUSDT))) ||
              (new BigNumber(totalMaxDeposit).isGreaterThan(
                maxDeposit || "0"
              ) &&
                saleTypes === 1 &&
                (status === 2 || (status && status >= 3))) ||
              (new BigNumber(value).isGreaterThan(acceptableAmount || "0") &&
                saleTypes === 2 &&
                (status === 2 || (status && status >= 3))) ||
              (new BigNumber(totalSoldCoinDeposit || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 3) ||
              (new BigNumber(totalSoldCoinDeposit || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 2) ||
              (new BigNumber(approximateAmount || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 3) ||
              (new BigNumber(approximateAmount || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 2) ||
              (new BigNumber(approximateAmount || "0").isGreaterThan(
                remainingTokenFromWei || "0"
              ) &&
                status === 3) ||
              (Number(value) + Number(TotalDeposit) > Number(totalSoldCoin) &&
                status === 2 &&
                saleTypes === SALES_TYPES.EQUAL_SALE)
                ? "btn-deposit-disable"
                : "btn-deposit"
            }
            disabled={
              value === "" ||
              value === "0" ||
              (acceptedCurrency === 1 &&
                new BigNumber(value).isGreaterThan(convert(balance))) ||
              (acceptedCurrency === 3 &&
                new BigNumber(value).isGreaterThan(convert(balanceUSDC))) ||
              (acceptedCurrency === 2 &&
                new BigNumber(value).isGreaterThan(convert(balanceUSDT))) ||
              (new BigNumber(totalMaxDeposit).isGreaterThan(
                maxDeposit || "0"
              ) &&
                saleTypes === 1 &&
                (status === 2 || (status && status >= 3))) ||
              (new BigNumber(value).isGreaterThan(acceptableAmount || "0") &&
                saleTypes === 2 &&
                (status === 2 || (status && status >= 3))) ||
              (new BigNumber(totalSoldCoinDeposit || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 3) ||
              (new BigNumber(totalSoldCoinDeposit || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 2) ||
              (new BigNumber(approximateAmount || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 3) ||
              (new BigNumber(approximateAmount || "0").isGreaterThan(
                totalSoldCoin || "0"
              ) &&
                status === 2) ||
              (new BigNumber(approximateAmount || "0").isGreaterThan(
                remainingTokenFromWei || "0"
              ) &&
                status === 3) ||
              (Number(value) + Number(TotalDeposit) > Number(totalSoldCoin) &&
                status === 2 &&
                saleTypes === SALES_TYPES.EQUAL_SALE)
            }
          >
            Deposit
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default ModalDeposit;
