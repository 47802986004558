import LogoLaunchpad from "../assets/logo_launchpad.png";
import LogoLandingLight from "../assets/logo_landing_light.svg";
import AboutUs from "../assets/about_us.svg";
import AboutUs1 from "../assets/about_us_1.png";
import AboutUs2 from "../assets/about_us_2.png";
import buyIdo from "../assets/buyIdo.png";
import LogoLaunchGarden from "../assets/svg/LogoLaunchGarden.svg";
import LogoIcon from "../assets/svg/LogoIcon.svg";
import BrightnessUp from "../assets/BrightnessUp.svg";
import ProjectIcon from "../assets/Vector.png";
import IconGuideLine1 from "../assets/IconGuideLine1.png";
import IconGuideLine2 from "../assets/IconGuideLine2.png";
import IconGuideLine3 from "../assets/IconGuideLine3.png";
import IconGuideLine4 from "../assets/IconGuideLine4.png";
import IconLine from "../assets/IconLine.png";
import HamburgerMemu from "../assets/burgerMenu.png";
import FrameIcon from "../assets/Frame.png";
import WalletIcon from "../assets/Wallet_Icon.png";
import IconDown from "../assets/Icon_Down.png";
import Upload from "../assets/Upload.png";
import MetaMask from "../assets/ico_metamask.png";
import Oasis from "../assets/oasisNetwork.png";
import CoinSymbol from "../assets/coinSymbol.png";
import Icon404 from "../assets/icon-404.png";
import IconNotRecordFound from "../assets/iconNotRecordFound.png";
import ethereum from "../assets/eth.png";
import AboutDark from "../assets/aboutDark.svg";
import OasisNetwork from "../assets/oasisNetwork.png";
import OasisIcon from "../assets/oasis.png";
import IconErrorNetwork from "../assets/IconErrorNetwork.png";
import DashedLine from "../assets/dashed_line.png";
import Confirmation from "../assets/confirmation.png";
import Composition from "../assets/Composition.png";
import WalletIconGrey from "../assets/Wallet_Icon_Grey.png";
import ETHNetwork from "../assets/ethNetwork.png";
//svg
import ArrowRight from "../assets/svg/arrow_right.svg";
import Twitter from "../assets/svg/Twitter.svg";
import Instagram from "../assets/svg/Instagram.svg";
import Facebook from "../assets/svg/Facebook.svg";
import Staking from "../assets/svg/staking_icon.svg";
import Airdrop from "../assets/svg/airdrop_icon.svg";
import BuyRose from "../assets/svg/buy_rose_icon.svg";
import Filter from "../assets/svg/filter.svg";
import Medium from "../assets/svg/Medium.svg";
import Unlock from "../assets/svg/Unlock.svg";
import BuggerIcon from "../assets/svg/burgerIcon.svg";
import StatusIconSvg from "../assets/svg/StatusIconSvg.svg";
import Tick from "../assets/svg/tick.svg";
import IconDropDownFilterDark from "../assets/svg/icon_dropdown_dark.svg";
import IconDropDownFilterLight from "../assets/svg/icon_dropdown_light.svg";
import IconDropDownUpOutlined from "../assets/svg/icon_dropdown_up.svg";
import IconDropDownDownOutlined from "../assets/svg/icon_dropdown_down.svg";

//icon
import Book from "../assets/icon/book.svg";
import BlogMe from "../assets/icon/blog_me.svg";
import Language from "../assets/icon/language.svg";
import Telegram from "../assets/icon/telegram.svg";
import TwitterIcon from "../assets/icon/twitter.svg";
import statusIcon from "../assets/icon/statusIcon.png";
import TransactionComplete from "../assets/icon/transactionComplete.png";
import ExternalLink from "../assets/icon/external-link.png";
import TransactionError from "../assets/icon/transactionError.png";
import TokenRose from "../assets/svg/token_rose.svg";
import TokenUSDT from "../assets/svg/token_usdt.svg";
import TokenUSDC from "../assets/svg/token_usdc.svg";
import BackIcon from "../assets/icon/back_arrow.png";
import IconIDO from "../assets/svg/logo_ido_pool.svg";
import IconGitHub from "../assets/svg/logoGithub.svg";
import IconChangeMode from "../assets/icon/change_mode.svg";

// landing page
import BgDiscover from "../assets/landing_page/bg_discover.svg";
import IconSapphire1 from "../assets/landing_page/sapphire_1.svg";
import IconSapphire2 from "../assets/landing_page/sapphire_2.svg";
import IconSapphire3 from "../assets/landing_page/sapphire_3.svg";
import BgParticipate from "../assets/landing_page/bg_participate.svg";
import IconStaking from "../assets/landing_page/icon_launchpad.svg";
import IconLaunchpad from "../assets/landing_page/icon_staking.svg";
import BgRoadmap from "../assets/landing_page/bg_roadmap.svg";
import SocialTwitter from "../assets/landing_page/social1.svg";
import SocialDiscord from "../assets/landing_page/social2.svg";
import SocialMedium from "../assets/landing_page/social3.svg";
import SocialTelegram from "../assets/landing_page/social4.svg";
import SocialCrew3 from "../assets/landing_page/social5.svg";
import Canh from "../assets/landing_page/canh_icon.svg";

const logo = {
  LogoLaunchpad,
  buyIdo,
  LogoLaunchGarden,
  LogoIcon,
  IconGitHub,
  LogoLandingLight,
};

const icons = {
  BrightnessUp,
  ArrowRight,
  Twitter,
  Instagram,
  Facebook,
  HamburgerMemu,
  FrameIcon,
  WalletIcon,
  IconDown,
  Upload,
  Staking,
  Airdrop,
  BuyRose,
  Filter,
  Icon404,
  IconNotRecordFound,
  ethereum,
  IconErrorNetwork,
  Book,
  BlogMe,
  Language,
  Telegram,
  TwitterIcon,
  OasisNetwork,
  statusIcon,
  DashedLine,
  TransactionComplete,
  ExternalLink,
  TransactionError,
  Medium,
  Unlock,
  OasisIcon,
  WalletIconGrey,
  BuggerIcon,
  StatusIconSvg,
  Tick,
  IconIDO,
  IconDropDownFilterDark,
  IconDropDownFilterLight,
  IconDropDownUpOutlined,
  IconDropDownDownOutlined,
  IconChangeMode,
  ETHNetwork,
};

const aboutUs = {
  AboutUs,
  AboutUs1,
  AboutUs2,
  AboutDark,
};

const ladingPage = {
  BgDiscover,
  IconSapphire1,
  IconSapphire2,
  IconSapphire3,
  BgParticipate,
  IconStaking,
  IconLaunchpad,
  BgRoadmap,
  SocialTwitter,
  SocialDiscord,
  SocialMedium,
  SocialTelegram,
  SocialCrew3,
  Canh,
};

const project_icon = {
  ProjectIcon,
  CoinSymbol,
  Composition,
};

const iconGuideLine = {
  IconGuideLine1,
  IconGuideLine2,
  IconGuideLine3,
  IconGuideLine4,
  IconLine,
};

const popup = {
  MetaMask,
  Oasis,
  OasisIcon,
  Confirmation,
};

const images = {
  logo,
  aboutUs,
  icons,
  project_icon,
  iconGuideLine,
  popup,
  TokenRose,
  TokenUSDT,
  TokenUSDC,
  BackIcon,
  ladingPage,
};

export default images;

// cách sử dụng
// import images from '......themes/images'; vào component cần dùng;
// sử dụng <img className="logo" src={images.icons.Logo} alt="logo" />
