import { useState, useEffect } from "react";

export default function useScreenResize() {
  const defaultScreenWidth = () => {
    const scrWidth = window.innerWidth;
    if (scrWidth >= 1200) {
      return'lg';
    } else if (768 <= scrWidth && scrWidth <= 1200) {
      return "md"
    } else {
      return "sm"
    }
  }

  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth());

  useEffect(() => {
    defaultScreenWidth()

    window.onresize = () => {
      const scrWidth = window.innerWidth;
      if (scrWidth >= 1200) {
        setScreenWidth("lg");
      } else if (768 <= scrWidth && scrWidth <= 1200) {
        setScreenWidth("md");
      } else {
        setScreenWidth("sm");
      }
    };
  }, [screenWidth]);

  return [screenWidth]
}
