import React from "react";
import styles from "./ProjectDetailItem.module.scss";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TOOLTIPS_TYPE } from "../constants";

interface CardStatusProps {
  title: string;
  value?: any;
  time?: string;
  tooltip?: boolean;
  staking?: boolean;
}

const CardStatus: React.FC<CardStatusProps> = ({
  title,
  value,
  time,
  tooltip,
  staking,
}) => {
  const { t } = useTranslation();

  const showToolTips = (type: string) => {
    switch (type) {
      case TOOLTIPS_TYPE.ACCESS_TYPE:
        return (
          <div className={styles.Tooltips}>
            <p>
              <span>{t("Staking.ProjectType.Public")}:</span>{" "}
              {t("Staking.ProjectType.PublicDesc")}
            </p>
            <p>
              <span>{t("Staking.ProjectType.Private")}:</span>{" "}
              {t("Staking.ProjectType.PrivateDesc")}
            </p>
            {staking && (
              <p>
                <span>{t("Staking.ProjectType.Hybrid")}:</span>{" "}
                {t("Staking.ProjectType.HybridDesc")}
              </p>
            )}
          </div>
        );

      case TOOLTIPS_TYPE.TRIGGER_TERM:
        return (
          <div className={styles.Tooltips}>
            <span>{t("Staking.Detail.TriggerTermDesc")}</span>
          </div>
        );

      case TOOLTIPS_TYPE.UNDER_TRIGGER_TERM:
        return (
          <div className={styles.Tooltips}>
            <span>{t("Staking.Detail.UnderTriggerTermDesc")}</span>
          </div>
        );
      case TOOLTIPS_TYPE.MINIMUM_GRDN:
        return (
          <div className={styles.Tooltips}>
            {staking ? (
              <span>
                To be eligible for the reward, please keep the GRDN in your
                wallet until the pool ends the staking period.
              </span>
            ) : (
              <span>
                To be eligible for the reward, please keep the GRDN in your
                wallet until the pool ends the snapshot period.
              </span>
            )}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={styles.CardStatus}>
      <span className={styles.CardStatus__Title}>
        <label className={styles.CardStatus__Title__Label} />
        <span>{title}</span>
        {tooltip && (
          <Tooltip
            placement="bottomLeft"
            color="#fff"
            title={showToolTips(title)}
          >
            <InfoCircleOutlined className={styles.Tooltips__TooltipsIcon} />
          </Tooltip>
        )}
      </span>
      <span className={styles.CardStatus__Value}>
        {value
          ? value
          : time
          ? t("projectDetail.time", { time })
          : t("Staking.MinimumStake.None")}
      </span>
    </div>
  );
};

export default CardStatus;
