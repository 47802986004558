import { Statistic } from "antd";
import moment from "moment";
import { IDO_LIST_FORMAT_TIME_TYPE } from "../constants";
import styles from "./countDown.module.scss";

interface PropsType {
  title?: string;
  timer: number;
  format?: string;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  onFinish?: () => void;
}

const getFormatByDiffTime = (
  diffTimeInDays: number,
  diffTimeInHours: number
) => {
  if (diffTimeInDays > 1) {
    return IDO_LIST_FORMAT_TIME_TYPE.DAYS;
  }
  if (diffTimeInHours > 1) {
    return IDO_LIST_FORMAT_TIME_TYPE.HOURS;
  }
  return IDO_LIST_FORMAT_TIME_TYPE.MINUTES;
};

const IDOListCoundown = (props: PropsType) => {
  const { Countdown } = Statistic;
  const { timer, title, suffix, prefix, onFinish } = props;

  const momentTimer = moment(timer * 1000);
  const diffTimeInDays = momentTimer.diff(moment(), "days", true);
  const diffTimeInHours = momentTimer.diff(moment(), "hours", true);
  const format = getFormatByDiffTime(diffTimeInDays, diffTimeInHours);

  return (
    <Countdown
      format={format}
      value={timer * 1000}
      title={title}
      suffix={suffix}
      prefix={prefix}
      className={styles.IDOListCoundown}
      onFinish={onFinish}
    />
  );
};

export default IDOListCoundown;
