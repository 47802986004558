import { Statistic } from "antd";
import moment from "moment";
import { FORMAT_TIME_TYPE } from "../constants";
import styles from "./countDown.module.scss";

interface CountDownProps {
  title?: string;
  timer: number;
  format?: string;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  isDetail?: boolean;
  onFinish?: () => void;
}

const getFormatByDiffTime = (
  diffTimeInDays: number,
  diffTimeInHours: number
) => {
  if (diffTimeInDays > 1) {
    return FORMAT_TIME_TYPE.DAYS;
  }
  if (diffTimeInHours > 1) {
    return FORMAT_TIME_TYPE.HOURS;
  }
  return FORMAT_TIME_TYPE.MINUTES;
};

const CountDown = (props: CountDownProps) => {
  const { Countdown } = Statistic;
  const { timer, title, suffix, prefix, onFinish, isDetail } = props;

  const momentTimer = moment(timer * 1000);
  const diffTimeInDays = momentTimer.diff(moment(), "days", true);
  const diffTimeInHours = momentTimer.diff(moment(), "hours", true);
  const format = getFormatByDiffTime(diffTimeInDays, diffTimeInHours);

  return (
    <Countdown
      format={format}
      value={timer * 1000}
      title={title}
      suffix={suffix}
      prefix={prefix}
      className={isDetail ?  styles.CountDown_Detail: styles.CountDown}
      onFinish={onFinish}
    />
  );
};

export default CountDown;
