import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getIconUrl } from "../../../../common/functions";
import styles from "./AirdropCardItem.module.scss";
import CountDown from "../../../../common/countDown";
import Latex from 'react-latex-next';
import images from "themes/images";

interface AirdropCardItemProps {
  dataItem: any;
  onFinishCountDown: () => void;
}

const AirdropCardItem: React.FC<AirdropCardItemProps> = ({
  dataItem,
  onFinishCountDown,
}) => {
  const { t } = useTranslation();
  const {
    id,
    token_name,
    token_symbol,
    token_icon,
    status,
    totalAmount,
    tokenRate,
    accessType,
    snapShotTime,
    claimMethod,
    finish_time,
    start_time,
    announce_time,
    distribute_time,
  } = dataItem;

  const [cardActive, setCardActive] = useState<number[]>([]);
  const history = useHistory();
  let timer = 0;

  const checkRenderTotalAmount = () => {
    return status === '5' || status === '6' || status === '4';
  }

  const handleClick = (index: number) => {
    const status = cardActive.findIndex((id) => id === index);
    if (status === -1) {
      const newArr = [...cardActive, index];
      setCardActive(newArr);
    } else {
      const newArr = [...cardActive];
      newArr.splice(status, 1);
      setCardActive(newArr);
    }
  };

  const viewDetailProject = (id: string) => {
    history.push(`/airdrop/${id}`);
  };

  const renderCardActive = (index: number | undefined) => {
    const status = cardActive.includes(index ? index : 0);
    if (status) {
      return `${styles.AirdropItem} ${styles.AirdropItem__Active}`;
    } else {
      return `${styles.AirdropItem}`;
    }
  };

  const isCardActive = (index: number) => {
    return cardActive.includes(index);
  };

  const renderStatus = () => {
    switch (status) {
      case "1": {
        timer = start_time;
        return (
          <div className={styles.Status__AwaitingApplication}>
            Awaiting application
          </div>
        );
      }
      case "2": {
        timer = finish_time;
        return <div className={styles.Status__Application}>Application</div>;
      }
      case "3": {
        timer = announce_time;
        return (
          <div className={styles.Status__AwaitingResults}>Awaiting results</div>
        );
      }
      case "4": {
        timer = distribute_time;
        return (
          <div className={styles.Status__AwaitingClaim}>Awaiting claim</div>
        );
      }
      case "5": {
        return <div className={styles.Status__Claiming}>Claiming</div>;
      }
      case "6": {
        return <div className={styles.Status__Completed}>Completed</div>;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div key={id} className={renderCardActive(id)}>
      <div
        className={styles.Content}
        onClick={() => {
          viewDetailProject(id);
        }}
      >
        <div className={styles.AirdropItem__Header}>
          <div >
            <img src={getIconUrl(token_icon)} alt={token_symbol} />
            <div className={styles.AirdropItem__Header__Token}>
              <div>{token_symbol}</div>
              <div>{token_name}</div>
            </div>
          </div>
          {renderStatus()}
        </div>
        <div className={styles.AirdropItem__TotalAmount}>
          <div className={styles.AirdropItem__TotalAmount__Countdown}>
            <span className={styles.AirdropItem__TotalAmount__Title}>
              {t("Airdrop.TotalAmount")}
            </span>
            {timer > 0 && (
              <CountDown timer={timer} onFinish={onFinishCountDown} />
            )}
          </div>
          <span className={styles.AirdropItem__TotalAmount__Value}>
            {checkRenderTotalAmount() ? (
              <>
                {totalAmount}
              </>
            ) : 'TBA' }
          </span>
        </div>
        <div className={styles.AirdropItem__Body}>
          <div>
            {t("Airdrop.AccessType")}
            <span>{accessType}</span>
          </div>
          <div>
            {t("Airdrop.SnapshotTime")}
            <span>{snapShotTime}</span>
          </div>
          <div>
            {t("Airdrop.ClaimMethod")}
            <span>{claimMethod}</span>
          </div>
          <div>
            {t("Airdrop.Rate")}
            <span>
            <Latex>{tokenRate}</Latex>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.AirdropItem__Show}>
          <Button
            onClick={() => {
              handleClick(id ? id : 0);
            }}
            className={styles.AirdropItem__Show__Btn}
          >
            {isCardActive(id ? id : 0) ? 
            <img src={images.icons.IconDropDownUpOutlined} alt='iconUp' /> 
            : 
            <img src={images.icons.IconDropDownDownOutlined} alt='iconDown' />
            }
          </Button>
        </div>
    </div>
  );
};

export default AirdropCardItem;
