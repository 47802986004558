import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "./tooltip.module.scss";
type Props = {
  children: React.ReactElement;
  title: React.ReactNode;
  place?: "top" | "right" | "bottom" | "left";
  style?: React.CSSProperties;
  hidden?: boolean;
};
type Position = {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

const Tooltip = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<Position>({ left: 0, top: 0 });
  const { children, title, place, hidden, style = {} } = props;
  const className =
    place && ["top", "right", "bottom", "left"].includes(place)
      ? styles[place]
      : style.right;
  const onMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    setVisible(true);
    const position: Position = {};
    switch (place) {
      case "top": {
        position.left =
          e.currentTarget.offsetLeft + e.currentTarget.offsetWidth / 2;
        position.top = e.currentTarget.offsetTop;
        break;
      }
      case "right": {
        position.left = e.clientX + 10;
        position.top = e.clientY;
        break;
      }
      case "bottom": {
        position.left = e.clientX;
        position.top = e.clientY + 10;
        break;
      }
      default: {
        position.left = e.clientX - 10;
        position.top = e.clientY;
        break;
      }
    }
    setPosition(position);
  };

  const onMouseLeave = (e: React.MouseEvent) => {
    setVisible(false);
  };

  const Child = () => {
    return (
      <div className={styles.tooltip} style={position}>
        <div className={styles.point}>
          <div className={`${styles.container} ${className}`} style={style}>
            {title}
          </div>
        </div>
      </div>
    );
  };

  const Component = children?.type || "div";
  const chilProps = children.props || { children: children };
  return (
    <>
      <Component
        {...chilProps}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
      {!hidden && visible && ReactDOM.createPortal(<Child />, document.body)}
    </>
  );
};

export default Tooltip;
