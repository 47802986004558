import React from 'react';
import 'antd/dist/antd.css';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers';
import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import AppContainer from './AppContainer';
import LaunchPadRoutes from './LaunchPadRoutes';
import { Provider } from 'react-redux';
import store from './stores';

export const getLibrary = (provider: any): Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider, 'any');
  library.pollingInterval = 10000;
  return library;
}

const createRouting = () => {
  return (
    <Router>
      <HashRouter basename="/">
        <AppContainer>
          <LaunchPadRoutes />
        </AppContainer>
      </HashRouter>
    </Router>
  );
};
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        {createRouting()}
      </Web3ReactProvider>
    </Provider>
  );
}

export default App;
