import { AxiosResponse } from "axios";
import { stringfy } from "../common/functions";
import { IDODetailParticipants } from "../common/types/IDOType";
import { ServiceBase } from "./core/service-base";
import { Users } from "./implements/implement-apis";
import {
  StakingDetailProjectParams,
  IDODetailParams,
  ProjectListParams,
  AirdropDetailProjectParams,
  CheckJoinPool,
} from "./param-types/index";

export class UserService extends ServiceBase implements Users {
  constructor() {
    super(true);
  }

  getProfile = async (wallet_address: string): Promise<AxiosResponse<any>> => {
    let queryString = `/user/user-profile?wallet_address=${wallet_address}`;
    return await this.get(queryString);
  };

  resetKYCSession = async (
    wallet_address: string
  ): Promise<AxiosResponse<any>> => {
    let queryString = `/user/kyc/init-new-session?wallet_address=${wallet_address}`;
    return await this.put(queryString, {});
  };

  // staking
  getListStakingProject = async (
    params: ProjectListParams
  ): Promise<AxiosResponse<any>> => {
    return await this.get(`/projects/staking-list?${stringfy(params)}`);
  };

  getStakingDetailProject = async (
    params: StakingDetailProjectParams
  ): Promise<AxiosResponse<any>> => {
    const { id, wallet } = params;
    return await this.get(`/staking/${id}?${stringfy({ wallet })}`);
  };

  getIDODetail = async (
    params: IDODetailParams
  ): Promise<AxiosResponse<any>> => {
    const { id } = params;
    let queryString = `/user/ido/detail/${id}`;
    return await this.get(queryString);
  };

  // airdrop
  getListAirdropProject = async (
    params: ProjectListParams
  ): Promise<AxiosResponse<any>> => {
    return await this.get(`/projects/airdrop?${stringfy(params)}`);
  };

  getAirdropDetailProject = async (
    params: AirdropDetailProjectParams
  ): Promise<AxiosResponse<any>> => {
    return await this.get(`/airdrop/${params.id}`);
  };

  getSignature = async (projectId: any): Promise<AxiosResponse<any>> => {
    return await this.get(`/user/projects/signature/${projectId}`);
  };

  getListUpcomingProject = async (): Promise<AxiosResponse<any>> => {
    return await this.get(`/projects/featured`);
  };

  checkWhiteList = async (projectId: any): Promise<AxiosResponse<any>> => {
    return await this.get(`/user/check-user/${projectId}`);
  };

  postSubscribe = async (params: any): Promise<AxiosResponse<any>> => {
    return await this.post(`/add-subscribe`, params);
  };
  getDelegateStakingAmount = async (
    oasisWallet: string
  ): Promise<AxiosResponse<any>> => {
    return await this.get(`user/delegate-staking-amount/${oasisWallet}`);
  };
  postOasisWallet = async (params: any): Promise<AxiosResponse<any>> => {
    return await this.post(`user/add-oasis-wallet`, params);
  };
  getOasisAdmin = async (): Promise<AxiosResponse<any>> => {
    return await this.get(`/oasis-verify-address`);
  };
  joinAirdrop = async (params: any): Promise<AxiosResponse<any>> => {
    const query = {
      wallet_address: params.address,
      signature: params.signature,
    };
    return await this.post(
      `/user/join_project/${params.projectId}?${stringfy(query)}`,
      params
    );
  };

  joinStaking = async (params: any): Promise<AxiosResponse<any>> => {
    const query = {
      signature: params.signature,
      wallet_address: params.address,
    };
    return await this.post(
      `/user/join_staking/${params.projectId}?${stringfy(query)}`,
      params
    );
  };

  checkJoinAndClaimAirdropProject = async (
    params: any
  ): Promise<AxiosResponse<any>> => {
    return await this.get(`/user/check-airdrop/${params.projectId}`);
  };

  checkJoinAndClaimStakingProject = async (
    params: any
  ): Promise<AxiosResponse<any>> => {
    return await this.get(
      `/user/check-staking/${params.projectId}?wallet_address=${params.address}`
    );
  };

  updateStatusClaimStaking = async (
    params: any
  ): Promise<AxiosResponse<any>> => {
    return await this.post(
      `/user/update-claim-status-staking/${params.projectId}`,
      params
    );
  };

  updateStatusClaimAirdrop = async (
    params: any
  ): Promise<AxiosResponse<any>> => {
    return await this.post(
      `/user/update-claim-status-airdrop/${params.projectId}`,
      params
    );
  };

  // IDO
  getListIDOProject = async (
    params: ProjectListParams
  ): Promise<AxiosResponse<any>> => {
    return await this.get(`/user/ido/pools-list?${stringfy(params)}`);
  };
  JoinIdoPool = async (params: any): Promise<AxiosResponse<any>> => {
    return await this.post(`/user/join-ido-pool/${params.id}`, params);
  };
  CheckJoinPool = async (
    params: CheckJoinPool
  ): Promise<AxiosResponse<any>> => {
    return await this.get(
      `/user/check-join-ido-pool?wallet_address=${params.wallet_address}&ido_pool_id=${params.ido_pool_id}`
    );
  };
  getSignatureClaim = async (params: any): Promise<AxiosResponse<any>> => {
    return await this.get(`/user/ido/claim/${params.id}`);
  };
  getListParticipants = async (
    params: IDODetailParticipants
  ): Promise<AxiosResponse<any>> => {
    const { id, walletAddress } = params;
    return await this.get(`/user/ido-pools/${id}/participants`, {
      walletAddress,
    });
  };
  getSignatureDeposit = async (param: any): Promise<AxiosResponse<any>> => {
    return await this.get(
      `/user/ido/signature-deposit/${param.id}?deposit_amount=${param.amount}`
    );
  };
}
