import AirdropCardItem from "./AirdropCardItem";
import styles from "./AirdropCard.module.scss";
import { Col, Pagination, Row } from "antd";
import NotRecordFound from "../../../../common/pages/NotRecordFound";
import useScreenResize from "../../../../common/hooks/useScreenResize";

interface AirdropCardProps {
  data: any[];
  handleChangePage: (page: number) => void;
  onFinishCountDown: (id: number, status: number) => void;
  total: string;
  pageInit: number;
  pageSize: string;
}

const AirdropCard: React.FC<AirdropCardProps> = ({
  data,
  handleChangePage,
  total,
  pageInit,
  onFinishCountDown,
  pageSize,
}) => {
  const [screenWidth] = useScreenResize();

  const renderArray = (length: number, subLength: number) => {
    let i = 0;
    let arr = [];
    while (i < length) {
      arr.push(new Array(subLength));
      i++;
    }
    return arr;
  };

  const divideData = (dataArray: any[]): any[] => {
    let newDataArray = [];
    switch (screenWidth) {
      case "lg":
        newDataArray = renderArray(3, 2);
        break;
      case "md":
        newDataArray = renderArray(2, 4);
        break;
      default:
        newDataArray = renderArray(1, 8);
        break;
    }

    let index = 0;
    while (index < dataArray.length) {
      newDataArray[index % newDataArray.length].push(dataArray[index]);
      index++;
    }
    return newDataArray;
  };

  const renderItemData = divideData(data).map(
    (dataItem: any[], index: number) => {
      return (
        <Col key={index} md={{ span: 12 }} xl={{ span: 8 }}>
          <div className={styles.ItemCol}>
            {dataItem.map((item: any, index: number) => {
              return (
                <AirdropCardItem
                  dataItem={item}
                  key={index}
                  onFinishCountDown={() =>
                    onFinishCountDown(item.id, item.status)
                  }
                />
              );
            })}
          </div>
        </Col>
      );
    }
  );

  const onChange = (page: number) => {
    handleChangePage(page);
  };

  return (
    <div className={styles.AirdropContainer}>
      {data.length > 0 ? (
        <>
          <Row className={styles.Row}>{renderItemData}</Row>
          {
            <Pagination
              className={styles.Pagination}
              onChange={onChange}
              total={parseInt(total) || 1}
              current={pageInit}
              showSizeChanger={false}
              showLessItems={true}
              pageSize={6}
              hideOnSinglePage={true}
            />
          }
        </>
      ) : (
        <NotRecordFound />
      )}
    </div>
  );
};

export default AirdropCard;
