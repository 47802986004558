import { Modal, Button } from "antd";
import _ from "lodash";
import { toNumber } from "lodash";
import { useState, useContext, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StakeContext } from "../../../pages/user/staking/detailProject";
import { NETWORK } from "../../constants";
import { changeToNumber, formatBigNumber, formatNumber } from "../../functions";
import IconClose from "../iconClose";
import "./stake.scss";

interface StakeProps {
  close: Function;
  isShow: boolean;
  setLoadingStake: any;
  getBalance: (walletAddress: string) => void;
}

const Stake: React.FC<StakeProps> = ({
  close,
  isShow,
  setLoadingStake,
  getBalance,
}) => {
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);
  const [amount, setAmount] = useState<string>("");
  const balance = useSelector((stake) => stake.wallet.balance);
  const { t } = useTranslation();
  const dataStake: any = useContext(StakeContext);
  const input = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>("");
  const { min_stake, max_stake, amountStake } = dataStake;
  const rootState = useSelector((state) => state.wallet);
  const { userInfo } = rootState;
  const wallet = _.get(userInfo, "wallet_address", null);

  const onlyNumber = (e: any) => {
    const re = /^(\d*\.{0,1}\d{0,2}$)/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setAmount(e.target.value);
    }
  };

  const handleClickOutside = (event: any) => {
    if (event.target.value) {
      const valueInput = changeToNumber(event.target.value);
      const value = formatNumber(event.target.value, true);
      event.target.value = value;
      setAmount(value);

      if (
        (valueInput + amountStake < toNumber(min_stake) && +min_stake !== 0) ||
        (valueInput + amountStake > toNumber(max_stake) && +max_stake !== 0)
      ) {
        setError("Invalid amount");
        return "Invalid amount";
      } else if (valueInput > balance) {
        setError("Not enough balance");
        return "Not enough balance";
      } else {
        setError("");
        return "";
      }
    }
  };

  const closePopUp = () => {
    if (close) {
      close();
      setAmount("");
      setError("");
    }
  };

  useEffect(() => {
    if (isShow) console.log("get");
    if (isShow) getBalance?.(wallet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const stake = () => {
    if (amount && !error) {
      dataStake.stakeToken(changeToNumber(amount), error, setLoadingStake);
      getBalance(wallet);
      closePopUp();
    } else if (!amount) {
      setError("Invalid amount");
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (!amount) {
        setError("Invalid amount");
      }
      const error = handleClickOutside(event);
      if (!error) {
        stake();
      }
    }
  };

  return (
    <Modal
      title=""
      visible={isShow}
      footer={null}
      className="ModalCustom"
      onCancel={closePopUp}
      closeIcon={<IconClose />}
      data-theme={isDarkMode ? "dark" : "light"}
    >
      <div className="Stake">
        <div className="Stake__Label">
          <div className="Stake__Label__Text">{t("PopUp.Stake")}</div>
        </div>
        <div className="Stake__Info">
          <ul>
            <li>
              <div className="Stake__Info__Title">
                <label className="Stake__Info__Title__Label"></label>
                <span className="Stake__Info__Title__Name">
                  {t("PopUp.Stake.Asset")}
                </span>
              </div>
              <span>{NETWORK.NATIVE_CURRENCY.SYMBOL}</span>
            </li>
            <li>
              <div className="Stake__Info__Title">
                <label className="Stake__Info__Title__Label"></label>
                <span className="Stake__Info__Title__Name">
                  {t("PopUp.Stake.Min")}
                </span>
              </div>
              <span>
                {+min_stake === 0
                  ? t("Staking.MinimumStake.None")
                  : formatBigNumber(min_stake)}
              </span>
            </li>
            <li>
              <div className="Stake__Info__Title">
                <label className="Stake__Info__Title__Label"></label>
                <span className="Stake__Info__Title__Name">
                  {t("PopUp.Stake.Max")}
                </span>
              </div>
              <span>
                {+max_stake === 0
                  ? t("Staking.MaximumStake")
                  : formatBigNumber(max_stake)}
              </span>
            </li>
            <li>
              <div className="Stake__Info__Title">
                <label className="Stake__Info__Title__Label"></label>
                <span className="Stake__Info__Title__Name">
                  {t("PopUp.Stake.YourStaking")}
                </span>
              </div>
              <span>{formatBigNumber(amountStake)}</span>
            </li>
          </ul>
        </div>

        <div className="Stake__Amount">
          <div className="Stake__Amount__Label">
            <span>Amount</span>
            <span>{`Balance: ${formatBigNumber(balance)} ${
              NETWORK.NATIVE_CURRENCY.SYMBOL
            }`}</span>
          </div>
          <input
            value={amount ? amount : ""}
            className={`${
              error
                ? "Stake__Amount__Input__Error"
                : "Stake__Amount__Input__Normal"
            } Stake__Amount__Input`}
            ref={input}
            placeholder="0.00"
            onChange={onlyNumber}
            onBlur={handleClickOutside}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <div className="Stake__Amount__Error">{error}</div>
        </div>
        <Button
          className="Stake__Button"
          type="primary"
          block={true}
          onClick={stake}
        >
          {t("PopUp.Stake")}
        </Button>
      </div>
    </Modal>
  );
};

export default Stake;
