import { useEffect } from "react";
import styles from "./Home.module.scss";
import GuideBuyIDO from "./guideBuyIDO";
// import MintYour from "./mintYour";
import Participate from "./participate";
import Launchpad from "./launchpad";
import Roadmap from "./roadmap";
import { resetStateToolbar } from "../../common/functions";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  useEffect(() => {
    resetStateToolbar();
  }, []);
  return (
    <div className={styles.HomeContainer}>
      <GuideBuyIDO />
      {/* <MintYour /> */}
      <div className={styles.BgParticipateLaunchpad}>
        <Participate />
        <Launchpad />
      </div>
      <Roadmap />
    </div>
  );
};

export default Home;
