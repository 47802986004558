import { ServiceBase } from "./core/service-base";
import { ConnectWallet } from "./implements/implement-apis";
import { ConnectWalletParams } from "./param-types/index";

export class ConnectWalletService extends ServiceBase implements ConnectWallet {
  constructor() {
    super(true);
  }
  connectWallet = async (params: ConnectWalletParams) => {
    return await this.post(`/user/login`, params);
  } 
}