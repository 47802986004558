import images from "../../../themes/images";
import styles from "./roadmap.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

interface RoadmapProps {}

const Roadmap: React.FC<RoadmapProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Roadmap}>
      <Row gutter={[50, 22]} align="middle">
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          className={styles.Launchpad__ColBox}
        >
          <h2 className={styles.Roadmap__Title}>{t("Roadmap.Title")}</h2>
          <ul className={styles.Roadmap__ListRoadmap}>
            <li>
              <div className={styles.Roadmap__BoxTitle}>
                <img src={images.ladingPage.Canh} alt="Canh" />
                <h3>{t("Roadmap.TitleSub1")}</h3>
              </div>
              <div className={styles.Roadmap__ListRoadmap__Box}>
                <div className={styles.Roadmap__ListRoadmap__Dot}></div>
                <p className={styles.Roadmap__ListRoadmap__Text}>
                  {t("Roadmap.TitleSubContent1")}
                </p>
              </div>
              <div className={styles.Roadmap__ListRoadmap__Box}>
                <div className={styles.Roadmap__ListRoadmap__Dot2}></div>
                <p className={styles.Roadmap__ListRoadmap__Text}>
                  {t("Roadmap.TitleSubContent2")}
                </p>
              </div>
            </li>
            <li>
              <div className={styles.Roadmap__BoxTitle}>
                <img src={images.ladingPage.Canh} alt="Canh" />
                <h3>{t("Roadmap.TitleSub2")}</h3>
              </div>

              <div className={styles.Roadmap__ListRoadmap__Box}>
                <div className={styles.Roadmap__ListRoadmap__Dot}></div>
                <p className={styles.Roadmap__ListRoadmap__Text}>
                  {t("Roadmap.TitleSubContent3")}
                </p>
              </div>
              <div className={styles.Roadmap__ListRoadmap__Box}>
                <div className={styles.Roadmap__ListRoadmap__Dot2}></div>
                <p className={styles.Roadmap__ListRoadmap__Text}>
                  {t("Roadmap.TitleSubContent4")}
                </p>
              </div>
            </li>
            <li>
              <div className={styles.Roadmap__BoxTitle}>
                <img src={images.ladingPage.Canh} alt="Canh" />
                <h3>{t("Roadmap.TitleSub3")}</h3>
              </div>
              <div className={styles.Roadmap__ListRoadmap__Box}>
                <div className={styles.Roadmap__ListRoadmap__Dot}></div>
                <p className={styles.Roadmap__ListRoadmap__Text}>
                  {t("Roadmap.TitleSubContent5")}
                </p>
              </div>
              <div className={styles.Roadmap__ListRoadmap__Box}>
                <div className={styles.Roadmap__ListRoadmap__Dot2}></div>
                <p className={styles.Roadmap__ListRoadmap__Text}>
                  {t("Roadmap.TitleSubContent6")}
                </p>
              </div>
            </li>
          </ul>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <img
            className={styles.Roadmap__Bg}
            src={images.ladingPage.BgRoadmap}
            alt=""
          />
        </Col>
      </Row>
    </div>
  );
};

export default Roadmap;
