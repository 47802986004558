import { Input, Layout, Button, Form, notification, Col, Row } from "antd";
import styles from "./Layout.module.scss";
import images from "../../themes/images";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/UserServicer";
import { SOCIAL_MEDIA_LINK } from "../../common/constants";
import "../../common/styles/customAntd.scss";

const { Footer } = Layout;

const LayoutFooter: React.FC = () => {
  const { t } = useTranslation();
  const userService = new UserService();
  const [form] = Form.useForm();

  const showEnterButton = () => {
    return (
      <Button
        htmlType="submit"
        type="primary"
        className={styles.SearchInput__EnterButton}
      >
        Subscribe
      </Button>
    );
  };

  const onSubmit = async (value: any) => {
    if (!!value.email) {
      const { status, data } = await userService.postSubscribe(value);
      const statusData = data["status"];
      const message = data["message"];

      if (status === 200) {
        if (statusData === 200) {
          notification.success({
            message: t("SubscribeSuccess", { email: value.email }),
          });
        } else {
          notification.error({
            message: message,
          });
        }
      }

      form.setFieldsValue({
        email: "",
      });
    } else {
      notification.warning({
        message: t("InputEmail"),
      });
    }
  };

  return (
    <Footer className={styles.Footer}>
      <div className={styles.Footer__Wrapper}>
        <Row gutter={[50, 22]} align="middle">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <p className={styles.Footer__Wrapper__Contact}>
              {t("Footer.SubscribeNow")}
            </p>
            <p className={styles.Footer__Wrapper__SubscribeNow}>
              {t("Footer.StayUpProjects")}
            </p>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form onFinish={onSubmit} form={form}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("InvalidEmail"),
                  },
                ]}
              >
                <Input
                  className={styles.InputFormFooter}
                  placeholder={t("Footer.EnterYourEmail")}
                  suffix={showEnterButton()}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <div className={styles.Footer__Info}>
        <div className={styles.Footer__Info__Container}>
          {t("Footer.Copyright")}

          <div className={styles.Footer__ListIcon}>
            <a
              href={SOCIAL_MEDIA_LINK.PROJECT_TWITTER}
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.ladingPage.SocialTwitter} alt="Icon" />
            </a>
            <a
              href={SOCIAL_MEDIA_LINK.PROJECT_DISCORD}
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.ladingPage.SocialDiscord} alt="Icon" />
            </a>
            <a
              href={SOCIAL_MEDIA_LINK.PROJECT_MEDIUM}
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.ladingPage.SocialMedium} alt="Icon" />
            </a>
            {/* <a
              href={SOCIAL_MEDIA_LINK.PROJECT_TELEGRAM}
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.ladingPage.SocialTelegram} alt="Icon" />
            </a>
            <a
              href={SOCIAL_MEDIA_LINK.PROJECT_CREW3}
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.ladingPage.SocialCrew3} alt="Icon" />
            </a> */}
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default LayoutFooter;
