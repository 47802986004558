import React from "react";
import { Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "./AirdropList.module.scss";
import { getIconUrl } from "../../../../common/functions";
import {
  AIRDROP_CLAIM_TYPE,
  AIRDROP_STATUS,
} from "../../../../common/constants";
import NotRecordFound from "../../../../common/pages/NotRecordFound";
import CountDown from "../../../../common/countDown";
import { InfoCircleOutlined } from "@ant-design/icons";
import Latex from "react-latex-next";

interface AirDropListProp {
  airdropList: any;
  total: string;
  pageInit: number;
  handleChangePage: (page: number) => void;
  onFinishCountDown: (id: number, status: number) => void;
}

const AirdropTable: React.FC<AirDropListProp> = ({
  airdropList,
  total,
  pageInit,
  handleChangePage,
  onFinishCountDown,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const viewDetailProject = (record: any) => {
    const { id } = record;
    history.push(`/airdrop/${id}`);
  };

  const showToolTips = (type: string) => {
    switch (type) {
      case "accessType":
        return (
          <div className={styles.Columns__Tooltips}>
            <p>
              <span>{t("Airdrop.ProjectType.Public")}: </span>
              {t("Airdrop.ProjectType.PublicDesc")}
            </p>
            <p>
              <span>{t("Airdrop.ProjectType.Private")}: </span>
              {t("Airdrop.ProjectType.PrivateDesc")}
            </p>
          </div>
        );

      case "claimMethod":
        return (
          <div className={styles.Columns__Tooltips}>
            <p>
              <span>{t("Airdrop.ClaimMethod.Linear")}: </span>
              {t("Airdrop.ClaimMethod.LinearDesc")}
            </p>
            <p>
              <span>{t("Airdrop.ClaimMethod.Quadratic")}: </span>
              {t("Airdrop.ClaimMethod.QuadraticDesc")}
            </p>
            <p>
              <span>{t("Airdrop.ClaimMethod.FixedAmount")}: </span>
              {t("Airdrop.ClaimMethod.FixedAmountDesc")}
            </p>
          </div>
        );
    }
  };
  const columns = [
    {
      title: "Asset",
      key: "Asset",
      width: "25%",
      render: (record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <div className={styles.Columns__Asset}>
              <img
                src={getIconUrl(record.token_icon)}
                alt={record.token_symbol}
              />
              <div>
                <h5>{record.token_symbol}</h5>
                <p className={styles.tokenName}>{record.token_name}</p>
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: () => {
        return (
          <div className={styles.Columns__Title}>
            Access type
            <Tooltip
              placement="bottomLeft"
              color="#fff"
              title={showToolTips("accessType")}
            >
              <InfoCircleOutlined className={styles.Columns__QuestionIcon} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "accessType",
      key: "Access type",
      render: (AccessType: number, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: <p className={styles.Columns__AccessType}>{AccessType}</p>,
        };
      },
    },
    {
      title: () => {
        return (
          <div className={styles.Columns__Title}>
            Claim method
            <Tooltip
              placement="bottomLeft"
              color="#fff"
              title={showToolTips("claimMethod")}
            >
              <InfoCircleOutlined className={styles.Columns__QuestionIcon} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "claimMethod",
      key: "Claim method",
      render: (claimMethod: number, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <div className={styles.Columns__HardCap}>{claimMethod}</div>
          ),
        };
      },
    },
    {
      title: "Rate",
      dataIndex: "tokenRate",
      key: "rate",
      render: (tokenRate: string, record: any) => {
        const { distributionMethod } = record;
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <p className={styles.Columns__TriggerTerms}>
              {distributionMethod === AIRDROP_CLAIM_TYPE.QUADRATIC ? (
                <Latex>{tokenRate}</Latex>
              ) : (
                tokenRate
              )}
            </p>
          ),
        };
      },
    },
    {
      title: "Snapshot time (UTC)",
      dataIndex: "snapShotTime",
      key: "snapshot time",
      // defaultSortOrder: 'descend',
      render: (snapshotTime: String, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <p className={styles.Columns__TriggerTerms}>{snapshotTime}</p>
          ),
        };
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status: number, record: any) => {
        let className = "";
        let displayStatus = "";
        let timer = 0;

        switch (Number(status)) {
          case AIRDROP_STATUS.AWAITING_APPLICATION:
            className = styles.Columns__AwaitingApplication;
            displayStatus = t("Project.Status.AwaitingApplication");
            timer = record.start_time;
            break;
          case AIRDROP_STATUS.APPLICATION:
            className = styles.Columns__Application;
            displayStatus = t("Project.Status.Application");
            timer = record.finish_time;
            break;
          case AIRDROP_STATUS.AWAITING_RESULTS:
            className = styles.Columns__AwaitingResults;
            displayStatus = t("Project.Status.AwaitingResults");
            timer = record.announce_time;
            break;
          case AIRDROP_STATUS.AWAITING_CLAIM:
            className = styles.Columns__AwaitingClaim;
            displayStatus = t("Project.Status.AwaitingClaim");
            timer = record.distribute_time;
            break;
          case AIRDROP_STATUS.CLAIMING:
            className = styles.Columns__Claiming;
            displayStatus = t("Project.Status.Claiming");
            break;
          case AIRDROP_STATUS.COMPLETED:
            className = styles.Columns__Completed;
            displayStatus = t("Project.Status.Completed");
            break;
        }

        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <>
              <div className={`${className} ${styles.Columns__StatusButton}`}>
                {displayStatus}
              </div>
              {timer > 0 && (
                <CountDown
                  timer={timer}
                  onFinish={() => onFinishCountDown(record.id, record.status)}
                />
              )}
            </>
          ),
        };
      },
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        className="Table"
        columns={columns}
        dataSource={airdropList}
        pagination={{
          showSizeChanger: false,
          total: parseInt(total),
          current: pageInit,
          showLessItems: true,
          pageSize: 8,
          hideOnSinglePage: true,
        }}
        onChange={(page) => page.current && handleChangePage(page.current)}
        locale={{ emptyText: <NotRecordFound /> }}
      />
    </div>
  );
};

export default AirdropTable;
