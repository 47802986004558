import { Input, Table, TableColumnsType } from "antd";
import "./styles.scss";

export interface ParticipantsUser {
  id: number;
  walletAddress: string;
  deposit_amount: string;
}

interface PropsType {
  onSearch: (searched: any) => void;
  data: ParticipantsUser[];
  value: string;
}

function formatAddress(address: string) {
  if (!address) {
    return "";
  }

  const suffix = address.substring(0, 14);
  const prefix = address.slice(address.length - 14);

  return `${suffix}*************${prefix}`;
}

const Participant = (props: PropsType) => {
  const { Search } = Input;
  const { value, onSearch } = props;

  const data = props.data.map((el, index) => ({
    key: index,
    ...el,
  }));
  const handleChange = (e: any) => {
    onSearch(e.target.value);
  };

  const columns: TableColumnsType<any> = [
    {
      title: <div className="text-table">No</div>,
      dataIndex: "no",
      align: "left",
      width: "10vh",
      key: "no",
      render: (value: any, record: any, index: number) => {
        return <span className="text">{index + 1}</span>;
      },
    },
    {
      title: <div className="text-table">Wallet Address</div>,
      align: "left",
      dataIndex: "walletAddress",
      key: "walletAddress",
      render: (value: any, record: any, index: number) => (
        <span className="text">{formatAddress(record?.wallet_address)}</span>
      ),
    },
  ];

  return (
    <div className="main-pool">
      <div className="wrapper-table">
        <div className="SearchInput">
          <Search
            className="toolBarSearchInput"
            placeholder="Search wallet address"
            value={value}
            onChange={handleChange}
            maxLength={14}
          />
          {!value && (
            <p className="text-search">
              Search first or last 14 characters of your wallet address
            </p>
          )}
          {!data.length && value && (
            <p className="text-search-fail">
              Sorry, there is no wallet address matching your search.
            </p>
          )}
        </div>

        <div className="table-participants">
          {data.length > 20 ? (
            <Table
              scroll={{ y: 1139 }}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <Table columns={columns} dataSource={data} pagination={false} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Participant;
