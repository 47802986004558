export enum KYCBypassTypes {
  Required = 0,
  Bypass = 1,
}
export enum DisplayTypes {
  Hidden = 0,
  Display = 1,
}
export enum DeployTypes {
  DEPLOY = 0,
  DEPLOYING = 1,
  DEPLOYED = 2,
}
export enum IDOPoolTypes {
  PUBLIC = 1,
  PRIVATE = 2,
  SEED = 3,
  COMMUNITY = 4,
}

export enum PrivateSettingTypes {
  NORMAL = 1,
  WHITELIST = 2,
}
export enum AcceptedCurrencyTypes {
  ROSE = 1,
  USDT = 2,
  USDC = 3,
}
export enum SalesTypes {
  EQUAL_SALE = 1,
  FIRST_COME_FIRST_SERVE = 2,
  PUBLIC_VALUATION_SALE = 3,
}
export enum FeeTokenTypes {
  PROJECT_TOKEN = 1,
  IDO_TOKEN = 2,
}
export type ClaimConfigItem = {
  id?: number | string;
  start_time: number;
  max_claim: number;
};
export enum IDOStatus {
  AWAITING_APPLICATION = 1,
  APPLICATION = 2,
  AWAITING_DEPOSIT = 3,
  DEPOSIT = 4,
  PUBLIC_SELL = 5,
  AWAITING_CLAIM = 6,
  CLAIM = 7,
}

export interface IDOItemType {
  id: number;
  is_display: number;
  pool_banner: string;
  pool_label: IDOPoolTypes;
  private_setting: PrivateSettingTypes;
  sale_type: SalesTypes;
  accepted_currency: AcceptedCurrencyTypes;
  total_sold_coin: string;
  token_symbol: string;
  token_sold_coin: string;
  start_time_application: string;
  end_time_application: string;

  claim_configs: ClaimConfigItem[];
  start_time_public_sell: string;
  end_time_public_sell: string;

  pool_address: string | null;
  fee: string | null;
  min_grdn: string | null;
  pool_name: string;
  website: string | null;
  whitepaper_link: string | null;
  twitter_link: string | null;
  telegram_link: string | null;
  medium_link: string | null;
  token_address: string;
  token_icon: string;
  address_receive_rose: string;
  exchange_rate: string;
  forbidden_countries: string;
  kyc_bypass: KYCBypassTypes;
  start_time_deposit: string;
  end_time_deposit: string;
  announce_results_time: string;
  claim_time: string;
  description: string | null;
  status: number;
  minimum_delegate_staking_amount: string;
  max_deposit: string;
  max_participant: number;
  ps_update_status: number;
  total_deposit: string;
  is_display_participant: number;
  is_display_special_whitelist_user: number;
  is_priority: number;
}
export interface IDOResponseType {
  id: number;
  is_display: DisplayTypes;
  kyc_bypass: KYCBypassTypes;
  forbidden_countries: string | string[];

  pool_banner: string;
  min_grdn: string;
  minimum_delegate_staking_amount: string;
  fee_token: FeeTokenTypes;
  fee: number;
  received_fee_wallet: string;
  pool_address: string;
  pool_name: string;
  website: string;
  whitepaper_link: string;
  telegram_link: string;
  medium_link: string;
  twitter_link: string;

  token_address: string;
  total_sold_coin: string;
  token_symbol: string;
  token_icon: string;
  address_receive_rose: string;

  pool_label: IDOPoolTypes;
  private_setting: PrivateSettingTypes;
  accepted_currency: AcceptedCurrencyTypes;
  sale_type: SalesTypes;
  max_deposit: string;
  max_participant: number;
  exchange_rate: string;

  start_time_application: string;
  end_time_application: string;
  start_time_deposit: string;
  end_time_deposit: string;

  claim_policy: string;
  claim_configs: ClaimConfigItem[];
  remaining_token: string;
  start_time_public_sell: string;
  end_time_public_sell: string;
  description: string;

  status_deploy: DeployTypes;
  status: IDOStatus;
  ps_update_status: number;
  total_deposit: string;
  is_display_participant: number;
  is_display_special_whitelist_user: number;
  is_priority: number;
  token_decimal: number;
}
export interface IDOType {
  id: number;
  is_display: boolean;
  kyc_bypass: number;
  forbidden_countries: string[];

  pool_banner: string;
  min_grdn: string;
  minimum_delegate_staking_amount: string;
  fee_token: FeeTokenTypes;
  fee: number;
  received_fee_wallet: string;
  pool_address: string;
  pool_name: string;
  website: string;
  whitepaper_link: string;
  telegram_link: string;
  medium_link: string;
  twitter_link: string;

  token_address: string;
  total_sold_coin: string;
  token_symbol: string;
  token_icon: string;
  address_receive_rose: string;

  pool_label: IDOPoolTypes;
  private_setting: PrivateSettingTypes;
  accepted_currency: AcceptedCurrencyTypes;
  sale_type: SalesTypes;
  max_deposit: string;
  max_participant: number;
  exchange_rate: string;

  start_time_application: string;
  end_time_application: string;

  start_time_deposit: string;
  end_time_deposit: string;

  claim_policy: string;
  claim_configs: ClaimConfigItem[];
  remaining_token: string;
  start_time_public_sell: string;
  end_time_public_sell: string;
  description: string;

  status_deploy: DeployTypes;
  status: IDOStatus;
  ps_update_status: number;
  total_deposit: string;
  is_display_participant: number;
  is_display_special_whitelist_user: number;
  is_priority: number;
  claim_time: string;
  token_decimal: number;
}

export interface IPool {
  totalSoldCoin?: string;
  tokenSymbol?: string;
  exchangeRate?: string;
  acceptedCurrency?: AcceptedCurrencyTypes;
  description?: string;
  startTimeDeposit?: string;
  endTimeDeposit?: string;
  startTimeApplication?: string;
  endTimeApplication?: string;
  status?: any;
  poolAddress?: string;
  maxDeposit?: string;
  claimConfig?: ClaimConfigItem[];
  startTimePS?: string;
  endTimePS?: string;
  totalDeposit?: string;
  remainingToken?: string;
  saleTypes?: number;
  maxParticipant?: number;
}

export interface IDODetailParticipants {
  id: number;
  walletAddress: string;
}
