import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openDeposit: false,
  joinIdo: "0",
  allowance: false,
  checkJoin: false,
  loading: false,
  userAmount: "0",
  userPSAmount: "0",
  isWhitelist: false,
  deposit: false,
  participant: "0",
  numberOfParticipant: "0",
  claim: "0",
  balanceMain: "0",
  totalPS:"0"
};

const idoSlice = createSlice({
  name: "ido",
  initialState,
  reducers: {
    setOpenDeposit(state, action) {
      state.openDeposit = action.payload;
    },
    setJoinIdo(state, action) {
      state.joinIdo = action.payload as string;
    },
    setAllowance(state, action) {
      state.allowance = action.payload;
    },
    setCheckJoin(state, action) {
      state.checkJoin = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setUserAmount(state, action) {
      state.userAmount = action.payload;
    },
    setIsWhiteList(state, action) {
      state.isWhitelist = action.payload;
    },
    setDeposit(state, action) {
      state.deposit = action.payload;
    },
    setParticipant(state, action) {
      state.participant = action.payload;
    },
    setNumberOfParticipant(state, action) {
      state.numberOfParticipant = action.payload;
    },
    setUserPSAmount(state, action) {
      state.userPSAmount = action.payload;
    },
    setClaims(state, action) {
      state.claim = action.payload;
    },
    setBalanceMain(state, action) {
      state.balanceMain = action.payload;
    },
    setTotalPS(state,action){
      state.totalPS = action.payload
    }
  },
});

export const {
  setOpenDeposit,
  setAllowance,
  setCheckJoin,
  setLoading,
  setJoinIdo,
  setUserAmount,
  setIsWhiteList,
  setDeposit,
  setParticipant,
  setNumberOfParticipant,
  setUserPSAmount,
  setClaims,
  setBalanceMain,
  setTotalPS
} = idoSlice.actions;

export default idoSlice.reducer;
