import { Alert } from "antd";
import './transaction.scss';
import TransactionComplete from "./transactionComplete";
import TransactionError from "./transactionError";
import TransactionReject from "./transactionReject";

interface TransactionProps {
  type: string;
  hash: any;
}

const Transaction: React.FC<TransactionProps> = ({ type, hash }) => {
  const AlertTransaction = () => {
    switch (type) {
      case "complete":
        return <TransactionComplete hash={hash} />;
      case "error":
        return <TransactionError hash={hash} />;
      case "reject":
        return <TransactionReject />;
      default:
        return <></>;
    }
  }
  return (
    <Alert
      className="Transaction"
      message={<AlertTransaction />}
      closable={true}
      data-type={type}
    />
  );
}

export default Transaction;