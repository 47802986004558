import React, { useEffect, useState } from "react";
import { Col, Pagination, Row } from "antd";
import Card from "./Card";
import ToolbarUser from "../../../../common/toolbarUser";
import styles from "./index.module.scss";
import { UserService } from "../../../../services/UserServicer";
import { IDOItemType } from "../../../../common/types/IDOType";
import { useSelector } from "react-redux";
import {
  setFilterIDO,
  setLayoutCardIDO,
  setLayoutTableIDO,
  setMyProjectIDO,
  setPageIDO,
  setSearchIDO,
} from "../../../../stores/toolbar";
import { ROUTES } from "../../../../common/constants";
import { ProjectListParams } from "../../../../services/param-types";
// import message from "../../../../common/alert/message";
import { Link } from "react-router-dom";
import NotRecordFound from "../../../../common/pages/NotRecordFound";

export interface StatusItem {
  id: number;
  status: number;
}
const IDOPool: React.FC = () => {
  const PER_PAGE = 6;
  const UserServiceAPI = new UserService();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<IDOItemType>>([]);
  const [total, setTotal] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const { isShowLayoutTableIDO, isShowLayoutCardIDO } = useSelector(
    (state) => state.toolbar
  );
  const { registed } = useSelector(
    (state) => state.toolbar.isActiveMyProjectIDO
  );
  const { optionFilterIDO, valueSearchIDO, pageIDO } = useSelector(
    (state) => state.toolbar
  );

  const getListIDOProject = async () => {
    try {
      setLoading(true);
      const params: ProjectListParams = {
        page: pageIDO,
        limit: PER_PAGE,
        status: optionFilterIDO.join(","),
        name: valueSearchIDO,
        registed: registed,
      };
      const result = await UserServiceAPI.getListIDOProject(params);
      if (!result.data?.data) throw new Error("Get IDO list failed");
      const data: IDOItemType[] = result.data?.data?.data;
      const total: number = result.data?.data?.total;
      const lastPage: number = result.data?.data?.lastPage;
      setData(data);
      setTotal(total);
      setLastPage(lastPage);
    } catch (error: any) {
      // return message.error("System Error!");
    }
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      setSearchIDO("");
      setPageIDO(1);
    };
  }, []);

  useEffect(() => {
    getListIDOProject();
    // return () => resetStateToolbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isShowLayoutCardIDO,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(optionFilterIDO),
    valueSearchIDO,
    pageIDO,
    registed,
    lastPage,
  ]);
  const handleFilter = (valueFilter: string[]) => {
    setFilterIDO(valueFilter);
    setPageIDO(1);
  };

  const handleSearch = (text: string) => {
    setSearchIDO(text);
    setPageIDO(1);
  };
  const handleChangePage = (page: number) => {
    if (document && document.getElementById("private-layout")) {
      document.getElementById("private-layout")?.scroll(0, 0);
    }
    setPageIDO(page);
  };

  const handleShowLayoutTable = () => {
    if (isShowLayoutTableIDO) return;
    setLayoutTableIDO();
    setPageIDO(1);
  };

  const handleShowLayoutCard = () => {
    if (isShowLayoutCardIDO) return;
    setLayoutCardIDO();
    setPageIDO(1);
  };
  const handleChangeMyprojects = (isActiveIDO: boolean, registed: string) => {
    setMyProjectIDO({ isActiveIDO, registed });
    setPageIDO(1);
  };
  return (
    <div>
      <ToolbarUser
        handleShowLayoutCard={handleShowLayoutCard}
        handleShowLayoutTable={handleShowLayoutTable}
        isShowLayoutTable={isShowLayoutTableIDO}
        isShowLayoutCard={isShowLayoutCardIDO}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        handleChangeMyprojects={handleChangeMyprojects}
        isIDO={true}
      />
      {!loading && !data.length ? (
        <NotRecordFound />
      ) : (
        <div className={styles.IDOList}>
          <Row gutter={[16, 16]}>
            {data.map((pool) => (
              <Col
                key={pool.id}
                xs={{ span: 24 }}
                md={{ span: 12 }}
                xl={{ span: 8 }}
              >
                <Link to={ROUTES.IDOPOOL_DETAIL.replace(":id", `${pool.id}`)}>
                  <Card pool={pool} onFinish={getListIDOProject} />
                </Link>
              </Col>
            ))}
          </Row>
          {lastPage > 1 && total > PER_PAGE && (
            <Pagination
              className={styles.Pagination}
              onChange={handleChangePage}
              total={total}
              current={pageIDO}
              responsive
              pageSize={PER_PAGE}
              showLessItems
              showSizeChanger={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default IDOPool;
