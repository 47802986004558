import { Row, Col, Button } from 'antd';
import images from '../../themes/images';
import styles from './Style_404.module.scss';
import { ROUTES } from '../../common/constants'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
    const { t } = useTranslation();

    return(
        <div className={styles.Container}>
            <Row className={styles.Container__Content}>
                <Col span={24}>
                    <img className={styles.Container__Content__Img} src={images.icons.Icon404} alt='404' />
                    <div className={styles.Container__Content__Message}>{t("PageNotFound.Message")}</div>
                </Col>
                <Col  span={24}>
                    <div className={styles.Container__Content__SideButton}>
                    <Button className={styles.Container__Content__SideButton__Button} size="large">
                        <NavLink to={ROUTES.HOME}>
                        {t("PageNotFound.GoToHomepage")}
                        </NavLink>
                    </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}