import { Modal } from 'antd';
import images from '../../../themes/images';
import { POPUP } from '../../constants';
import { MouseEvent, SetStateAction, useState, useEffect } from 'react';
import './connectWallet.scss'
import { useSelector } from 'react-redux';
import IconClose from '../iconClose';
import Unavailable from './unavailable';
import ErrorConnect from './errorConnect';
import { useTranslation } from 'react-i18next';

interface ConnectWalletProps {
  isShow: boolean,
  handleCancel: (e: MouseEvent<HTMLElement>) => void,
  connectWallet: Function,
}

const ConnectWallet: React.FC<ConnectWalletProps> = (props) => {
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const handleConnectWallet = () => {
    props.connectWallet().then((result: SetStateAction<string>) => setError(result));
  }

  const Error = () => {
    switch (error) {
      case POPUP.CONNECTWALLET.STATUS.UNINSTALL:
        return <Unavailable />
      case POPUP.CONNECTWALLET.STATUS.ERROR:
        return <ErrorConnect />
      default:
        return <></>
    }
  }

  useEffect(() => {
    return () => {
      setError('')
    }
  }, [props.isShow]);

  return (
    <Modal
      title=""
      visible={props.isShow}
      footer={null}
      className="ModalCustom"
      onCancel={props.handleCancel}
      closeIcon={<IconClose />}
      data-theme={isDarkMode ? 'dark' : 'light'}
    >
      <div className="ConnectWallet" >
        <div className="ConnectWallet__Label">
          <div className="ConnectWallet__Label__Text">{t("PopUp.ConnectWallet.Label")}</div>
        </div>
        <div className={
          `ConnectWallet__Wallet 
          ${error === 'uninstall' || error === 'error' ? 'ConnectWallet__Wallet__Error' : ''}`
        }
          onClick={handleConnectWallet}>
          <img src={images.popup.MetaMask} alt={t("PopUp.ConnectWallet.Wallet")} />
          <div className="ConnectWallet__Wallet__Name">{t("PopUp.ConnectWallet.Wallet")}</div>
        </div>
        <div className="ConnectWallet__Error">
          <Error />
        </div>
      </div>
    </Modal>
  );
}

export default ConnectWallet;