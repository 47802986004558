import React, { useEffect, useState } from 'react';
import Synaps from '@synaps-io/react-verify';
import { useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import styles from "./index.module.scss";
import { setUserInfo, UserInfoType } from '../../stores/wallet';
import { STATUS_KYC_USER } from '../../common/constants';
import { UserService } from '../../services/UserServicer';
import messageCustom from '../../common/alert/message';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';


interface PropsType {
    onReady?: () => void;
    onFinish?: () => void;
    onClose?: () => void;
    visible?: boolean;
}

const UserAPI = new UserService();

const SynapsKYC: React.FC<PropsType> = (props) => {
    const { onReady, onFinish, onClose, visible } = props;
    const isDarkMode = useSelector((state) => state.wallet.isDarkMode);
    const userInfo: UserInfoType | null = useSelector(({ wallet }) => wallet.userInfo);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const handleResetSession = async () => {
            if (userInfo?.wallet_address) {
                setLoading(true);
                try {
                    const result = await UserAPI.resetKYCSession(userInfo.wallet_address);
                    const { data } = result;
                    const session_id = data?.data?.session_id;
                    if (session_id) setUserInfo({ ...userInfo, session_id });
                    else throw new Error(t("ErrorSystem"));
                }
                catch (err: any) {
                    messageCustom.error(err?.message);
                }
            }
            setLoading(false);
        }
        if (visible && !userInfo?.session_id) {
            handleResetSession()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, userInfo])


    const isCancelled = userInfo?.kyc_status === STATUS_KYC_USER.CANCELLED;
    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            okText="Close"
            footer={null}
            maskClosable={true}
            className={styles.kycModal}
        >
            <div className={styles.kycContainer + (isCancelled ? " " + styles.kycCancelled : "")}>
                {loading ?
                    <Spin size="default" />
                    :
                    userInfo?.session_id ?
                        !isCancelled ?
                            <Synaps
                                sessionId={userInfo?.session_id}
                                service="individual"
                                lang="en"
                                onReady={onReady}
                                onFinish={onFinish}
                                color={{
                                    primary: isDarkMode ? '17181b' : '212b39',
                                    secondary: isDarkMode ? '17181b' : 'ffffff'
                                }}
                                className={styles.kycIframe}
                            />
                            :
                            <div className={styles.cancelledContainer}>
                                <div className={styles.cautionIcon}>
                                    <WarningOutlined />
                                </div>
                                <div className={styles.cancelledMessage}>
                                    Your KYC is cancelled.
                                </div>
                                <div className={styles.needHelp}>
                                    Need help? Contact to our <a href="#contact">support team</a>.
                                </div>
                            </div>
                        : null
                }
            </div>
        </Modal>
    )
}
export default SynapsKYC