import { Button, Input } from "antd";
import BigNumber from "bignumber.js";
import { IDO_STATUS, SALES_TYPES } from "common/constants";
import { convert } from "common/functions";
import CurrencyInput from "common/Input/CurrencyInput";
import { AcceptedCurrencyTypes } from "common/types/IDOType";
import React, { memo } from "react";
import { useSelector } from "react-redux";

interface PropsType {
  handleChange: any;
  getMaxValue: () => void;
  checkDeposit: boolean;
  checkBalance: boolean;
  checkBalanceUSDT: boolean;
  checkBalanceUSDC: boolean;
  value: string;
  maxDeposit?: string;
  checkValueZero: boolean;
  saleTypes?: number;
  totalSoldCoin?: any;
  checkTotalSoldcoin: boolean;
  acceptedCurrency?: AcceptedCurrencyTypes;
  status?: number;
  acceptableAmount?: string;
  checkAcceptableAmount: boolean;
  checkRemainingToken: boolean;
  remainingToken?: any;
  totalMaxDeposit?: any;
  totalRemaining?: string;
  totalSoldCoinDeposit?: string;
  allowance?: string | number;
  totalDeposit?: string | number;
  approximateAmount?: string | number;
}
const InputDeposit: React.FC<PropsType> = memo((props: PropsType) => {
  const {
    handleChange,
    value,
    getMaxValue,
    checkBalance,
    checkDeposit,
    maxDeposit,
    checkBalanceUSDC,
    checkBalanceUSDT,
    checkValueZero,
    saleTypes,
    totalSoldCoin,
    checkTotalSoldcoin,
    acceptedCurrency,
    status,
    acceptableAmount,
    checkAcceptableAmount,
    checkRemainingToken,
    remainingToken,
    totalMaxDeposit,
    // totalRemaining,
    totalSoldCoinDeposit,
    totalDeposit,
    approximateAmount,
  } = props;

  const { balance, balanceUSDC, balanceUSDT } = useSelector(
    (state) => state.wallet
  );

  const renderError = () => {
    if (value && (Number(value) === 0 || value === "") && checkValueZero)
      return <span className="text-validate">Invalid amount</span>;

    if (new BigNumber(totalSoldCoinDeposit || 0).isGreaterThan(totalSoldCoin)) {
      return <span className="text-validate">Invalid amount</span>;
    }
    if (
      acceptedCurrency === 2 &&
      new BigNumber(value).isGreaterThan(convert(balanceUSDT)) &&
      checkBalanceUSDT
    )
      return <span className="text-validate">Not enough balance</span>;

    if (
      acceptedCurrency === 1 &&
      new BigNumber(value).isGreaterThan(convert(balance)) &&
      checkBalance
    )
      return <span className="text-validate">Not enough balance</span>;

    if (
      acceptedCurrency === 3 &&
      new BigNumber(value).isGreaterThan(convert(balanceUSDC)) &&
      checkBalanceUSDC
    )
      return <span className="text-validate">Not enough balance</span>;

    if (
      new BigNumber(value).isGreaterThan(acceptableAmount || "0") &&
      checkAcceptableAmount &&
      saleTypes === SALES_TYPES.FIRST_COME_FIRST_SERVE &&
      (status === IDO_STATUS.DEPOSIT || status === IDO_STATUS.PUBLIC_SELL)
    )
      return <span className="text-validate">Not enough remaining tokens</span>;

    if (
      new BigNumber(totalMaxDeposit).isGreaterThan(maxDeposit || "0") &&
      saleTypes === SALES_TYPES.EQUAL_SALE &&
      status &&
      status >= 2 &&
      checkDeposit
    )
      return <span className="text-validate">Invalid amount</span>;

    if (
      new BigNumber(approximateAmount || "0").isGreaterThan(
        convert(remainingToken) || "0"
      ) &&
      status === 3 &&
      checkRemainingToken
    )
      return <span className="text-validate">Invalid Amount</span>;

    if (
      new BigNumber(totalSoldCoinDeposit || "0").isGreaterThan(totalSoldCoin) &&
      checkTotalSoldcoin &&
      status &&
      status >= 2
    )
      return <span className="text-validate">Invalid Amount</span>;

    if (
      Number(value) + Number(totalDeposit) > Number(totalSoldCoin) &&
      saleTypes === SALES_TYPES.EQUAL_SALE &&
      status &&
      status >= 2
    ) {
      return <span className="text-validate">Invalid Amount</span>;
    }
  };

  return (
    <Input.Group compact>
      <CurrencyInput
        name="amout"
        form={null}
        placeHolder="0.00"
        decimalsLimit={2}
        style={{ width: "calc(100%)", paddingRight: 80 }}
        onChange={handleChange}
        value={value}
        maxLength={10}
      />
      <Button onClick={getMaxValue} className="button">
        Max
      </Button>
      {renderError()}
    </Input.Group>
  );
});

export default InputDeposit;
