import { Button  } from 'antd'
import Tooltip from 'common/popup/tooltipCustom';
import { useEffect, useRef, useState } from 'react';
import CopyIcon from '../../common/icon/CopyIcon'
import styles from "./index.module.scss";
interface PropType {
    text: string | undefined,
    title: string,
}
export default function TooltipCustom({ text, title }: PropType) {
    const [copied, setCopied] = useState(false)
    const [copiedErr, setCopiedErr] = useState('')
    const setTimeOutRef = useRef<any>(null)

    const copyAddress = () => {
        if (text) {
            navigator.clipboard?.writeText(text).then(res => {
                setCopied(true)
                clearTimeout(setTimeOutRef.current)
                setTimeOutRef.current = setTimeout(() => {
                    setCopied(false)
                }, 1200);
            }).catch(err => {
                setCopiedErr(err?.message || err)
                setTimeOutRef.current = setTimeout(() => {
                    setCopiedErr('')
                }, 1200);

            })
        }
    };
    useEffect(() => {
        return () => clearTimeout(setTimeOutRef.current)
    }, [])
    return (
        <Tooltip title={copied ? 'Copied' : copiedErr || title} place={'top'}>
            <Button type="link" className={styles.Icon} onClick={copyAddress}>
                <CopyIcon />
            </Button>
        </Tooltip>
    )
}
