import { Alert, message } from "antd";
import images from "../../../themes/images";
import { ALERT_TIME } from "../../constants";
import "./message.scss";

interface MessageProps {
  message: string,
  type: "error" | "success" | "warning"
}

const Message: React.FC<MessageProps> = (props) => {
  const { message, type } = props;

  return (
    <Alert
      className="message-alret"
      message={
        <span className="message-content">
          <img
            src={
              type === "success" ?
                images.icons.TransactionComplete
                : images.icons.TransactionError
            }
            alt={type} />
          {message}
        </span>
      }
      closable={true}
      data-type={type}
    />
  );
}
export const messageAlert = (type: "error" | "success" | "warning", text: string) => {
  message.info({
    content: <Message message={text} type={type} />,
    icon: <></>,
    duration: ALERT_TIME,
  });
}
export const messageCustom = {
  success: (message: string) => messageAlert("success", message),
  error: (message: string) => messageAlert("error", message),
  warning: (message: string) => messageAlert("warning", message),
}
export default messageCustom;