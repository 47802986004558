
import { useSelector } from 'react-redux'

export default function BrightNess() {
    const { isDarkMode } = useSelector(wallet => wallet.wallet)
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C11.4477 2 11 2.44772 11 3V5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5V3C13 2.44772 12.5523 2 12 2Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M12 18C11.4477 18 11 18.4477 11 19V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V19C13 18.4477 12.5523 18 12 18Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19C18.4477 13 18 12.5523 18 12C18 11.4477 18.4477 11 19 11H21Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M6 12C6 11.4477 5.55228 11 5 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H5C5.55228 13 6 12.5523 6 12Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M17.6569 4.92903C18.0474 4.53851 18.6806 4.53851 19.0711 4.92903C19.4616 5.31955 19.4616 5.95272 19.0711 6.34324L17.6569 7.75746C17.2664 8.14798 16.6332 8.14798 16.2427 7.75746C15.8522 7.36693 15.8522 6.73377 16.2427 6.34324L17.6569 4.92903Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M7.7574 16.2428C7.36688 15.8522 6.73371 15.8522 6.34319 16.2428L4.92897 17.657C4.53845 18.0475 4.53845 18.6807 4.92897 19.0712C5.3195 19.4617 5.95266 19.4617 6.34319 19.0712L7.7574 17.657C8.14792 17.2664 8.14792 16.6333 7.7574 16.2428Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M19.0711 17.6569C19.4616 18.0474 19.4616 18.6806 19.0711 19.0711C18.6806 19.4616 18.0474 19.4616 17.6569 19.0711L16.2427 17.6569C15.8521 17.2664 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 17.2664 15.8522 17.6569 16.2427L19.0711 17.6569Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path d="M7.75737 7.7574C8.14789 7.36688 8.14789 6.73371 7.75737 6.34319L6.34316 4.92897C5.95263 4.53845 5.31947 4.53845 4.92894 4.92897C4.53842 5.3195 4.53842 5.95266 4.92894 6.34319L6.34316 7.7574C6.73368 8.14792 7.36685 8.14792 7.75737 7.7574Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill={isDarkMode ? '#B1B5C3' : '#353945'} />
        </svg>

    )
}
