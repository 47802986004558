import React, { useEffect, useState, createContext } from "react";
import Web3 from "web3";
import moment from "moment";
import images from "../../../../themes/images";
import _, { toNumber } from "lodash";
import styles from "./DetailProject.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { UserService } from "../../../../services/UserServicer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ABI_ALLOCATION,
  ALERT_TIME,
  DETAIL_PROJECT_STATUS,
  DETAIL_STAKING_STATUS,
  PROJECT_STAKING_TYPE,
  ROUTES,
  STATUS_JOIN_POOL,
} from "../../../../common/constants";
import StatusItem from "../../../../common/projectDetailItem/StatusItem";
import WrapperStake from "./WrapperStake";
import YourStaking from "./YourStaking";
import CardStatus from "../../../../common/projectDetailItem/CardStatus";
import { Dictionary } from "../../../../common/types/Types";
import { BigNumberish } from "ethers";
import { message } from "antd";
import Transaction from "../../../../common/alert/transaction";
import "./MessageCustom.scss";
import {
  formatBigNumber,
  formatUnixTimeToDate,
  getBalance,
} from "../../../../common/functions";
import { checkProjectType, getIconUrl } from "../../../../common/functions";
import CountDown from "../../../../common/countDown";
import ErrorSystem from "../../../../common/alert/errorSystem";
import HeaderNotification from "../../../../components/HeaderNotification";
import BigNumber from "bignumber.js";
import messageCustom from "../../../../common/alert/message";
import {
  setAmountTokenGRDN,
  // setTotalDelegateStakingAmount,
} from "../../../../stores/wallet";
const ERC20_ABI = require("../../../../abi/ERC20.json");
interface DetailStaking {
  id?: number;
  airdrop_fix_amount?: number;
  announce_time: number;
  buy_type?: number;
  created_at?: moment.Moment;
  display_price_rate?: number;
  distribute_time: number;
  distribution_method?: number;
  finish_time: number;
  max_stake?: number;
  medium_link?: string;
  min_stake?: number;
  other_hardcap?: string;
  other_stake?: Dictionary<number>;
  other_trigger?: string;
  other_untrigger?: string;
  project_contract_address?: string;
  project_information?: string;
  project_name?: string;
  project_type?: number;
  snapshot_time?: moment.Moment;
  start_time: number;
  status?: number;
  telegram_link?: string;
  title?: string;
  token_address?: string;
  token_name?: string;
  token_symbol?: string;
  total_raise_amount?: number;
  twitter_link?: string;
  updated_at?: moment.Moment;
  website?: string;
  whitelist_stake?: Dictionary<number>;
  whitelist_hardcap?: string;
  whitelist_trigger?: string;
  whitelist_untrigger?: string;
  whitepaper_link?: string;
  token_icon?: string;
  total_stake?: any;
  status_deploy?: number;
  kyc_bypass?: number;
  forbidden_countries?: string[];
  stake_type?: number;
  fee?: string;
  min_grdn?: string;
  minimum_delegate_staking_amount?: string;
  token_decimal?: number;
}
export const StakeContext = createContext({});

const DetailProject: React.FC = () => {
  const { id } = useParams<any>();
  const { t } = useTranslation();
  const UserServiceAPI = new UserService();
  const rootState = useSelector((state) => state.wallet);
  const { userInfo, isConnectNetwork, isConnectMetaMark } = rootState;
  const [dataDetail, setDataDetail] = useState<DetailStaking>({
    start_time: 0,
    finish_time: 0,
    announce_time: 0,
    distribute_time: 0,
  });
  const [amountStake, setAmountStake] = useState(0);
  const balance = useSelector((stake) => stake.wallet.balance);
  const wallet = _.get(userInfo, "wallet_address", null);
  // const [currentStatus, setCurrentStatus] = useState<any>("");
  const [totalParticipantFake, setTotalParticipantFake] = useState(0);
  const [isWhiteList, setIsWhiteList] = useState<boolean>(false);
  const [joinStatus, setJoinStatus] = useState(0);
  const [isClaim, setClaim] = useState<boolean>(false);
  const [isCheckJoinAndClaim, setIsCheckJoinAndClaim] = useState(false);
  const [processCheck, setProcessCheck] = useState<boolean>(false);

  // let interval: any;
  const history = useHistory();

  const {
    project_type,
    token_symbol,
    twitter_link,
    medium_link,
    telegram_link,
    whitepaper_link,
    status,
    other_stake,
    other_hardcap,
    other_trigger,
    other_untrigger,
    min_stake,
    max_stake,
    whitelist_stake,
    whitelist_hardcap,
    whitelist_untrigger,
    whitelist_trigger,
    start_time,
    finish_time,
    announce_time,
    distribute_time,
    project_contract_address,
    token_icon,
    total_stake,
    website,
    token_name,
    status_deploy,
    kyc_bypass,
    forbidden_countries,
    min_grdn,
    minimum_delegate_staking_amount,
    token_decimal,
  } = dataDetail;
  const dataStake = {
    projectType: project_type,
    otherUnTrigger: other_untrigger,
    otherTrigger: other_trigger,
    whiteListUnTrigger: whitelist_untrigger,
    whiteListTrigger: whitelist_trigger,
    tokenSymbol: token_symbol,
  };

  const windowObj = window as any;
  const { ethereum } = windowObj;
  const web3 = new Web3(ethereum);
  const abiAllocation = ABI_ALLOCATION;
  const address = userInfo?.wallet_address;

  const stakeToken = async (
    amount: number,
    error: string,
    setLoadingStake?: any
  ) => {
    if (amount && address && project_contract_address && !error) {
      const contractAllocation = new web3.eth.Contract(
        abiAllocation,
        project_contract_address
      );
      setLoadingStake(true);
      const amountGwei: BigNumberish = web3.utils.toWei(amount.toString());
      let transactionHash = "";
      contractAllocation.methods
        .stakeROSE()
        .send({
          from: address,
          value: amountGwei,
        })
        .on("transactionHash", function (hash: any) {
          transactionHash = hash;
        })
        .on("receipt", function (receipt: any) {
          getAmount();
          getBalance(wallet);
          // setTotalParticipantFake(
          //   (Number(total_stake?.total_participant) || 0) + 1
          // );
          getDetailProject();
          setLoadingStake(false);
          return message.info({
            content: <Transaction type="complete" hash={transactionHash} />,
            icon: <></>,
            duration: ALERT_TIME,
          });
        })
        .on("error", function (error: any, receipt: any) {
          setLoadingStake(false);
          if (receipt) {
            return message.info({
              content: <Transaction type="error" hash={transactionHash} />,
              icon: <></>,
              duration: ALERT_TIME,
            });
          }
        })
        .catch((err: any) => {
          setLoadingStake(false);
          if (!transactionHash) {
            return message.info({
              content: <Transaction type="reject" hash={transactionHash} />,
              icon: <></>,
              duration: ALERT_TIME,
            });
          }
        });
    }
  };

  const getAmount = async () => {
    if (address && project_contract_address) {
      const contractAllocation = new web3.eth.Contract(
        abiAllocation,
        project_contract_address
      );
      await contractAllocation?.methods
        .userAmounts(address)
        .call({ from: address }, function (err: any, result: any) {
          if (result) {
            setAmountStake(toNumber(web3.utils.fromWei(result.toString())));
          } else {
            setAmountStake(0);
          }
        });
    }
  };
  const getAmountTokenGRDN = async () => {
    if (userInfo?.wallet_address) {
      const contract =
        web3 &&
        (new web3.eth.Contract(
          ERC20_ABI,
          process.env.REACT_APP_TOKEN_GRDN_ADRRESS
        ) as any);
      const decimals = await contract?.methods.decimals().call();

      const tokenGRDN = await contract?.methods
        .balanceOf(userInfo.wallet_address)
        .call();

      setAmountTokenGRDN(
        new BigNumber(tokenGRDN).div(new BigNumber(10).pow(decimals)).toString()
      );
    }
  };
  // const getDelegateAmount = async () => {
  //   if (userInfo?.oasis_address) {
  //     try {
  //       const { data } = await UserServiceAPI.getDelegateStakingAmount(
  //         userInfo.oasis_address
  //       );
  //       const totalAmount = _.get(data, "data.total_amount", "");
  //       setTotalDelegateStakingAmount(web3?.utils.fromWei(totalAmount) || "0");
  //     } catch (err) {
  //       return message.info({
  //         content: <ErrorSystem />,
  //         icon: <></>,
  //         duration: ALERT_TIME,
  //       });
  //     }
  //   }
  // };
  useEffect(() => {
    getAmount();
    getAmountTokenGRDN();
    // getDelegateAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, status, isConnectNetwork]);

  const getDetailProject = async () => {
    try {
      const params = {
        id,
        wallet,
      };
      const result = await UserServiceAPI.getStakingDetailProject(params);
      const dataDetail = _.get(result, "data.data", {});

      if (!dataDetail) {
        history.push(ROUTES.PAGE_NOT_FOUND);
        return;
      }
      // status !== currentStatus && clearInterval(interval);
      // setCurrentStatus(status);
      setDataDetail(dataDetail);
      setTotalParticipantFake(dataDetail?.total_stake?.total_participant);
    } catch (error) {
      return message.info({
        content: <ErrorSystem />,
        icon: <></>,
        duration: ALERT_TIME,
      });
    }
  };
  const checkJoinAndClaimProject = async (projectId: number) => {
    try {
      const params = { projectId: projectId, address: address };
      const result = await UserServiceAPI.checkJoinAndClaimStakingProject(
        params
      );
      const data = result.data.data;
      if (!data) throw new Error("Cannot check Join and Claim!");
      if (
        data?.status_join === STATUS_JOIN_POOL.JOINED ||
        data?.status_join === STATUS_JOIN_POOL.INVALID_GRDN_TOKEN_AMOUNT
      ) {
        if (data.status_claim === 1) {
          setClaim(true);
        } else {
          setClaim(false);
        }
      }
      setJoinStatus(data?.status_join);
      setIsCheckJoinAndClaim(true);
    } catch {
      setIsCheckJoinAndClaim(false);
      return messageCustom.error("System Error");
    }
  };
  useEffect(() => {
    getDetailProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, id]);
  useEffect(() => {
    getCheckWhiteList(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectMetaMark, isConnectNetwork, project_type, id]);

  const { accessType, underTriggerTerm, triggerTerm } =
    checkProjectType(dataStake);
  const minimumStakeAmountRequired =
    minimum_delegate_staking_amount &&
    new BigNumber(minimum_delegate_staking_amount).gt(0)
      ? t("Staking.Detail.StakeValue", {
          value: formatBigNumber(minimum_delegate_staking_amount),
        })
      : t("Staking.MinimumStake.None");
  const minimumStakeGRDNAmount =
    min_grdn && new BigNumber(min_grdn).gt(0)
      ? t("Staking.Detail.StakeGRDNToken", {
          value: formatBigNumber(min_grdn),
        })
      : t("Staking.MinimumStake.None");
  const minimumStake =
    min_stake && +min_stake
      ? t("Staking.Detail.StakeValue", {
          value: formatBigNumber(min_stake),
        })
      : t("Staking.MinimumStake.None");

  const maximumStake =
    max_stake && +max_stake
      ? t("Staking.Detail.StakeValue", {
          value: formatBigNumber(max_stake),
        })
      : t("Staking.MaximumStake");

  const handleFinishCountdown = () => {
    // interval = setInterval(() => {
    //   if (dataDetail.status === currentStatus) {
    //     getDetailProject();
    //   }
    // }, 1000);
    setTimeout(() => {
      getDetailProject();
    }, 5000);
  };

  const renderCountdown = () => {
    let timer, prefix;
    switch (status) {
      case DETAIL_PROJECT_STATUS.AWAITING_STAKING:
        timer = start_time;
        prefix = "Staking starts in ";
        break;
      case DETAIL_PROJECT_STATUS.STAKING:
        timer = finish_time;
        prefix = "Staking ends in ";
        break;
      case DETAIL_PROJECT_STATUS.AWAITING_RESULTS:
        timer = announce_time;
        prefix = "Results announced in ";
        break;
      case DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION:
        timer = distribute_time;
        prefix = "Claiming starts in ";
        break;
      default:
        return;
    }
    return (
      <CountDown
        timer={timer}
        prefix={prefix}
        onFinish={handleFinishCountdown}
        isDetail={true}
      />
    );
  };
  const backToList = () => {
    history.push(ROUTES.STAKING);
  };
  const getCheckWhiteList = async (projectId: any) => {
    if (
      (project_type === PROJECT_STAKING_TYPE.PRIVATE ||
        project_type === PROJECT_STAKING_TYPE.HYBRID) &&
      isConnectMetaMark &&
      isConnectNetwork
    ) {
      try {
        setProcessCheck(true);
        const result = await UserServiceAPI.checkWhiteList(projectId);
        const data = _.get(result, "data.data", {});
        if (data) {
          setIsWhiteList(true);
        }
      } catch (error: any) {
        setIsWhiteList(false);
      } finally {
        setProcessCheck(false);
      }
    } else {
      setIsWhiteList(false);
    }
  };
  return (
    <StakeContext.Provider
      value={{
        min_stake,
        max_stake,
        stakeToken,
        amountStake,
        project_contract_address,
      }}
    >
      <div className={styles.DetailProject}>
        <HeaderNotification
          bypass={kyc_bypass}
          forbiddenCountries={forbidden_countries}
          poolDetail={dataDetail}
          isWhiteList={isWhiteList}
          joinStatus={joinStatus}
          isClaim={isClaim}
        />
        <div className={styles.DetailProject__Header}>
          <div className={styles.DetailProject__Header__Information}>
            <div className={styles.Back} onClick={backToList}>
              <img src={images.BackIcon} alt="Back" />
              <span>Back</span>
            </div>
            <div className={styles.DetailProject__Header__Information__Wrapper}>
              <div
                className={
                  styles.DetailProject__Header__Information__Wrapper__Token
                }
              >
                <img src={getIconUrl(token_icon)} alt="icon" />
                <h4>
                  {t("Staking.Detail.Asset", {
                    tokenName: token_name,
                    tokenSymbol: token_symbol,
                  })}
                </h4>
              </div>
              <div
                className={
                  styles.DetailProject__Header__Information__Wrapper__ListIcon
                }
              >
                {website && (
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    <img src={images.icons.Language} alt="Language" />
                  </a>
                )}
                {whitepaper_link && (
                  <a
                    href={whitepaper_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={images.icons.Book} alt="Book" />
                  </a>
                )}
                {telegram_link && (
                  <a
                    href={telegram_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={images.icons.Telegram} alt="Telegram" />
                  </a>
                )}
                {medium_link && (
                  <a
                    href={medium_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={images.icons.BlogMe} alt="Blog Me" />
                  </a>
                )}
                {twitter_link && (
                  <a
                    href={twitter_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={images.icons.TwitterIcon} alt="Twitter Icon" />
                  </a>
                )}
              </div>
            </div>
            {renderCountdown()}
          </div>
          <div className={styles.DetailProject__Header__ListStatus}>
            {DETAIL_STAKING_STATUS.map((statusItem: any, index: number) => (
              <React.Fragment key={index}>
                <StatusItem statusItem={statusItem} currentStatus={status} />
                {statusItem.status < DETAIL_PROJECT_STATUS.COMPLETED && (
                  <img
                    className={styles.ProjectHeader__ListStatus__DashedLine}
                    src={images.icons.DashedLine}
                    alt="Dashed Line"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={styles.DetailProject__Wrapper}>
          <WrapperStake
            projectType={project_type}
            otherStake={other_stake}
            whiteListStake={whitelist_stake}
            otherHardCap={other_hardcap}
            whitelistHardCap={whitelist_hardcap}
            tokenSymbol={token_symbol}
            dataTotalStake={total_stake}
            totalParticipantFake={totalParticipantFake}
          />
          <YourStaking
            status={status}
            projectId={id}
            amountStake={amountStake}
            tokenSymbol={token_symbol}
            projectContractAddress={project_contract_address}
            projectType={project_type}
            announce_time={announce_time}
            statusDeploy={status_deploy}
            minGRDN={min_grdn}
            minDelegateStakingAmount={minimum_delegate_staking_amount}
            poolDetail={dataDetail}
            isWhiteList={isWhiteList}
            setJoinStatus={setJoinStatus}
            checkJoinAndClaimProject={checkJoinAndClaimProject}
            isJoinProject={joinStatus >= STATUS_JOIN_POOL.JOINED}
            isClaim={isClaim}
            isCheckJoinAndClaim={isCheckJoinAndClaim}
            setClaim={setClaim}
            processCheck={processCheck}
            tokenDecimal={token_decimal}
          />
        </div>
        <div className={styles.DetailProject__List}>
          <div className={styles.DetailProject__List__Type}>
            <CardStatus
              title={t("Staking.Detail.AccessType")}
              value={accessType}
              tooltip
              staking
            />
            {new BigNumber(minimum_delegate_staking_amount || 0).gt(0) ? (
              <CardStatus
                title={t("Staking.Detail.MinimumDelegateStakeAmount")}
                value={minimumStakeAmountRequired}
              />
            ) : null}
            {new BigNumber(min_grdn || 0).gt(0) ? (
              <CardStatus
                title={t("Staking.Detail.MinimumGRDN")}
                value={minimumStakeGRDNAmount}
                tooltip
                staking
              />
            ) : null}
            <CardStatus
              title={t("Staking.Detail.MinimumStake")}
              value={minimumStake}
            />
            <CardStatus
              title={t("Staking.Detail.MaximumStake")}
              value={maximumStake}
            />
            <CardStatus
              title={t("Staking.Detail.TriggerTerm")}
              value={triggerTerm}
              tooltip
            />
            <CardStatus
              title={t("Staking.Detail.UnderTriggerTerm")}
              value={underTriggerTerm}
              tooltip
            />
          </div>
          <div className={styles.DetailProject__List__Time}>
            <CardStatus
              title={t("Staking.Detail.StartStakingTime")}
              time={formatUnixTimeToDate(start_time)}
            />
            <CardStatus
              title={t("Staking.Detail.EndStakingTime")}
              time={formatUnixTimeToDate(finish_time)}
            />
            <CardStatus
              title={t("Staking.Detail.AnnounceResultsTime")}
              time={formatUnixTimeToDate(announce_time)}
            />
            <CardStatus
              title={t("Staking.Detail.DistributeTime")}
              time={formatUnixTimeToDate(distribute_time)}
            />
          </div>
        </div>
      </div>
    </StakeContext.Provider>
  );
};

export default DetailProject;
