import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { message } from "antd";
import StakingCard from "./stakingCard/StakingCard";
import ToolbarUser from "../../../common/toolbarUser";
import StakingTable from "./stakingTable";
import { UserService } from "../../../services/UserServicer";
import { StakingType } from "../../../common/types/StakingType";
import {
  ALERT_TIME,
  plainOptions,
  PROJECT_STAKING_TYPE,
} from "../../../common/constants";
import { useTranslation } from "react-i18next";
import {
  // toRealNumber,
  // plusBigNumber,
  // formatNumber,
  formatBigNumber,
  // resetStateToolbar,
} from "../../../common/functions";
import { useSelector } from "react-redux";
import {
  setMyProjectStaking,
  setMyProjectAirdrop,
  setFilterStaking,
  setFilterAirdrop,
  setSearchStaking,
  setSearchAirdrop,
  setPageStaking,
  setPageAirdrop,
} from "../../../stores/toolbar";
import ErrorSystem from "../../../common/alert/errorSystem";
import StorageUtils from "../../../utils/storage";
import BigNumber from "bignumber.js";

export interface StatusArr {
  id: number;
  status: number;
}

const Staking = () => {
  const UserServiceAPI = new UserService();
  const { t } = useTranslation();
  const [dataStaking, setDataStaking] = useState<Array<StakingType>>([]);
  const [total, setTotal] = useState<string>("");
  const [isPhoneVer, setIsPhoneVer] = useState<boolean>(
    window.innerWidth <= 1023
  );
  const [pageSize, setPageSize] = useState<string>("");
  const time = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isGridStaking, setIsGridStaking] = useState<boolean>(
    StorageUtils.getItem("isGridStaking", "false") === "true"
  );

  const { registed } = useSelector(
    (state) => state.toolbar.isActiveMyProjectStaking
  );
  const { optionFilterStaking, valueSearchStaking, pageStaking } = useSelector(
    (state) => state.toolbar
  );
  let statusArr: StatusArr[] = [{ id: 0, status: 0 }];
  let listInterval: NodeJS.Timeout[] = [];
  let count = 0;

  const formatData = (data: any) => {
    return data.map((project: StakingType) => {
      const {
        id,
        token_name,
        token_symbol,
        token_icon,
        project_type,
        whitelist_trigger,
        whitelist_untrigger,
        other_trigger,
        other_untrigger,
        whitelist_hardcap,
        other_hardcap,
        status,
        finish_time,
        start_time,
        announce_time,
        distribute_time,
      } = project;
      let AccessType = "";
      let TriggerTerm;
      let UnderTriggerTerm;
      let hard_cap;
      if (project_type === PROJECT_STAKING_TYPE.PRIVATE) {
        AccessType = t("Staking.ProjectType.Private");
        TriggerTerm = t("Staking.Detail.Normal.ValueTriggerTerm", {
          value: formatBigNumber(whitelist_trigger),
        });
        UnderTriggerTerm = t("Staking.Detail.Normal.ValueUnderTriggerTerm", {
          value: formatBigNumber(whitelist_untrigger),
          tokenSymbol: token_symbol,
        });
        hard_cap = formatBigNumber(whitelist_hardcap);
      } else if (project_type === PROJECT_STAKING_TYPE.PUBLIC) {
        AccessType = t("Staking.ProjectType.Public");
        TriggerTerm = t("Staking.Detail.Normal.ValueTriggerTerm", {
          value: formatBigNumber(other_trigger),
        });
        UnderTriggerTerm = t("Staking.Detail.Normal.ValueUnderTriggerTerm", {
          value: formatBigNumber(other_untrigger),
          tokenSymbol: token_symbol,
        });
        hard_cap = formatBigNumber(other_hardcap);
      } else {
        AccessType = t("Staking.ProjectType.Hybrid");
        TriggerTerm = t("Staking.Detail.Hybrid.ValueTriggerTerm", {
          valueWhiteList: formatBigNumber(whitelist_trigger),
          valueOthers: formatBigNumber(other_trigger),
        });
        UnderTriggerTerm = t("Staking.Detail.Hybrid.ValueUnderTriggerTerm", {
          valueWhiteList: formatBigNumber(whitelist_untrigger),
          valueOthers: formatBigNumber(other_untrigger),
          tokenSymbol: token_symbol,
        });
        hard_cap = formatBigNumber(
          new BigNumber(whitelist_hardcap).plus(other_hardcap).toString()
        );
      }
      return {
        id,
        token_name,
        token_symbol,
        token_icon,
        AccessType,
        TriggerTerm,
        UnderTriggerTerm,
        hard_cap,
        status,
        finish_time,
        start_time,
        announce_time,
        distribute_time,
        project_type,
      };
    });
  };

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth <= 1023) {
        setIsGridStaking(true);
        StorageUtils.setItem("isGridStaking", "true");
        setIsPhoneVer(true);
      } else {
        setIsPhoneVer(false);
      }
    };
    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    setMyProjectAirdrop({ isActiveStaking: false, registed: "" });
    setFilterAirdrop(plainOptions);
    setSearchAirdrop("");
    setPageAirdrop(1);
    return () => {
      // resetStateToolbar();
      window.removeEventListener("resize", checkWindowSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListStakingProject = async () => {
    try {
      const params = {
        page: pageStaking,
        limit: !isGridStaking || isPhoneVer ? 8 : 6,
        status: optionFilterStaking.join(","),
        name: valueSearchStaking,
        registed: registed,
      };
      const result = await UserServiceAPI.getListStakingProject(params);
      const data = _.get(result, "data.data.data", []);
      const total = _.get(result, "data.data.total", "0");
      const pageSize = _.get(result, "data.data.perPage", "0");

      statusArr.forEach((e) => {
        const lastEle = dataStaking.find((ele) => ele.id === e.id);
        if (lastEle && e.status !== lastEle.status) {
          listInterval.forEach((i: NodeJS.Timeout) => clearInterval(i));
        }
      });

      statusArr = data.map((ele: any) => {
        return { id: ele.id, status: ele.status };
      });
      const dataStakingAfterFormat = formatData(data);
      setDataStaking(dataStakingAfterFormat);

      setTotal(total);
      setPageSize(pageSize);
    } catch (error: any) {
      return message.info({
        content: <ErrorSystem />,
        icon: <></>,
        duration: ALERT_TIME,
      });
    }
  };
  useEffect(() => {
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      getListStakingProject();
    }, 200);
    return () => {
      if (time.current) clearTimeout(time.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGridStaking,
    optionFilterStaking,
    valueSearchStaking,
    pageStaking,
    registed,
  ]);

  const handleFilter = (valueFilter: string[]) => {
    setFilterStaking(valueFilter);
    setPageStaking(1);
  };

  const handleSearch = (text: string) => {
    setSearchStaking(text);
    setPageStaking(1);
  };

  const handleChangePage = (page: number) => {
    setPageStaking(page);
  };

  const handleFinishCountdown = (id: number, status: number) => {
    const item = dataStaking.find((ele) => ele.id === id);
    const interval = setInterval(() => {
      if (item && item.status === status) {
        count < 3 && getListStakingProject();
        count++;
      }
    }, 5000);
    listInterval.push(interval);
  };

  const handleShowLayoutTable = () => {
    StorageUtils.setItem("isGridStaking", "false");
    setIsGridStaking(false);
    if (isGridStaking) return;
    setPageStaking(1);
  };

  const handleShowLayoutCard = () => {
    StorageUtils.setItem("isGridStaking", "true");
    setIsGridStaking(true);
    if (isGridStaking) return;
    setPageStaking(1);
  };

  const handleChangeMyprojects = (
    isActiveStaking: boolean,
    registed: string
  ) => {
    setMyProjectStaking({ isActiveStaking, registed });
    setPageStaking(1);
  };

  return (
    <div>
      <ToolbarUser
        handleShowLayoutCard={handleShowLayoutCard}
        handleShowLayoutTable={handleShowLayoutTable}
        isShowLayoutTable={!isGridStaking}
        isShowLayoutCard={isGridStaking}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        handleChangeMyprojects={handleChangeMyprojects}
        isStake={true}
        isAirdrop={false}
      />
      {!isGridStaking && (
        <StakingTable
          handleChangePage={handleChangePage}
          total={total}
          stakingList={dataStaking}
          pageInit={pageStaking}
          onFinishCountDown={handleFinishCountdown}
        />
      )}
      {isGridStaking && (
        <StakingCard
          stakingList={dataStaking}
          pageInit={pageStaking}
          handleChangePage={handleChangePage}
          total={total}
          pageSize={pageSize}
          onFinishCountDown={handleFinishCountdown}
        />
      )}
    </div>
  );
};

export default Staking;
