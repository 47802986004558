import { Button, Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react';
import CopyIcon from '../../common/icon/CopyIcon'
import styles from "./index.module.scss";
import { CopyToClipboard } from 'react-copy-to-clipboard';
interface PropType {
    text: string | undefined,
    title: string,
}
export default function TooltipCustom({ text, title }: PropType) {
    const [copied, setCopied] = useState(false)
    const [copiedErr, setCopiedErr] = useState('')
    const setTimeOutRef = useRef<any>(null)

    const onCopy = (text: string, result: boolean) => {
        if(result){
            setCopied(true)
            clearTimeout(setTimeOutRef.current)
            setTimeOutRef.current = setTimeout(() => {
                setCopied(false)
            }, 1200);
        }
        else {
            setCopiedErr(text)
            setTimeOutRef.current = setTimeout(() => {
                setCopiedErr('')
            }, 1200);
            
        }
    };
    useEffect(() => {
        return () => clearTimeout(setTimeOutRef.current)
    }, [])
    return (
        <Tooltip title={copied ? 'Copied' : copiedErr || title} overlayInnerStyle={{ padding: "5px 10px" }}>
            <CopyToClipboard text={text || ''} onCopy={onCopy}>
                <Button type="link" className={styles.Icon}>
                    <CopyIcon />
                </Button>
            </CopyToClipboard>
        </Tooltip>
    )
}
