import { useTranslation } from "react-i18next";
import { Dropdown, Button, Input, Checkbox, Tabs } from "antd";
import styles from "./ToolbarUser.module.scss";
import "../styles/customAntd.scss";
import { useSelector } from "react-redux";
import {
  CHECK_ALL,
  IDO_STATUS,
  plainOptions,
  UN_CHECK_ALL,
} from "../constants";
import { useState } from "react";
import images from "themes/images";
// import IDOTab from './ido-tabs'
const { Search } = Input;
interface ToolbarUserProps {
  handleShowLayoutCard?: () => void;
  handleShowLayoutTable?: () => void;
  handleFilter?: (valueFilter: string[]) => void;
  handleSearch: (text: string) => void;
  handleChangeMyprojects: (active: boolean, registed: string) => void;
  handleChangeTab?: (key: number | string) => void;
  isShowLayoutTable?: boolean;
  isShowLayoutCard?: boolean;
  isStake?: boolean;
  isAirdrop?: boolean;
  isIDO?: boolean;
}

const ToolbarUser: React.FC<ToolbarUserProps> = ({
  handleShowLayoutCard = () => {},
  handleShowLayoutTable = () => {},
  handleFilter = () => {},
  handleSearch = () => {},
  handleChangeMyprojects = () => {},
  handleChangeTab = () => {},
  isShowLayoutTable,
  isShowLayoutCard,
  isStake = false,
  isAirdrop = false,
  isIDO = false,
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);
  const user = useSelector((state) => state.wallet.userInfo);
  const { isActiveStaking } = useSelector(
    (state) => state.toolbar.isActiveMyProjectStaking
  );
  const { isActiveAirdrop } = useSelector(
    (state) => state.toolbar.isActiveMyProjectAirdrop
  );
  const { isActiveIDO } = useSelector(
    (state) => state.toolbar.isActiveMyProjectIDO
  );
  const {
    optionFilterAirdrop,
    optionFilterStaking,
    optionFilterIDO,
    valueSearchAirdrop,
    valueSearchStaking,
    valueSearchIDO,
  } = useSelector((state) => state.toolbar);

  const RootState = useSelector((state) => state.wallet);
  const { isConnectMetaMark } = RootState;

  const onCheckAllChange = async (e: any) => {
    handleFilter(e.target.checked ? plainOptions : [UN_CHECK_ALL]);
  };

  const onChange = (list: any) => {
    handleFilter(list.length ? list : [UN_CHECK_ALL]);
  };

  const onchangeMyProjects = (e: any) => {
    if (e.target.checked) {
      handleChangeMyprojects(true, user.wallet_address);
    } else {
      handleChangeMyprojects(false, "");
    }
  };

  const handleCheckAll = () => {
    return (
      (optionFilterStaking.length === CHECK_ALL && isStake) ||
      (optionFilterAirdrop.length === CHECK_ALL && isAirdrop)
    );
  };

  const filterOptionStake = [
    { id: 1, label: t("ToolbarUser.Filter.AwaitingStaking") },
    { id: 2, label: t("ToolbarUser.Filter.Staking") },
    { id: 3, label: t("ToolbarUser.Filter.AwaitingResults") },
    { id: 4, label: t("ToolbarUser.Filter.AwaitingClaim") },
    { id: 5, label: t("ToolbarUser.Filter.Claiming") },
    { id: 6, label: t("ToolbarUser.Filter.Completed") },
  ];

  const filterOptionAirdrop = [
    { id: 1, label: t("ToolbarUser.Filter.AwaitingApplication") },
    { id: 2, label: t("ToolbarUser.Filter.Application") },
    { id: 3, label: t("ToolbarUser.Filter.AwaitingResults") },
    { id: 4, label: t("ToolbarUser.Filter.AwaitingClaim") },
    { id: 5, label: t("ToolbarUser.Filter.Claiming") },
    { id: 6, label: t("ToolbarUser.Filter.Completed") },
  ];

  const filterOptionIDO: any[] = [
    { id: IDO_STATUS.UPCOMING, label: t("ToolbarUser.Filter.Upcoming") },
    {
      id: `${IDO_STATUS.DEPOSIT},${IDO_STATUS.PUBLIC_SELL},${IDO_STATUS.CLAIMABLE}`,
      label: t("ToolbarUser.Filter.InProgress"),
    },
    { id: `${IDO_STATUS.ENDED}`, label: t("ToolbarUser.Filter.Ended") },
    {
      id: Object.values(IDO_STATUS).join(","),
      label: t("ToolbarUser.Filter.All"),
    },
  ];

  const handleOnChange = (e: any) => {
    if (e.target.current) {
      clearTimeout(e.target.current);
    }
    e.target.current = setTimeout(() => {
      handleSearch(e.target.value.trim());
    }, 500);
  };

  const renderMenu = () => {
    return (
      <div
        className={styles.WrapperOption}
        data-theme={isDarkMode ? "dark" : "light"}
      >
        {!!user && isConnectMetaMark && (
          <Checkbox
            className={styles.WrapperOption__MyProject}
            onChange={onchangeMyProjects}
            checked={isStake ? isActiveStaking : isActiveAirdrop}
          >
            {t("MyProjects")}
          </Checkbox>
        )}
        <Checkbox
          className={styles.WrapperOption__All}
          value={""}
          onChange={onCheckAllChange}
          checked={handleCheckAll()}
        >
          {t("ToolbarUser.Filter.All")}
        </Checkbox>
        <Checkbox.Group
          className={styles.WrapperOption__Group}
          value={isStake ? optionFilterStaking : optionFilterAirdrop}
          onChange={onChange}
        >
          {isStake &&
            filterOptionStake.map((status) => (
              <Checkbox
                key={status.id}
                className={styles.WrapperOption__Group__Check}
                value={status.id}
              >
                {status.label}
              </Checkbox>
            ))}

          {isAirdrop &&
            filterOptionAirdrop.map((status) => (
              <Checkbox
                key={status.id}
                className={styles.WrapperOption__Group__Check}
                value={status.id}
              >
                {status.label}
              </Checkbox>
            ))}
        </Checkbox.Group>
      </div>
    );
  };

  return (
    <>
      <div className={isIDO ? styles.ToolbarUserIDO : styles.ToolbarUser}>
        {isIDO && (
          <div className="tabs-mgs-0">
            <Tabs
              defaultActiveKey={`${optionFilterIDO?.[0] || 1}`}
              onChange={(value) => onChange([value])}
            >
              {filterOptionIDO.map((status) => {
                return <Tabs.TabPane tab={status.label} key={`${status.id}`} />;
              })}
            </Tabs>
          </div>
        )}
        <div
          className={
            isIDO ? styles.ToolbarUserIDOLeft : styles.ToolbarUserAridrop
          }
        >
          <div className={styles.ToolbarUser__ToolbarLeft}>
            {isIDO ? null : (
              <Dropdown
                trigger={["click"]}
                // onVisibleChange={(visible) => !visible && handleFilter(filter)}
                overlay={renderMenu}
                onVisibleChange={setVisible}
                visible={visible}
              >
                <Button className={styles.ToolbarUser__Dropdown__Button}>
                  <>
                    <div
                      className={
                        styles.ToolbarUser__Dropdown__Button__FilterIcon
                      }
                    />
                    <span
                      className={
                        styles.ToolbarUser__Dropdown__Button__FilterText
                      }
                    >
                      {t("ToolbarUser.Filter")}
                    </span>
                  </>
                  <img
                    src={
                      isDarkMode
                        ? images.icons.IconDropDownFilterDark
                        : images.icons.IconDropDownFilterLight
                    }
                    alt="Icon"
                    className={styles.IconSelect}
                  />
                </Button>
              </Dropdown>
            )}
            {!!user && isConnectMetaMark && (
              <Checkbox
                className={
                  isIDO
                    ? styles.ToolbarUser__MyProjectIDO
                    : styles.ToolbarUser__MyProject
                }
                onChange={onchangeMyProjects}
                checked={
                  isIDO
                    ? isActiveIDO
                    : isStake
                    ? isActiveStaking
                    : isActiveAirdrop
                }
              >
                {t("MyProjects")}
              </Checkbox>
            )}
          </div>
          <div className={styles.ToolbarUser__Wrapper}>
            <Search
              className={`${styles.ToolbarUser__Wrapper__SearchInput} toolBarSearchInput`}
              placeholder={t("ToolbarUser.SearchAsset")}
              allowClear
              onChange={handleOnChange}
              onSearch={() => {}}
              defaultValue={
                isIDO
                  ? valueSearchIDO
                  : isStake
                  ? valueSearchStaking
                  : valueSearchAirdrop
              }
            />

            {isIDO ? null : (
              <>
                <div
                  onClick={handleShowLayoutTable}
                  className={`${styles.ToolbarUser__Wrapper__MenuTable} ${
                    isShowLayoutTable && styles.ActiveLayout
                  }`}
                />
                <div
                  onClick={handleShowLayoutCard}
                  className={`${styles.ToolbarUser__Wrapper__MenuCard} ${
                    isShowLayoutCard && styles.ActiveLayout
                  }`}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolbarUser;
