import { createSlice, PayloadAction, Store } from "@reduxjs/toolkit";
import StorageUtils, { STORAGE_KEYS } from "../../utils/storage";

let customStore: Store | undefined;

export const setStoreWallet = (store: Store) => {
  customStore = store;
};

interface DarkModeType {
  isDarkMode: boolean;
}
export interface UserInfoType {
  email: string | null;
  firstname: string | null;
  id: number;
  kyc_country: string | null;
  kyc_status: number;
  lastname: string | null;
  role: number;
  session_id: string;
  status: number;
  type: number;
  username: string | null;
  wallet_address: string;
  verify_amount: string;
  oasis_address: string;
  oasis_status: number;
}

const initialState = {
  isDarkMode: StorageUtils.getItem(STORAGE_KEYS.DARKMODE, "false"),
  isConnectMetaMark: false,
  isConnectNetwork: false,
  userInfo: StorageUtils.getUser() || " ",
  balance: 0,
  chainId: "",
  account: "null",
  delegateStakingAmount: "",
  amountTokenGRDN: "",
  balanceUSDC: "0",
  balanceUSDT: "0",
};

const storeWallet = createSlice({
  name: "storeWallet",
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<any>) => ({
      ...state,
      isDarkMode: action.payload.isDarkMode,
    }),
    setConnectMetaMark: (state, action: PayloadAction<any>) => ({
      ...state,
      isConnectMetaMark: action.payload,
    }),
    setConnectNetwork: (state, action: PayloadAction<any>) => ({
      ...state,
      isConnectNetwork: action.payload,
    }),
    setUserInfo: (state, action: PayloadAction<any>) => ({
      ...state,
      userInfo: action.payload,
    }),
    setBalance: (state, action: PayloadAction<any>) => ({
      ...state,
      balance: action.payload,
    }),
    setChainId: (state, action: PayloadAction<any>) => ({
      ...state,
      chainId: action.payload,
    }),
    setChangeAccount: (state, action: PayloadAction<any>) => ({
      ...state,
      account: action.payload,
    }),
    setDelegateStakingAmount: (state, action: PayloadAction<any>) => ({
      ...state,
      delegateStakingAmount: action.payload,
    }),
    setAmountTokenGRDN: (state, action: PayloadAction<any>) => ({
      ...state,
      amountTokenGRDN: action.payload,
    }),
    setGetBalanceUSDC: (state, action: PayloadAction<any>) => ({
      ...state,
      balanceUSDC: action.payload,
    }),
    setGetBalanceUSDT: (state, action: PayloadAction<any>) => ({
      ...state,
      balanceUSDT: action.payload,
    }),
  
  },
});
export const setDarkMode = (isDarkMode: DarkModeType) => {
  customStore &&
    customStore.dispatch(storeWallet.actions.setDarkMode(isDarkMode));
};

export const setConnectMetaMark = (isConnectMetaMark: boolean) => {
  customStore &&
    customStore.dispatch(
      storeWallet.actions.setConnectMetaMark(isConnectMetaMark),
    );
};

export const setConnectNetwork = (isConnectNetwork: boolean) => {
  customStore &&
    customStore.dispatch(
      storeWallet.actions.setConnectNetwork(isConnectNetwork),
    );
};

export const setUserInfo = (userInfo: any) => {
  customStore &&
    customStore.dispatch(storeWallet.actions.setUserInfo(userInfo));
};

export const setBalance = (balance: number) => {
  customStore && customStore.dispatch(storeWallet.actions.setBalance(balance));
};

export const setChainId = (chainId: string) => {
  customStore && customStore.dispatch(storeWallet.actions.setChainId(chainId));
};

export const setChangeAccount = (newAccount: string) => {
  customStore &&
    customStore.dispatch(storeWallet.actions.setChangeAccount(newAccount));
};

export const setTotalDelegateStakingAmount = (delegateAmount: string) => {
  customStore &&
    customStore.dispatch(
      storeWallet.actions.setDelegateStakingAmount(delegateAmount),
    );
};
export const setAmountTokenGRDN = (amountTokenGRDN: string) => {
  customStore &&
    customStore.dispatch(
      storeWallet.actions.setAmountTokenGRDN(amountTokenGRDN),
    );
};

export const setGetBalanceUSDC = (balance: string) => {
  customStore &&
    customStore.dispatch(storeWallet.actions.setGetBalanceUSDC(balance));
};
export const setGetBalanceUSDT = (balance: string) => {
  customStore &&
    customStore.dispatch(storeWallet.actions.setGetBalanceUSDT(balance));
};

export { storeWallet };
