import { Grid, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TooltipCustom from '../../../../../components/Tooltip';
import { UserInfoType } from '../../../../../stores/wallet';
import styles from './MyWallet.module.scss'
interface ProjectFormLayoutProps {
    title: string;
}
const SUFFIX_COUNT = 12;

const MyWallet: React.FC<ProjectFormLayoutProps> = ({ title }) => {
    const [myWallet, setMyWallet] = useState('')
    const userInfo: UserInfoType = useSelector(({ wallet }) => wallet.userInfo);
    const start = myWallet?.slice(0, SUFFIX_COUNT).trim();
    const suffix = myWallet?.slice(-SUFFIX_COUNT).trim();

    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    useEffect(() => {
        if (userInfo) {
            setMyWallet(userInfo.wallet_address)
        }
    }, [userInfo])
    const renderButtonInput = () => (
        <div className={styles.ButtonInput} >
            <TooltipCustom text={myWallet} title={'Copy address'} />
        </div>
    );
    return (
        <div className={styles.MyWallet}>
            <div className={styles.MyWallet__title}>{title}</div>
            <div className={styles.MyWallet__divider}></div>
            <div className={styles.MyWallet__walletInfo}>
                <Input
                    placeholder="Wallet Address"
                    name="wallet_address"
                    suffix={renderButtonInput()}
                    disabled
                    value={screens.xs ? myWallet ? `${start}...${suffix}` : undefined : myWallet}
                    className={styles.MyWallet__walletInfo__input}
                />
            </div>

        </div>
    )
}
export default MyWallet