/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from "react";
import ToolbarUser from "../../../common/toolbarUser";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import BigNumber from "bignumber.js";
import AirdropCard from "./airdropCard/AirdropCard";
import { AirdropType } from "../../../common/types/AirdropType";
import { UserService } from "../../../services/UserServicer";
import {
  AIRDROP_CLAIM_TYPE,
  ALERT_TIME,
  DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC,
  DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC,
  plainOptions,
  PROJECT_AIRDROP_TYPE,
} from "../../../common/constants";
import {
  formatBigNumber,
  formatNumber,
  formatUnixTimeToDate,
  resetStateToolbar,
  toRealNumber,
} from "../../../common/functions";
import { message } from "antd";
import AirdropTable from "./airdropTable";
import { useSelector } from "react-redux";
import {
  setFilterAirdrop,
  setFilterStaking,
  setMyProjectAirdrop,
  setMyProjectStaking,
  setPageAirdrop,
  setPageStaking,
  setSearchAirdrop,
  setSearchStaking,
} from "../../../stores/toolbar";
import { StatusArr } from "../staking";
import ErrorSystem from "../../../common/alert/errorSystem";
import StorageUtils from "../../../utils/storage";

const Airdrop = () => {
  const [dataAirdrop, setDataAirdrop] = useState<any>([]);
  const [total, setTotal] = useState<string>("");
  const UserServiceAPI = new UserService();
  const [isPhoneVer, setPhoneVer] = useState<boolean>(
    window.innerWidth <= 1023
  );
  const [pageSize, setPageSize] = useState<string>("");
  const time = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isGridAirDrop, setIsGridAirDrop] = useState<boolean>(
    StorageUtils.getItem("isGridAirDrop", "false") === "true"
  );
  const { registed } = useSelector(
    (state) => state.toolbar.isActiveMyProjectAirdrop
  );
  const { optionFilterAirdrop, valueSearchAirdrop, pageAirdrop } = useSelector(
    (state) => state.toolbar
  );
  let statusArr: StatusArr[] = [{ id: 0, status: 0 }];
  let listInterval: NodeJS.Timeout[] = [];
  let count = 0;

  const { t } = useTranslation();

  const formatData = (data: any) => {
    return data.map((project: AirdropType) => {
      const {
        id,
        token_name,
        token_symbol,
        token_icon,
        project_type,
        status,
        total_raise_amount,
        token_conversion_rate,
        distribution_method,
        snapshot_time,
        finish_time,
        announce_time,
        distribute_time,
        start_time,
        fee,
      } = project;
      let accessType = "";
      let snapShotTime = "";
      let claimMethod = "";
      let totalAmount = "";
      let tokenRate = "";
      let ignoreStatus = 3;

      if (project_type === PROJECT_AIRDROP_TYPE.PRIVATE) {
        accessType = t("Airdrop.ProjectType.Private");
        ignoreStatus =
          DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.AWAITING_RESULTS;
      } else {
        /* Public Type */
        accessType = t("Airdrop.ProjectType.Public");
        ignoreStatus =
          DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_RESULTS;
      }

      switch (distribution_method) {
        case AIRDROP_CLAIM_TYPE.LINEAR:
          claimMethod = t("Airdrop.ClaimMethod.Linear");
          tokenRate = t("Airdrop.Linear.Rate", {
            tokenSymbol: token_symbol,
            tokenRate: formatBigNumber(token_conversion_rate),
          });
          ignoreStatus =
            DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_RESULTS;
          break;
        case AIRDROP_CLAIM_TYPE.QUADRATIC:
          claimMethod = t("Airdrop.ClaimMethod.Quadratic");
          tokenRate = t("Airdrop.Quadratic.Rate", {
            tokenSymbol: token_symbol,
          });
          ignoreStatus =
            DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_RESULTS;
          break;
        /* Fixed Amount Claim type*/
        default:
          claimMethod = t("Airdrop.ClaimMethod.FixedAmount");
          tokenRate = t("Airdrop.FixedAmount.Rate", {
            tokenRate: formatBigNumber(token_conversion_rate),
            tokenSymbol: token_symbol,
          });
      }

      snapShotTime = snapshot_time
        ? formatUnixTimeToDate(parseInt(snapshot_time))
        : t("None");
      totalAmount =
        total_raise_amount && Number(status) !== ignoreStatus
          ? `${formatBigNumber(
              toRealNumber(total_raise_amount)
            )} ${token_symbol}`
          : t("Airdrop.Tba");
      return {
        id,
        token_name,
        token_symbol,
        token_icon,
        status,
        totalAmount,
        tokenRate,
        accessType,
        snapShotTime,
        claimMethod,
        snapshot_time,
        finish_time,
        announce_time,
        distribute_time,
        start_time,
        distributionMethod: distribution_method,
      };
    });
  };

  const handleFilter = (valueFilter: string[]) => {
    setFilterAirdrop(valueFilter);
    setPageAirdrop(1);
  };

  const handleSearch = (text: string) => {
    setSearchAirdrop(text);
    setPageAirdrop(1);
  };

  const handleChangePage = (page: number) => {
    setPageAirdrop(page);
  };

  const handleShowLayoutTable = () => {
    StorageUtils.setItem("isGridAirDrop", "false");
    setIsGridAirDrop(false);
    if (isGridAirDrop) return;
    setPageAirdrop(1);
  };

  const handleChangeMyprojects = (
    isActiveAirdrop: boolean,
    registed: string
  ) => {
    setMyProjectAirdrop({ isActiveAirdrop, registed });
    setPageAirdrop(1);
  };

  const handleShowLayoutCard = () => {
    StorageUtils.setItem("isGridAirDrop", "true");
    setIsGridAirDrop(true);
    if (isGridAirDrop) return;
    setPageAirdrop(1);
  };
  const getListStakingProject = async () => {
    try {
      const params = {
        page: pageAirdrop,
        limit: !isGridAirDrop || isPhoneVer ? 8 : 6,
        status: optionFilterAirdrop.join(","),
        name: valueSearchAirdrop,
        registed: registed,
        grid: isGridAirDrop ? 1 : "",
      };
      const result = await UserServiceAPI.getListAirdropProject(params);
      const dataAirdropList = _.get(result, "data.data.data", []);
      const total = _.get(result, "data.data.total", 0);
      const pageSize = _.get(result, "data.data.perPage", 0);

      statusArr.forEach((e) => {
        const lastEle = dataAirdrop.find((ele: any) => ele.id === e.id);
        if (lastEle && e.status !== lastEle.status) {
          listInterval.forEach((i: NodeJS.Timeout) => clearInterval(i));
        }
      });

      statusArr = dataAirdropList.map((ele: any) => {
        return { id: ele.id, status: ele.status };
      });

      const dataAirdropAfterFormat = await formatData(dataAirdropList);
      setDataAirdrop(dataAirdropAfterFormat);
      setTotal(total);
      setPageSize(pageSize);
    } catch (error: any) {
      return message.info({
        content: <ErrorSystem />,
        icon: <></>,
        duration: ALERT_TIME,
      });
    }
  };

  useEffect(() => {
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      getListStakingProject();
    }, 200);
    return () => {
      if (time.current) clearTimeout(time.current);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGridAirDrop,
    optionFilterAirdrop,
    valueSearchAirdrop,
    pageAirdrop,
    registed,
  ]);

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth <= 1023) {
        setIsGridAirDrop(true);
        StorageUtils.setItem("isGridAirDrop", "true");
        setPhoneVer(true);
      } else {
        setPhoneVer(false);
      }
    };
    window.addEventListener("resize", checkWindowSize);
    checkWindowSize();
    setMyProjectStaking({ isActiveStaking: false, registed: "" });
    setFilterStaking(plainOptions);
    setSearchStaking("");
    setPageStaking(1);
    return () => {
      // resetStateToolbar();
      window.removeEventListener("resize", checkWindowSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinishCountdown = (id: number, status: number) => {
    const item = dataAirdrop.find((ele: any) => ele.id === id);
    const interval = setInterval(() => {
      if (item && item.status === status) {
        count < 3 && getListStakingProject();
        count++;
      }
    }, 5000);
    listInterval.push(interval);
  };

  return (
    <div>
      <ToolbarUser
        handleShowLayoutCard={handleShowLayoutCard}
        handleShowLayoutTable={handleShowLayoutTable}
        isShowLayoutTable={!isGridAirDrop}
        isShowLayoutCard={isGridAirDrop}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        handleChangeMyprojects={handleChangeMyprojects}
        isAirdrop={true}
        isStake={false}
      />
      {!isGridAirDrop && (
        <AirdropTable
          handleChangePage={handleChangePage}
          total={total}
          airdropList={dataAirdrop}
          pageInit={pageAirdrop}
          onFinishCountDown={handleFinishCountdown}
        />
      )}
      {isGridAirDrop && (
        <AirdropCard
          data={dataAirdrop}
          handleChangePage={handleChangePage}
          total={total}
          pageInit={pageAirdrop}
          onFinishCountDown={handleFinishCountdown}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

export default Airdrop;
