import React, { createContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ACCEPTED_CURRENCIES_ICONS,
  ACCEPTED_CURRENCIES_NAME,
  DETAIL_IDO_STATUS,
  DETAIL_PROJECT_IDO_STATUS,
  IDO_POOL_TYPES_NAME,
  IDO_STATUS,
  ROUTES,
} from "../../../../../common/constants";
import StatusItem, {
  Dictionary,
} from "../../../../../common/projectDetailItem/StatusItem";
import { IDOType } from "../../../../../common/types/IDOType";
import images from "../../../../../themes/images";
import styles from "../index.module.scss";

export const StakeContext = createContext({});

interface PropsType {
  pool: Partial<IDOType>;
}

const IDODetailHeader: React.FC<PropsType> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pool } = props;

  const statusDisablePS = DETAIL_IDO_STATUS.filter(
    (el) =>
      pool.start_time_public_sell ||
      (!pool.start_time_public_sell &&
        el.status !== DETAIL_PROJECT_IDO_STATUS.PUBLICSELL)
  );
  const statussEnablePS = DETAIL_IDO_STATUS;

  const handleOpenWebSite = (url: string) => {
    if (url && url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open(`https://${url}`, "_blank");
    }
  };

  let countdownStatus = 0;
  const now = Math.floor(Date.now() / 1000);
  let claim_time = Number(pool.claim_time);

  if (!claim_time) claim_time = Number(pool.claim_configs?.[0]?.start_time);
  if (Number(pool.start_time_deposit) > now) {
    countdownStatus = IDO_STATUS.UPCOMING;
  } else if (
    Number(pool.start_time_public_sell) > now ||
    !pool.claim_time ||
    (!pool.start_time_public_sell && Number(pool.claim_time) > now)
  ) {
    if (pool.end_time_public_sell) {
      countdownStatus = IDO_STATUS.PUBLIC_SELL;
    } else {
      countdownStatus = IDO_STATUS.DEPOSIT;
    }
  } else if (Number(pool.claim_time) > now) {
    countdownStatus = IDO_STATUS.PUBLIC_SELL;
  } else {
    countdownStatus = IDO_STATUS.CLAIMABLE;
  }
  if (pool.status === IDO_STATUS.ENDED) {
    countdownStatus = IDO_STATUS.ENDED;
  }

  const mapStatus =
    pool.start_time_public_sell && pool.start_time_public_sell
      ? statussEnablePS
      : statusDisablePS;

  return (
    <div className={styles.Header}>
      <div className={styles.Information}>
        <div
          className={styles.Back}
          onClick={() => history.push(ROUTES.IDOPOOL)}
        >
          <img src={images.BackIcon} alt="Back" />
          <span>Back</span>
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.Token}>
            <img src={pool?.token_icon} alt="" />
            <h4>
              {t("Staking.Detail.Asset", {
                tokenName: pool.pool_name,
                tokenSymbol: pool.token_symbol,
              })}
            </h4>
          </div>
          <div className={styles.ListIcon}>
            {pool.website && (
              <span onClick={() => handleOpenWebSite(pool.website || "")}>
                <img src={images.icons.Language} alt="Language" />
              </span>
            )}
            {pool.whitepaper_link && (
              <span
                onClick={() => handleOpenWebSite(pool.whitepaper_link || "")}
              >
                <img src={images.icons.Book} alt="Book" />
              </span>
            )}
            {pool.telegram_link && (
              <span onClick={() => handleOpenWebSite(pool.telegram_link || "")}>
                <img src={images.icons.Telegram} alt="Telegram" />
              </span>
            )}
            {pool.medium_link && (
              <span onClick={() => handleOpenWebSite(pool.medium_link || "")}>
                <img src={images.icons.BlogMe} alt="Blog Me" />
              </span>
            )}
            {pool.twitter_link && (
              <span onClick={() => handleOpenWebSite(pool.twitter_link || "")}>
                <img src={images.icons.TwitterIcon} alt="Twitter Icon" />
              </span>
            )}
          </div>
        </div>
        <div>
          <div className={styles.Chip}>
            {pool.accepted_currency ? (
              <span className={styles.Rose}>
                <img
                  className={styles.RoseToken}
                  src={ACCEPTED_CURRENCIES_ICONS[pool.accepted_currency]}
                  alt="ROSE"
                />
                <span>{ACCEPTED_CURRENCIES_NAME[pool.accepted_currency]}</span>
              </span>
            ) : (
              ""
            )}
            {pool.pool_label ? (
              <span className={styles.Community}>
                {IDO_POOL_TYPES_NAME[pool.pool_label]}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={styles.ListStatus}>
        {mapStatus.map((statusItem: Dictionary<any>, index: number) => (
          <React.Fragment key={index}>
            <StatusItem
              statusItem={statusItem}
              currentStatus={countdownStatus}
              index={index + 1}
            />
            {statusItem.status < DETAIL_PROJECT_IDO_STATUS.ENDED && (
              <img
                className={styles.DashedLine}
                src={images.icons.DashedLine}
                alt="Dashed Line"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default IDODetailHeader;
