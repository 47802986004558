import { ArrowRightOutlined, CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { STATUS_KYC_USER } from '../../../../../common/constants';
import SynapsKYC from '../../../../../components/SynapsKYC';
import { UserInfoType } from '../../../../../stores/wallet';
import styles from './StatusKYC.module.scss'

interface ProjectFormLayoutProps {
    title: string;
}
interface StatusButtonType {
    background: string,
    text: string,
    icon: React.ReactNode
}

const StatusKYC: React.FC<ProjectFormLayoutProps> = ({ title }) => {
    const [visible, setVisible] = useState(false);
    const userInfo: UserInfoType = useSelector(({ wallet }) => wallet.userInfo);

    const handleOpen = () => setVisible(true);

    let status: StatusButtonType = { background: '#F22222', text: 'Unverified', icon: <ExclamationCircleOutlined /> };
    let buttonKYC: React.ReactNode = <Button className={styles.btnKyc} onClick={handleOpen}>KYC Now <span style={{ marginLeft: '10px' }}><ArrowRightOutlined /></span></Button>;;
    switch (userInfo?.kyc_status) {
        case STATUS_KYC_USER.PENDING:
            status = { background: '#FFD166', text: 'Pending', icon: <ClockCircleOutlined /> };
            buttonKYC = <Button className={styles.btnKyc} onClick={handleOpen}>KYC process<ArrowRightOutlined /></Button>;
            break;
        case STATUS_KYC_USER.VERIFIED:
            status = { background: '#58BD7D', text: 'Verified ', icon: <CheckCircleOutlined /> };
            buttonKYC = null;
            break;
        case STATUS_KYC_USER.REJECTED:
            status = { background: '#F22222', text: 'Rejected ', icon: <ExclamationCircleOutlined /> };
            buttonKYC = <Button className={styles.btnKyc} onClick={handleOpen}>Re-KYC<span style={{ marginLeft: '10px' }}><ArrowRightOutlined /></span></Button>
            break;
        case STATUS_KYC_USER.CANCELLED:
            status = { background: '#FF6838', text: 'Cancelled', icon: <CloseCircleOutlined /> };
            buttonKYC = null;
            break;
        default:
            break;
    }
    return (
        <div className={styles.StatusKYC}>
            <div className={styles.StatusKYC__title}>{title}</div>
            <div className={styles.StatusKYC__divider}></div>
            <div className={styles.StatusKYC__button}>
                <Button style={{ backgroundColor: status.background }} className={styles.btnCustom} disabled>
                    <span>{status.icon}</span>{status.text}
                </Button>
                {buttonKYC}
            </div>
            <SynapsKYC
                visible={visible}
                onClose={() => setVisible(false)}
            />
        </div>
    )
}
export default StatusKYC