import { AxiosResponse } from "axios";
import { HttpClient } from "./http-service";

interface Dict<T> {
  [key: string]: T;
  [key: number]: T;
}

export interface ChangeListener {
  (event: any): any;
}

export class ServiceBase extends HttpClient {
  private onChangeListeners: Dict<ChangeListener> = {};

  get = async (url: string, params?: any): Promise<AxiosResponse> => {
    return await this.axiosInstance.get(url, { params });
  };

  put = async (url: string, data: any): Promise<AxiosResponse> => {
    return await this.axiosInstance.put(url, data);
  };

  post = async (url: string, params: any): Promise<AxiosResponse> => {
    return await this.axiosInstance.post(url, params);
  };

  subscribe(key: string, listener: ChangeListener) {
    if (this.onChangeListeners[key]) return;
    this.onChangeListeners[key] = listener;
  }

  unsubcribe(key: string) {
    delete this.onChangeListeners[key];
  }

  fire(data: any) {
    Object.values(this.onChangeListeners).forEach((listener) => listener(data));
  }
}
