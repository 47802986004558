import { Row, Col } from 'antd';
import images from '../../themes/images';
import styles from './NotRecordFound.module.scss'
export default function NotRecordFound() {

    return(
        <div className={styles.Container}>
            <Row>
                <Col span={24}>
                    <img className={styles.Container__Img} src={images.icons.IconNotRecordFound} alt='Not record found' />
                </Col>
            </Row>
        </div>
    )
}
