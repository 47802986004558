import images from "../../../themes/images";
import { URL_TRANSACTION } from "../../functions";
import "./transaction.scss";

interface TransactionErrorProps {
  hash: string;
}

const TransactionError: React.FC<TransactionErrorProps> = ({ hash }) => {
  return (
    <span className="Transaction">
      <img src={images.icons.TransactionError} alt="Transaction Error"></img>
      Transaction Error
      <a href={URL_TRANSACTION(hash)} target="_blank" rel="noreferrer">
        <img src={images.icons.ExternalLink} alt="external-link" />
        <span>View on Etherscan</span>
      </a>
    </span>
  );
};

export default TransactionError;
