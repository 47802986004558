import images from "../../../themes/images";
import styles from "./launchpad.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import { NavLink } from "react-router-dom";
import { ROUTES } from "common/constants";

interface LaunchpadProps {}

const Launchpad: React.FC<LaunchpadProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Launchpad}>
      <Row gutter={[50, 22]} align="middle">
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          className={styles.Launchpad__ColBox}
        >
          <div className={styles.Launchpad__Box}>
            <div className={styles.Launchpad__Head}>
              <img src={images.ladingPage.IconStaking} alt="" />

              <h2 className={styles.Launchpad__title}>
                {t("Launchpad.Title1")}
              </h2>
            </div>
            <div
              className={styles.Launchpad__title_des}
              dangerouslySetInnerHTML={{ __html: t("Launchpad.TitleDes1") }}
            ></div>
            <div className={styles.Launchpad__ListBtn}>
              <Button className={styles.Launchpad__Btn1}>
                <a
                  href={"https://forms.gle/2euvPNYYg5HyYSg2A"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Launchpad.Btn1")}
                </a>
              </Button>
              <Button className={styles.Launchpad__Btn2}>
                <NavLink to={ROUTES.IDOPOOL}>{t("Launchpad.Btn2")}</NavLink>
              </Button>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          className={styles.Launchpad__ColBox}
        >
          <div className={styles.Launchpad__Box}>
            <div className={styles.Launchpad__Head}>
              <img src={images.ladingPage.IconLaunchpad} alt="" />

              <h2 className={styles.Launchpad__title}>
                {t("Launchpad.Title2")}
              </h2>
            </div>
            <div
              className={styles.Launchpad__title_des}
              dangerouslySetInnerHTML={{ __html: t("Launchpad.TitleDes2") }}
            ></div>
            <div className={styles.Launchpad__ListBtn}>
              <Button className={styles.Launchpad__Btn3}>
                {t("Launchpad.Btn3")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Launchpad;
