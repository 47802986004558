/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import BigNumber from "bignumber.js";
import {
  ABI_ALLOCATION,
  DECIMAL,
  DECIMAL_6,
  DECIMAL_ACCEPTED_CURRENCY,
} from "common/constants";
import { checkCurrency, toRealNumber } from "common/functions";
import { IDOType } from "common/types/IDOType";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserService } from "services/UserServicer";
import { web3 } from "services/Web3";
import {
  setAllowance,
  setBalanceMain,
  setTotalPS,
  setUserAmount,
  setUserPSAmount,
} from "stores/ido";
import { setGetBalanceUSDC, setGetBalanceUSDT } from "stores/wallet";
import StorageUtils from "utils/storage";
import Web3 from "web3";
import Claimable from "../claimable";
import AmoutCard from "./amounToJoin";
import DepositProgress from "./depositProgress";
import ListType from "./List";
import PoolInfoAndParticipants from "./TabInforAndParticipants";

const ERC20_ABI = require("../../../../../../abi/ERC20.json");
interface PropsType {
  pool: Partial<IDOType>;
  onFinish?: () => void;
  onDepositSuccess: (value: string) => void;
}

const DepositAmount = (props: PropsType) => {
  const { pool, onFinish, onDepositSuccess } = props;
  const { ethereum } = window as any;
  const WEB3 = new Web3(ethereum);
  const { userInfo } = useSelector((state) => state.wallet);
  const { claim } = useSelector((state) => state.ido);
  const wallet_address = useSelector(
    (state) => state?.wallet?.userInfo?.wallet_address
  );
  const dispatch = useDispatch();
  const wallet = Web3.utils.isAddress(userInfo?.wallet_address)
    ? userInfo?.wallet_address
    : " ";
  const RootState = useSelector((state) => state.wallet);
  const { isConnectMetaMark } = RootState;

  const userService = new UserService();
  const { id } = useParams<any>();
  const token = StorageUtils.getToken();

  const [total, setTotal] = useState("");
  const [yourReward, setYourReward] = useState("");

  const getBalanceUSDT = async () => {
    try {
      const contract = new WEB3.eth.Contract(
        ERC20_ABI,
        process.env.REACT_APP_TOKEN_USDT
      );
      const balance = await contract.methods
        .balanceOf(wallet)
        .call({ from: wallet });
      const balanceFromWei = new BigNumber(balance)
        .div(10 ** DECIMAL_6)
        .toString();
      setGetBalanceUSDT(balanceFromWei);
    } catch (error) {
      console.log({ error });
    }
  };
  const getBalanceUSDC = async () => {
    try {
      const contract = new WEB3.eth.Contract(
        ERC20_ABI,
        process.env.REACT_APP_TOKEN_USDC
      );
      const balance = await contract.methods
        .balanceOf(wallet)
        .call({ from: wallet });
      const balanceFromWei = new BigNumber(balance)
        .div(10 ** DECIMAL_6)
        .toString();
      setGetBalanceUSDC(balanceFromWei);
    } catch (error) {
      console.log({ error });
    }
  };
  const handleCheckAllowance = async () => {
    try {
      if (pool?.accepted_currency !== 1) {
        const contract = new WEB3.eth.Contract(
          ERC20_ABI,
          pool?.accepted_currency && checkCurrency(pool?.accepted_currency)
        );
        const allowance = await contract.methods
          .allowance(wallet, pool?.pool_address)
          .call({
            from: wallet,
          });
        dispatch(setAllowance(allowance !== "0"));
      }
    } catch (error) {
      console.log({ error });
      dispatch(setAllowance(false));
    }
  };
  const getBalance = async () => {
    try {
      await web3?.eth.getBalance(wallet).then((balance: any) => {
        const fromWei = new BigNumber(balance).div(10 ** DECIMAL).toString();
        dispatch(setBalanceMain(fromWei || "0"));
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const getAmountsUser = async () => {
    try {
      const contract = new WEB3.eth.Contract(
        ABI_ALLOCATION,
        pool?.pool_address
      );
      const amount = await contract.methods
        .userAmounts(wallet)
        .call({ from: wallet });

      let amountWei = new BigNumber(amount)
        .div(10 ** DECIMAL_ACCEPTED_CURRENCY[pool?.accepted_currency || 1])
        .toString();

      dispatch(setUserAmount(amountWei));
    } catch (error) {
      console.log({ error });
    }
  };

  const getAmountsPSUser = async () => {
    try {
      const contract = new WEB3.eth.Contract(
        ABI_ALLOCATION,
        pool?.pool_address
      );
      const amount = await contract.methods
        .userPSAmount(wallet)
        .call({ from: wallet });
      let amountWei = new BigNumber(amount)
        .div(10 ** DECIMAL_ACCEPTED_CURRENCY[pool?.accepted_currency || 1])
        .toString();

      dispatch(setUserPSAmount(amountWei));
    } catch (error) {
      console.log({ error });
    }
  };

  const getTotalPublicSell = async () => {
    try {
      const contract = new WEB3.eth.Contract(
        ABI_ALLOCATION,
        pool?.pool_address
      );
      const total = await contract.methods
        .publicSellTotal()
        .call({ from: wallet });
      const totalFromWei = new BigNumber(total).div(10 ** DECIMAL).toString();
      dispatch(setTotalPS(totalFromWei));
    } catch (error) {
      console.log({ error });
    }
  };
  const getAllBalance = () => {
    getBalanceUSDT();
    getBalanceUSDC();
    getBalance();
  };
  useEffect(() => {
    if (wallet && pool?.pool_address) {
      getAmountsPSUser();
      getAmountsUser();
      handleCheckAllowance();
      getAllBalance();
      getTotalPublicSell();
    }
  }, [wallet, pool?.pool_address]);

  // const yourReward = useMemo(() => {
  //   return new BigNumber(userPSAmount).plus(new BigNumber(userAmount));
  // }, [userPSAmount, userAmount]);

  // const isNotEnoghGRDN =
  //   amountTokenGRDN !== "" &&
  //   new BigNumber(amountTokenGRDN || 0).isGreaterThanOrEqualTo(
  //     new BigNumber(pool?.min_grdn || 0)
  //   );

  useEffect(() => {
    const getSignature = async () => {
      try {
        const result = await userService.getSignatureClaim({ id, token });
        const { data } = result.data;

        if (data) {
          setTotal(toRealNumber(data.totalReward, pool?.token_decimal));
          setYourReward(toRealNumber(data.yourReward, pool?.token_decimal));
        } else {
          setTotal("0");
          setYourReward("0");
        }
      } catch (error) {}
    };

    if (wallet_address && pool?.token_decimal) {
      getSignature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claim,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // Date.now() / 1000 > pool?.claim_configs[0]?.start_time,
    wallet_address,
    id,
    pool?.token_decimal,
  ]);

  return (
    <>
      <Row style={{ marginTop: "4px" }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
          <DepositProgress pool={pool} onFinish={onFinish} />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 12 }}
          style={{ paddingBottom: "83px!important" }}
        >
          <AmoutCard
            pool={pool}
            yourReward={yourReward}
            getAllBalance={getAllBalance}
            onDepositSuccess={onDepositSuccess}
          />
        </Col>
      </Row>

      {isConnectMetaMark &&
      pool?.status &&
      pool?.status >= 4 &&
      pool.claim_time &&
      new BigNumber(total).isGreaterThan(0) ? (
        // isNotEnoghGRDN
        <Claimable pool={pool} />
      ) : null}
      <ListType pool={pool} />
      <PoolInfoAndParticipants pool={pool} />
    </>
  );
};

export default DepositAmount;
