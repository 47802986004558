import React from "react";
import { Card, Progress } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import styles from "./card.module.scss";
import { IDOItemType } from "../../../../common/types/IDOType";
import { formatBigNumber, getIconUrl } from "../../../../common/functions";
import {
  ACCEPTED_CURRENCIES_NAME,
  DECIMAL_ACCEPTED_CURRENCY,
  IDO_COUNTDOWN_STATUS,
  IDO_COUNTDOWN_STATUS_LABEL,
  POOL_LABEL_TEXT,
  PRIVATE_SETTING_TEXT,
  SALES_TYPE_TEXT,
  SALES_TYPES,
} from "../../../../common/constants";
import BigNumber from "bignumber.js";
import IDOListCoundown from "../../../../common/countDown/IDOListCoundown";

interface PropsType {
  pool: IDOItemType;
  onFinish?: () => void;
}

const IDOCard: React.FC<PropsType> = (props) => {
  const { pool, onFinish } = props;

  const soldCoin = new BigNumber(pool.total_deposit || 0)
    .div(
      new BigNumber(10).pow(
        DECIMAL_ACCEPTED_CURRENCY[pool.accepted_currency || 1]
      )
    )
    .multipliedBy(Number(pool.exchange_rate));
  const soldRate = Number(soldCoin.div(pool.total_sold_coin).multipliedBy(100));
  const exchangeRate = pool.exchange_rate;
  let time = 0;
  let countdownStatus = 0;
  let isClaim = false;
  const now = Math.floor(Date.now() / 1000);
  let claim_time = Number(pool.claim_time);

  const existLabelEnded = pool.status === 5;
  const existProgresbar =
    Number(pool.start_time_application) > now ||
    Number(pool.end_time_application) > now;

  if (!claim_time) claim_time = pool.claim_configs?.[0]?.start_time;
  if (Number(pool.start_time_application) > now) {
    time = Number(pool.start_time_application);
    countdownStatus = IDO_COUNTDOWN_STATUS.AWAITING_APPLICATION;
  } else if (Number(pool.end_time_application) > now) {
    time = Number(pool.end_time_application);
    countdownStatus = IDO_COUNTDOWN_STATUS.APPLICATION;
  } else if (Number(pool.start_time_deposit) > now) {
    time = Number(pool.start_time_deposit);
    countdownStatus = IDO_COUNTDOWN_STATUS.AWAITING_DEPOSIT;
  } else if (Number(pool.end_time_deposit) > now) {
    time = Number(pool.end_time_deposit);
    countdownStatus = IDO_COUNTDOWN_STATUS.DEPOSIT;
  } else if (Number(pool.start_time_public_sell) > now) {
    time = Number(pool.start_time_public_sell);
    countdownStatus = IDO_COUNTDOWN_STATUS.AWAITING_PUBLIC_SELL;
  } else if (Number(pool.end_time_public_sell) > now) {
    time = Number(pool.end_time_public_sell);
    countdownStatus = IDO_COUNTDOWN_STATUS.PUBLIC_SELL;
  } else if (claim_time > now) {
    time = claim_time;
    countdownStatus = IDO_COUNTDOWN_STATUS.AWAITING_CLAIM;
  } else if (claim_time <= now) {
    countdownStatus = IDO_COUNTDOWN_STATUS.CLAIM;
    isClaim = true;
  }

  return (
    <Card className={styles.IDOCard}>
      <div className={styles.head}>
        <img src={getIconUrl(pool.pool_banner)} alt="" />
        <span>{POOL_LABEL_TEXT[pool.pool_label]}</span>
      </div>
      <div className={styles.body}>
        <div>
          <p className={styles.poolName}>
            {pool.pool_name} ({pool.token_symbol})
          </p>
          <p className={styles.tokenIcon}>
            <img alt="" src={pool.token_icon} />
          </p>
        </div>
        <div>
          <p className={styles.label}>Access type:</p>
          <p className={styles.value}>
            {PRIVATE_SETTING_TEXT[pool.private_setting]}
          </p>
        </div>
        <div>
          <p className={styles.label}>Sales type:</p>
          <p className={styles.value}>{SALES_TYPE_TEXT[pool.sale_type]}</p>
        </div>
        <div>
          <p className={styles.label}>Hard cap:</p>
          <p className={styles.value}>
            {formatBigNumber(pool.total_sold_coin)} {pool.token_symbol}
          </p>
        </div>
        <div>
          <p className={styles.label}>Exchange rate:</p>
          <p className={styles.value}>
            {pool.sale_type === 3 && now < Number(pool.end_time_deposit) ? (
              "TBA"
            ) : (
              <>
                1 {ACCEPTED_CURRENCIES_NAME[pool.accepted_currency]} ={" "}
                {formatBigNumber(exchangeRate)} {pool.token_symbol}
              </>
            )}
          </p>
        </div>
        <div>
          <p className={styles.label}>Supported:</p>
          <p className={styles.value}>
            {ACCEPTED_CURRENCIES_NAME[pool.accepted_currency]}
          </p>
        </div>
      </div>
      <div className={styles.footer}>
        {existLabelEnded ||
        isClaim ||
        existProgresbar ||
        pool?.sale_type === SALES_TYPES.PUBLIC_VALUATION_SALE ? null : (
          <div>
            <div className={styles.rowFlex}>
              <p className={styles.label}>Progress</p>

              <div className={styles.textright}>
                <p className={styles.danger}>
                  ({formatBigNumber(soldRate || 0)}%){" "}
                  {formatBigNumber(soldCoin || 0)}
                </p>
                <p className={styles.value}>
                  /{formatBigNumber(pool.total_sold_coin)} {pool.token_symbol}
                </p>
              </div>
            </div>
            <div>
              <Progress
                percent={soldRate}
                showInfo={false}
                strokeColor="#F22222"
                trailColor="#7F8997"
              />
            </div>
          </div>
        )}
        <div className={styles.rowFlex}>
          <p className={styles.label}>
            {IDO_COUNTDOWN_STATUS_LABEL[countdownStatus] || ""}
          </p>
          <div className={existLabelEnded ? styles.ended : styles.timebox}>
            {isClaim || existLabelEnded ? (
              ""
            ) : (
              <ClockCircleOutlined className={styles.timeboxIcon} />
            )}
            {existLabelEnded ? (
              <span>Ended</span>
            ) : (
              <>
                {isClaim ? (
                  <span>Claimable</span>
                ) : time ? (
                  <IDOListCoundown timer={time} onFinish={onFinish} />
                ) : (
                  <span>TBA</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default IDOCard;
