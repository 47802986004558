import React from "react";
import styles from "./DetailProject.module.scss";
import { useTranslation } from "react-i18next";
import { formatBigNumber,  toRealNumber } from "../../../../common/functions";
import { AIRDROP_CLAIM_TYPE, DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC } from "../../../../common/constants";
import Latex from 'react-latex-next';

interface WrapperStakeProps {
  projectType?: number;
  tokenRate: any;
  claimMethod: any;
  tokenSymbol: any;
  totalParticipant: any;
  totalAmount: any;
  status: number;
  distributionMethod: number;
}

const WrapperStake: React.FC<WrapperStakeProps> = ({
  tokenRate,
  claimMethod,
  tokenSymbol,
  totalParticipant,
  totalAmount,
  status,
  distributionMethod,
}) => {
  const { t } = useTranslation();
  const total = toRealNumber(totalAmount);

  const checkRenderTotalAmount = () => {
    return status === DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_CLAIM
      || status === DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.CLAIMING
      || status === DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.COMPLETED;
  }

  const checkRenderTotalParticipants = () => {
    if (status === DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_APPLICATION) {
      return t('Airdrop.Tba');
    } else {
      return totalParticipant;
    }
  }

  return (
    <div className={styles.DetailProject__WrapperStake}>
      <div className={styles.DetailProject__WrapperStake__Content}>
        <article>
          <p className={styles.DetailProject__WrapperStake__Title}>
            {t("Airdrop.Detail.TotalAmount")}
          </p>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {checkRenderTotalAmount() ? (
              <>
                {formatBigNumber(total, 2, true)}
                {(tokenSymbol && totalAmount) && <label>{tokenSymbol}</label>}
              </>
            ) : t("Airdrop.Tba")}
          </p>
        </article>
        <article>
          <p className={styles.DetailProject__WrapperStake__Title}>
            {t("Airdrop.Detail.TotalParticipants")}
          </p>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {checkRenderTotalParticipants()}
          </p>
        </article>
      </div>
      <div className={styles.DetailProject__WrapperStake__Content}>
        <article>
          <p className={styles.DetailProject__WrapperStake__Title}>
            {t("Airdrop.Detail.ClaimMethod")}
          </p>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {claimMethod}
          </p>
        </article>
        <article>
          <p className={styles.DetailProject__WrapperStake__Title}>
            {t("Airdrop.Detail.Rate")}
          </p>
          <p className={styles.DetailProject__WrapperStake__Value}>
            {distributionMethod === AIRDROP_CLAIM_TYPE.QUADRATIC ? <Latex>{tokenRate}</Latex> : tokenRate}
          </p>
        </article>
      </div>
    </div>
  );
};

export default WrapperStake;
