import { ACCEPTED_CURRENCIES_NAME, PRIVATE_SETTING_TEXT, SALES_TYPE_TEXT } from "common/constants";
import { formatBigNumber } from "common/functions";
import { IDOType } from "common/types/IDOType";
import React from "react";

interface PropsType {
  pool: Partial<IDOType>;
  renderContent: any;
}

const ContentLeftDepositComponent = (props: PropsType) => {
  const { pool, renderContent } = props;

  const contentLeftDeposit = [
    {
      text: "Access Type",
      data: PRIVATE_SETTING_TEXT[pool?.private_setting || 0],
    },
    { text: "Sales type", data: SALES_TYPE_TEXT[pool.sale_type || 0] },
    { text: "Max deposit", data: formatBigNumber(pool.max_deposit || 0) + ` ${pool.accepted_currency && ACCEPTED_CURRENCIES_NAME[pool.accepted_currency]}` },
    { text: "Max number of participants", data: (pool.max_participant || 0)  +  ` participants ` },
    Number(pool.min_grdn) > 0 && {
      text: "Minimum GRDN tokens required",
      data: formatBigNumber(pool.min_grdn || 0) +  ` GRDN `,
    },
    Number(pool.minimum_delegate_staking_amount) > 0 && {
      text: "Minimum delegate staking amount required ",
      data:  formatBigNumber(pool.minimum_delegate_staking_amount || 0) +  ` ROSE `,
    },
  ];
  return (
    <>
      {contentLeftDeposit.map((item) => {
        if(item) {
          const { text, data } = item;
          const content = [
            {
              text: text,
              data: data,
            },
          ];
          return (
            <React.Fragment key={text}>{renderContent(content)}</React.Fragment>
          );
        }

        return null;
       
      })}
      
    </>
  );
};

export default ContentLeftDepositComponent;
