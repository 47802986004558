import React, { useState } from "react";
import { Button } from "antd";
import styles from "./CardComponent.module.scss";
import {
  DETAIL_PROJECT_STATUS,
  PROJECT_STAKING_TYPE,
} from "../../../../common/constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getIconUrl } from "../../../../common/functions";
import CountDown from "../../../../common/countDown";
import images from "themes/images";

interface projectType {
  id: string;
  token_name: string;
  token_symbol: any;
  token_icon: any;
  AccessType: string;
  TriggerTerm: string;
  UnderTriggerTerm: string;
  hard_cap: number;
  status: number;
  finish_time: number;
  start_time: number;
  announce_time: number;
  distribute_time: number;
  project_type: number;
}

const CardComponent: React.FC<{
  item: projectType;
  onFinishCountDown: (id: number, status: number) => void;
}> = (props) => {
  const [cardActive, setCardActive] = useState<number[]>([]);
  const { t } = useTranslation();
  const history = useHistory();
  let timer = 0;

  const viewDetailProject = (id: string) => {
    history.push(`/staking/${id}`);
  };

  const handleClick = (index: number) => {
    const status = cardActive.findIndex((id) => id === index);
    if (status === -1) {
      const newArr = [...cardActive, index];
      setCardActive(newArr);
    } else {
      const newArr = [...cardActive];
      newArr.splice(status, 1);
      setCardActive(newArr);
    }
  };

  const renderCardActive = (item: projectType) => {
    const status = cardActive.includes(parseInt(item.id));
    if (status) {
      return `${styles.StakingCard} ${
        item.project_type === PROJECT_STAKING_TYPE.HYBRID
          ? styles.Active_Hybird
          : styles.Active
      }`;
    } else {
      return `${styles.StakingCard}`;
    }
  };

  const isCardActive = (index: number) => {
    return cardActive.includes(index);
  };

  const renderTagStatus = (status: number) => {
    let className = "";
    let displayStatus = "";

    switch (Number(status)) {
      case DETAIL_PROJECT_STATUS.AWAITING_STAKING:
        className = `${styles.StakingCard__Project__Title__Status__Tag} ${styles.StakingCard__Project__Title__Status__Tag__AwaitingStaking}`;
        displayStatus = t("Project.Status.AwaitingStaking");
        timer = props.item.start_time;
        break;
      case DETAIL_PROJECT_STATUS.STAKING:
        className = `${styles.StakingCard__Project__Title__Status__Tag} ${styles.StakingCard__Project__Title__Status__Tag__Staking}`;
        displayStatus = t("Project.Status.Staking");
        timer = props.item.finish_time;
        break;
      case DETAIL_PROJECT_STATUS.AWAITING_RESULTS:
        className = `${styles.StakingCard__Project__Title__Status__Tag} ${styles.StakingCard__Project__Title__Status__Tag__AwaitResult}`;
        displayStatus = t("Project.Status.AwaitingResults");
        timer = props.item.announce_time;
        break;
      case DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION:
        className = `${styles.StakingCard__Project__Title__Status__Tag} ${styles.StakingCard__Project__Title__Status__Tag__AwaitDistribution}`;
        displayStatus = t("Project.Status.AwaitingClaim");
        timer = props.item.distribute_time;
        break;
      case DETAIL_PROJECT_STATUS.DISTRIBUTION:
        className = `${styles.StakingCard__Project__Title__Status__Tag} ${styles.StakingCard__Project__Title__Status__Tag__Distributing}`;
        displayStatus = t("Project.Status.Claiming");
        break;
      case DETAIL_PROJECT_STATUS.COMPLETED:
        className = `${styles.StakingCard__Project__Title__Status__Tag} ${styles.StakingCard__Project__Title__Status__Tag__Completed}`;
        displayStatus = t("Project.Status.Completed");
        break;
    }

    return (
      <div key={Date.now()} className={className}>
        {displayStatus}
      </div>
    );
  };

  return (
    <div key={props.item.id} className={renderCardActive(props.item)}>
      <div
        onClick={() => {
          viewDetailProject(props.item.id);
        }}
        className={styles.StakingCard__Project}
      >
        <div className={styles.StakingCard__Project__Title}>
          <div
            key={Date.now()}
            className={styles.StakingCard__Project__Title__Asset}
          >
            <img
              src={getIconUrl(props.item.token_icon)}
              alt={props.item.token_symbol}
            />
            <div>
              <p>{props.item.token_symbol}</p>
              <span>{props.item.token_name}</span>
            </div>
          </div>
          <div className={styles.StakingCard__Project__Title__Status}>
            {renderTagStatus(props.item.status)}
          </div>
        </div>
        {timer > 0 && (
          <div className={styles.StakingCard__Project__CountdownPhone}>
            <CountDown
              timer={timer}
              onFinish={() =>
                props.onFinishCountDown(
                  parseInt(props.item.id),
                  props.item.status
                )
              }
            />
          </div>
        )}
        <div className={styles.StakingCard__Project__HardCap}>
          <div className={styles.StakingCard__Project__HardCap__Countdown}>
            <span className={styles.StakingCard__Project__HardCap__Title}>
              Hard cap
            </span>
            {timer > 0 && (
              <CountDown
                timer={timer}
                onFinish={() =>
                  props.onFinishCountDown(
                    parseInt(props.item.id),
                    props.item.status
                  )
                }
              />
            )}
          </div>
          <span
            className={styles.StakingCard__Project__HardCap__Value}
          >{`${props.item.hard_cap} ${props.item.token_symbol}`}</span>
        </div>

        <div className={styles.StakingCard__Project__Detail}>
          <div className={styles.StakingCard__Project__Detail__Content}>
            <span>Access type</span>
            <div className={styles.StakingCard__Project__Detail__Content__Term}>
              {props.item.AccessType}
            </div>
          </div>
          <div className={styles.StakingCard__Project__Detail__Content}>
            <span>Hard cap</span>
            <div className={styles.StakingCard__Project__Detail__Content__Term}>
              {`${props.item.hard_cap} ${props.item.token_symbol}`}
            </div>
          </div>
          <div className={styles.StakingCard__Project__Detail__Content}>
            <span>Trigger term</span>
            <div className={styles.StakingCard__Project__Detail__Content__Term}>
              {props.item?.TriggerTerm}
            </div>
          </div>
          <div className={styles.StakingCard__Project__Detail__Content}>
            <span>Under trigger term</span>
            <div className={styles.StakingCard__Project__Detail__Content__Term}>
              {props.item?.UnderTriggerTerm}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.StakingCard__Action}>
        <Button
          onClick={() => {
            handleClick(parseInt(props.item.id));
          }}
          className={styles.StakingCard__Action__Btn}
          type="ghost"
          icon={
            isCardActive(props.item.id ? Number(props.item.id) : 0) ? (
              <img src={images.icons.IconDropDownUpOutlined} alt="icon" />
            ) : (
              <img src={images.icons.IconDropDownDownOutlined} alt="icon" />
            )
          }
        ></Button>
      </div>
    </div>
  );
};

export default CardComponent;
