/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import BigNumber from "bignumber.js";
// import BigNumber from "bignumber.js";
import { ABI_ALLOCATION } from "common/constants";
import { convert, formatBigNumber, toRealNumber } from "common/functions";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { UserService } from "services/UserServicer";
import { setClaims } from "stores/ido";
// import StorageUtils from "utils/storage";
import Web3 from "web3";

interface PropsType {
  yourReward?: any;
  poolAddress?: string;
  tokenSymbol?: string;
  wallet?: string;
  status?: number;
  yourPSReward?: any;
  userPSAmount?: string;
  tokenDecimal?: number;
  minGrdn?: string;
  claimConfig?: any;
}

const YourReward = (props: PropsType) => {
  const {
    poolAddress,
    tokenSymbol,
    wallet,
    status,
    // yourPSReward,
    tokenDecimal,
    // minGrdn,
    // claimConfig,
    yourReward,
  } = props;
  const { ethereum } = window as any;

  // const { amountTokenGRDN } = useSelector((state) => state.wallet);
  const { userAmount, userPSAmount } = useSelector((state) => state.ido);

  const web3 = new Web3(ethereum);
  const dispatch = useDispatch();

  const totalYourDeposit = useMemo(() => {
    return new BigNumber(userAmount).plus(new BigNumber(userPSAmount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAmount, userPSAmount, status, wallet]);

  // const userService = new UserService();
  // const { id } = useParams<any>();
  // const token = StorageUtils.getToken();

  // const [yourReward, setYourReward] = useState("");

  // const wallet_address = useSelector(
  //   (state) => state?.wallet?.userInfo?.wallet_address
  // );

  // useEffect(() => {
  //   const getSignature = async () => {
  //     try {
  //       const result = await userService.getSignatureClaim({ id, token });
  //       const { data } = result.data;

  //       if (data) {
  //         setYourReward(toRealNumber(data.yourReward, tokenDecimal));
  //         // console.log("amount", convert(data.amount));
  //       } else {
  //         setYourReward("0");
  //       }
  //     } catch (error) {}
  //   };

  //   if (wallet_address) {
  //     getSignature();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   claim,
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   Date.now() / 1000 > claimConfig?.[0]?.start_time,
  //   wallet_address,
  //   id,
  // ]);

  const getUserClaim = async () => {
    try {
      const contract = new web3.eth.Contract(ABI_ALLOCATION, poolAddress);
      const balance = await contract.methods.userClaimed(wallet).call();
      dispatch(setClaims(toRealNumber(balance, tokenDecimal)));
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (poolAddress && wallet) {
      getUserClaim();
    }
  }, [poolAddress, wallet, status]);

  // const isNotEnoghGRDN =
  //   amountTokenGRDN !== "" &&
  //   minGrdn &&
  //   new BigNumber(minGrdn || 0).isGreaterThan(
  //     new BigNumber(amountTokenGRDN || 0)
  //   );

  // const yourRewardValue = useMemo(() => {
  //   const convertReward = new BigNumber(yourReward);
  //   return convertReward;
  // }, [yourReward, status]);
  // const yourPSRewardValue = useMemo(() => {
  //   const convertReward = new BigNumber(yourPSReward);
  //   return convertReward;
  // }, [yourPSReward, status]);

  // let totalRewardValue: BigNumber | number = useMemo(() => {
  //   return new BigNumber(yourRewardValue)
  //     .plus(new BigNumber(yourPSRewardValue))
  //     .minus(new BigNumber(claim));
  // }, [yourPSRewardValue, yourRewardValue, claim, status, wallet]);

  // totalRewardValue = Math.max(totalRewardValue.toNumber(), 0);

  return (
    <>
      {status && status > 3 ? (
        <Row className="row-1">
          <Col span={12} className="your-deposit">
            <div className="text">
              <span></span>
              <span>Your reward</span>
            </div>
          </Col>
          <Col span={12} className="token">
            {!wallet || totalYourDeposit.lte(0) ? (
              "-"
            ) : (
              <>
                {formatBigNumber(convert(yourReward)) || "0"} {tokenSymbol}
              </>
            )}
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default YourReward;
