import { message } from "antd";
import BigNumber from "bignumber.js";
import { resetStateToolbar } from "common/functions";
import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ErrorSystem from "./common/alert/errorSystem";
import {
  ALERT_TIME,
  TIME_OUT_INTERVAL,
  OASIS_WALLET_STATUS,
} from "./common/constants";
import { UserService } from "./services/UserServicer";
import { web3 } from "./services/Web3";
import {
  setAmountTokenGRDN,
  setTotalDelegateStakingAmount,
  setUserInfo,
  UserInfoType,
} from "./stores/wallet";
const ERC20_ABI = require("./abi/ERC20.json");
interface AppContainerProps {
  children?: React.ReactNode;
}

const AppContainer: React.FC<AppContainerProps> = (props) => {
  const { children } = props;
  const userInfo: UserInfoType | null = useSelector(
    ({ wallet }) => wallet.userInfo
  );
  const UserAPI = new UserService();
  const location = useLocation();

  const getAmountTokenGRDN = async () => {
    if (userInfo?.wallet_address) {
      try {
        const contract =
          web3 &&
          (new web3.eth.Contract(
            ERC20_ABI,
            process.env.REACT_APP_TOKEN_GRDN_ADRRESS
          ) as any);
        const decimals = await contract?.methods.decimals().call();

        const tokenGRDN = await contract?.methods
          .balanceOf(userInfo.wallet_address)
          .call();

        setAmountTokenGRDN(
          new BigNumber(tokenGRDN)
            .div(new BigNumber(10).pow(decimals))
            .toString()
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const getDelegateAmount = async () => {
  //   if (userInfo?.oasis_address) {
  //     try {
  //       const { data } = await UserAPI.getDelegateStakingAmount(
  //         userInfo.oasis_address
  //       );
  //       const totalAmount = _.get(data, 'data.total_amount', '');
  //       setTotalDelegateStakingAmount(web3?.utils.fromWei(totalAmount) || '0');
  //     } catch (err) {
  //       return message.info({
  //         content: <ErrorSystem />,
  //         icon: <></>,
  //         duration: ALERT_TIME,
  //       });
  //     }
  //   }
  // };

  const getUserInfo = async () => {
    if (userInfo?.wallet_address) {
      try {
        const result = await UserAPI.getProfile(userInfo.wallet_address);
        const { data } = result;
        const user = _.get(
          data,
          "data.findedUser",
          null
        ) as UserInfoType | null;
        if (!user) throw new Error("System error");
        if (!user.oasis_address) {
          setTotalDelegateStakingAmount("0");
        }
        setUserInfo(user);
      } catch (err) {
        return message.info({
          content: <ErrorSystem />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
    }
  };

  useEffect(() => {
    const realtimeData =
      location.pathname === "/profile" &&
      userInfo?.wallet_address &&
      userInfo?.oasis_address &&
      userInfo?.oasis_status !== OASIS_WALLET_STATUS.VERIFIED;
    if (realtimeData) {
      const interval = setInterval(() => getUserInfo(), TIME_OUT_INTERVAL);
      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    if (userInfo?.wallet_address) {
      // getDelegateAmount();
      getAmountTokenGRDN();
      getUserInfo();
      resetStateToolbar();
    }
  }, [userInfo?.wallet_address]); // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment>{children}</React.Fragment>;
};

export default AppContainer;
