import styles from "./Layout.module.scss";
import { Layout, Button, Drawer } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setDarkMode } from "../../stores/wallet";
import images from "../../themes/images";
import StorageUtils, { STORAGE_KEYS } from "../../utils/storage";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../common/constants";

const { Header } = Layout;

const LayoutHeaderHome = () => {
  const { t } = useTranslation();
  const [isDarkMode, setDarkModeState] = useState<boolean>(
    StorageUtils.getItem(STORAGE_KEYS.DARKMODE, "false") === "true"
  );
  const [isShowMenu, setShowMenu] = useState<boolean>();

  const handleDarkMode = () => {
    setDarkModeState(!isDarkMode);
  };

  useEffect(() => {
    setDarkMode({ isDarkMode });
    StorageUtils.setItem(STORAGE_KEYS.DARKMODE, `${isDarkMode}`);
  }, [isDarkMode]);

  return (
    <Header className={styles.Header}>
      <div className={styles.Header__WrapperLogo}>
        <img
          className={styles.imageLogo}
          src={images.logo.LogoLandingLight}
          alt="logo"
        />
        <NavLink to={ROUTES.HOME} />
      </div>
      <div className={`${styles.Header__ConnectWallet} `}>
        {/* <a href={HYPER_LINK.BUY_ROSE} rel="noreferrer" target="_blank">
          #LGPass
        </a> */}
        <Button
          className={`${styles.Header__ButtonPrimary} ${styles.Header__ConnectWallet_LaunchApp}`}
          type="primary"
        >
          <NavLink to={ROUTES.IDOPOOL}>{t("Header.LaunchApp")}</NavLink>
        </Button>
        <img
          onClick={handleDarkMode}
          src={images.icons.IconChangeMode}
          alt="BrightnessUp"
          className={styles.BrightnessUp_Web}
        />
      </div>
      <div
        onClick={() => setShowMenu(true)}
        className={styles.Header__HamburgerMemu}
      >
        <img src={images.icons.BuggerIcon} alt="hbg-menu" />
      </div>
      <Drawer
        width="100vw"
        onClose={() => setShowMenu(false)}
        visible={isShowMenu}
        data-theme={isDarkMode ? "dark" : "light"}
        title={
          <NavLink to={ROUTES.HOME}>
            <img
              className={styles.imageLogoMobile}
              src={images.logo.LogoLandingLight}
              alt="logo"
            />
          </NavLink>
        }
      >
        <div className={styles.WrapperButton}>
          <img
            onClick={handleDarkMode}
            src={images.icons.BrightnessUp}
            alt="BrightnessUp"
          />
          <Button className={styles.WrapperButton__LaunchApp} type="primary">
            <NavLink to={ROUTES.STAKING}>{t("Header.LaunchApp")}</NavLink>
          </Button>
          {/* <a
            href={HYPER_LINK.BUY_ROSE}
            rel="noreferrer"
            className={styles.WrapperButton__BuyRose}
            target="_blank"
          >
            {t("Header.BuyRose")}
          </a> */}
        </div>
      </Drawer>
    </Header>
  );
};

export default LayoutHeaderHome;
