import { useSelector } from 'react-redux'

export default function CopyIcon() {
    const { isDarkMode } = useSelector(wallet => wallet.wallet)
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M1.83366 3.33317C1.46699 3.33317 1.16699 3.63317 1.16699 3.99984V13.9998C1.16699 14.7332 1.76699 15.3332 2.50033 15.3332H12.5003C12.867 15.3332 13.167 15.0332 13.167 14.6665C13.167 14.2998 12.867 13.9998 12.5003 13.9998H3.16699C2.80033 13.9998 2.50033 13.6998 2.50033 13.3332V3.99984C2.50033 3.63317 2.20033 3.33317 1.83366 3.33317ZM14.5003 0.666504H5.16699C4.43366 0.666504 3.83366 1.2665 3.83366 1.99984V11.3332C3.83366 12.0665 4.43366 12.6665 5.16699 12.6665H14.5003C15.2337 12.6665 15.8337 12.0665 15.8337 11.3332V1.99984C15.8337 1.2665 15.2337 0.666504 14.5003 0.666504ZM13.8337 11.3332H5.83366C5.46699 11.3332 5.16699 11.0332 5.16699 10.6665V2.6665C5.16699 2.29984 5.46699 1.99984 5.83366 1.99984H13.8337C14.2003 1.99984 14.5003 2.29984 14.5003 2.6665V10.6665C14.5003 11.0332 14.2003 11.3332 13.8337 11.3332Z"
                    fill={isDarkMode ? '#B1B5C3' : '#353945'} />
            </g>
        </svg>

    )
}
