import React from "react";
import { Table, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "./Staking.module.scss";
import { getIconUrl } from "../../../../common/functions";
import { DETAIL_PROJECT_STATUS } from "../../../../common/constants";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotRecordFound from "../../../../common/pages/NotRecordFound";
import "../../../../common/styles/customAntd.scss";
import CountDown from "../../../../common/countDown";

interface StakingProp {
  stakingList: any;
  total: string;
  pageInit: number;
  handleChangePage: (page: number) => void;
  onFinishCountDown: (id: number, status: number) => void;
}

const Staking: React.FC<StakingProp> = ({
  stakingList,
  total,
  pageInit,
  handleChangePage,
  onFinishCountDown,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const viewDetailProject = (record: any) => {
    const { id } = record;
    history.push(`/staking/${id}`);
  };
  const showToolTips = (type: string) => {
    switch (type) {
      case "accessType":
        return (
          <div className={styles.Columns__Tooltips}>
            <p>
              <span>{t("Staking.ProjectType.Public")}:</span>{" "}
              {t("Staking.ProjectType.PublicDesc")}
            </p>
            <p>
              <span>{t("Staking.ProjectType.Private")}:</span>{" "}
              {t("Staking.ProjectType.PrivateDesc")}
            </p>
            <p>
              <span>{t("Staking.ProjectType.Hybrid")}:</span>{" "}
              {t("Staking.ProjectType.HybridDesc")}
            </p>
          </div>
        );

      case "trigger":
        return (
          <div className={styles.Columns__Tooltips}>
            <span>{t("Staking.Detail.TriggerTermDesc")}</span>
          </div>
        );

      case "underTrigger":
        return (
          <div className={styles.Columns__Tooltips}>
            <span>{t("Staking.Detail.UnderTriggerTermDesc")}</span>
          </div>
        );
      default:
        break;
    }
  };

  const columns = [
    {
      title: "Asset",
      dataIndex: "Asset",
      key: "Asset",
      width: "25%",
      render: (asset: string, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <div className={styles.Columns__Asset}>
              <img
                src={getIconUrl(record.token_icon)}
                alt={record.token_symbol}
              />
              <div>
                <h5>{record.token_symbol}</h5>
                <p className={styles.tokenName}>{record.token_name}</p>
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: () => {
        return (
          <div className={styles.Columns__Title}>
            Access type
            <Tooltip
              placement="bottomLeft"
              color="#fff"
              title={showToolTips("accessType")}
            >
              <InfoCircleOutlined className={styles.Columns__QuestionIcon} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "AccessType",
      key: "Access type",
      render: (accessType: string, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: <p className={styles.Columns__AccessType}>{accessType}</p>,
        };
      },
    },
    {
      title: "Hard cap",
      dataIndex: "hard_cap",
      key: "Hard cap",
      render: (hardCap: number, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <div className={styles.Columns__HardCap}>
              {`${hardCap} ${record.token_symbol}`}
            </div>
          ),
        };
      },
    },
    {
      title: () => {
        return (
          <div className={styles.Columns__Title}>
            Trigger term
            <Tooltip
              placement="bottomLeft"
              color="#fff"
              title={showToolTips("trigger")}
            >
              <InfoCircleOutlined className={styles.Columns__QuestionIcon} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "TriggerTerm",
      key: "Trigger term",
      render: (TriggerTerms: string, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <p className={styles.Columns__TriggerTerms}>{TriggerTerms}</p>
          ),
        };
      },
    },
    {
      title: () => {
        return (
          <div className={styles.Columns__Title}>
            Under trigger term
            <Tooltip
              placement="bottomLeft"
              color="#fff"
              title={showToolTips("underTrigger")}
            >
              <InfoCircleOutlined className={styles.Columns__QuestionIcon} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "UnderTriggerTerm",
      key: "Under trigger term",
      render: (UnderTriggerTerms: String, record: any) => {
        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <p className={styles.Columns__TriggerTerms}>{UnderTriggerTerms}</p>
          ),
        };
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status: any, record: any) => {
        let className = "";
        let displayStatus = "";
        let timer = 0;
        switch (Number(status)) {
          case DETAIL_PROJECT_STATUS.AWAITING_STAKING:
            className = styles.Columns__AwaitingStaking;
            displayStatus = t("Project.Status.AwaitingStaking");
            timer = record.start_time;
            break;
          case DETAIL_PROJECT_STATUS.STAKING:
            className = styles.Columns__Staking;
            displayStatus = t("Project.Status.Staking");
            timer = record.finish_time;
            break;
          case DETAIL_PROJECT_STATUS.AWAITING_RESULTS:
            className = styles.Columns__AwaitingResults;
            displayStatus = t("Project.Status.AwaitingResults");
            timer = record.announce_time;
            break;
          case DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION:
            className = styles.Columns__AwaitingDistribution;
            displayStatus = t("Project.Status.AwaitingClaim");
            timer = record.distribute_time;
            break;
          case DETAIL_PROJECT_STATUS.DISTRIBUTION:
            className = styles.Columns__Distributing;
            displayStatus = t("Project.Status.Claiming");
            break;
          case DETAIL_PROJECT_STATUS.COMPLETED:
            className = styles.Columns__Completed;
            displayStatus = t("Project.Status.Completed");
            break;
        }

        return {
          props: {
            style: { cursor: "pointer" },
            onClick: () => viewDetailProject(record),
          },
          children: (
            <>
              <div className={`${className} ${styles.Columns__StatusButton}`}>
                {displayStatus}
              </div>
              {timer > 0 && (
                <CountDown
                  timer={timer}
                  onFinish={() => onFinishCountDown(record.id, record.status)}
                />
              )}
            </>
          ),
        };
      },
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        className="Table"
        columns={columns}
        dataSource={stakingList}
        pagination={{
          total: parseInt(total),
          showLessItems: true,
          responsive: true,
          current: pageInit,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
        }}
        onChange={(page) => page.current && handleChangePage(page.current)}
        locale={{ emptyText: <NotRecordFound /> }}
      />
    </div>
  );
};

export default Staking;
