import { Divider, Progress } from "antd";
import BigNumber from "bignumber.js";
import {
  CHECK_TEXT_TIME,
  DECIMAL_ACCEPTED_CURRENCY,
  DETAIL_PROJECT_IDO_STATUS,
  SALES_TYPES,
} from "common/constants";
import { formatBigNumber } from "common/functions";
import useValueFunc from "common/hooks/useValueFunc";
import { IDOType } from "common/types/IDOType";

import CountdownStart from "./countdownStart";
import MaxDeposit from "./MaxDeposit";
import "./styles.scss";
interface PropsType {
  pool: Partial<IDOType>;
  onFinish?: () => void;
}

const DepositProgress: React.FC<PropsType> = (props: PropsType) => {
  const { pool, onFinish } = props;
  const {
    soldRate,
    timeDepositStart,
    timeApplicationStart,
    totalSoldCoin,
    timeApplicationEnd,
    timeDepositEnd,
    status,
    timeClaimStart,
    timePublicSellEnd,
    timePublicSellStart,
    totalDeposit,
    exchangeRate,
  } = useValueFunc({ pool });

  let checkTime = 0;
  if (timeApplicationStart > 0) checkTime = timeApplicationStart;
  else if (timeApplicationEnd > 0) checkTime = timeApplicationEnd;
  else if (timeDepositStart > 0) checkTime = timeDepositStart;
  else if (timeDepositEnd > 0) checkTime = timeDepositEnd;
  else if (timePublicSellStart > 0) checkTime = timePublicSellStart;
  else if (timePublicSellEnd > 0) checkTime = timePublicSellEnd;
  else if (timeClaimStart > 0) checkTime = timeClaimStart;

  const getTextTime = () => {
    if (
      timeApplicationStart !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.UP_COMMING
    ) {
      return CHECK_TEXT_TIME.APPLICATION_START;
    } else if (
      timeApplicationEnd !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.UP_COMMING
    ) {
      return CHECK_TEXT_TIME.APPLICATION_END;
    } else if (
      timeDepositStart !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.DEPOSIT
    ) {
      return CHECK_TEXT_TIME.DEPOSIT_START;
    } else if (
      timeDepositEnd !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.DEPOSIT
    ) {
      return CHECK_TEXT_TIME.DEPOSIT_END;
    } else if (
      timePublicSellStart !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.PUBLICSELL
    ) {
      return CHECK_TEXT_TIME.PUBLIC_SELL_START;
    } else if (
      timePublicSellEnd !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.PUBLICSELL
    ) {
      return CHECK_TEXT_TIME.PUBLIC_SELL_END;
    } else if (
      timeClaimStart !== 0 &&
      Number(status) === DETAIL_PROJECT_IDO_STATUS.CLAIMABLE
    ) {
      return CHECK_TEXT_TIME.CAN_CLAIM;
    }
  };

  const DepositFromWei =
    (totalDeposit &&
      new BigNumber(totalDeposit)
        .div(
          new BigNumber(10).pow(
            DECIMAL_ACCEPTED_CURRENCY[pool.accepted_currency || 1]
          )
        )
        .multipliedBy(Number(exchangeRate))) ||
    0;

  return (
    <div className="main-time">
      {pool.sale_type === SALES_TYPES.PUBLIC_VALUATION_SALE && (
        <MaxDeposit pool={pool} />
      )}

      {pool.sale_type !== SALES_TYPES.PUBLIC_VALUATION_SALE && (
        <>
          <span className="title-progress">Deposit Progress:</span>
          <Progress
            percent={soldRate}
            showInfo={false}
            strokeColor="#F22222"
            trailColor="#7F8997"
          />
          <div className="info-progress">
            <span>{formatBigNumber(soldRate || 0)}%</span>
            <span>
              {`${
                DepositFromWei
                  ? formatBigNumber(Number(DepositFromWei?.toString()))
                  : 0
              } / ${formatBigNumber(totalSoldCoin || 0)} `}
            </span>
          </div>
        </>
      )}
      <Divider />
      {pool?.status && (
        <CountdownStart
          pool={pool}
          time={checkTime}
          checkTextTime={getTextTime()}
          status={status}
          onFinish={onFinish}
        />
      )}
    </div>
  );
};

export default DepositProgress;
