import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

interface IconCloseProps {

}

const IconClose: React.FC<IconCloseProps> = () => {
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);

  return (
    <div className="Stake__Label__Close">
      <CloseOutlined style={{ color: `${isDarkMode ? "#FFFFFF" : "#23262F"}` }} />
    </div>
  );
}

export default IconClose;