import { Button, message, Modal } from "antd";
import BigNumber from "bignumber.js";
import messageCustom from "common/alert/message";
import Transaction from "common/alert/transaction";
import { formatBigNumber } from "common/functions";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close-popup.svg";
import { ABI_ALLOCATION, ABI_ERC20, ALERT_TIME } from "../../constants";
import { AcceptedCurrencyTypes, IDOType } from "../../types/IDOType";
import "./styles.scss";
interface PropsType {
  openDeposit: boolean;
  handleCloseDeposit: () => void;
  acceptedCurrency?: AcceptedCurrencyTypes;
  poolAddress?: string;
  value?: number;
  rewardClaim?: any;
  signature?: string;
  yourRewardValue?: any;
  amount?: string;
  claim?: string;
  onClaim?: (loading: boolean, success?: boolean) => void;
  pool?: Partial<IDOType>;
  tokenDecimal: number;
}

const ClaimDeposit = (props: PropsType) => {
  const {
    openDeposit,
    handleCloseDeposit,
    poolAddress,
    rewardClaim,
    signature,
    amount,
    claim,
    onClaim,
    pool,
    tokenDecimal,
  } = props;
  const { ethereum } = window as any;
  const { userInfo } = useSelector((state) => state.wallet);
  const web3 = new Web3(ethereum);
  const [loading, setLoading] = useState(false);
  const totalAmount = new BigNumber(amount || "0").minus(
    new BigNumber(claim || "0")
  );

  const valueSlider = new BigNumber(rewardClaim);
  const dataDeposit: any = [
    {
      reward: pool?.token_symbol,
      text: "Reward asset",
    },
    {
      reward:
        totalAmount && new BigNumber(totalAmount).isLessThanOrEqualTo(0)
          ? formatBigNumber(amount || "0")
          : formatBigNumber(totalAmount.toString()),
      text: "Your reward",
    },
  ];

  const renderData = useCallback((data) => {
    return data.map((item: any) => {
      const { text, reward } = item;
      return (
        <div className="reward" key={text}>
          <div className="reward__left">
            <span></span>
            <span>{text}</span>
          </div>
          <span className="reward__right">{reward || 0}</span>
        </div>
      );
    }, []);
  }, []);

  const handleClaimToken = async () => {
    const contract = new web3.eth.Contract(ABI_ALLOCATION, poolAddress);
    const claimTowei = new BigNumber(valueSlider)
      .multipliedBy(10 ** tokenDecimal)
      .toString();
    try {
      console.log("vào");
      console.log("claimTowei", claimTowei);
      setLoading(true);
      onClaim?.(true);
      let transactionHash = "";
      await contract.methods
        .claimTokens(userInfo.wallet_address, claimTowei, signature)
        .send({ from: userInfo.wallet_address })
        .on("transactionHash", function (hash: any) {
          transactionHash = hash;
        })
        .on("receipt", (receipt: any) => {
          handleCloseDeposit();
          setLoading(false);
          onClaim?.(true, true);
          messageCustom.success("Transaction complete");
        })
        .on("error", function (error: any, receipt: any) {
          setLoading(false);
          return message.info({
            content: <Transaction type="reject" hash={transactionHash} />,
            icon: <></>,
            duration: ALERT_TIME,
            className: "custom-message",
          });
        });
    } catch (error: any) {
      if (error?.code !== 4001) {
        console.log("error", error);
        try {
          const contract = new web3.eth.Contract(
            ABI_ERC20,
            pool?.token_address
          );
          const result = await contract.methods
            .balanceOf(poolAddress)
            .call({ from: poolAddress });

          console.log("result", result);
          console.log("claimTowei", claimTowei);

          new BigNumber(result).lt(claimTowei) &&
            messageCustom.error("Transaction Error");
        } catch {}
      }
      setLoading(false);
      onClaim?.(false);
    } finally {
      handleCloseDeposit();
      setLoading(false);
      onClaim?.(false);
    }
  };
  return (
    <Modal
      visible={openDeposit}
      onOk={handleCloseDeposit}
      onCancel={handleCloseDeposit}
      closeIcon={() => null}
      footer={null}
      width="448px"
    >
      <div className="header-claim">
        <span>Claim</span>
        <CloseIcon onClick={handleCloseDeposit} className="icon" />
      </div>
      <div className="content-claim">
        {dataDeposit.map((item: any) => {
          const { text, reward } = item;
          const content = [
            {
              reward: reward,
              text: text,
            },
          ];
          return (
            <React.Fragment key={text}>{renderData(content)}</React.Fragment>
          );
        })}
      </div>
      <Button
        loading={loading}
        className="claim-btn"
        onClick={handleClaimToken}
      >
        Claim
      </Button>
    </Modal>
  );
};

export default ClaimDeposit;
