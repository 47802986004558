import { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import styles from "./MyProfile.module.scss";
import images from "../../../themes/images";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import {
  setUserInfo,
  setConnectNetwork,
  setConnectMetaMark,
} from "../../../stores/wallet";
import { isMetaMaskInstalled } from "../../../services/Web3";
import StorageUtils from "../../../utils/storage";
import {
  METAMASK_SIGN_MESSAGE,
  NETWORK,
  POPUP,
} from "../../../common/constants";
import ConnectWallet from "../../../common/popup/connectWallet";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../../components/layout/connectWallet";

import { signMessage } from "../../../common/functions";
import { ConnectWalletService } from "../../../services/ConnectWallet";
import DetailMyProfile from "./detailMyProfile";
const windowObj = window as any;
const { ethereum } = windowObj;

const MyProfile = () => {
  const { t } = useTranslation();
  const [isConnectWalletPopUp, setIsConnectWalletPopUp] =
    useState<boolean>(false);
  const RootState = useSelector((state) => state.wallet);
  const { isConnectMetaMark } = RootState;
  const [isNetworkPopUp, setIsNetworkPopUp] = useState<boolean>(false);
  const { activate, deactivate } = useWeb3React();
  const checkNetwork = () => {
    const network = ethereum?.networkVersion;
    if (network === NETWORK.CHAIN_ID_DECIMAL) {
      return true;
    }
    return false;
  };
  const ConnectWalletAPI = new ConnectWalletService();

  const closePopUpConnectWallet = () => {
    if (isConnectMetaMark) {
      setIsNetworkPopUp(!isNetworkPopUp);
    } else {
      setIsConnectWalletPopUp(!isConnectWalletPopUp);
    }
  };

  const connectWallet = async () => {
    try {
      if (!isMetaMaskInstalled()) {
        return POPUP.CONNECTWALLET.STATUS.UNINSTALL;
      }
      deactivate();
      await activate(injected);
      const accounts = await ethereum?.request({
        method: "eth_requestAccounts",
      });
      const loginUser = accounts.length ? accounts[0] : "";
      const signature = await signMessage(METAMASK_SIGN_MESSAGE, loginUser);
      if (!signature) {
        return;
      }
      const params = {
        wallet_address: loginUser,
        signature,
      };
      const result = await ConnectWalletAPI.connectWallet(params);
      const { data } = result;
      const resultToken = _.get(data, "data.token", "");
      const user = _.get(data, "data.user", "");

      if (resultToken) {
        setConnectMetaMark(true);
        setIsConnectWalletPopUp(false);
        const { token } = resultToken;
        StorageUtils.setUser(user);
        StorageUtils.setToken(token);
        setUserInfo(user);
        const check = checkNetwork();

        if (!check) {
          setIsNetworkPopUp(true);
          setConnectNetwork(false);
        } else {
          setConnectNetwork(true);
          setIsNetworkPopUp(false);
        }
      } else {
        setConnectMetaMark(false);
        return POPUP.CONNECTWALLET.STATUS.ERROR;
      }
    } catch (error) {
      return POPUP.CONNECTWALLET.STATUS.ERROR;
    }
  };

  const NotConnectWallet = () => {
    return (
      <div className={styles.ConnectWallet}>
        <img src={images.icons.Unlock} alt="Unlock" />
        <Button
          type="primary"
          className={styles.ConnectWallet__Button}
          onClick={closePopUpConnectWallet}
        >
          {t("Header.ConnectWallet")}
        </Button>
      </div>
    );
  };
  return (
    <div>
      {isConnectMetaMark ? <DetailMyProfile /> : <NotConnectWallet />}
      <ConnectWallet
        isShow={isConnectMetaMark ? false : isConnectWalletPopUp}
        handleCancel={closePopUpConnectWallet}
        connectWallet={connectWallet}
      />
    </div>
  );
};

export default MyProfile;
