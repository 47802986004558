/* eslint-disable react-hooks/exhaustive-deps */
import { Button, message, Modal } from "antd";
import styles from "./DetailProject.module.scss";
import { useTranslation } from "react-i18next";
import {
  PROJECT_STAKING_TYPE,
  DETAIL_PROJECT_STATUS,
  PROJECT_AIRDROP_TYPE,
  AIRDROP_CLAIM_TYPE,
  DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT,
  DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT,
  DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC,
  JOIN_AIRDROP_MESSAGE,
  ABI_ALLOCATION,
  HTTP_STATUS,
  DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC,
  STATUS_DEPLOY_PROJECT,
  ALERT_TIME,
  BYPASS_STATUS,
  STATUS_KYC_USER,
  STATUS_JOIN_POOL,
} from "../../../../common/constants";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import IconClose from "../../../../common/popup/iconClose";
import { UserService } from "../../../../services/UserServicer";
import _ from "lodash";
import StorageUtils from "../../../../utils/storage";
import {
  signMessage,
  toRealNumber,
  checkRenderPublicLinearAndQuadratic,
  checkRenderPublicFixedAmount,
  checkRenderPrivateLinearAndQuadratic,
  checkRenderPrivateFixedAmount,
  formatBigNumber,
} from "../../../../common/functions";
import AirdropClaim from "../../../../common/popup/airdropClaim";
import Transaction from "../../../../common/alert/transaction";
import Web3 from "web3";
import ErrorSystem from "../../../../common/alert/errorSystem";
import BigNumber from "bignumber.js";
import messageCustom from "../../../../common/alert/message";
import { UserRejectedRequestError } from "@web3-react/injected-connector";

const YourReward: React.FC<any> = ({
  projectId,
  status,
  tokenSymbol,
  projectContractAddress,
  projectType,
  distributionMethod,
  statusDeploy,
  joinAirDrop,
  minGRDN,
  minDelegateStakingAmount,
  poolDetail,
  setJoinStatus,
  setClaim,
  checkJoinAndClaimProject,
  isJoinProject,
  isClaim,
  isCheckJoinAndClaim,
  isWhiteList,
  getCheckWhiteList,
  isProcess,
}) => {
  const { t } = useTranslation();
  const UserServiceAPI = new UserService();
  const [istShowModalClaim, setShowModalClaim] = useState<boolean>(false);
  const [dataSignature, setDataSignature] = useState<any>(false);
  const rootState = useSelector((state) => state.wallet);
  const [loading, setLoading] = useState<boolean>(false);

  const { isConnectMetaMark, isConnectNetwork, isDarkMode } = rootState;
  const token = StorageUtils.getToken();
  const amountReward = toRealNumber(
    _.get(dataSignature, "data.amount", 0),
    poolDetail?.token_decimal
  );

  const userInfo = useSelector((state) => state.wallet.userInfo);
  const { wallet_address: address, kyc_status, kyc_country } = userInfo || {};
  const amountTokenGRDN = useSelector(({ wallet }) => wallet.amountTokenGRDN);

  const windowObj = window as any;
  const { ethereum } = windowObj;
  const web3 = new Web3(ethereum);
  const abiAllocation = ABI_ALLOCATION;
  const amountTotalDelegateStaking = useSelector(
    ({ wallet }) => wallet.delegateStakingAmount
  );

  const isEnoghDelegateStaking = new BigNumber(
    amountTotalDelegateStaking || 0
  ).gte(new BigNumber(minDelegateStakingAmount || 0));
  const isEnoghGRDN = new BigNumber(amountTokenGRDN || 0).gte(
    new BigNumber(minGRDN || 0)
  );
  const isNotKYC =
    poolDetail?.kyc_bypass === BYPASS_STATUS.REQUIRED &&
    address &&
    kyc_status !== STATUS_KYC_USER.VERIFIED;
  const isForbiddenCountries =
    kyc_country && poolDetail?.forbidden_countries?.includes(kyc_country);
  const checkShowButtonJoin = () => {
    if (isProcess) return false;
    return (
      status ===
        DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.APPLICATION ||
      status ===
        DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_APPLICATION
    );
  };

  const checkRenderWrapperButtonPublic = () => {
    return (
      isConnectMetaMark &&
      isConnectNetwork &&
      statusDeploy === STATUS_DEPLOY_PROJECT.SUCCESS &&
      projectType === PROJECT_AIRDROP_TYPE.PUBLIC
    );
  };

  const checkShowButtonClaimPublic = () => {
    if (projectType === PROJECT_STAKING_TYPE.PUBLIC) {
      if (distributionMethod === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT) {
        return (
          status >= DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT.AWAITING_CLAIM &&
          status <= DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT.COMPLETED
        );
      }
      return (
        status >= DETAIL_PROJECT_STATUS.AWAITING_RESULTS &&
        status <= DETAIL_PROJECT_STATUS.COMPLETED
      );
    }
  };

  const checkShowButtonClaimPrivate = () => {
    if (
      projectType === PROJECT_AIRDROP_TYPE.PRIVATE &&
      isWhiteList &&
      statusDeploy === STATUS_DEPLOY_PROJECT.SUCCESS
    ) {
      if (distributionMethod === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT) {
        return (
          status >= DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT.AWAITING_CLAIM &&
          status <= DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT.COMPLETED
        );
      }
      return (
        status >=
          DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.AWAITING_RESULTS &&
        status <= DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.COMPLETED
      );
    }
  };

  const getSignature = async (projectId: any) => {
    if (token && status >= DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION) {
      try {
        const result = await UserServiceAPI.getSignature(projectId);
        const dataSignature = _.get(result, "data", {});
        setDataSignature(dataSignature);
      } catch (error: any) {
        return message.info({
          content: <ErrorSystem />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
    }
  };

  const handleClaim = async () => {
    try {
      const data = _.get(dataSignature, "data", {});
      const messageSignature = _.get(dataSignature, "message", "");
      const status = _.get(dataSignature, "status", 0);
      setLoading(true);
      if (status === HTTP_STATUS.SUCCESS && data) {
        const { amount, signature, wallet_address } = data;
        const contractAllocation = new web3.eth.Contract(
          abiAllocation,
          projectContractAddress
        );
        let transactionHash = "";
        contractAllocation.methods
          .claimTokens(wallet_address, amount, signature)
          .send({
            from: wallet_address,
          })
          .on("transactionHash", function (hash: any) {
            transactionHash = hash;
          })
          .on("receipt", async function (receipt: any) {
            setLoading(false);
            await UserServiceAPI.updateStatusClaimAirdrop({ projectId });
            setClaim(true);
            return message.info({
              content: <Transaction type="complete" hash={transactionHash} />,
              icon: <></>,
              duration: ALERT_TIME,
            });
          })
          .on("error", function (error: any, receipt: any) {
            setLoading(false);
            if (receipt) {
              return message.info({
                content: <Transaction type="error" hash={transactionHash} />,
                icon: <></>,
                duration: ALERT_TIME,
              });
            }
          })
          .catch((err: any) => {
            setLoading(false);
            if (!transactionHash) {
              if (err instanceof UserRejectedRequestError || err.code === 4001)
                return message.info({
                  content: <Transaction type="reject" hash={transactionHash} />,
                  icon: <></>,
                  duration: ALERT_TIME,
                });
              else return messageCustom.error("Transaction Error");
            }
          });
        setShowModalClaim(false);
      } else {
        message.error(messageSignature);
      }
    } catch (error) {
      setLoading(false);
      return message.info({
        content: <ErrorSystem />,
        icon: <></>,
        duration: ALERT_TIME,
      });
    }
  };

  const joinProject = async () => {
    const signature = await signMessage(JOIN_AIRDROP_MESSAGE, address);
    const params = {
      projectId: projectId,
      address: address,
      signature: signature,
    };
    const result = await UserServiceAPI.joinAirdrop(params);
    const data = _.get(result, "data.data", {});
    if (data) {
      setJoinStatus(STATUS_JOIN_POOL.JOINED);
      joinAirDrop();
      messageCustom.success("Joined successfully!");
    } else {
      messageCustom.error("Joined error!");
    }
  };

  useEffect(() => {
    if (address && statusDeploy === 2)
      checkJoinAndClaimProject(projectId, address);
    return () => {
      setJoinStatus(STATUS_JOIN_POOL.NOT_JOIN);
    };
  }, [address, statusDeploy, projectId]);

  useEffect(() => {
    setShowModalClaim(false);
  }, [isConnectNetwork === false]);

  useEffect(() => {
    getSignature(poolDetail?.id);
  }, [token, isConnectNetwork, status, poolDetail?.id]);

  useEffect(() => {
    getCheckWhiteList(
      projectId,
      projectType,
      isConnectMetaMark,
      isConnectNetwork
    );
  }, [isConnectMetaMark, isConnectNetwork, projectType, projectId]);
  const renderWrapperButtonPublic = () => (
    <>
      {checkShowButtonJoin() && (
        <Button
          onClick={joinProject}
          disabled={
            (!isWhiteList && (!isEnoghDelegateStaking || !isEnoghGRDN)) ||
            isJoinProject ||
            status !==
              DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.APPLICATION ||
            isNotKYC ||
            isForbiddenCountries
          }
          className={`
            ${
              status ===
                DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.APPLICATION &&
              !isJoinProject &&
              !isWhiteList &&
              styles.DetailProject__YourStaking__ButtonEnable
            }
            ${
              (status ===
                DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_APPLICATION ||
                isJoinProject) &&
              styles.DetailProject__YourStaking__ButtonDisable
            }`}
        >
          {isJoinProject ? t("Joined") : t("Airdrop.Detail.Join")}
        </Button>
      )}
      {checkShowButtonClaimPublic() &&
        isCheckJoinAndClaim &&
        !isClaim &&
        new BigNumber(amountReward).isGreaterThan(0) &&
        isJoinProject && (
          <Button
            onClick={() => setShowModalClaim(true)}
            disabled={
              !isEnoghDelegateStaking ||
              // !isEnoghGRDN ||
              !(status >= DETAIL_PROJECT_STATUS.DISTRIBUTION) ||
              !amountReward ||
              new BigNumber(amountReward).lte(0)
            }
            className={`
          ${
            status >= DETAIL_PROJECT_STATUS.DISTRIBUTION
              ? styles.DetailProject__YourStaking__ButtonEnable
              : styles.DetailProject__YourStaking__ButtonDisable
          }`}
            loading={loading}
          >
            {t("Claim")}
          </Button>
        )}
    </>
  );

  const renderWrapperButtonPrivate = () => {
    let disabled = true;
    if (distributionMethod === AIRDROP_CLAIM_TYPE.FIXED_AMOUNT && isClaim) {
      if (status > DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT.AWAITING_CLAIM) {
        disabled = false;
      }
    } else if (
      status > DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.AWAITING_CLAIM
    ) {
      disabled = false;
    }
    if (!amountReward || new BigNumber(amountReward).lte(0)) disabled = true;
    if (!isEnoghDelegateStaking) disabled = true;
    return (
      <>
        {checkShowButtonClaimPrivate() &&
          isCheckJoinAndClaim &&
          !isClaim &&
          new BigNumber(amountReward).isGreaterThan(0) && (
            <Button
              onClick={() => setShowModalClaim(true)}
              disabled={
                !isEnoghDelegateStaking ||
                // || !isEnoghGRDN
                disabled
              }
              className={`
          ${
            !disabled
              ? styles.DetailProject__YourStaking__ButtonEnable
              : styles.DetailProject__YourStaking__ButtonDisable
          }`}
              loading={loading}
            >
              {t("Claim")}
            </Button>
          )}
      </>
    );
  };
  const renderYourReward = () => {
    let yourReward = t("Airdrop.YourReward.Default");

    if (isConnectMetaMark) {
      if (
        checkRenderPublicLinearAndQuadratic(projectType, distributionMethod) &&
        isJoinProject
      ) {
        switch (status) {
          case DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.APPLICATION:
          case DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_RESULTS:
            yourReward = t("Airdrop.Tba");
            break;
          case DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.AWAITING_CLAIM:
            yourReward = `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          case DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.CLAIMING:
          case DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC.COMPLETED:
            yourReward = isClaim
              ? `${t("Airdrop.YourReward.Zero")} ${tokenSymbol}`
              : `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          default:
            yourReward = t("Airdrop.YourReward.Default");
            break;
        }
      } else if (
        checkRenderPublicFixedAmount(projectType, distributionMethod) &&
        isJoinProject
      ) {
        switch (status) {
          case DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT.APPLICATION:
            yourReward = t("Airdrop.Tba");
            break;
          case DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT.AWAITING_CLAIM:
            yourReward = `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          case DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT.CLAIMING:
          case DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT.COMPLETED:
            yourReward = isClaim
              ? `${t("Airdrop.YourReward.Zero")} ${tokenSymbol}`
              : `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          default:
            yourReward = t("Airdrop.YourReward.Default");
            break;
        }
      } else if (
        checkRenderPrivateLinearAndQuadratic(projectType, distributionMethod)
      ) {
        switch (status) {
          case DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.AWAITING_RESULTS:
            yourReward = t("Airdrop.Tba");
            break;
          case DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.AWAITING_CLAIM:
            yourReward =
              !isJoinProject && !isWhiteList
                ? t("Airdrop.YourReward.Default")
                : `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          case DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.CLAIMING:
          case DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC.COMPLETED:
            yourReward =
              !isJoinProject && !isWhiteList
                ? t("Airdrop.YourReward.Default")
                : isClaim
                ? `${t("Airdrop.YourReward.Zero")} ${tokenSymbol}`
                : `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          default:
            yourReward = t("Airdrop.YourReward.Default");
            break;
        }
      } else if (
        checkRenderPrivateFixedAmount(projectType, distributionMethod)
      ) {
        switch (status) {
          case DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT.AWAITING_CLAIM:
            yourReward =
              !isJoinProject && !isWhiteList
                ? t("Airdrop.YourReward.Default")
                : `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          case DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT.CLAIMING:
          case DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT.COMPLETED:
            yourReward =
              !isJoinProject && !isWhiteList
                ? t("Airdrop.YourReward.Default")
                : isClaim
                ? `${t("Airdrop.YourReward.Zero")} ${tokenSymbol}`
                : `${formatBigNumber(amountReward)} ${tokenSymbol}`;
            break;
          default:
            yourReward = t("Airdrop.YourReward.Default");
            break;
        }
      }
    }
    return yourReward;
  };

  return (
    <div className={styles.DetailProject__YourStaking}>
      <>
        <div className={styles.DetailProject__YourStaking__Top}>
          <div className={styles.DetailProject__YourStaking__Card}>
            <span className={styles.DetailProject__YourStaking__Card__Title}>
              <div
                className={
                  styles.DetailProject__YourStaking__Card__Title__Label
                }
              />
              <span>{t("YourReward")}</span>
            </span>
            <span className={styles.DetailProject__YourStaking__Card__Value}>
              {renderYourReward()}
            </span>
          </div>
        </div>
        <div className={styles.DetailProject__YourStaking__WrapperButton}>
          {checkRenderWrapperButtonPublic() && renderWrapperButtonPublic()}
          {checkShowButtonClaimPrivate() && renderWrapperButtonPrivate()}
        </div>
      </>
      <Modal
        title=""
        visible={istShowModalClaim}
        footer={null}
        className="ModalCustom"
        onCancel={() => setShowModalClaim(false)}
        closeIcon={<IconClose />}
        data-theme={isDarkMode ? "dark" : "light"}
      >
        <AirdropClaim
          tokenSymbol={tokenSymbol}
          yourReward={amountReward}
          handleClaim={handleClaim}
        />
      </Modal>
    </div>
  );
};

export default YourReward;
