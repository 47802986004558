import BigNumber from "bignumber.js";
import {
  ACCEPTED_CURRENCIES,
  DECIMAL_ACCEPTED_CURRENCY,
  IDO_STATUS,
  SALES_TYPES,
} from "common/constants";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IDOType } from "../types/IDOType";

interface PropsType {
  pool: Partial<IDOType>;
}

const useValueFunc = ({ pool }: PropsType) => {
  const { userInfo } = useSelector((state) => state.wallet);
  const soldCoin = new BigNumber(pool?.total_deposit || 0)
    .div(
      new BigNumber(10).pow(
        DECIMAL_ACCEPTED_CURRENCY[pool.accepted_currency || 1]
      )
    )
    .multipliedBy(Number(pool.exchange_rate));

  const now = useMemo(
    () => Math.floor(Date.now() / 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pool, userInfo?.wallet_address]
  );

  return {
    soldCoin,
    soldRate: Number(
      soldCoin.div(pool?.total_sold_coin || 0).multipliedBy(100)
    ),
    timeApplicationEnd:
      (pool?.end_time_application &&
        Math.max(Number(pool?.end_time_application) - now, 0)) ||
      0,
    timeApplicationStart:
      (pool?.start_time_application &&
        Math.max(Number(pool?.start_time_application) - now, 0)) ||
      0,
    timeDepositEnd:
      (pool?.end_time_deposit &&
        Math.max(Number(pool?.end_time_deposit) - now, 0)) ||
      0,
    totalSoldCoin: pool?.total_sold_coin,
    tokenSymbol: pool?.token_symbol,
    exchangeRate: pool?.exchange_rate,
    acceptedCurrency: pool?.accepted_currency || ACCEPTED_CURRENCIES.USDC,
    description: pool?.description,
    status: Number(pool?.status || 0) || IDO_STATUS.UPCOMING,
    timeDepositStart:
      (pool?.start_time_deposit &&
        Math.max(Number(pool?.start_time_deposit) - now, 0)) ||
      0,
    poolAddress: pool?.pool_address,
    maxDeposit: pool?.max_deposit,
    timeClaimStart:
      (pool?.claim_configs?.[0]?.start_time &&
        Math.max(Number(pool?.claim_configs?.[0]?.start_time) - now, 0)) ||
      0,
    timePublicSellStart:
      (pool?.start_time_public_sell &&
        Math.max(Number(pool?.start_time_public_sell) - now, 0)) ||
      0,
    timePublicSellEnd:
      (pool?.end_time_public_sell &&
        Math.max(Number(pool?.end_time_public_sell) - now, 0)) ||
      0,
    totalDeposit: pool?.total_deposit,
    remainingToken: pool?.remaining_token,
    claimConfig: pool?.claim_configs || [],
    saleTypes: pool?.sale_type || SALES_TYPES.EQUAL_SALE,
    maxParticipant: pool?.max_participant,
    receivedFeeWallet: pool?.received_fee_wallet,
    statusDeploy: pool?.status_deploy,
    tokenDecimal: pool?.token_decimal,
    id: pool?.id,
    minGrdn: pool?.min_grdn,
  };
};

export default useValueFunc;
