import styles from "./ProjectDetailItem.module.scss";
import images from "../../themes/images";
import StatusItem from "./StatusItem";
import { getIconUrl } from "../functions";
import { useTranslation } from "react-i18next";
import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../constants";

const HeaderProject: React.FC<any> = ({
  tokenIcon,
  tokenName,
  tokenSymbol,
  website,
  whitepaperLink,
  telegramLink,
  mediumLink,
  twitterLink,
  ListStatus,
  projectStatus,
  renderCountdown,
}) => {

  const history = useHistory();
  const { t } = useTranslation();
  const backToList = () => {
    history.push(ROUTES.AIRDROP);
  }
  return (
    <div className={styles.ProjectHeader}>
      <div className={styles.ProjectHeader__Information}>
        <div className={styles.Back} onClick={backToList}>
          <img src={images.BackIcon} alt="Back" />
          <span>Back</span>
        </div>
        <div className={styles.ProjectHeader__Information__Wrapper}>
          <div className={styles.ProjectHeader__Information__Wrapper__Token}>
            <img src={getIconUrl(tokenIcon)} alt="icon" />
            <h4>{t("Staking.Detail.Asset", { tokenName, tokenSymbol })}</h4>
          </div>
          <div
            className={styles.ProjectHeader__Information__Wrapper__ListIcon}
          >
            {website && (
              <a href={website} target="_blank" rel="noopener noreferrer">
                <img src={images.icons.Language} alt="Language" />
              </a>
            )}
            {whitepaperLink && (
              <a
                href={whitepaperLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images.icons.Book} alt="Book" />
              </a>
            )}
            {telegramLink && (
              <a
                href={telegramLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images.icons.Telegram} alt="Telegram" />
              </a>
            )}
            {mediumLink && (
              <a href={mediumLink} target="_blank" rel="noopener noreferrer">
                <img src={images.icons.BlogMe} alt="Blog Me" />
              </a>
            )}
            {twitterLink && (
              <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                <img src={images.icons.TwitterIcon} alt="Twitter Icon" />
              </a>
            )}
          </div>
        </div>
        {renderCountdown()}
      </div>
      <div className={styles.ProjectHeader__ListStatus}>
        {ListStatus.map((statusItem: any, index: number) => (
          <React.Fragment key={index}>
            <StatusItem
              statusItem={statusItem}
              currentStatus={projectStatus}
            // statusRender
            />
            {index < ListStatus.length - 1 && (
              <img
                className={styles.ProjectHeader__ListStatus__DashedLine}
                src={images.icons.DashedLine}
                alt="Dashed Line"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HeaderProject;
