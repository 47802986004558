/* eslint-disable jsx-a11y/anchor-is-valid */
import { POPUP } from '../../constants';
import './connectWallet.scss'
interface UnavailableProps { }

const Unavailable: React.FC<UnavailableProps> = () => {
  return <span>
    Unavailable extension.
    <a className="ClickHere" target="_blank"
      href={POPUP.CONNECTWALLET.METAMASK_URL} rel="noreferrer"
    >
      Click here
    </a>
    to install
  </span>;
}

export default Unavailable;