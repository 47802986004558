import React from "react";

interface IDeposit {
  deposit: string;
  string: string;
}

interface PropsType {
  dataDeposit?: IDeposit[];
  renderData: Function;
}

const MainTitle = (props: PropsType) => {
  const { dataDeposit, renderData } = props;

  
  return (
    <>
      {dataDeposit?.map((item: any) => {
        const content = [
          {
            text: item.text,
            deposit: item.deposit,
          },
        ];
        return (
          <React.Fragment key={item.text}>{renderData(content)}</React.Fragment>
        );
      })}
    </>
  );
};

export default MainTitle;
