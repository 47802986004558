/* eslint-disable react-hooks/exhaustive-deps */
import { Button, message, Modal } from "antd";
import styles from "./DetailProject.module.scss";
import { useTranslation } from "react-i18next";
import {
  PROJECT_STAKING_TYPE,
  DETAIL_PROJECT_STATUS,
  JOIN_AIRDROP_MESSAGE,
  ABI_ALLOCATION,
  HTTP_STATUS,
  AMOUNT_ZERO,
  STATUS_DEPLOY_PROJECT,
  ALERT_TIME,
  BYPASS_STATUS,
  STATUS_KYC_USER,
  STATUS_JOIN_POOL,
} from "../../../../common/constants";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import IconClose from "../../../../common/popup/iconClose";
import PopupClaimAndUnStake from "../../../../common/popup/claimAndUnstake/index";
import Stake from "../../../../common/popup/stake";
import { UserService } from "../../../../services/UserServicer";
import _ from "lodash";
import StorageUtils from "../../../../utils/storage";
import {
  formatBigNumber,
  getBalance,
  signMessage,
  toRealNumber,
} from "../../../../common/functions";
import Transaction from "../../../../common/alert/transaction";
import Web3 from "web3";
import ErrorSystem from "../../../../common/alert/errorSystem";
import BigNumber from "bignumber.js";
import messageCustom from "../../../../common/alert/message";
import { UserRejectedRequestError } from "@web3-react/injected-connector";

const YourStaking: React.FC<any> = ({
  projectId,
  status,
  tokenSymbol,
  projectContractAddress,
  amountStake,
  projectType,
  announce_time,
  statusDeploy,
  minGRDN,
  minDelegateStakingAmount,
  poolDetail,
  isWhiteList,
  setJoinStatus,
  setClaim,
  checkJoinAndClaimProject,
  isJoinProject,
  isClaim,
  isCheckJoinAndClaim,
  processCheck,
  tokenDecimal,
}) => {
  const { t } = useTranslation();
  const UserServiceAPI = new UserService();
  const [isShowModalClaimAndUnStake, setShowModalClaimAndUnStake] =
    useState<boolean>(false);
  const [isShowModalStake, setShowModalStake] = useState<boolean>(false);
  const [dataSignature, setDataSignature] = useState<any>(false);
  const rootState = useSelector((state) => state.wallet);
  const { isConnectMetaMark, isConnectNetwork, isDarkMode } = rootState;
  const token = StorageUtils.getToken();
  const amountStaking = toRealNumber(
    _.get(dataSignature, "data.amount", 0),
    tokenDecimal
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingJoinStake, setLoadingJoinStake] = useState<boolean>(false);
  const [loadingStake, setLoadingStake] = useState<boolean>(false);

  const userInfo = useSelector((state) => state.wallet.userInfo);
  const { wallet_address: address, kyc_status, kyc_country } = userInfo || {};
  const amountTokenGRDN = useSelector(({ wallet }) => wallet.amountTokenGRDN);
  const amountTotalDelegateStaking = useSelector(
    ({ wallet }) => wallet.delegateStakingAmount
  );
  const isNotKYC =
    poolDetail?.kyc_bypass === BYPASS_STATUS.REQUIRED &&
    address &&
    kyc_status !== STATUS_KYC_USER.VERIFIED;
  const isForbiddenCountries =
    kyc_country && poolDetail?.forbidden_countries?.includes(kyc_country);
  const windowObj = window as any;
  const { ethereum } = windowObj;
  const web3 = new Web3(ethereum);
  const abiAllocation = ABI_ALLOCATION;

  const isEnoghDelegateStaking = new BigNumber(
    amountTotalDelegateStaking || 0
  ).gte(minDelegateStakingAmount || 0);
  const isEnoghGRDN = new BigNumber(amountTokenGRDN).gte(minGRDN || 0);

  const checkShowButtonStaking = () => {
    return (
      status === DETAIL_PROJECT_STATUS.STAKING ||
      status === DETAIL_PROJECT_STATUS.AWAITING_STAKING
    );
  };

  const checkShowWrapperButton = () => {
    if (processCheck) return false;
    const isConnectMetaMarkAndDeploySuccess =
      isConnectMetaMark &&
      isConnectNetwork &&
      statusDeploy === STATUS_DEPLOY_PROJECT.SUCCESS;
    if (projectType === PROJECT_STAKING_TYPE.PRIVATE) {
      return isConnectMetaMarkAndDeploySuccess && isWhiteList;
    }
    return isConnectMetaMarkAndDeploySuccess;
  };

  const checkShowButtonClaimAndUnStake = () => {
    return (
      status >= DETAIL_PROJECT_STATUS.AWAITING_RESULTS &&
      status <= DETAIL_PROJECT_STATUS.COMPLETED &&
      amountStake > 0
      //  &&
      // !isClaim
    );
  };

  const checkAnnounceTime = () => {
    const now = Math.ceil(Date.now() / 1000);
    return announce_time <= now;
  };

  const showPopUpStake = () => {
    if (status === DETAIL_PROJECT_STATUS.STAKING) {
      setShowModalStake(!isShowModalStake);
      return !isShowModalStake;
    }
    return isShowModalStake;
  };

  const getSignature = async (projectId: any) => {
    if (token && projectId) {
      try {
        const result = await UserServiceAPI.getSignature(projectId);
        const dataSignature = _.get(result, "data", {});
        setDataSignature(dataSignature);
      } catch (error: any) {
        return message.info({
          content: <ErrorSystem />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
    }
  };

  const joinProject = async () => {
    setLoadingJoinStake(true);
    const signature = await signMessage(JOIN_AIRDROP_MESSAGE, address);
    const params = {
      projectId: projectId,
      address: address,
      signature: signature,
    };
    const result = await UserServiceAPI.joinStaking(params);
    const data = _.get(result, "data.data", {});

    if (data) {
      setLoadingJoinStake(false);
      setJoinStatus(STATUS_JOIN_POOL.JOINED);
      messageCustom.success("Joined successfully!");
    } else setLoadingJoinStake(false);
  };

  const handleClaimAndUnStake = async () => {
    try {
      const data = _.get(dataSignature, "data", {});
      const messageSignature = _.get(dataSignature, "message", "");
      const status = _.get(dataSignature, "status", 0);
      setLoading(true);
      if (status === HTTP_STATUS.SUCCESS && data) {
        const { amount, signature, wallet_address } = data;
        const contractAllocation = new web3.eth.Contract(
          abiAllocation,
          projectContractAddress
        );
        let transactionHash = "";
        contractAllocation.methods
          .withdrawROSE(wallet_address, amount, signature)
          .send({
            from: wallet_address,
          })
          .on("transactionHash", function (hash: any) {
            transactionHash = hash;
          })
          .on("receipt", async function (receipt: any) {
            await UserServiceAPI.updateStatusClaimStaking({ projectId });
            getBalance(address);
            setClaim(true);
            setLoading(false);
            return message.info({
              content: <Transaction type="complete" hash={transactionHash} />,
              icon: <></>,
              duration: ALERT_TIME,
            });
          })
          .on("error", function (error: any, receipt: any) {
            if (receipt) {
              setLoading(false);
              return message.info({
                content: <Transaction type="error" hash={transactionHash} />,
                icon: <></>,
                duration: ALERT_TIME,
              });
            }
          })
          .catch((err: any) => {
            setLoading(false);
            if (!transactionHash) {
              if (err instanceof UserRejectedRequestError || err.code === 4001)
                return message.info({
                  content: <Transaction type="reject" hash={transactionHash} />,
                  icon: <></>,
                  duration: ALERT_TIME,
                });
              else return messageCustom.error("Transaction Error");
            }
          });
        setShowModalClaimAndUnStake(false);
      } else {
        message.error(messageSignature);
      }
    } catch (error) {
      setLoading(false);
      return message.info({
        content: <ErrorSystem />,
        icon: <></>,
        duration: ALERT_TIME,
      });
    }
  };

  const renderYourStaking = () => {
    let result = t("Airdrop.YourReward.Default");
    if (isConnectNetwork) {
      if (amountStake === AMOUNT_ZERO && !isClaim) {
        result =
          status === DETAIL_PROJECT_STATUS.STAKING
            ? `${t("Airdrop.YourReward.Zero")} ${t("ETH")}`
            : t("Airdrop.YourReward.Default");
      } else {
        switch (status) {
          case DETAIL_PROJECT_STATUS.STAKING:
          case DETAIL_PROJECT_STATUS.AWAITING_RESULTS:
          case DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION:
          case DETAIL_PROJECT_STATUS.DISTRIBUTION:
            result = `${formatBigNumber(amountStake)} ${t("ETH")}`;
            break;
          case DETAIL_PROJECT_STATUS.COMPLETED:
            result = `${formatBigNumber(amountStake)} ${t("ETH")}`;
            break;
          default:
            result = t("Airdrop.YourReward.Default");
            break;
        }
      }
    }
    return result;
  };

  const renderYourReward = () => {
    let result = t("Airdrop.YourReward.Default");
    if (!isConnectMetaMark) return result;
    if (
      status >= DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION &&
      status <= DETAIL_PROJECT_STATUS.COMPLETED
    ) {
      result = isClaim
        ? `${t("Airdrop.YourReward.Zero")} ${tokenSymbol}`
        : ` ${formatBigNumber(amountStaking)} ${tokenSymbol}`;
    }
    return result;
  };

  useEffect(() => {
    if (address && statusDeploy === 2) checkJoinAndClaimProject(projectId);
    return () => {
      setJoinStatus(STATUS_JOIN_POOL.NOT_JOIN);
    };
  }, [address, statusDeploy, projectId]);

  useEffect(() => {
    setShowModalClaimAndUnStake(false);
    setShowModalStake(false);
  }, [isConnectNetwork === false]);

  useEffect(() => {
    getSignature(poolDetail?.id);
  }, [token, isConnectNetwork, checkAnnounceTime(), poolDetail?.id]);

  const isZeroAmount =
    new BigNumber(amountStake).isEqualTo(0) &&
    new BigNumber(amountStaking).isEqualTo(0);

  const renderWrapperButton = () => (
    <>
      {checkShowButtonStaking() &&
        isCheckJoinAndClaim &&
        (isJoinProject || isWhiteList ? (
          <Button
            onClick={showPopUpStake}
            disabled={
              (!isWhiteList && (!isEnoghDelegateStaking || !isEnoghGRDN)) ||
              !(status === DETAIL_PROJECT_STATUS.STAKING) ||
              isForbiddenCountries ||
              isNotKYC ||
              !isEnoghDelegateStaking ||
              !isEnoghGRDN
            }
            className={`
            ${
              status === DETAIL_PROJECT_STATUS.STAKING &&
              styles.DetailProject__YourStaking__ButtonEnable
            }
            ${
              status === DETAIL_PROJECT_STATUS.AWAITING_STAKING &&
              styles.DetailProject__YourStaking__ButtonDisable
            }`}
            loading={loadingStake}
          >
            {t("Stake")}
          </Button>
        ) : (
          <Button
            onClick={joinProject}
            disabled={
              !isEnoghDelegateStaking ||
              !isEnoghGRDN ||
              status !== DETAIL_PROJECT_STATUS.STAKING ||
              isForbiddenCountries ||
              isNotKYC
            }
            className={`
              ${
                status === DETAIL_PROJECT_STATUS.STAKING &&
                styles.DetailProject__YourStaking__ButtonEnable
              }
              ${
                status === DETAIL_PROJECT_STATUS.AWAITING_STAKING &&
                styles.DetailProject__YourStaking__ButtonDisable
              }`}
            loading={loadingJoinStake}
          >
            {t("Staking.Detail.Join")}
          </Button>
        ))}
      {checkShowButtonClaimAndUnStake() && isCheckJoinAndClaim && (
        <Button
          onClick={() => setShowModalClaimAndUnStake(true)}
          disabled={
            !isEnoghDelegateStaking ||
            // !isEnoghGRDN ||
            isZeroAmount ||
            (status >= DETAIL_PROJECT_STATUS.AWAITING_RESULTS &&
            status <= DETAIL_PROJECT_STATUS.AWAITING_DISTRIBUTION
              ? true
              : false)
          }
          className={`
          ${
            styles.DetailProject__YourStaking__ButtonEnable
            // status >= DETAIL_PROJECT_STATUS.DISTRIBUTION && !isClaim
            //   ? styles.DetailProject__YourStaking__ButtonEnable
            //   : styles.DetailProject__YourStaking__ButtonDisable
          }`}
          loading={loading}
        >
          {t("ClaimAndUnStake")}
        </Button>
      )}
    </>
  );

  const renderCard = (title: any, value: any) => (
    <div className={styles.DetailProject__YourStaking__Card}>
      <span className={styles.DetailProject__YourStaking__Card__Title}>
        <div
          className={styles.DetailProject__YourStaking__Card__Title__Label}
        />
        <span>{title}</span>
      </span>
      <span className={styles.DetailProject__YourStaking__Card__Value}>
        {value}
      </span>
    </div>
  );
  return (
    <div className={styles.DetailProject__YourStaking}>
      <Stake
        close={() => setShowModalStake(false)}
        isShow={isShowModalStake}
        getBalance={getBalance}
        setLoadingStake={setLoadingStake}
      />
      <div className={styles.DetailProject__YourStaking__Top}>
        {renderCard(t("YourStaking"), renderYourStaking())}
        {checkAnnounceTime() && renderCard(t("YourReward"), renderYourReward())}
      </div>
      <div className={styles.DetailProject__YourStaking__WrapperButton}>
        {checkShowWrapperButton() ? renderWrapperButton() : <div />}
      </div>
      <Modal
        title=""
        visible={isShowModalClaimAndUnStake}
        footer={null}
        className="ModalCustom"
        onCancel={() => setShowModalClaimAndUnStake(false)}
        closeIcon={<IconClose />}
        data-theme={isDarkMode ? "dark" : "light"}
      >
        <PopupClaimAndUnStake
          tokenSymbol={tokenSymbol}
          amountStake={amountStake}
          amountStaking={amountStaking}
          handleClaimAndUnStake={handleClaimAndUnStake}
        />
      </Modal>
    </div>
  );
};

export default YourStaking;
