import BigNumber from "bignumber.js";
import IDOnotification from "components/HeaderNotification/IDOnotification";
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setIsWhiteList } from "stores/ido";
import messageCustom from "../../../../common/alert/message";
import {
  ACCEPTED_CURRENCIES,
  FEE_TOKENS,
  IDO_POOL_DISPLAY,
  IDO_POOL_TYPES,
  PRIVATE_SETTINGS,
  ROUTES,
  SALES_TYPES,
} from "../../../../common/constants";
import { IDOResponseType, IDOType } from "../../../../common/types/IDOType";
import { UserService } from "../../../../services/UserServicer";
import DepositAmount from "./components/deposistAmount";
import IDODetailHeader from "./components/IDODetailHeader";
import styles from "./index.module.scss";

export const StakeContext = createContext({});

const DetailIDO = () => {
  const { id } = useParams<any>();
  const UserServiceAPI = new UserService();
  const { userInfo } = useSelector((state) => state.wallet);
  const { isWhitelist, joinIdo } = useSelector((state) => state.ido);
  const [dataDetail, setDataDetail] = useState<Partial<IDOType>>({});
  const history = useHistory();
  const dispatch = useDispatch();
  // const [isWhiteList, setIsWhiteList] = useState<boolean>(false)

  const mappingIDOPool = (data: Partial<IDOResponseType>) => {
    let forbidden_countries: string[] = [];
    try {
      const array =
        typeof data.forbidden_countries === "string" &&
        JSON.parse(data.forbidden_countries);
      if (typeof array === "object" && array?.length)
        forbidden_countries = array;
      else forbidden_countries = [];
    } catch {}
    const dataMapping: Partial<IDOType> = {
      id: data.id,
      is_display: data.is_display === IDO_POOL_DISPLAY.DISPLAY,
      kyc_bypass: data.kyc_bypass,
      forbidden_countries: forbidden_countries,

      pool_banner: data.pool_banner || "",
      min_grdn: data.min_grdn || "",
      minimum_delegate_staking_amount:
        data.minimum_delegate_staking_amount || "",
      fee_token: data.fee_token || FEE_TOKENS.PROJECT_TOKEN,
      fee: data.fee,
      received_fee_wallet: data.received_fee_wallet || "",
      pool_address: data.pool_address || "",
      pool_name: data.pool_name || "",
      website: data.website || "",
      whitepaper_link: data.whitepaper_link || "",
      telegram_link: data.telegram_link || "",
      medium_link: data.medium_link || "",
      twitter_link: data.twitter_link || "",

      token_address: data.token_address || "",
      total_sold_coin: data.total_sold_coin || "",
      token_symbol: data.token_symbol || "",
      token_icon: data.token_icon || "",
      address_receive_rose: data.address_receive_rose || "",
      token_decimal: data.token_decimal || 18,

      pool_label: data.pool_label || IDO_POOL_TYPES.PUBLIC,
      private_setting: data.private_setting || PRIVATE_SETTINGS.NORMAL,
      accepted_currency: data.accepted_currency || ACCEPTED_CURRENCIES.USDC,
      sale_type: data.sale_type || SALES_TYPES.EQUAL_SALE,
      max_deposit: data.max_deposit,
      max_participant: data.max_participant,
      exchange_rate: data.exchange_rate,
      claim_policy: data.claim_policy,
      claim_configs: (data.claim_configs || []).sort(
        (a, b) => a.start_time - b.start_time
      ),
      remaining_token: data.remaining_token,
      start_time_public_sell: data.start_time_public_sell,
      end_time_public_sell: data.end_time_public_sell,
      description: data.description,
      status: data.status,
      status_deploy: data.status_deploy,
      start_time_deposit: data.start_time_deposit,
      end_time_deposit: data.end_time_deposit,
      start_time_application: data.start_time_application,
      end_time_application: data.end_time_application,
      total_deposit: data.total_deposit || "",
      is_priority: data.is_priority,
      is_display_participant:
        data.is_display_participant === IDO_POOL_DISPLAY.DISPLAY ? 1 : 0,
      ps_update_status: data.ps_update_status,
      claim_time: (data.claim_configs || [])
        .sort((a, b) => a.start_time - b.start_time)?.[0]
        ?.start_time?.toString(),
    };
    return dataMapping;
  };
  const getDetailProject = async () => {
    try {
      const params = {
        id,
        wallet: userInfo?.wallet_address || null,
      };
      const result = await UserServiceAPI.getIDODetail(params);
      const { data } = result?.data;
      if (!data) {
        history.push(ROUTES.PAGE_NOT_FOUND);
        return;
      }
      if (data.private_setting === 2) {
        dispatch(setIsWhiteList(true));
      } else {
        dispatch(setIsWhiteList(false));
      }
      const dataDetail: Partial<IDOResponseType> = result?.data?.data || {};
      setDataDetail(mappingIDOPool(dataDetail));
    } catch (error: any) {
      return messageCustom.error(error?.message || "Pool not found!");
    }
  };
  useEffect(() => {
    getDetailProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinishCountdown = () => {
    setTimeout(() => {
      getDetailProject();
    }, 5000);
  };

  const onDepositSuccess = (value: string) => {
    setDataDetail({
      ...dataDetail,
      total_deposit: new BigNumber(dataDetail.total_deposit || 0)
        .plus(value)
        .toString(),
    });
  };

  return (
    <div className={styles.DetailIDO}>
      <IDOnotification
        pool={dataDetail}
        joinIdo={joinIdo}
        isWhitelist={isWhitelist}
      />
      <IDODetailHeader pool={dataDetail} />
      <DepositAmount
        onDepositSuccess={onDepositSuccess}
        pool={dataDetail}
        onFinish={onFinishCountdown}
      />
    </div>
  );
};

export default DetailIDO;
