import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { formatBigNumber, formatNumber } from "../../functions";
import "./claimAndUnstake.scss";

interface ClaimAndUnStakeProps {
  tokenSymbol: string;
  handleClaimAndUnStake: () => void;
  amountStake?: string;

  amountStaking: string;
}

const ClaimAndUnStake: React.FC<ClaimAndUnStakeProps> = ({
  tokenSymbol,
  amountStake,

  amountStaking,
  handleClaimAndUnStake,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ClaimAndUnstake">
      <div className="ClaimAndUnstake__Label">
        <div className="ClaimAndUnstake__Label__Text">
          {t("Popup.ClaimAndUnStake")}
        </div>
      </div>
      <div className="ClaimAndUnstake__Infor">
        <ul>
          <li>
            <div className="ClaimAndUnstake__Infor__Title">
              <label className="ClaimAndUnstake__Infor__Title__Label"></label>
              <span className="ClaimAndUnstake__Infor__Title__Name">
                {t("StakeAsset")}
              </span>
            </div>
            <span>{t("ETH")}</span>
          </li>
          <li>
            <div className="ClaimAndUnstake__Infor__Title">
              <label className="ClaimAndUnstake__Infor__Title__Label"></label>
              <span className="ClaimAndUnstake__Infor__Title__Name">
                {t("YourStaking")}
              </span>
            </div>
            <span>{formatNumber(amountStake || 0, true)}</span>
          </li>
          <li>
            <div className="ClaimAndUnstake__Infor__Title">
              <label className="ClaimAndUnstake__Infor__Title__Label"></label>
              <span className="ClaimAndUnstake__Infor__Title__Name">
                {t("RewardAsset")}
              </span>
            </div>
            <span>{tokenSymbol}</span>
          </li>
          <li>
            <div className="ClaimAndUnstake__Infor__Title">
              <label className="ClaimAndUnstake__Infor__Title__Label"></label>
              <span className="ClaimAndUnstake__Infor__Title__Name">
                {t("YourReward")}
              </span>
            </div>
            <span>{formatBigNumber(amountStaking)}</span>
          </li>
        </ul>
      </div>
      <Button
        className="ClaimAndUnstake__Button"
        type="primary"
        onClick={handleClaimAndUnStake}
        block={true}
      >
        {t("Popup.ClaimAndUnStake")}
      </Button>
    </div>
  );
};

export default ClaimAndUnStake;
