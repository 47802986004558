import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import images from "../../../themes/images";
import "./errorSystem.scss";

interface ErrorSystemProps {

}

const ErrorSystem: React.FC<ErrorSystemProps> = () => {
  const { t } = useTranslation();
  const Content = () => {
    return (
      <span className="ErrorSystem">
        <img src={images.icons.TransactionError} alt="TransactionReject"></img>
        {t("ErrorSystem")}
      </span>
    )
  }

  return (
    <Alert
      className="ErrorSystem"
      message={<Content />}
      closable={true}
      data-type="error"
    />
  );
}

export default ErrorSystem;