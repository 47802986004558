import { useTranslation } from 'react-i18next';
import { Button } from "antd";
import './AirdropClaim.scss';
import { formatBigNumber } from '../../functions';

interface AirdropClaimProps {
  handleClaim: () => void;
  tokenSymbol: string;
  yourReward: any;
}

const AirdropClaim: React.FC<AirdropClaimProps> = ({
  tokenSymbol,
  yourReward,
  handleClaim,
}) => {
  const { t } = useTranslation();

  return (
    <div className="AirdropClaim">
      <div className="AirdropClaim__Label">{t('Claim')}</div>
      <div className="AirdropClaim__Content">
        <div>
          <span className="AirdropClaim__Content__Title">
            <label />
            {t('RewardAsset')}
          </span>
          <span className="AirdropClaim__Content__Value">
            {tokenSymbol}
          </span>
        </div>
        <div>
          <span className="AirdropClaim__Content__Title">
            <label />
            {t('YourReward')}
          </span>
          <span className="AirdropClaim__Content__Value">
            {formatBigNumber(yourReward)}
          </span>
        </div>
      </div>
      <Button
        className=""
        type="primary"
        block={true}
        onClick={handleClaim}
      >
        {t("Claim")}
      </Button>
    </div>
  )
}

export default AirdropClaim;