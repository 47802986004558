import images from "../themes/images";
import {
  renderIconAirDrop,
  renderIconIDO,
  renderIconProfile,
  renderIconStaking,
} from "./renderSvgs";

export const KEY = {
  USER: "USER",
  TOKEN_ACCESS: "TOKEN_ACCESS",
  SIGNATURE: "SIGNATURE",
};

export const POPUP = {
  CONNECTWALLET: {
    STATUS: {
      UNINSTALL: "uninstall",
      ERROR: "error",
    },
    METAMASK_URL: "https://metamask.io/",
  },
};

export const ROUTES = {
  HOME: "/",
  STAKING: "/staking",
  AIRDROP: "/airdrop",
  IDOPOOL: "/ido-pool",
  STAKING_DETAIL: "/staking/:id",
  AIRDROP_DETAIL: "/airdrop/:id",
  IDOPOOL_DETAIL: "/ido-pool/:id",
  MYPROFILE: "/profile",
  PAGE_NOT_FOUND: "/*",
};

export const SIDER = {
  STAKING: "Staking",
  AIRDROP: "Airdrop",
  IDOPOOL: "IDO Pool",
  BUY_ROSE: "Buy Rose",
  MYPROFILE: "My Profile",
  LAUNCH_GARDEN_GITHUB: "LaunchGarden Github",
};

export const MENUS = [
  { lable: SIDER.IDOPOOL, href: ROUTES.IDOPOOL, icon: renderIconIDO },
  { lable: SIDER.STAKING, href: ROUTES.STAKING, icon: renderIconStaking },
  { lable: SIDER.AIRDROP, href: ROUTES.AIRDROP, icon: renderIconAirDrop },
  { lable: SIDER.MYPROFILE, href: ROUTES.MYPROFILE, icon: renderIconProfile },
];

export const AIRDROP_STATUS = {
  AWAITING_APPLICATION: 1,
  APPLICATION: 2,
  AWAITING_RESULTS: 3,
  AWAITING_CLAIM: 4,
  CLAIMING: 5,
  COMPLETED: 6,
};

// Oasis Network
export const NETWORK = {
  CHAIN_ID_HEX: process.env.REACT_APP_CHAIN_ID_HEX,
  CHAIN_ID_DECIMAL: process.env.REACT_APP_CHAIN_ID_DECIMAL,
  CHAIN_NAME: process.env.REACT_APP_CHAIN_NAME,
  RPC_URLS: process.env.REACT_APP_RPC_URLS,
  BLOCK_EXPLORER_URLS: process.env.REACT_APP_BLOCK_EXPLORER_URLS,
  NATIVE_CURRENCY: {
    NAME: process.env.REACT_APP_NATIVE_CURRENCY_NAMEF,
    SYMBOL: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL,
    DECIMAL: process.env.REACT_APP_NATIVE_CURRENCY_DECIMAL,
  },
};

export const DECIMAL = 18;
export const DECIMAL_6 = 6;

// Rinkeby Test Network
// export const NETWORK = {
//   CHAIN_ID_HEX: "0x4",
//   CHAIN_ID_DECIMAL: "4",
//   CHAIN_NAME: "Rinkeby Test Network",
//   RPC_URLS: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
//   BLOCK_EXPLORER_URLS: "https://rinkeby.etherscan.io/tx/",
//   NATIVE_CURRENCY: {
//     NAME: "ETH",
//     SYMBOL: "ETH",
//     DECIMAL: 18,
//   },
// }

export const EMERAL_ID =
  "00000000000000000000000000000000000000000000000072c8215e60d5bca7";

export const URL_SCAN_TRANSACTION =
  process.env.REACT_APP_URL_SCAN_TRANSACTION ||
  "https://testnet.oasisscan.com/paratimes/transactions";

export const PROJECT_STAKING_TYPE = {
  PRIVATE: 1,
  PUBLIC: 2,
  HYBRID: 3,
};

export const PROJECT_AIRDROP_TYPE = {
  PRIVATE: 1,
  PUBLIC: 2,
};

export const AIRDROP_CLAIM_TYPE = {
  LINEAR: 1,
  QUADRATIC: 2,
  FIXED_AMOUNT: 3,
};

export const BUY_TYPE = {
  STAKING: 1,
  AIRDROP: 2,
};

export const DETAIL_STAKING_STATUS = [
  { status: 1, label: "ToolbarUser.Filter.AwaitingStaking" },
  { status: 2, label: "ToolbarUser.Filter.Staking" },
  { status: 3, label: "ToolbarUser.Filter.AwaitingResults" },
  { status: 4, label: "ToolbarUser.Filter.AwaitingClaim" },
  { status: 5, label: "ToolbarUser.Filter.Claiming" },
  { status: 6, label: "ToolbarUser.Filter.Completed" },
];
export const DETAIL_IDO_STATUS = [
  { status: 1, label: "ToolbarUser.Filter.UpComing" },
  { status: 2, label: "ToolbarUser.Filter.Deposit" },
  { status: 3, label: "ToolbarUser.Filter.PublicSell" },
  { status: 4, label: "ToolbarUser.Filter.Claimable" },
  { status: 5, label: "ToolbarUser.Filter.Ended" },
];

export const DETAIL_PROJECT_IDO_STATUS = {
  UP_COMMING: 1,
  DEPOSIT: 2,
  PUBLICSELL: 3,
  CLAIMABLE: 4,
  ENDED: 5,
};

export const DETAIL_PROJECT_STATUS = {
  AWAITING_STAKING: 1,
  STAKING: 2,
  AWAITING_RESULTS: 3,
  AWAITING_DISTRIBUTION: 4,
  DISTRIBUTION: 5,
  COMPLETED: 6,
};

export const DETAIL_PROJECT_STATUS_AIRDROP_PUBLIC_LINEAR_AND_QUADRATIC = {
  AWAITING_APPLICATION: 1,
  APPLICATION: 2,
  AWAITING_RESULTS: 3,
  AWAITING_CLAIM: 4,
  CLAIMING: 5,
  COMPLETED: 6,
};

export const DETAIL_PROJECT_STATUS_AIRDROP_FIXED_AMOUNT = {
  AWAITING_APPLICATION: 1,
  APPLICATION: 2,
  AWAITING_CLAIM: 4,
  CLAIMING: 5,
  COMPLETED: 6,
};

export const DETAIL_PROJECT_STATUS_PRIVATE_FIXED_AMOUNT = {
  AWAITING_CLAIM: 4,
  CLAIMING: 5,
  COMPLETED: 6,
};

export const DETAIL_PROJECT_STATUS_PRIVATE_LINEAR_AND_QUADRATIC = {
  AWAITING_RESULTS: 3,
  AWAITING_CLAIM: 4,
  CLAIMING: 5,
  COMPLETED: 6,
};

export const PROJECT_TYPE = {
  STAKING: 1,
  AIRDROP: 2,
};

export const HTTP_STATUS = {
  SUCCESS: 200,
};

export const ABI_ALLOCATION = require("../abi/Allocation.json");

export const ABI_ERC20 = require("../abi/ERC20.json");

export const NUMBER_ITEM_LIST = {
  TABLE: 8,
  GRID: 6,
};
export const APPROVE_MAX_VALUE =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const METAMASK_SIGN_MESSAGE =
  "LaunchGarden uses this cryptographic signature to verify that you are the owner of this wallet";

export const JOIN_AIRDROP_MESSAGE =
  "Oasis launchpad uses this cryptographic signature to verify that you are the owner of this wallet";

// aridrop

export const AIRDROP_TYPE = {
  PRIVATE: 1,
  PUBLIC: 2,
};

export const FORMAT_TIME_TYPE = {
  DAY: "D day",
  DAYS: "D [days] HH [hours]",
  HOURS: "HH[h] : mm[m]",
  MINUTES: "mm[m] : ss[s]",
};
export const IDO_LIST_FORMAT_TIME_TYPE = {
  DAYS: "D [days] : HH [hours]",
  HOURS: "HH[h] : mm[m]",
  MINUTES: "mm[m] : ss[s]",
};

export const AIRDROP_STATUS_PUBLIC_LINEAR_AND_QUADRATIC = [
  { status: 1, label: "ToolbarUser.Filter.AwaitingApplication" },
  { status: 2, label: "ToolbarUser.Filter.Application" },
  { status: 3, label: "ToolbarUser.Filter.AwaitingResults" },
  { status: 4, label: "ToolbarUser.Filter.AwaitingClaim" },
  { status: 5, label: "ToolbarUser.Filter.Claiming" },
  { status: 6, label: "ToolbarUser.Filter.Completed" },
];

export const AIRDROP_STATUS_PUBLIC_FIXED_AMOUNT = [
  {
    statusRender: 1,
    status: 1,
    label: "ToolbarUser.Filter.AwaitingApplication",
  },
  { statusRender: 2, status: 2, label: "ToolbarUser.Filter.Application" },
  { statusRender: 3, status: 4, label: "ToolbarUser.Filter.AwaitingClaim" },
  { statusRender: 4, status: 5, label: "ToolbarUser.Filter.Claiming" },
  { statusRender: 5, status: 6, label: "ToolbarUser.Filter.Completed" },
];

export const AIRDROP_STATUS_PRIVATE_LINEAR_AND_QUADRATIC = [
  { statusRender: 1, status: 3, label: "ToolbarUser.Filter.AwaitingResults" },
  { statusRender: 2, status: 4, label: "ToolbarUser.Filter.AwaitingClaim" },
  { statusRender: 3, status: 5, label: "ToolbarUser.Filter.Claiming" },
  { statusRender: 4, status: 6, label: "ToolbarUser.Filter.Completed" },
];

export const AIRDROP_STATUS_PRIVATE_FIXED_AMOUNT = [
  { statusRender: 1, status: 4, label: "ToolbarUser.Filter.AwaitingClaim" },
  { statusRender: 2, status: 5, label: "ToolbarUser.Filter.Claiming" },
  { statusRender: 3, status: 6, label: "ToolbarUser.Filter.Completed" },
];

export const TOOLTIPS_TYPE = {
  ACCESS_TYPE: "Access Type",
  TRIGGER_TERM: "Trigger term",
  UNDER_TRIGGER_TERM: "Under trigger term",
  MINIMUM_GRDN: "Minimum GRDN tokens required",
};

export const HYPER_LINK = {
  BUY_ROSE: "https://yuzu-swap.com/",
  OASIS_COMMUNITY_HUB: "https://oasisprotocolhub.org/",
  OASIS_NETWORK_GITHUB: "https://github.com/oasisprotocol",
  LAUNCH_GARDEN_GITHUB: "",
};

export const SOCIAL_MEDIA_LINK = {
  PROJECT_TWITTER: "https://twitter.com/launch_garden",
  PROJECT_DISCORD: "https://discord.gg/gMetA43zcz",
  PROJECT_MEDIUM: "https://medium.com/@LaunchGarden",
  PROJECT_TELEGRAM: "",
  PROJECT_CREW3: "",
};

export const STATUS_DEPLOY_PROJECT = {
  SUCCESS: 2,
};

export const ROUND_UP_ONE = 1;

export const PRECISION = 2;

export const NUMBER_OF_DECIMAL = 2;

export const UN_CHECK_ALL = "Un check all";

export const CHECK_ALL = 6;

export const RATE = "Rate:";

export const plainOptions: any = [1, 2, 3, 4, 5, 6];

export const defaultMyProjectStaking = { isActiveStaking: false, registed: "" };
export const defaultMyProjectAirdrop = { isActiveAirdrop: false, registed: "" };
export const defaultMyProjectIDO = { isActiveIDO: false, registed: "" };

export const AMOUNT_ZERO = 0;

export const ALERT_TIME = 7;
export const TIME_OUT_INTERVAL = 30000;

export const STATUS_KYC_USER = {
  UNVERIFIED: 0,
  PENDING: 1,
  VERIFIED: 2,
  REJECTED: 3,
  CANCELLED: 4,
};
export const BYPASS_STATUS = {
  REQUIRED: 0,
  BYPASS: 1,
};
export const OASIS_WALLET_STATUS = {
  UNVERIFIED: 0,
  VERIFIED: 1,
};

export const ADDRESS_OASIS_LENGTH = {
  MIN: 32,
  MAX: 47,
};

export const POOL_LABEL = {
  PUBLIC: 1,
  PRIVATE: 2,
  SEED: 3,
  COMMUNITY: 4,
};
export const POOL_LABEL_TEXT = {
  [POOL_LABEL.PUBLIC]: "Public",
  [POOL_LABEL.PRIVATE]: "Private",
  [POOL_LABEL.SEED]: "Seed",
  [POOL_LABEL.COMMUNITY]: "Community",
};

export const PRIVATE_SETTING = {
  NORMAL: 1,
  WHITELIST: 2,
};
export const PRIVATE_SETTING_TEXT = {
  [PRIVATE_SETTING.NORMAL]: "Normal users",
  [PRIVATE_SETTING.WHITELIST]: "Whitelist",
};

export const IDO_STATUS = {
  UPCOMING: 1,
  DEPOSIT: 2,
  PUBLIC_SELL: 3,
  CLAIMABLE: 4,
  ENDED: 5,
};
export const IDO_COUNTDOWN_STATUS = {
  AWAITING_APPLICATION: 1,
  APPLICATION: 2,
  AWAITING_DEPOSIT: 3,
  DEPOSIT: 4,
  AWAITING_PUBLIC_SELL: 5,
  PUBLIC_SELL: 6,
  AWAITING_CLAIM: 7,
  CLAIM: 8,
};

export const IDO_COUNTDOWN_STATUS_LABEL = {
  [IDO_COUNTDOWN_STATUS.AWAITING_APPLICATION]: "Application starts in",
  [IDO_COUNTDOWN_STATUS.APPLICATION]: "Application ends in",
  [IDO_COUNTDOWN_STATUS.AWAITING_DEPOSIT]: "Token deposit starts in",
  [IDO_COUNTDOWN_STATUS.DEPOSIT]: "Token deposit ends in",
  [IDO_COUNTDOWN_STATUS.AWAITING_PUBLIC_SELL]: "Public sell starts in",
  [IDO_COUNTDOWN_STATUS.PUBLIC_SELL]: "Public sell ends in",
  [IDO_COUNTDOWN_STATUS.AWAITING_CLAIM]: "Claim in",
  [IDO_COUNTDOWN_STATUS.CLAIM]: "",
};
export const IDO_POOL_DISPLAY = {
  DISPLAY: 1,
  HIDDEN: 0,
};
export const KYCBYPASS = {
  REQUIRED: 0,
  BYPASS: 1,
};

export const IDO_POOL_TYPES = {
  PUBLIC: 1,
  PRIVATE: 2,
  SEED: 3,
  COMMUNITY: 4,
};
export const IDO_POOL_TYPES_NAME = {
  [IDO_POOL_TYPES.PUBLIC]: "Public",
  [IDO_POOL_TYPES.PRIVATE]: "Private",
  [IDO_POOL_TYPES.SEED]: "Seed",
  [IDO_POOL_TYPES.COMMUNITY]: "Community",
};

export const PRIVATE_SETTINGS = {
  NORMAL: 1,
  WHITELIST: 2,
};
export const ACCEPTED_CURRENCIES = {
  ROSE: 1,
  USDT: 2,
  USDC: 3,
};
export const ACCEPTED_CURRENCIES_ICONS = {
  [ACCEPTED_CURRENCIES.ROSE]: images.TokenRose,
  [ACCEPTED_CURRENCIES.USDT]: images.TokenUSDT,
  [ACCEPTED_CURRENCIES.USDC]: images.TokenUSDC,
};
export const ACCEPTED_CURRENCIES_NAME = {
  [ACCEPTED_CURRENCIES.ROSE]: "ROSE",
  [ACCEPTED_CURRENCIES.USDT]: "USDT",
  [ACCEPTED_CURRENCIES.USDC]: "USDC",
};
export const SALES_TYPES = {
  EQUAL_SALE: 1,
  FIRST_COME_FIRST_SERVE: 2,
  PUBLIC_VALUATION_SALE: 3,
};

export const SALES_TYPE_TEXT = {
  [SALES_TYPES.EQUAL_SALE]: "Equal sale",
  [SALES_TYPES.FIRST_COME_FIRST_SERVE]: "First come first serve",
  [SALES_TYPES.PUBLIC_VALUATION_SALE]: "Public valuation sale",
};

export const FEE_TOKENS = {
  PROJECT_TOKEN: 1,
  IDO_TOKEN: 2,
};
export const STATUS_JOIN_POOL = {
  NOT_JOIN: 0,
  JOINED: 1,
  INVALID_DELEGATE_STAKING_AMOUNT: 2,
  INVALID_GRDN_TOKEN_AMOUNT: 3,
};
export const JOIN_IDO_POOL_STATUS = {
  NOT_QUALIFIED: 0,
  NOT_KYC: 1,
  FORBIDDEN_COUNTRY: 2,
  OUT_OF_APPLICATION_TIME: 3,
  USER_NOT_WHITELISTED: 4,
  INVALID_DELEGATE_STAKING_AMOUNT: 5,
  INVALID_GRDN_TOKEN_AMOUNT: 6,
  USER_DOES_NOT_HAVE_OASIS_WALLET: 7,
  QUALIFIED_TO_JOIN: 8,
  JOINED: 9,
  NOT_START_APPLICATION_TIME: 10,
};

export const STATUS_TEXT_tIME = {
  CAN_NOT_JOIN: "You can not join this pool. See you in the upcoming pools.",
  CAN_NOT_DEPOSIT: "You can not deposit. See you in the upcoming pools.",
  CAN_NOT_CLAIM: "You can not claim. See you in the upcoming pools.",
  CAN_NOT_CLAIM_TIME: "Claim time is coming soon.",
  CAN_CLAIM: "Pool is claimable.",
  POOL_ENDED: "Pool is ended. See you in the upcoming pools.",
};

export const CHECK_TEXT_TIME = {
  APPLICATION_START: "Application starts in",
  APPLICATION_END: "Application ends in",
  DEPOSIT_START: "Deposit starts in",
  DEPOSIT_END: "Deposit ends in",
  PUBLIC_SELL_START: "Public sell starts in",
  PUBLIC_SELL_END: "Public sell ends in",
  CAN_CLAIM: "You can claim in",
};

export const DECIMAL_ACCEPTED_CURRENCY = {
  [ACCEPTED_CURRENCIES.ROSE]: DECIMAL,
  [ACCEPTED_CURRENCIES.USDT]: DECIMAL_6,
  [ACCEPTED_CURRENCIES.USDC]: DECIMAL_6,
};
