import { Tooltip } from "antd";
import { ReactNode, useEffect } from "react";
import "./tooltip.scss";

interface ToolTipConnectProps {
  show: boolean;
  closeTooltip: Function;
  onRenderTitle?: () => ReactNode;
}

const ToolTipConnect: React.FC<ToolTipConnectProps> = ({
  children,
  show,
  closeTooltip,
  onRenderTitle,
}) => {
  useEffect(() => {
    if (show && closeTooltip) {
      setTimeout(() => {
        closeTooltip();
      }, 7000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Tooltip title={onRenderTitle} visible={show} placement="bottomRight">
      {children}
    </Tooltip>
  );
};

export default ToolTipConnect;
