import useValueFunc from "common/hooks/useValueFunc";
import { IDOType } from "../../../../../../../../common/types/IDOType";
import "./styles.scss";

interface PropsType {
  pool: Partial<IDOType>;
}

const PoolInfo = (props: PropsType) => {
  const { pool } = props;
  const { description } = useValueFunc({ pool });
  return (
    <div className="main-pool">
      <p className="content">{description}</p>
    </div>
  );
};

export default PoolInfo;
