import { message, Modal } from "antd";
import images from "../../../themes/images";
import { ALERT_TIME, NETWORK } from "../../constants";
import { MouseEvent } from "react";
import "./connectWallet.scss";
import { useSelector } from "react-redux";
import IconClose from "../iconClose";
import { useTranslation } from "react-i18next";
import ErrorSystem from "../../alert/errorSystem";
export const dec2hex = (number: string | number): string =>
  `0x${(+number).toString(16)}`;
interface ConnectNetworkProps {
  isNetworkPopUp: boolean;
  handleCancel: (e: MouseEvent<HTMLElement>) => void;
}

const ConnectNetwork: React.FC<ConnectNetworkProps> = (props) => {
  const isDarkMode = useSelector((state) => state.wallet.isDarkMode);
  const { t } = useTranslation();

  const changeNetwork = async () => {
    const windowObj = window as any;
    const { ethereum } = windowObj;

    try {
      await ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${NETWORK.CHAIN_ID_HEX}` }],
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await ethereum?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${NETWORK.CHAIN_ID_HEX}`,
                rpcUrls: [NETWORK.RPC_URLS],
                chainName: NETWORK.CHAIN_NAME,
                blockExplorerUrls: [NETWORK.BLOCK_EXPLORER_URLS],
                nativeCurrency: {
                  name: NETWORK.NATIVE_CURRENCY.NAME,
                  symbol: NETWORK.NATIVE_CURRENCY.SYMBOL,
                  decimals: Number(NETWORK.NATIVE_CURRENCY.DECIMAL),
                },
              },
            ],
          });
        } catch (addError) {
          return message.info({
            content: <ErrorSystem />,
            icon: <></>,
            duration: ALERT_TIME,
          });
        }
      } else {
        return message.info({
          content: <ErrorSystem />,
          icon: <></>,
          duration: ALERT_TIME,
        });
      }
    }
  };

  return (
    <Modal
      title=""
      visible={props.isNetworkPopUp}
      footer={null}
      className="ModalCustom"
      onCancel={props.handleCancel}
      closeIcon={<IconClose />}
      data-theme={isDarkMode ? "dark" : "light"}
    >
      <div className="ConnectWallet">
        <div className="ConnectWallet__Label">
          <div className="ConnectWallet__Label__Text">
            {t("PopUp.Wrongnetwork.Label")}
          </div>
        </div>
        <div className="ConnectWallet__Wallet" onClick={changeNetwork}>
          <img
            src={images.icons.ETHNetwork}
            alt={NETWORK.CHAIN_NAME}
            style={{ width: "30px", height: "30px", objectFit: "contain" }}
          />
          <div className="ConnectWallet__Wallet__Name">
            {NETWORK.CHAIN_NAME}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectNetwork;
