import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  IDODetailParticipants,
  IDOType,
} from "../../../../../../../common/types/IDOType";
import { UserService } from "../../../../../../../services/UserServicer";
import "../TabInforAndParticipants/poolInfomation/styles.scss";
import Participant, { ParticipantsUser } from "./participants";
import PoolInfo from "./poolInfomation";

interface PropsType {
  pool: Partial<IDOType>;
}

const PoolInfoAndParticipants = (props: PropsType) => {
  const { pool } = props;
  const { id } = useParams<any>();
  const UserServiceAPI = new UserService();
  const [inputWalletAddress, setInputWalletAddress] = useState<string>("");
  const [data, setData] = useState<ParticipantsUser[]>([]);

  useEffect(() => {
    const getListParticipantsDetail = async () => {
      try {
        const params: IDODetailParticipants = {
          id: Number(id),
          walletAddress: inputWalletAddress,
        };
        const result = await UserServiceAPI.getListParticipants(params);
        if (!result.data?.data)
          throw new Error("Get list participants IDO Detail failed");
        const data: ParticipantsUser[] = result.data?.data;
        setData(data);
      } catch (error: any) {
        console.log(error);
      }
    };
    getListParticipantsDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, inputWalletAddress]);

  return (
    <div className="tabs-mg-0">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Pool information" key="1">
          <PoolInfo pool={pool} />
        </Tabs.TabPane>
        {pool.is_display_participant && (
          <Tabs.TabPane tab={`${"Participants"} (${data.length})`} key="2">
            <Participant
              onSearch={setInputWalletAddress}
              data={data}
              value={inputWalletAddress}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};
export default PoolInfoAndParticipants;
