import BigNumber from "bignumber.js";
import { SALES_TYPES } from "common/constants";
import { convert } from "common/functions";
import { AcceptedCurrencyTypes } from "common/types/IDOType";
import { useEffect, useState } from "react";

interface PropsType {
  value: string;
  maxDeposit?: string;
  fromWei?: any;
  acceptedCurrency?: AcceptedCurrencyTypes;
  balance?: number;
  balanceUSDC?: string;
  balanceUSDT?: string;
  saleTypes?: number;
  totalSoldCoin?: string;
  approximateAmount?: string;
  status?: number;
  acceptableAmount?: string;
  remainingTokenFromWei?: any;
  totalMaxDeposit?: any;
  totalRemaining?: string;
  totalSoldCoinDeposit?:string
}

const useValidateInput = ({
  value,
  maxDeposit,
  fromWei,
  acceptedCurrency,
  balance,
  balanceUSDC,
  balanceUSDT,
  saleTypes,
  totalSoldCoin,
  approximateAmount,
  status,
  acceptableAmount,
  remainingTokenFromWei,
  totalMaxDeposit,
  totalRemaining,
  totalSoldCoinDeposit
}: PropsType) => {
  const [checkValidate, setCheckValidate] = useState({
    deposit: false,
    checkBalance: false,
    checkBalanceUSDC: false,
    checkBalanceUSDT: false,
    checkValueZero: false,
    checkTotalSoldcoins: false,
    checkAcceptableAmount: false,
    checkRemainingToken: false,
  });

  useEffect(() => {
    if (value && (Number(value) === 0 || value === "")) {
      setCheckValidate({ ...checkValidate, checkValueZero: true });
      return;
    }
    if (
      acceptedCurrency === 1 &&
      new BigNumber(value).isGreaterThan(convert(balance))
    ) {
      setCheckValidate({ ...checkValidate, checkBalance: true });
      return;
    }
    if (
      acceptedCurrency === 3 &&
      new BigNumber(value).isGreaterThan(convert(balanceUSDC))
    ) {
      setCheckValidate({ ...checkValidate, checkBalanceUSDC: true });
      return;
    }
    if (
      acceptedCurrency === 2 &&
      new BigNumber(value).isGreaterThan(convert(balanceUSDT))
    ) {
      setCheckValidate({ ...checkValidate, checkBalanceUSDT: true });
      return;
    }
    if (
      remainingTokenFromWei &&
      status === 3 &&
      new BigNumber(totalRemaining || "0").isGreaterThan(
        convert(remainingTokenFromWei),
      )
    ) {
      setCheckValidate({ ...checkValidate, checkRemainingToken: true });
      return;
    }
    if (
      maxDeposit &&
      new BigNumber(totalMaxDeposit).isGreaterThan(maxDeposit) &&
      saleTypes === SALES_TYPES.EQUAL_SALE &&
      status &&
      status >= 2
    ) {
      setCheckValidate({ ...checkValidate, deposit: true });
      return;
    }
    if (
      acceptableAmount &&
      new BigNumber(value).isGreaterThan(acceptableAmount) &&
      saleTypes === SALES_TYPES.FIRST_COME_FIRST_SERVE &&
      status === 2
    ) {
      setCheckValidate({ ...checkValidate, checkAcceptableAmount: true });
      return;
    }
    if (
      totalSoldCoin &&
      status &&
      status >= 2 &&
      new BigNumber(totalSoldCoinDeposit || "0").isGreaterThan(totalSoldCoin || "0")
    ) {
      setCheckValidate({ ...checkValidate, checkTotalSoldcoins: true });
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    checkValidate.checkBalance,
    checkValidate.checkBalanceUSDC,
    checkValidate.checkBalanceUSDT,
    checkValidate.deposit,
    checkValidate.checkValueZero,
    checkValidate.checkTotalSoldcoins,
    checkValidate.checkAcceptableAmount,
    checkValidate.checkRemainingToken,
    totalSoldCoin,
    balance,
    balanceUSDT,
    balanceUSDC,
    acceptedCurrency,
    maxDeposit,
    saleTypes,
    approximateAmount,
    status,
    acceptableAmount,
    remainingTokenFromWei,
  ]);
  const handleCloseModal = () => {
    setCheckValidate({ ...checkValidate, checkBalance: false });
    setCheckValidate({ ...checkValidate, deposit: false });
    setCheckValidate({ ...checkValidate, checkBalanceUSDT: false });
    setCheckValidate({ ...checkValidate, checkBalanceUSDC: false });
    setCheckValidate({ ...checkValidate, checkTotalSoldcoins: false });
    setCheckValidate({ ...checkValidate, checkAcceptableAmount: false });
    setCheckValidate({ ...checkValidate, checkValueZero: false });

    setCheckValidate({ ...checkValidate, checkRemainingToken: false });
  };

  const {
    checkBalance,
    checkBalanceUSDT,
    deposit,
    checkBalanceUSDC,
    checkValueZero,
    checkTotalSoldcoins,
    checkAcceptableAmount,
    checkRemainingToken,
  } = checkValidate;
  return {
    checkBalance,
    checkBalanceUSDT,
    deposit,
    checkBalanceUSDC,
    checkValueZero,
    checkTotalSoldcoins,
    checkAcceptableAmount,
    setCheckValidate,
    checkValidate,
    handleCloseModal,
    checkRemainingToken,
  };
};

export default useValidateInput;
